<spinner *ngIf="loading"></spinner>
<div class="text-center create-project">
	<div class="row">
		<div class="col-12">
			<h3 class="mb-5 title"> {{ id != null ? 'Edit Project' : 'Create New Project' }}</h3>
			<!-- <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut aliquip ex ea commodo consequat.</p> -->
		</div>
		<div class="col-md-6 mx-auto">
			<form [formGroup]="userProjectForm">

				<div class="row">
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{'TITLE.NAME' | translate}}</mat-label>
							<input matInput type="text" id="name" formControlName="name" autocomplete="off" />
						</mat-form-field>
					</div>
					<!-- <div class="col-md-6"> -->
					<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'FIELDS.START_DATE' | translate}}</mat-label>
                            <input matInput formControlName="startDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field> -->
					<!-- </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>First Year</mat-label>
                            <select matNativeControl required id="firstYear" formControlName="firstYear">
                                <option>Select Year</option>
                                <option *ngFor="let year of startYears" [value]="year">{{year}}</option>
                            </select>
                           
                        </mat-form-field> -->

					<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'FIELDS.FIRST_YEAR' | translate}}</mat-label>
                            <mat-select  matNativeControl required id="firstYear" formControlName="firstYear">
                              <mat-option>{{'FIELDS.SELECT_YEAR' | translate}}</mat-option>
                              <mat-option *ngFor="let year of startYears" [value]="year">{{year}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
					<!-- <div class="col-md-6"> -->
					<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Input Currency</mat-label>
                            <select matNativeControl required id="inputCurrency" formControlName="inputCurrency">
                                <option>Select</option>
                                <option *ngFor="let currency of currencies" [value]="currency.code">
                                    {{currency.name}} ({{currency.code}})</option>
                            </select>
                        </mat-form-field> -->
					<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'FIELDS.INPUT_CURRENCY' | translate}}</mat-label>
                            <mat-select  matNativeControl required id="inputCurrency" formControlName="inputCurrency">
                              <mat-option>{{'FIELDS.SELECT' | translate}}</mat-option>
                              <mat-option  *ngFor="let currency of currencies" [value]="currency.code"> {{currency.name}} ({{currency.code}})</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6"> -->
					<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Output Currency</mat-label>
                            <select id="outputCurrency" formControlName="outputCurrency" matNativeControl required>
                                <option>Select</option>
                                <option *ngFor="let currency of currencies" [value]="currency.code">
                                    {{currency.name}} ({{currency.code}})</option>
                            </select>
                        </mat-form-field> -->

					<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'FIELDS.OUTPUT_CURRENCY' | translate}}</mat-label>
                            <mat-select  id="outputCurrency" formControlName="outputCurrency" matNativeControl required>
                              <mat-option>{{'FIELDS.SELECT' | translate}}</mat-option>
                              <mat-option  *ngFor="let currency of currencies" [value]="currency.code">  {{currency.name}} ({{currency.code}})</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
					<div class="col-md-6">
						<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Industry Id</mat-label>
                            <select id="outputCurrency" formControlName="industryId" matNativeControl required>
                                <option>Select</option>
                                <option *ngFor="let industry of industries" [value]="industry.id">{{industry.name}}
                                </option>
                            </select>
                        </mat-form-field> -->
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{'FIELDS.INDUSTRY_ID' | translate}}</mat-label>
							<mat-select id="outputCurrency" formControlName="industryId" matNativeControl required>
								<mat-option>{{'FIELDS.SELECT' | translate}}</mat-option>
								<mat-option *ngFor="let industry of industries" [value]="industry.id">{{industry.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-6">

						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{'FIELDS.SELECT_LANGUAGE' | translate}}</mat-label>
							<mat-select id="inputLanguage" formControlName="inputLanguage" matNativeControl required>
								<mat-option>{{'FIELDS.SELECT' | translate}}</mat-option>
								<mat-option [value]="0">English</mat-option>
								<mat-option [value]="1">العربية</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-accordion class="example-headers-align" multi>
							<mat-expansion-panel hideToggle>
								<mat-expansion-panel-header>
									<mat-panel-title>
										Select Plans
									</mat-panel-title>
								</mat-expansion-panel-header>
								<div class="row" *ngFor="let plan of subscribedPlansList">
									<div class="col"> {{plan?.planName}}
										<mat-checkbox *ngIf="plan?.totalCount > 0" [(ngModel)]="plan.isSelected"
											[ngModelOptions]="{standalone: true}" [disabled]="plan.isAlreadySelected"
											class="example-margin">
										</mat-checkbox>
										<p *ngIf="plan?.totalCount == 0"> License Not Available. </p>
										<button *ngIf="plan?.isAlreadySelected" type="submit"
											id="kt_modal_new_card_submit" class="btn btn-primary mx-1">
											<span class="indicator-label">De-Activate</span>
										</button>
										<button *ngIf="showBuyButton" type="submit" id="kt_modal_new_card_submit"
											class="btn btn-primary mx-1" (click)="onBuyPlanClick(plan)">
											<span class="indicator-label">Subscribe</span>
										</button>
									</div>
								</div>
							</mat-expansion-panel>
						</mat-accordion>
						<mat-error *ngIf="selectionCount == 0 && isSaveClicked">
							<strong>Select atleast 1 plan</strong>
						</mat-error>
					</div>

					<div class="col-md-12">
						<!-- <mat-form-field appearance="outline" class="w-100">
                            <mat-label>User ID</mat-label>
                            <input matInput type="text"  id="userId" formControlName="userId"
                                autocomplete="off" />
                        </mat-form-field> -->
						<div class="text-center pt-15">
							<button id="kt_modal_new_card_cancel" class="btn btn-light mx-1"
								mat-dialog-close>{{'FIELDS.CANCEL' |
								translate}}</button>
							<button type="submit" id="kt_modal_new_card_submit" (click)="save()"
								class="btn btn-primary mx-1" [disabled]="showBuyButton">
								<span class="indicator-label">{{ id != null ? 'Update' : 'Save'}}</span>
								<span class="indicator-progress">{{'FIELDS.PLEASE_WAIT' | translate}}...
									<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>
							<!-- <button (click)="selectIndustry()" class="btn btn-light mx-1">Select Plan</button> -->
						</div>
					</div>

				</div>
			</form>
		</div>
	</div>
	<div class="close-alert-icons">
		<!-- 
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->

		<div><span class="material-icons" mat-dialog-close>keyboard_arrow_left</span></div>
		<div><span class="material-icons" mat-dialog-close>close</span></div>
	</div>


	<!-- <div class="abstract-design bottom right"><img src="../../../assets/media/bg/abstract-design.png" /></div>
    <div  class="abstract-design top right"><img src="../../../assets/media/bg/polygon-borders.png" /></div>
    <div  class="abstract-design left top"><img src="../../../assets/media/bg/polygon-borders-left.png" /></div> -->


</div>
<kt-buy-plan-popup *ngIf="showPlanPopup" [selectedPlanDetails]="selectedPlanDetails"
	[allPlansList]="allPlansList" (onPlanPopupClose)="onPlanPopupClose($event)">
</kt-buy-plan-popup>