/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tooltip.component";
var styles_TooltipContentComponent = [i0.styles];
var RenderType_TooltipContentComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TooltipContentComponent, data: {} });
export { RenderType_TooltipContentComponent as RenderType_TooltipContentComponent };
function View_TooltipContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TooltipContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TooltipContentComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { model: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.context); var currVal_1 = _co.template; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TooltipContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
export function View_TooltipContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { caretElm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["caretElm", 1]], null, 0, "span", [], [[8, "hidden", 0], [8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "tooltip-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipContentComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipContentComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.title; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.title; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showCaret; var currVal_1 = i1.ɵinlineInterpolate(1, "tooltip-caret position-", _co.placement, ""); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_TooltipContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-tooltip-content", [], [[8, "className", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TooltipContentComponent_0, RenderType_TooltipContentComponent)), i1.ɵdid(1, 4243456, null, 0, i3.TooltipContentComponent, [i1.ElementRef, i1.Renderer2], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).cssClasses; _ck(_v, 0, 0, currVal_0); }); }
var TooltipContentComponentNgFactory = i1.ɵccf("ngx-tooltip-content", i3.TooltipContentComponent, View_TooltipContentComponent_Host_0, { host: "host", showCaret: "showCaret", type: "type", placement: "placement", alignment: "alignment", spacing: "spacing", cssClass: "cssClass", title: "title", template: "template", context: "context" }, {}, []);
export { TooltipContentComponentNgFactory as TooltipContentComponentNgFactory };
