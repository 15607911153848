import * as tslib_1 from "tslib";
// Actions
import { AuthActionTypes } from '../_actions/auth.actions';
export var initialAuthState = {
    language: 'en',
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    isUserLoaded: false,
    userInfo: {}
};
export function authReducer(state, action) {
    if (state === void 0) { state = initialAuthState; }
    switch (action.type) {
        case AuthActionTypes.Login: {
            var _token = action.payload.authToken;
            return {
                language: 'en',
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false
            };
        }
        case AuthActionTypes.Register: {
            var _token = action.payload.authToken;
            return {
                language: 'en',
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false
            };
        }
        case AuthActionTypes.Logout:
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userId');
            return initialAuthState;
        case AuthActionTypes.UserLoaded: {
            var _user = action.payload.user;
            return tslib_1.__assign({}, state, { user: _user, isUserLoaded: true });
        }
        case AuthActionTypes.CurrentUser: {
            if (action.payload && action.payload.userInfo) {
                var _user = JSON.parse(action.payload.userInfo);
                return tslib_1.__assign({}, state, { userInfo: _user });
            }
            else {
                return state;
            }
        }
        case AuthActionTypes.SetLanguage: {
            return tslib_1.__assign({}, state, { language: action.payload.language });
        }
        default:
            return state;
    }
}
