import * as tslib_1 from "tslib";
import { forkJoin } from 'rxjs';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { ProductsService } from '../_services/';
// Actions
import { ProductActionTypes, ProductsPageLoaded, ProductsPageToggleLoading, ProductCreated } from '../_actions/product.actions';
import { of } from 'rxjs';
var ProductEffects = /** @class */ (function () {
    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new ProductsPageRequested({ page: queryParams }));
    // });
    function ProductEffects(actions$, productsService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.productsService = productsService;
        this.store = store;
        this.showPageLoadingDistpatcher = new ProductsPageToggleLoading({ isLoading: true });
        this.showLoadingDistpatcher = new ProductsPageToggleLoading({ isLoading: true });
        this.hideActionLoadingDistpatcher = new ProductsPageToggleLoading({ isLoading: false });
        this.loadProductsPage$ = this.actions$
            .pipe(ofType(ProductActionTypes.ProductsPageRequested), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showPageLoadingDistpatcher);
            var requestToServer = _this.productsService.findProducts(payload.page);
            var lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }), map(function (response) {
            var result = response[0];
            var lastQuery = response[1];
            return new ProductsPageLoaded({
                products: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
        }));
        this.deleteProduct$ = this.actions$
            .pipe(ofType(ProductActionTypes.OneProductDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showLoadingDistpatcher);
            return _this.productsService.deleteProduct(payload.id);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.deleteProducts$ = this.actions$
            .pipe(ofType(ProductActionTypes.ManyProductsDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showLoadingDistpatcher);
            return _this.productsService.deleteProducts(payload.ids);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.updateProductsStatus$ = this.actions$
            .pipe(ofType(ProductActionTypes.ProductsStatusUpdated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showLoadingDistpatcher);
            return _this.productsService.updateStatusForProduct(payload.products, payload.status);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.updateProduct$ = this.actions$
            .pipe(ofType(ProductActionTypes.ProductUpdated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showLoadingDistpatcher);
            return _this.productsService.updateProduct(payload.product);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.createProduct$ = this.actions$
            .pipe(ofType(ProductActionTypes.ProductOnServerCreated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showLoadingDistpatcher);
            return _this.productsService.createProduct(payload.product).pipe(tap(function (res) {
                _this.store.dispatch(new ProductCreated({ product: res }));
            }));
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductEffects.prototype, "loadProductsPage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductEffects.prototype, "deleteProduct$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductEffects.prototype, "deleteProducts$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductEffects.prototype, "updateProductsStatus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductEffects.prototype, "updateProduct$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductEffects.prototype, "createProduct$", void 0);
    return ProductEffects;
}());
export { ProductEffects };
