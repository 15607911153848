// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService } from '../../core/auth';
import Swal from "sweetalert2";

@Component({
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	today = new Date();
	// Public params
	forgotPasswordForm: FormGroup;
	resetPasswordForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {

		this.resetPasswordForm = this.fb.group({
				userId: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) 
			]) ],
			password: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) 
			]) ],

			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
			]) ],
			code: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(6),
				Validators.maxLength(320) 
			]) ]
		});

		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) 
			])
			]
		});
	}

	/**
	 * Form Submit
	 */

	 
	
	showResetForm : boolean;
	submit() {
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const emailObj = {email : controls.email.value} ;
		this.authService.forgetPassword(emailObj).subscribe((response: any) => {
		  this.loading = false;
		  if(response.result){
			  this.showResetForm = true;

		  }else{

		  }
			// console.log(response.result);
		  // this.isLoading = false;
		  // this.bookingData = response.result.bookings;
		  // this.totalCount = response.result.totalCount;
		  // let oldPageNumber = this.currentSearchPageNumber;
		  // this.totalCountForCurrentSearch = response.result.totalCount;
		  // try { this.changeDetector.detectChanges(); } catch (e) { }
		}, error => {
		  this.loading = false;
			console.log(error);
	
			Swal.fire({
			  title : (error.error) ?error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  icon: 'warning',
			  showCancelButton: false,
			  confirmButtonColor: '#37c0b9',
			  confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
		// this.authService.requestPassword(email).pipe(
		// 	tap(response => {
		// 		if (response) {
		// 			'Reset Password link sent to email'
		// 			// this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
		// 			// this.router.navigateByUrl('/auth');
		// 		} else {
		// 			this.authNoticeService.setNotice(this.translate.instant('VALIDATION.NOT_FOUND', {name: this.translate.instant('FIELDS.EMAIL')}), 'danger');
		// 		}
		// 	}),
		// 	takeUntil(this.unsubscribe),
		// 	finalize(() => {
		// 		this.loading = false;
		// 		this.cdr.markForCheck();
		// 	})
		// ).subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
