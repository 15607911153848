import * as tslib_1 from "tslib";
// RxJS
import { debounceTime } from 'rxjs/operators';
// NGRX
import { select } from '@ngrx/store';
// CRUD
import { BaseDataSource } from '../../_base/crud';
import { selectProductSpecificationsInStore, selectProductSpecificationsPageLoading, selectPSShowInitWaitingMessage } from '../_selectors/product-specification.selectors';
var ProductSpecificationsDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(ProductSpecificationsDataSource, _super);
    function ProductSpecificationsDataSource(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.store.pipe(select(selectProductSpecificationsInStore), debounceTime(600)).subscribe(function (response) {
            _this.entitySubject.next(response.items);
            _this.paginatorTotalSubject.next(response.totalCount);
        });
        _this.isPreloadTextViewed$ = _this.store.pipe(select(selectPSShowInitWaitingMessage));
        _this.loading$ = _this.store.pipe(select(selectProductSpecificationsPageLoading));
        return _this;
    }
    return ProductSpecificationsDataSource;
}(BaseDataSource));
export { ProductSpecificationsDataSource };
