import * as tslib_1 from "tslib";
import { FieldArrayType } from '@ngx-formly/core';
var ClauseInputsControlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ClauseInputsControlComponent, _super);
    function ClauseInputsControlComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ClauseInputsControlComponent;
}(FieldArrayType));
export { ClauseInputsControlComponent };
