/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-linear-gradient.component.ngfactory";
import * as i2 from "./svg-linear-gradient.component";
import * as i3 from "@angular/common";
import * as i4 from "./circle.component.ngfactory";
import * as i5 from "./tooltip/tooltip.directive";
import * as i6 from "./tooltip/tooltip.service";
import * as i7 from "./circle.component";
import * as i8 from "./circle-series.component";
var styles_CircleSeriesComponent = [];
var RenderType_CircleSeriesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CircleSeriesComponent, data: { "animation": [{ type: 7, name: "animationState", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: 250 }], options: null }], options: {} }] } });
export { RenderType_CircleSeriesComponent as RenderType_CircleSeriesComponent };
function View_CircleSeriesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:rect", [["class", "tooltip-bar"]], [[24, "@animationState", 0], [1, "x", 0], [1, "y", 0], [1, "width", 0], [1, "height", 0], [1, "fill", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = "active"; var currVal_1 = (_co.circle.cx - _co.circle.radius); var currVal_2 = _co.circle.cy; var currVal_3 = (_co.circle.radius * 2); var currVal_4 = _co.circle.height; var currVal_5 = _co.gradientFill; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_CircleSeriesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, ":svg:g", [["ngx-charts-svg-linear-gradient", ""], ["orientation", "vertical"]], null, null, null, i1.View_SvgLinearGradientComponent_0, i1.RenderType_SvgLinearGradientComponent)), i0.ɵdid(3, 573440, null, 0, i2.SvgLinearGradientComponent, [], { orientation: [0, "orientation"], name: [1, "name"], stops: [2, "stops"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CircleSeriesComponent_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 16777216, null, null, 3, ":svg:g", [["class", "circle"], ["ngx-charts-circle", ""], ["ngx-tooltip", ""]], [[2, "active", null]], [[null, "select"], [null, "activate"], [null, "deactivate"], [null, "focusin"], [null, "blur"], [null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focusin" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onBlur() !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).onMouseEnter() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7).onMouseLeave($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 7).onMouseClick() !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (i0.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_5 && ad);
    } if (("mouseenter" === en)) {
        var pd_6 = (i0.ɵnov(_v, 8).onMouseEnter() !== false);
        ad = (pd_6 && ad);
    } if (("mouseleave" === en)) {
        var pd_7 = (i0.ɵnov(_v, 8).onMouseLeave() !== false);
        ad = (pd_7 && ad);
    } if (("select" === en)) {
        var pd_8 = (_co.onClick(_co.circle.data) !== false);
        ad = (pd_8 && ad);
    } if (("activate" === en)) {
        var pd_9 = (_co.activateCircle() !== false);
        ad = (pd_9 && ad);
    } if (("deactivate" === en)) {
        var pd_10 = (_co.deactivateCircle() !== false);
        ad = (pd_10 && ad);
    } return ad; }, i4.View_CircleComponent_0, i4.RenderType_CircleComponent)), i0.ɵdid(7, 147456, null, 0, i5.TooltipDirective, [i6.TooltipService, i0.ViewContainerRef, i0.Renderer2], { tooltipTitle: [0, "tooltipTitle"], tooltipDisabled: [1, "tooltipDisabled"], tooltipPlacement: [2, "tooltipPlacement"], tooltipType: [3, "tooltipType"], tooltipTemplate: [4, "tooltipTemplate"], tooltipContext: [5, "tooltipContext"] }, null), i0.ɵdid(8, 573440, null, 0, i7.CircleComponent, [], { cx: [0, "cx"], cy: [1, "cy"], r: [2, "r"], fill: [3, "fill"], data: [4, "data"], classNames: [5, "classNames"], pointerEvents: [6, "pointerEvents"] }, { select: "select", activate: "activate", deactivate: "deactivate" }), i0.ɵpod(9, { name: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "vertical"; var currVal_1 = _co.gradientId; var currVal_2 = _co.circle.gradientStops; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.barVisible && (_co.type === "standard")); _ck(_v, 5, 0, currVal_3); var currVal_5 = (_co.tooltipTemplate ? undefined : _co.getTooltipText(_co.circle)); var currVal_6 = _co.tooltipDisabled; var currVal_7 = "top"; var currVal_8 = "tooltip"; var currVal_9 = _co.tooltipTemplate; var currVal_10 = _co.circle.data; _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.circle.cx; var currVal_12 = _co.circle.cy; var currVal_13 = _co.circle.radius; var currVal_14 = _co.circle.color; var currVal_15 = _co.circle.value; var currVal_16 = _co.circle.classNames; var currVal_17 = ((_co.circle.value === 0) ? "none" : "all"); _ck(_v, 8, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isActive(_ck(_v, 9, 0, _co.circle.seriesName)); _ck(_v, 6, 0, currVal_4); }); }
export function View_CircleSeriesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CircleSeriesComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.circle; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CircleSeriesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-circle-series", ""]], null, null, null, View_CircleSeriesComponent_0, RenderType_CircleSeriesComponent)), i0.ɵdid(1, 638976, null, 0, i8.CircleSeriesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CircleSeriesComponentNgFactory = i0.ɵccf("g[ngx-charts-circle-series]", i8.CircleSeriesComponent, View_CircleSeriesComponent_Host_0, { data: "data", type: "type", xScale: "xScale", yScale: "yScale", colors: "colors", scaleType: "scaleType", visibleValue: "visibleValue", activeEntries: "activeEntries", tooltipDisabled: "tooltipDisabled", tooltipTemplate: "tooltipTemplate" }, { select: "select", activate: "activate", deactivate: "deactivate" }, []);
export { CircleSeriesComponentNgFactory as CircleSeriesComponentNgFactory };
