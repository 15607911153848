<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
		<!--begin::Aside-->
		<div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside position-relative" style="background:#fafafa  url(./assets/media/bg/login-bg-5.png) no-repeat center;">
			<div class="kt-grid__item">
				<a href="javascript:;" class="kt-login__logo">
					<!-- <img src="./assets/media/logos/logo-4.png" alt=""> -->
					<!-- JADWA -->
				</a>
			</div>
			<!-- <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
				<div class="kt-grid__item kt-grid__item--middle">
					<h3 class="kt-login__title">مرحبا بكم في جدوى</h3>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة التسويقية</h4>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة المالية</h4>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة الإستراتيجية</h4>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة الفنية</h4>
					
				</div>
			</div> -->
			<div class="kt-grid__item">
				<div class="kt-login__info">
					<div class="kt-login__copyright">
						© {{today|date:'yyyy'}} جدوى
					</div>
                    <div class="kt-login__menu">
                        <a href="javascript:;" class="kt-link">{{'FIELDS.PRIVACY' | translate}}</a>
						<a href="javascript:;" class="kt-link">{{'FIELDS.LEGAL' | translate}}</a>
						<a href="javascript:;" class="kt-link">{{'FIELDS.CONTACT' | translate}}</a>
                    </div>
				</div>
			</div> 
            <div class="register-title">{{'TITLE.REGISTERTITLE' | translate}}<br />{{'TITLE.REGISTERTITLE2' | translate}}</div>
		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
			<!-- <router-outlet></router-outlet> -->

            <div class="language">
                <a href="">English</a>
                <span class="mx-2">|</span>
                <a href="">Arabic</a>
            </div>

            <!--begin::Body-->
            <div class="kt-login__body">
                <!--begin::Signin-->
                <div class="kt-login__form" >
                    <div class="kt-login__title">
                        <img src="../../../assets/logo.png" class="img-fluid login-logo" />
                        <h3>{{ 'TITLE.FORGOT_ACCOUNT_BUTTON' | translate }}</h3>
                    </div>

                    <!-- <kt-auth-notice></kt-auth-notice> -->

                    <form class="kt-form" [formGroup]="forgotPasswordForm" autocomplete="off" *ngIf="!showConfirmationForm">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'FIELDS.EMAIL' | translate }}</mat-label>
                                <input matInput type="email" placeholder="{{ 'FIELDS.EMAIL' | translate }}" formControlName="email" autocomplete="off"/>
                                <mat-icon matSuffix>email</mat-icon>
                                <mat-error *ngIf="isControlHasError('email','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','email')">
                                    <strong>{{ 'VALIDATION.INVALID_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','minLength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','maxLength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--begin::Action-->
                        <div class="kt-login__actions">
                            <button routerLink="/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'TITLE.BACK_BUTTON' | translate }}</button>
                            <button (click)="submit()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'TITLE.SUBMIT_BUTTON' | translate }}</button>
                        </div>
                        <!--end::Action-->
                    </form>

                    <form class="kt-login__form kt-form" [formGroup]="registerConfirmForm" *ngIf="!!showConfirmationForm">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>{{ 'FIELDS.CODE' | translate }}</mat-label>
                                <input matInput type="password" placeholder="{{ 'FIELDS.CODE' | translate }}" formControlName="code" autocomplete="off"/>
                                <mat-error *ngIf="isConfirmControlHasError('code','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <!-- <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                                <mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
                                    <strong>Passsword and ConfirmPassword didn't match.</strong>
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!--begin::Action-->
                        <div class="kt-login__actions">
                            <a  class="kt-link kt-login__link-forgot" (click)="resendEmail()">
                                <!-- href="javascript:;" routerLink="/forgot-password" {{ 'FIELDS.FORGOT_BUTTON' | translate }} -->
                                Resend Email 
                            </a>
                            <!-- <button routerLink="/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'FIELDS.BACK_BUTTON' | translate }}</button> -->
                            <button (click)="confirmSubmit()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'FIELDS.SUBMIT_BUTTON' | translate }}</button>
                        </div>
                    </form>

					<div  >
						<!-- {{'TITLE.VERIFY_EMAIL_LINK_SENT' | translate}} -->
					</div>
					
                </div>

			</div>

		</div>
		<!--end::Content-->
	</div>
</div>
