import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { JadwaService } from '../core/auth/_services/jadwa.service';

@Component({
  selector: 'kt-input-controls',
  templateUrl: './input-controls.component.html',
  styleUrls: ['./input-controls.component.scss'],
  // changeDetection : ChangeDetectionStrategy.OnPush
})
export class InputControlsComponent implements OnInit {

  id: string;
  currencies1 = [];
  currencies : any = [
    { id: '1', name: 'Soccer' },
    { id: '2', name: 'Basketball' },
  ];
  constructor(
    private jadwaService: JadwaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private changeDetection : ChangeDetectorRef
    ) {



    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id'];

    });
    
    this.getCurrencies();
    

    
  }

  ngOnInit() {
    
  }

  // currencies1 = [
  //   { id: '1', name: 'Soccer' },
  //   { id: '2', name: 'Basketball' },
  // ];;
  getCurrencies(){
    this.jadwaService.getCurrencies().subscribe((response: any) => {
		  this.loading = false;
      
      
      this.currencies1 = response.result;
      this.changeDetection.detectChanges();
      // let currenciesArray = [];
      // let currenciesObj = response.result;
      // currenciesObj.forEach(objRef => {
      //   currenciesArray.push({id : objRef.id ,  name :objRef.name});
      // });
      // this.currencies1 = currenciesArray;
      
		}, error => {
		  this.loading = false;
			Swal.fire({
			  title : (error.error) ?error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  icon: 'warning',
			  showCancelButton: false,
			  confirmButtonColor: '#37c0b9',
			  confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
  }


  industries : any;
  getIndustries(){

    this.jadwaService.getIndustries().subscribe((response: any) => {
		  this.loading = false;
      this.industries = response.result;
		}, error => {
		  this.loading = false;
			Swal.fire({
			  title : (error.error) ?error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  icon: 'warning',
			  showCancelButton: false,
			  confirmButtonColor: '#37c0b9',
			  confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
  }

  documents : any;
  getDocuments(){
    this.jadwaService.getDocuments().subscribe((response: any) => {
		  this.loading = false;
      this.documents = response.result;
		}, error => {
		  this.loading = false;
			Swal.fire({
			  title : (error.error) ?error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  icon: 'warning',
			  showCancelButton: false,
			  confirmButtonColor: '#37c0b9',
			  confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
  }

  loading: any;
  planDetails: any;

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [{
    type: 'tabs',
    fieldGroup: [
      {
        key : 'customer-segment', //xtz
        expressionProperties: {
          'templateOptions.label': this.translate.stream('TITLE.CUSTOMER_SEGMENT'),
        },
        templateOptions: {          
          // label: 'CUSTOMER SEGMENTS',
          key : uuidv4().replaceAll('-', '_'), //xtz
          templateMetaData: `<div class="left-block">
          <div class="tabs mb-2">
              <div>Project plan</div>
              <div class="mx-2">></div>
              <div>Plan title</div>
          </div>
          <h3>1. Form title</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </p>
          <div class="row m-0">
            <div class="col-md-12">
              <h4>Quick Links</h4>
              <ul>
                <li><a href="">Test 1</a></li>
                <li><a href="">Test 2</a></li>
                <li><a href="">Test 3</a></li>
                <li><a href="">Test 4</a></li>
              </ul>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <img src="https://i.picsum.photos/id/122/536/354.jpg?hmac=LN0BaIaTS5AbEptgW0-6CmgtjICcjJlfcTpuZ9PW1RM" class="img-fluid" />
            </div>
            <div class="col-md-12 p-0 mt-3">
              <h4>Lorem Picsum</h4>
              <h6>Lorem Picsum</h6>
            </div>
          </div>
          </div>`
        },

        // fieldArray: {
        fieldGroup: [

          {
            key: 'Input',
            type: 'input',
            expressionProperties: {
              'templateOptions.label': this.translate.stream('FIELDS.EMAIL'),
              'templateOptions.placeholder': this.translate.stream('FIELDS.EMAIL'),
              'templateOptions.description': this.translate.stream('FIELDS.EMAIL'),
            },
            templateOptions: {
              label: 'Input',
              placeholder: 'Placeholder',
              description: 'Description',
              required: true,
            },
          },
          {
            key: 'text-inputs',
            type : 'text-input',
            wrappers: ['panel'],
            templateOptions: { label: 'Simple Text Sentence' , addText: 'Add More',},
            fieldArray: {
            fieldGroup: [
              // {
              //   key: 'text',
              //   type: 'editor',
              //   templateOptions: {
              //     label: 'Text',
              //   },
              // },
              {
                key: uuidv4().replaceAll('-', '_'),
                type: 'editor',
                templateOptions: {
                  label: 'Editor',
  
                  placeholder: ' What do you want your project to achieve?',
                },
              }
          
          ],
          }
          },

          {
            key: 'bullet-inputs',
            type : 'bullet-input',
            wrappers: ['panel'],
            templateOptions: { label: 'Only Bullet Sentences' , addText: 'Add More',},
            fieldArray: {
            fieldGroup: [
              {
              key: 'town',
              type: 'input',
              templateOptions: {
                // required: true,
                type: 'text',
                label: 'Enter Point',
              },
            },
            
          ],
          }
          },


          {
            key: 'text-with-bullet-inputs',
            type : 'text-with-bullets',
            wrappers: ['panel'],
            templateOptions: { label: 'Title Text Sentence with bullet Sentences' , addText: 'Add Text with Bullet Input',},
            
            fieldArray: {
            fieldGroup: [
              {
                key: 'town',
                type: 'input',
                templateOptions: {
                  // required: true,
                  type: 'text',
                  label: 'Enter Text',
                },
              },
             
              {
                key: 'bullet-inputs',
                type : 'bullet-input',
                wrappers: ['panel'],
                templateOptions: { label: 'Only Bullet Sentences' , addText: 'Add Bullet',},
                fieldArray: {
                fieldGroup: [
                  {
                  key: uuidv4().replaceAll('-', '_'),
                  type: 'input',
                  templateOptions: {
                    // required: true,
                    type: 'text',
                    label: 'Enter Point',
                  },
                },
                
              ],
              }
              },
              ],
          }
          },



          {
           
            
            key: 'clause-inputs-test',
            // type : 'clause-inputs',
            wrappers: ['panel'],
            templateOptions: { label: '3 Col Inputs with Default inputs' , addText: 'Add Text Input',},
            // fieldArray: {
            // fieldGroupClassName: 'display-flex',
            fieldGroup: [

             


              {
           
                key: 'clause-inputs-test',
                // wrappers: ['panel'],
                // templateOptions: { label: '3 Col Inputs'},
                // fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                  
                  {
                    className: 'flex-1',
                  key: 'clause',
                  type: 'input', // autom
                  templateOptions: {
                    type: 'text',
                    label: 'clause',
                   },
                  },
                  
                  {     
                    className: 'flex-1',               
                    key: 'unit cost rate',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'unit cost rate',
                     },
                  },
                  {
                    className: 'flex-1',
                    key: 'Monthly quantity',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'Monthly quantity',
                      },
                  },
                  
    
              
              ],
                // }
              },


              {
           
                key: 'clause-inputs-test',
                // wrappers: ['panel'],
                // templateOptions: { label: '3 Col Inputs'},
                // fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                  
                  {
                    className: 'flex-1',
                  key: 'clause',
                  type: 'input', // autom
                  templateOptions: {
                    type: 'text',
                    label: 'clause',
                   },
                  },
                  
                  {     
                    className: 'flex-1',               
                    key: 'unit cost rate',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'unit cost rate',
                     },
                  },
                  {
                    className: 'flex-1',
                    key: 'Monthly quantity',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'Monthly quantity',
                      },
                  },
                  
    
              
              ],
                // }
              },

              {
           
                key: 'clause-inputs-test',
                // wrappers: ['panel'],
                // templateOptions: { label: '3 Col Inputs'},
                // fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                  
                  {
                    className: 'flex-1',
                  key: 'clause',
                  type: 'input', // autom
                  templateOptions: {
                    type: 'text',
                    label: 'clause',
                   },
                  },
                  
                  {     
                    className: 'flex-1',               
                    key: 'unit cost rate',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'unit cost rate',
                     },
                  },
                  {
                    className: 'flex-1',
                    key: 'Monthly quantity',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'Monthly quantity',
                      },
                  },
                  
    
              
              ],
                // }
              },
             
            


              {
           
                key: 'clause-inputs',
                type : 'clause-inputs',
                wrappers: ['panel'],
                templateOptions: { 
                  // label: '3 Col Inputs' ,
                   addText: 'Add Text Input',},
    
    
                
                fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                  
                  {
                    className: 'flex-1',
                  key: 'clause',
                  type: 'input', // autom
                  templateOptions: {
                    type: 'text',
                    label: 'clause',
                   },
                  },
                  
                  {     
                    className: 'flex-1',               
                    key: 'unit cost rate',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'unit cost rate',
                     },
                  },
                  {
                    className: 'flex-1',
                    key: 'Monthly quantity',
                    type: 'input',
                    templateOptions: {
                      type: 'number',
                      label: 'Monthly quantity',
                      },
                  },
                  
    
              
              ],
                }
              },
          ],
          // }
          },


          {
            key: 'currency-control',
            type: 'select',
            templateOptions:{
              label: 'Currency',
              options: this.jadwaService.getCurrenciesList(),
              valueProp: 'id',
              labelProp: 'name',
              required: true,
              placeholder: 'Select Currenc Location'
            }
          },


          {
            key: 'awesome',
            type: 'checkbox',
            templateOptions: { label: '' },
            expressionProperties: {
              'templateOptions.disabled': 'formState.awesomeIsForced',
              'templateOptions.label': (model, formState) => {
                if (formState.awesomeIsForced) {
                  return 'Too bad, formly is really awesome...';
                } else {
                  return 'Is formly totally awesome? (uncheck this and see what happens)';
                }
              },
            },
          },
          {
            key: 'whyNot',
            type: 'textarea',
            expressionProperties: {
              'templateOptions.placeholder': (model, formState) => {
                if (formState.awesomeIsForced) {
                  return 'Too bad... It really is awesome! Wasn\'t that cool?';
                } else {
                  return 'Type in here... I dare you';
                }
              },
              'templateOptions.disabled': 'formState.awesomeIsForced',
            },
            hideExpression: 'model.awesome',
            templateOptions: {
              label: 'Why Not?',
              placeholder: 'Type in here... I dare you',
            },
          },

          {
            key: 'whyNot',
            type: 'textarea',
            expressionProperties: {
              'templateOptions.placeholder': (model, formState) => {
                if (formState.awesomeIsForced) {
                  return 'Too bad... It really is awesome! Wasn\'t that cool?';
                } else {
                  return 'Type in here... I dare you';
                }
              },
              'templateOptions.disabled': 'formState.awesomeIsForced',
            },
            hideExpression: 'model.awesome',
            templateOptions: {
              label: 'Why Not?',
              placeholder: 'Type in here... I dare you',
            },
          },
          
        ],
      },

      {
        templateOptions: {
          label: 'PROBLEMS',
          templateMetaData: `<div class="left-block">
          <div class="tabs mb-2">
              <div>PROBLEMS</div>
              <div class="mx-2"></div>
              <div>Plan title</div>
          </div>
          <h3>1. Form title</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </p>
          </div>`
        },
        fieldGroup: [
          {
            key: uuidv4().replaceAll('-', '_'),
            type: 'editor',
            templateOptions: {
              label: 'Problem Statement',
            },
          },
          {
            key: 'problems-inputs',
            type: 'text-input',
            wrappers: ['panel'],
            templateOptions: { label: 'Simple Text Sentence', addText: 'Add More', },

            fieldArray: {
              fieldGroup: [
                {
                  key: uuidv4().replaceAll('-', '_'),
                  type: 'editor',
                  templateOptions: {
                    label: 'Text',
                  },
                }
              ],
            }
          }
        ],
      },
      
      
     

    ],
  }];

 











  submit() {
    // alert(JSON.stringify(this.model));
    localStorage.setItem('fields', JSON.stringify(this.fields));
    localStorage.setItem('model', JSON.stringify(this.model));
  }
}

