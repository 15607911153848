import { Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';
import { LoadJsonComponent } from './load-json/load-json.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotAccountComponent } from './components/forgot-account/forgot-account.component';
import { InputControlsComponent } from './input-controls/input-controls.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PlanReportTrialComponent } from './shared/plan-report-trial/plan-report-trial.component';
import { PaymentComponent } from './components/payment/payment.component';
import { TestComponent } from './components/test/test.component';
var ɵ0 = { returnUrl: window.location.pathname }, ɵ1 = function () { return import("./industry/industry.module.ngfactory").then(function (m) { return m.IndustryModuleNgFactory; }); }, ɵ2 = function () { return import("./products/products.module.ngfactory").then(function (m) { return m.ProductsModuleNgFactory; }); }, ɵ3 = function () { return import("./fixedassets/fixedassets.module.ngfactory").then(function (m) { return m.FixedAssetsModuleNgFactory; }); }, ɵ4 = function () { return import("./databank/databank.module.ngfactory").then(function (m) { return m.DataBankModuleNgFactory; }); }, ɵ5 = function () { return import("./plan/plan.module.ngfactory").then(function (m) { return m.PlanModuleNgFactory; }); }, ɵ6 = function () { return import("./document/document.module.ngfactory").then(function (m) { return m.DocumentModuleNgFactory; }); }, ɵ7 = function () { return import("./users/users.module.ngfactory").then(function (m) { return m.UsersModuleNgFactory; }); }, ɵ8 = function () { return import("./project/project.module.ngfactory").then(function (m) { return m.ProjectModuleNgFactory; }); }, ɵ9 = function () { return import("./user-profile/user-profile.module.ngfactory").then(function (m) { return m.UserProfileModuleNgFactory; }); }, ɵ10 = {
    type: 'error-v6',
    code: 403,
    title: '403... Access forbidden',
    desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
};
var routes = [
    {
        path: 'a4-test',
        component: TestComponent
    },
    {
        path: 'register',
        component: RegisterComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'forgot-account',
        component: ForgotAccountComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    { path: 'load-from-model', component: LoadJsonComponent },
    {
        path: 'form-builder',
        component: LoadJsonComponent,
    },
    {
        path: 'plan-generate-pdf',
        component: PlanReportTrialComponent,
    },
    {
        path: 'payment',
        component: PaymentComponent
    },
    {
        path: '',
        component: BaseComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'industry',
                loadChildren: ɵ1
            },
            {
                path: 'products',
                loadChildren: ɵ2
            },
            {
                path: 'fixedassets',
                loadChildren: ɵ3
            },
            {
                path: 'databank',
                loadChildren: ɵ4
            },
            {
                path: 'plan',
                loadChildren: ɵ5
            },
            {
                path: 'document',
                loadChildren: ɵ6
            },
            {
                path: 'users',
                loadChildren: ɵ7
            },
            {
                path: 'project',
                loadChildren: ɵ8
            },
            {
                path: 'dashboard',
                // loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
                component: DashboardComponent
            },
            { path: 'user-profile', loadChildren: ɵ9 },
            {
                path: 'input-controls',
                component: InputControlsComponent
                // loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            // {
            // 	path: 'mail',
            // 	loadChildren: () => import('../app/views/pages/apps/mail/mail.module').then(m => m.MailModule)
            // },
            // {
            // 	path: 'ecommerce',
            // 	loadChildren: () => import('../app/views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
            // },
            // {
            // 	path: 'ngbootstrap',
            // 	loadChildren: () => import('../app/views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule)
            // },
            // {
            // 	path: 'material',
            // 	loadChildren: () => import('../app/views/pages/material/material.module').then(m => m.MaterialModule)
            // },
            // {
            // 	path: 'user-management',
            // 	loadChildren: () => import('../app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
            // },
            // {
            // 	path: 'wizard',
            // 	loadChildren: () => import('../app/views/pages/wizard/wizard.module').then(m => m.WizardModule)
            // },
            // {
            // 	path: 'builder',
            // 	loadChildren: () => import('../app/views/theme/content/builder/builder.module').then(m => m.BuilderModule)
            // },
            {
                path: 'error/403',
                component: ErrorPageComponent,
                data: ɵ10
            },
            { path: 'error/:type', component: ErrorPageComponent },
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
        ]
    },
    { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
