// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
// Auth
import { AuthNoticeService, AuthService, Register, User, Login, CurrentUser } from '../../../app/core/auth';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { environment } from '../../../environments/environment';
import { AppState } from '../../../app/core/reducers';
import Swal from "sweetalert2";

@Component({
	selector: 'register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	today = new Date();
	registerForm: FormGroup;
	registerConfirmForm: FormGroup;
	loading = false;
	errors: any = [];
	SearchCountryField = SearchCountryField;
	selectedCountryCode = environment.defaultCountryCode;
	preferredCountries = [environment.defaultCountryCode];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	*/

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
	}

	/*
	* On destroy
	*/
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			/*fullname: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			*/
			// firstname: ['', Validators.compose([
			// 	Validators.pattern(/(^[a-zA-Z\s]+$)|(^[\u0621-\u064A\s]+$)/),
			// 	Validators.required,
			// 	Validators.minLength(3),
			// 	Validators.maxLength(100)
			// ])
			// ],			
			// lastname: ['', Validators.compose([
			// 	Validators.pattern(/(^[a-zA-Z\s]+$)|(^[\u0621-\u064A\s]+$)/),
			// 	Validators.required,
			// 	Validators.minLength(3),
			// 	Validators.maxLength(100)
			// ])
			// ],

			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				Validators.maxLength(320)
			]),
			],
			phoneNumber: ['', Validators.compose([
				Validators.required,

			]),
			],
			// username: ['', Validators.compose([
			// 	Validators.pattern(/(^[a-zA-Z0-9]+$)|(^[\u0600-\u06FF]+$)/),
			// 	Validators.required,
			// 	Validators.minLength(3),
			// 	Validators.maxLength(100)
			// ]),
			// ],

			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});

		this.registerConfirmForm = this.fb.group({
			code: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
			])],
		});

	}


	resendEmail() {
		if (this.registerForm.get('email').value) {
			let requestObject = {
				email: this.registerForm.get('email').value,
			};
			this.auth.regenerateEmail(requestObject).subscribe((response: any) => {
				this.loading = false;
				Swal.fire({
					title: 'Code sent to Email',
					// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
					icon: 'success',
					showCancelButton: false,
					confirmButtonColor: '#37c0b9',
					confirmButtonText: this.translate.instant('TITLE.OKAY'),
				});
			}, error => {
				this.loading = false;
				Swal.fire({
					title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
					// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#37c0b9',
					confirmButtonText: this.translate.instant('TITLE.OKAY'),
				});
			});
		}

	}

	confirmSubmit() {
		const controls = this.registerConfirmForm.controls;

		// check form
		if (this.registerConfirmForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		let requestObject = {
			email: this.registerForm.get('email').value,
			code: this.registerConfirmForm.get('code').value,
		};
		this.auth.confirmEmail(requestObject).subscribe((response: any) => {
			this.loading = false;
			Swal.fire({
				title: 'Registration Successful',
				// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				icon: 'success',
				showCancelButton: false,
				confirmButtonColor: '#37c0b9',
				confirmButtonText: this.translate.instant('TITLE.OKAY'),
			}).then((result) => {
				// this.router.navigateByUrl('/login');
				this.userLogin();
			});

		}, error => {
			this.loading = false;
			Swal.fire({
				title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#37c0b9',
				confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
	}
	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		if (!controls.agree.value) {
			// you must agree the terms and condition
			// checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
			this.authNoticeService.setNotice('You must agree the terms and condition', 'danger');
			return;
		}
		let requestObject = {
			// firstName:this.registerForm.get('firstname').value,
			// lastName:this.registerForm.get('lastname').value,
			// userName: this.registerForm.get('username').value,
			email: this.registerForm.get('email').value,
			phoneNumber: this.registerForm.get('phoneNumber').value.number.replaceAll(/\s/g, ""),
			phoneNumberCode: this.registerForm.get('phoneNumber').value.dialCode.split("+")[1],//without + we need like 91 or 966
			password: this.registerForm.get('password').value,
			isTcAccepted: this.registerForm.get('agree').value,
		};
		this.auth.registerJADWAUser(requestObject).subscribe((response: any) => {
			this.loading = false;
			if (response.result) {
				this.showConfirmationForm = true;
			}
		}, error => {
			this.loading = false;
			Swal.fire({
				title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#37c0b9',
				confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
		// this.auth.registerJADWAUser(requestObject).pipe(
		// 	tap(user => {
		// 		if (user) {
		// 			//this.store.dispatch(new Register({authToken: user.accessToken}));
		// 			// pass notice message to the login page
		// 			this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');
		// 			this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.VERIFY_EMAIL_LINK_SENT'), 'success');
		// 			this.router.navigateByUrl('/login');
		// 		} else {
		// 			this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
		// 		}
		// 	}),
		// 	takeUntil(this.unsubscribe),
		// 	finalize(() => {
		// 		this.loading = false;
		// 		this.cdr.markForCheck();
		// 	})
		// ).subscribe();
	}
	showConfirmationForm: boolean;

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	isConfirmControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerConfirmForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	showPassword: boolean;
	showPasswordFun() {
		this.showPassword = !this.showPassword;
	}

	showConfirmPassword: boolean;
	showConfirmPasswordFun() {
		this.showConfirmPassword = !this.showConfirmPassword;
	}
	userLogin() {
		const authData = {
			"email": this.registerForm.get('email').value,
			"password": this.registerForm.get('password').value,
			"isSocialLogin": false
		};

		this.auth.JADWALogin(authData).subscribe((response: any) => {
			this.loading = false;
			//   localStorage.setItem('userInfo',JSON.stringify(response.result));
			localStorage.setItem('userId', response.result.id);
			this.store.dispatch(new Login({ authToken: response.result.tokenResponse.jwToken }));
			this.store.dispatch(new CurrentUser({ userInfo: JSON.stringify(response.result) }))
			// this.router.navigateByUrl(this.returnUrl);
			this.router.navigateByUrl('/dashboard');

		}, error => {
			this.loading = false;
			Swal.fire({
				title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#37c0b9',
				confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
	}
}
