/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-result.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./search-result.component";
var styles_SearchResultComponent = [i0.styles];
var RenderType_SearchResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchResultComponent, data: {} });
export { RenderType_SearchResultComponent as RenderType_SearchResultComponent };
function View_SearchResultComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { item: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent.parent, 4); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SearchResultComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { item: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent.parent, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SearchResultComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === 1); _ck(_v, 4, 0, currVal_1); }, null); }
function View_SearchResultComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "kt-quick-search__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "kt-quick-search__item-img": 0, "kt-quick-search__item-icon": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "kt-quick-search__item-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "kt-quick-search__item-title"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "kt-quick-search__item-desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = ""; var currVal_2 = _ck(_v, 4, 0, _v.context.item.img, _v.context.item.icon); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.item.icon ? _v.context.item.icon : _v.context.item.img); _ck(_v, 1, 0, currVal_0); var currVal_3 = _v.context.item.text; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.item.text; _ck(_v, 9, 0, currVal_4); }); }
function View_SearchResultComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "kt-quick-search__category"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.item.text; _ck(_v, 1, 0, currVal_0); }); }
function View_SearchResultComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "kt-quick-search__message kt-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.noRecordText || "No record found"); _ck(_v, 1, 0, currVal_0); }); }
export function View_SearchResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "kt-quick-search__result"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchResultComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["itemTemplate", 2]], null, 0, null, View_SearchResultComponent_4)), (_l()(), i1.ɵand(0, [["separatorTemplate", 2]], null, 0, null, View_SearchResultComponent_5)), (_l()(), i1.ɵand(0, [["emptyResultTemplate", 2]], null, 0, null, View_SearchResultComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SearchResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-search-result", [], null, null, null, View_SearchResultComponent_0, RenderType_SearchResultComponent)), i1.ɵdid(1, 49152, null, 0, i3.SearchResultComponent, [], null, null)], null, null); }
var SearchResultComponentNgFactory = i1.ɵccf("kt-search-result", i3.SearchResultComponent, View_SearchResultComponent_Host_0, { data: "data", noRecordText: "noRecordText" }, {}, []);
export { SearchResultComponentNgFactory as SearchResultComponentNgFactory };
