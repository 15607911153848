/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./y-axis-ticks.component";
var styles_YAxisTicksComponent = [];
var RenderType_YAxisTicksComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_YAxisTicksComponent, data: {} });
export { RenderType_YAxisTicksComponent as RenderType_YAxisTicksComponent };
function View_YAxisTicksComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, ":svg:g", [["class", "tick"]], [[1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, ":svg:text", [["stroke-width", "0.01"]], [[1, "dy", 0], [1, "x", 0], [1, "y", 0], [1, "text-anchor", 0], [4, "font-size", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transform(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.tickFormat(_v.context.$implicit); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.dy; var currVal_3 = _co.x1; var currVal_4 = _co.y1; var currVal_5 = _co.textAnchor; var currVal_6 = "12px"; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.tickTrim(_co.tickFormat(_v.context.$implicit)); _ck(_v, 4, 0, currVal_7); }); }
function View_YAxisTicksComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:path", [["class", "reference-area"]], [[1, "d", 0], [1, "transform", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.referenceAreaPath; var currVal_1 = _co.gridLineTransform(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_YAxisTicksComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:line", [["class", "gridline-path gridline-path-horizontal"], ["x1", "0"]], [[1, "x2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridLineWidth; _ck(_v, 0, 0, currVal_0); }); }
function View_YAxisTicksComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:line", [["class", "gridline-path gridline-path-horizontal"], ["x1", "0"]], [[1, "x2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (0 - _co.gridLineWidth); _ck(_v, 0, 0, currVal_0); }); }
function View_YAxisTicksComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, ":svg:g", [], [[1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_5)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.orient === "left"); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.orient === "right"); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridLineTransform(); _ck(_v, 0, 0, currVal_0); }); }
function View_YAxisTicksComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":svg:g", [], [[1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showGridLines; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transform(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_YAxisTicksComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, ":svg:text", [["class", "refline-label"]], [[1, "dy", 0], [1, "y", 0], [1, "x", 0], [1, "text-anchor", 0]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tickTrim(_co.tickFormat(_v.parent.parent.context.$implicit.value)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.dy; var currVal_2 = (0 - 6); var currVal_3 = _co.gridLineWidth; var currVal_4 = _co.textAnchor; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.parent.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_5); }); }
function View_YAxisTicksComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, ":svg:g", [], [[1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:line", [["class", "refline-path gridline-path-horizontal"], ["x1", "0"]], [[1, "x2", 0], [1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_9)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.showRefLabels; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transform(_v.parent.context.$implicit.value); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.gridLineWidth; var currVal_2 = _co.gridLineTransform(); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
function View_YAxisTicksComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_8)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showRefLines; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_YAxisTicksComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { ticksElement: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["ticksel", 1]], null, 2, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_3)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_YAxisTicksComponent_7)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ticks; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((((_co.referenceLineLength > 1) && _co.refMax) && _co.refMin) && _co.showRefLines); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.ticks; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.referenceLines; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_YAxisTicksComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-y-axis-ticks", ""]], null, null, null, View_YAxisTicksComponent_0, RenderType_YAxisTicksComponent)), i0.ɵdid(1, 4767744, null, 0, i2.YAxisTicksComponent, [], null, null)], null, null); }
var YAxisTicksComponentNgFactory = i0.ɵccf("g[ngx-charts-y-axis-ticks]", i2.YAxisTicksComponent, View_YAxisTicksComponent_Host_0, { scale: "scale", orient: "orient", tickArguments: "tickArguments", tickValues: "tickValues", tickStroke: "tickStroke", trimTicks: "trimTicks", maxTickLength: "maxTickLength", tickFormatting: "tickFormatting", showGridLines: "showGridLines", gridLineWidth: "gridLineWidth", height: "height", referenceLines: "referenceLines", showRefLabels: "showRefLabels", showRefLines: "showRefLines" }, { dimensionsChanged: "dimensionsChanged" }, []);
export { YAxisTicksComponentNgFactory as YAxisTicksComponentNgFactory };
