
<div id="kt_content_container" class="d-flex flex-column-fluid align-items-start container-xxl p-0">
    <!--begin::Post-->
    <div class="content flex-row-fluid" id="kt_content">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
            <div class="card-header border-0 pt-6">
                <!--begin::Card title-->
                <div class="card-title">
                    Controls
                </div>
                <!--begin::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">

                <form [formGroup]="form" (ngSubmit)="submit()" >
                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
                </form>  
                <pre>
                    Here
                    {{currencies | json}}

                    ----------

                    {{currencies1 | json}}
                </pre>
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
    </div>
    <!--end::Post-->
</div>

<!-- <pre>
    {{model | json}}
</pre>-->

<!-- <pre>
    {{fields | json}}
</pre>  -->