import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  htmlTemplate=`<div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;overflow:hidden;border:1px solid #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative;padding:0">
      <div style="position:absolute;top:200px;z-index:2;width:100%;text-align:center;padding-left:10px">{{ReportNo}}
      </div>
      <div
          style="position:absolute;bottom:400px;z-index:2;width:100%;font-size:50px;text-align:center;padding-left:10px;color:#1e1a4d;font-weight:600">
          <div align="center"><img src="assets/media/logo.jpg"
                  style="width:300px;height:96px;margin-bottom:60px"></div>Feasibility Study<div
              style="font-size:30px;color:#faab1b;font-weight:400;margin-top:15px">Report</div>
      </div><img src="assets/media/first-page-top-bg.jpg"
          style="width:100%;position:absolute;left:0;right:0;top:0;z-index:1"> <img
          src="assets/media/firstpage-bottom-curve-img.jpg"
          style="width:100%;position:absolute;left:0;right:0;bottom:0;z-index:1">
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageFooterLeft}}</td>
                  <td align="right">&nbsp;</td>
              </tr>
          </table>
          <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
              {{Page1#}}</div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:21px">Vision</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:160px">{{VisionTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Mission</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:160px">{{MissionTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Goals and Objectives</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:160px">{{GoalsObjectivesTXT}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageFooterLeft}}</td>
                  <td align="right">&nbsp;</td>
              </tr>
          </table>
          <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
              {{Page1#}}</div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px">Problem Definition</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:120px">{{ProblemSummaryTXT}}</div>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:240px">{{ProblemDetailsTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Solution</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:120px">{{SolutionSummaryTXT}}</div>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:240px">{{SolutionDetailsTXT}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageFooterLeft}}</td>
                  <td align="right">&nbsp;</td>
              </tr>
          </table>
          <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
              {{Page1#}}</div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Key Metrics</h3>
          <div style="width:100%;height:5px"></div>
          <div style="min-height:120px">{{KeyMetricsSummaryTXT}}</div>
          <div style="width:100%;height:5px"></div>
          <div style="min-height:240px">{{KeyMetricsDetailsTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px">Unique Value Proposition</h3>
          <div style="width:100%;height:5px"></div>
          <div style="min-height:120px">{{UVPSummaryTXT}}</div>
          <div style="width:100%;height:5px"></div>
          <div style="min-height:240px">{{UVPDetailsTXT}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageFooterLeft}}</td>
                  <td align="right">&nbsp;</td>
              </tr>
          </table>
          <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
              {{Page1#}}</div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Un Fair Advantage</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:120px">{{UFASummaryTXT}}</div>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:240px">{{UFADetailsTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Sales Channels</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:120px">{{SalesChannelsSummaryTXT}}</div>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:240px">{{SalesChannelsDetailsTXT}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageFooterLeft}}</td>
                  <td align="right">&nbsp;</td>
              </tr>
          </table>
          <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
              {{Page1#}}</div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px">Customer Segments</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:120px">{{CustSegmentSummaryTXT}}</div>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:240px">{{CustSegmentDetailsTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Cost Structure</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:120px">{{CostSummaryTXT}}</div>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:240px">{{CostDetailsTXT}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageFooterLeft}}</td>
                  <td align="right">&nbsp;</td>
              </tr>
          </table>
          <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
              {{Page1#}}</div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Revenue Stream</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:120px">{{RevenueSummaryTXT}}</div>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:240px">{{RevenueDetailsTXT}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageFooterLeft}}</td>
                  <td align="right">&nbsp;</td>
              </tr>
          </table>
          <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
              {{Page1#}}</div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px">SWOT Analysis</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Strength</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:150px">{{StrengthTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Weakness</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:150px">{{WeaknessTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Opportunity</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:150px">{{OpportunityTXT}}</div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Threat</h3>
          <div style="width:100%;height:10px"></div>
          <div style="min-height:150px">{{ThreatTXT}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;overflow:hidden;border:1px solid #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative;padding:0">
      <div
          style="position:absolute;bottom:400px;z-index:2;width:100%;font-size:50px;text-align:center;padding-left:10px;color:#1e1a4d;font-weight:600">
          <div align="center"><img src="assets/media/logo.jpg"
                  style="width:300px;height:96px;margin-bottom:60px"></div>Financial Analysis Important Input Data
          <div style="font-size:30px;color:#faab1b;font-weight:400;margin-top:15px">Section</div>
      </div><img src="assets/media/top-bg.png"
          style="width:100%;position:absolute;left:0;right:0;top:0;z-index:1"> <img
          src="assets/media/bottom-bg.png" style="width:100%;position:absolute;left:0;right:0;bottom:0;z-index:1">
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#403885;position:absolute;bottom:50px;left:50px;right:50px;z-index:2">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">{{PageFooterLeft}}</td>
              </tr>
          </table>
          <div class="number-left"
              style="background:#403885;position:absolute;left:0;top:-9px;padding-right:9px;color:#fff">
              {{Module1Page#}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Project Inputs</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:5px">Project Dates</h3>
          <div style="min-height:150px">{{ProjectDatesTBL}}</div>
          <div style="width:100%;height:5px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:5px">Tax Settings</h3>
          <div style="min-height:150px">{{TaxSettingsTBL}}</div>
          <div style="width:100%;height:5px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:5px">Fixed Assets Settings</h3>
          <div style="min-height:150px">{{FASettingsTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Revenue Sources without Direct Cost</h3>
          <div style="min-height:240px">{{RevenueOption1TBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Revenue Sources With Direct Cost</h3>
          <div style="min-height:240px">{{RevenueOption2TBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Start Up Expenses</h3>
          <div style="min-height:200px">{{StartupExpenseTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Expense As % of Revenue</h3>
          <div style="min-height:200px">{{ExpenseAsPercentageOfRevenueTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Expense By Amount</h3>
          <div style="min-height:200px">{{ExpenseByAmountTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Pre Paid Expenses</h3>
          <div style="min-height:200px">{{PrePaidExpenseTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Working Capital</h3>
          <div style="min-height:200px">{{WorkingCapitalTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Dividends</h3>
          <div style="min-height:200px">{{DividendsTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:2px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Equity and Long Terms Loan</h3>
          <div style="min-height:200px">{{EquityAndLongTermLoanTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Short Term Loan</h3>
          <div style="min-height:200px">{{ShortTermLoanTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;overflow:hidden;border:1px solid #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative;padding:0">
      <div
          style="position:absolute;bottom:400px;z-index:2;width:100%;font-size:50px;text-align:center;padding-left:10px;color:#1e1a4d;font-weight:600">
          <div align="center"><img src="assets/media/logo.jpg"
                  style="width:300px;height:96px;margin-bottom:60px"></div>Financial Analysis<div
              style="font-size:30px;color:#faab1b;font-weight:400;margin-top:15px">Section</div>
      </div><img src="assets/media/top-bg.png"
          style="width:100%;position:absolute;left:0;right:0;top:0;z-index:1"> <img
          src="assets/media/bottom-bg.png" style="width:100%;position:absolute;left:0;right:0;bottom:0;z-index:1">
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#403885;position:absolute;bottom:50px;left:50px;right:50px;z-index:2">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">{{PageFooterLeft}}</td>
              </tr>
          </table>
          <div class="number-left"
              style="background:#403885;position:absolute;left:0;top:-9px;padding-right:9px;color:#fff">
              {{Module1Page#}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{ProfitLossTblTitle}}</h3>
          <div>{{ProfitLossTbl}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Fixed Assets By Category</h3>
          <div style="min-height:180px">{{FACategoryTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Fixed Assets Summary</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Tangible</h3>
          <div style="min-height:180px">{{FATangibleTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">InTangible</h3>
          <div style="min-height:180px">{{FAInTangibleTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Total</h3>
          <div style="min-height:180px">{{FATotalTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Cash Flow Statement</h3>
          <div style="min-height:180px">{{CashFlowSummaryTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Current Liability</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Trade Payables</h3>
          <div style="min-height:180px">{{TradePayableTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Other Current Liabilities</h3>
          <div style="min-height:180px">{{OtherCurrentLiabilitiesTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Short Terms Debt and Provisions</h3>
          <div style="min-height:180px">{{ShortTermDebtTBL}}</div>
          <div style="min-height:180px">{{ShortTermProvisionsTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Others</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Cash Availavble For RCL</h3>
          <div style="min-height:180px">{{CashAvailableRclTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">BALANCE SHEET</h3>
          <div style="min-height:180px">{{CurrentAssetsTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Current Assets</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Trade Receivables</h3>
          <div style="min-height:180px">{{TradeReceivableTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">VAT Recoverables / Input Credits</h3>
          <div style="min-height:180px">{{InputCreditTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Pre Paid Expenses</h3>
          <div style="min-height:180px">{{PrePaidExpensesTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Taxes</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Computation of Profits Chargable to Tax</h3>
          <div style="min-height:180px">{{ProfitChargableTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Corporate Tax</h3>
          <div style="min-height:180px">{{CorporateTaxTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Deferred Tax</h3>
          <div style="min-height:180px">{{DeferredTaxTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Zakat Base</h3>
          <div style="min-height:180px">{{ZakatBaseTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Zakat Tax</h3>
          <div style="min-height:180px">{{ZakatTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Tax Loses</h3>
          <div style="min-height:180px">{{TaxLossesTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Equity Share Holder Funds</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Common Stock</h3>
          <div style="min-height:180px">{{CommonStockTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Retained Earnings</h3>
          <div style="min-height:180px">{{RetainedEarningTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Ratios</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Margin Ratios</h3>
          <div style="min-height:150px">{{MarginRatioTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Profitability Ratio</h3>
          <div style="min-height:150px">{{ProfitabilityRatioTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Liquidity Ratio</h3>
          <div style="min-height:150px">{{LiquidityRatioTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Ratios</h3>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Solvency Ratio</h3>
          <div style="min-height:150px">{{SolvencyRatioTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Activity Ratio</h3>
          <div style="min-height:150px">{{ActivityRatioTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Use & Sources</h3>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Uses</h3>
          <div style="min-height:150px">{{UsesTBL}}</div>
          <div style="width:100%;height:10px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Sources</h3>
          <div style="min-height:150px">{{SourcesTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Break Even and Burn Rate</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Break Even</h3>
          <div style="min-height:180px">{{BreakEvenTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Burn Rate</h3>
          <div style="min-height:180px">{{BurnRateTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">Dividends & Returns</h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Dividends</h3>
          <div style="min-height:180px">{{DividendTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">IRR on Dividends</h3>
          <div style="min-height:180px">{{IRRonDividendTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Un Discounted PayBack</h3>
          <div style="min-height:180px">{{UnDiscountedPayBackTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px"></h3>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">IRR on Cash Flow</h3>
          <div style="min-height:180px">{{IRRonCashFlowTBL}}</div>
          <div style="width:100%;height:30px"></div>
          <h3 style="color:#403887;font-size:22px;margin-bottom:10px">Discounted PayBack</h3>
          <div style="min-height:180px">{{DiscountedPayBackTBL}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{FATblTitle}}</h3>
          <div>{{FATbl}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart1PageTitle}}</h3>
          <div>{{Chart1}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart2PageTitle}}</h3>
          <div>{{Chart2}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart3PageTitle}}</h3>
          <div>{{Chart3}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart4PageTitle}}</h3>
          <div>{{Chart4}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart5PageTitle}}</h3>
          <div>{{Chart5}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart6PageTitle}}</h3>
          <div>{{Chart6}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart7PageTitle}}</h3>
          <div>{{Chart7}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart8PageTitle}}</h3>
          <div>{{Chart8}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart9PageTitle}}</h3>
          <div>{{Chart9}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{ChartPageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{Chart10PageTitle}}</h3>
          <div style="min-height:180px">{{Chart10}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
      #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative"><img
          src="assets/media/left-bg-2.jpg"
          style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1">
      <div class="pdf-header" style="border-bottom:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">{{PageHeadingLeft}}</td>
                  <td align="right">{{PageHeadingRight}}</td>
              </tr>
          </table>
      </div>
      <div class="pdf-footer" style="border-top:2px solid
          #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
          <table width="100%">
              <tr>
                  <td align="left">&nbsp;</td>
                  <td align="right">sdfsdf</td>
              </tr>
          </table>
          <div class="number-left" style="background:#fff;position:absolute;left:0;top:-9px;padding-right:9px">2
          </div>
      </div>
      <div
          style="font-size:15px;padding-top:80px;text-align:left;padding-bottom:80px;position:relative;z-index:2;line-height:22px">
          <h3 style="color:#403887;font-size:22px;margin-bottom:20px">{{JsonTitle}}</h3>
          <div>{{JsonTbl}}</div>
      </div>
  </div>
  <div class="a4-block a4-img-block"
      style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;overflow:hidden;border:1px solid #ccc;margin:0
      auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative;padding:0">
      <img src="assets/media/last-banner.png"
          style="width:100%;position:absolute;left:0;right:0;bottom:0;top:0;z-index:1;height:297mm;width:210mm">
  </div>
</div>`

}
