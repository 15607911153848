/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../common/svg-radial-gradient.component.ngfactory";
import * as i2 from "../common/svg-radial-gradient.component";
import * as i3 from "@angular/common";
import * as i4 from "./pie-arc.component";
var styles_PieArcComponent = [];
var RenderType_PieArcComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PieArcComponent, data: {} });
export { RenderType_PieArcComponent as RenderType_PieArcComponent };
function View_PieArcComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, ":svg:g", [["ngx-charts-svg-radial-gradient", ""], ["orientation", "vertical"]], null, null, null, i1.View_SvgRadialGradientComponent_0, i1.RenderType_SvgRadialGradientComponent)), i0.ɵdid(2, 573440, null, 0, i2.SvgRadialGradientComponent, [], { color: [0, "color"], name: [1, "name"], startOpacity: [2, "startOpacity"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fill; var currVal_1 = _co.radialGradientId; var currVal_2 = _co.startOpacity; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PieArcComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, ":svg:g", [["class", "arc-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PieArcComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["class", "arc"]], [[8, "id", 0], [1, "d", 0], [2, "active", null], [1, "fill", 0], [4, "pointer-events", null]], [[null, "click"], [null, "dblclick"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } if (("dblclick" === en)) {
        var pd_1 = (_co.onDblClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (_co.activate.emit(_co.data) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (_co.deactivate.emit(_co.data) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:use", [["fill", "none"], ["stroke", "white"]], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, ":svg:text", [["font-family", "Verdana"], ["font-size", "17"], ["rotate", "180"]], [[1, "fill", 0], [1, "x", 0]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, ":svg:textPath", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gradient; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.uniqueId; var currVal_2 = _co.path; var currVal_3 = _co.isActive; var currVal_4 = _co.getGradient(); var currVal_5 = _co.getPointerEvents(); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = ("#" + _co.uniqueId); _ck(_v, 4, 0, currVal_6); var currVal_7 = "white"; var currVal_8 = _co.getXValue(); _ck(_v, 5, 0, currVal_7, currVal_8); var currVal_9 = ("#" + _co.uniqueId); _ck(_v, 6, 0, currVal_9); var currVal_10 = _co.value.toString().split("").reverse().join(""); _ck(_v, 7, 0, currVal_10); }); }
export function View_PieArcComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-pie-arc", ""]], null, null, null, View_PieArcComponent_0, RenderType_PieArcComponent)), i0.ɵdid(1, 573440, null, 0, i4.PieArcComponent, [i0.ElementRef], null, null)], null, null); }
var PieArcComponentNgFactory = i0.ɵccf("g[ngx-charts-pie-arc]", i4.PieArcComponent, View_PieArcComponent_Host_0, { fill: "fill", startAngle: "startAngle", endAngle: "endAngle", innerRadius: "innerRadius", outerRadius: "outerRadius", cornerRadius: "cornerRadius", value: "value", max: "max", data: "data", explodeSlices: "explodeSlices", gradient: "gradient", animate: "animate", pointerEvents: "pointerEvents", isActive: "isActive" }, { select: "select", activate: "activate", deactivate: "deactivate", dblclick: "dblclick" }, []);
export { PieArcComponentNgFactory as PieArcComponentNgFactory };
