/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./x-axis-ticks.component.ngfactory";
import * as i2 from "./x-axis-ticks.component";
import * as i3 from "./axis-label.component.ngfactory";
import * as i4 from "./axis-label.component";
import * as i5 from "@angular/common";
import * as i6 from "./x-axis.component";
var styles_XAxisComponent = [];
var RenderType_XAxisComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_XAxisComponent, data: {} });
export { RenderType_XAxisComponent as RenderType_XAxisComponent };
function View_XAxisComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:g", [["ngx-charts-x-axis-ticks", ""]], null, [[null, "dimensionsChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dimensionsChanged" === en)) {
        var pd_0 = (_co.emitTicksHeight($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_XAxisTicksComponent_0, i1.RenderType_XAxisTicksComponent)), i0.ɵdid(1, 4767744, [[1, 4]], 0, i2.XAxisTicksComponent, [], { scale: [0, "scale"], orient: [1, "orient"], tickArguments: [2, "tickArguments"], tickValues: [3, "tickValues"], tickStroke: [4, "tickStroke"], trimTicks: [5, "trimTicks"], maxTickLength: [6, "maxTickLength"], tickFormatting: [7, "tickFormatting"], showGridLines: [8, "showGridLines"], gridLineHeight: [9, "gridLineHeight"], width: [10, "width"], rotateTicks: [11, "rotateTicks"] }, { dimensionsChanged: "dimensionsChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.xScale; var currVal_1 = _co.xOrient; var currVal_2 = _co.tickArguments; var currVal_3 = _co.ticks; var currVal_4 = _co.tickStroke; var currVal_5 = _co.trimTicks; var currVal_6 = _co.maxTickLength; var currVal_7 = _co.tickFormatting; var currVal_8 = _co.showGridLines; var currVal_9 = _co.dims.height; var currVal_10 = _co.dims.width; var currVal_11 = _co.rotateTicks; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_XAxisComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:g", [["ngx-charts-axis-label", ""]], null, null, null, i3.View_AxisLabelComponent_0, i3.RenderType_AxisLabelComponent)), i0.ɵdid(1, 573440, null, 0, i4.AxisLabelComponent, [i0.ElementRef], { orient: [0, "orient"], label: [1, "label"], offset: [2, "offset"], width: [3, "width"], height: [4, "height"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bottom"; var currVal_1 = _co.labelText; var currVal_2 = _co.labelOffset; var currVal_3 = _co.dims.width; var currVal_4 = _co.dims.height; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_XAxisComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { ticksComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, ":svg:g", [], [[1, "class", 0], [1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XAxisComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XAxisComponent_2)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.xScale; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.showLabel; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.xAxisClassName; var currVal_1 = _co.transform; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_XAxisComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-x-axis", ""]], null, null, null, View_XAxisComponent_0, RenderType_XAxisComponent)), i0.ɵdid(1, 573440, null, 0, i6.XAxisComponent, [], null, null)], null, null); }
var XAxisComponentNgFactory = i0.ɵccf("g[ngx-charts-x-axis]", i6.XAxisComponent, View_XAxisComponent_Host_0, { xScale: "xScale", dims: "dims", trimTicks: "trimTicks", rotateTicks: "rotateTicks", maxTickLength: "maxTickLength", tickFormatting: "tickFormatting", showGridLines: "showGridLines", showLabel: "showLabel", labelText: "labelText", ticks: "ticks", xAxisTickInterval: "xAxisTickInterval", xAxisTickCount: "xAxisTickCount", xOrient: "xOrient", xAxisOffset: "xAxisOffset" }, { dimensionsChanged: "dimensionsChanged" }, []);
export { XAxisComponentNgFactory as XAxisComponentNgFactory };
