import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as arLang } from './core/_config/i18n/ar';

import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { AppState } from './core/reducers';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	//changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'JEDWA';
	loader: boolean;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  currentLanguage : string = 'en';
  private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
    private translateService: TranslateService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private store: Store<AppState>) {

		// register translations
    
		this.translationService.loadTranslations(enLang, arLang);
    
    // dont set language
    // localStorage.removeItem("language");
    // this.translationService.setLanguage("ar");    
    document.body.dir=this.translateService.instant('DIRECTION');
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

    const sortSubscription = this.store.pipe(
			select(AuthState => AuthState)
		).subscribe(State => {
			//@ts-ignore
			this.currentLanguage = State.auth.language;
		});
		this.subscriptions.push(sortSubscription);
    
    
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
	}

	form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [
    {
      key: uuidv4().replaceAll('-', '_'),
      id: uuidv4().replaceAll('-', '_'),
      name: 'dfdfdf',
      type: 'stepper',
      fieldGroup: [
        {
          templateOptions: { label: 'Strategic Plan' },
          fieldGroup: [
            {
              key: uuidv4().replaceAll('-', '_'),
              type: 'input',
              templateOptions: {
                label: 'Vision',

                placeholder: ' What do you want your project to achieve?',
              },
            },
            {
              key: uuidv4().replaceAll('-', '_'),
              type: 'editor',
              templateOptions: {
                label:
                  'Include some description about project and also add youtube videos',

                placeholder: ' What do you want your project to achieve?',
              },
            },
            {
              key: uuidv4().replaceAll('-', '_'),
              type: 'input',
              templateOptions: {
                label: 'message',

                placeholder:
                  'Which is the main objective of establishing the project, and it is not specified in time',
              },
            },
            {
              key: uuidv4().replaceAll('-', '_'),
              type: 'input',
              templateOptions: {
                label: 'message',

                placeholder:
                  'Which is the main objective of establishing the project, and it is not specified in time',
              },
            },
            {
              key: uuidv4().replaceAll('-', '_'),
              type: 'tabs',
              fieldGroup: [
                {
                  templateOptions: { label: 'Personal data' },
                  fieldGroup: [
                    {
                      key: uuidv4().replaceAll('-', '_'),
                      type: 'input',
                      templateOptions: {
                        label: 'Strategic goals',
                      },
                    },
                    {
                      key: uuidv4().replaceAll('-', '_'),
                      type: 'input',
                      templateOptions: {
                        type: 'number',
                        label: 'First year goals',
                      },
                    },
                  ],
                },
                {
                  templateOptions: { label: 'Destination' },
                  fieldGroup: [
                    {
                      key: uuidv4().replaceAll('-', '_'),
                      type: 'input',
                      templateOptions: {
                        label: 'Country',
                      },
                    },
                  ],
                },
                {
                  templateOptions: { label: 'Day of the trip' },
                  fieldGroup: [
                    {
                      key: uuidv4().replaceAll('-', '_'),
                      type: 'input',
                      templateOptions: {
                        type: 'date',
                        label: 'Day of the trip',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          templateOptions: { label: 'Destination' },
          fieldGroup: [
            {
              key: uuidv4().replaceAll('-', '_'),
              type: 'input',
              templateOptions: {
                label: 'Country',
              },
            },
          ],
        },
        {
          templateOptions: { label: 'Day of the trip' },
          fieldGroup: [
            {
              key: uuidv4().replaceAll('-', '_'),
              type: 'input',
              templateOptions: {
                type: 'date',
                label: 'Day of the trip',
              },
            },
          ],
        },
      ],
    },
  ];
  showJSON: boolean = false;
  templateJSON = ` [
    {
      type: 'stepper',
      fieldGroup: [
        {
          templateOptions: { label: 'Strategic Plan' },
          fieldGroup: [
            {
              key: 'vision',
              type: 'input',
              templateOptions: {
                label: 'Vision',
                required: true,
                placeholder: ' What do you want your project to achieve?',
              },
            },
            {
              key: 'message',
              type: 'input',
              templateOptions: {
                label: 'message',
                required: true,
                placeholder:
                  'Which is the main objective of establishing the project, and it is not specified in time',
              },
            },
            {
              key: 'objective',
              type: 'input',
              templateOptions: {
                label: 'message',
                required: true,
                placeholder:
                  'Which is the main objective of establishing the project, and it is not specified in time',
              },
            },
            {
              type: 'tabs',
              fieldGroup: [
                {
                  templateOptions: { label: 'Personal data' },
                  fieldGroup: [
                    {
                      key: 'goals',
                      type: 'input',
                      templateOptions: {
                        label: 'Strategic goals',
                        required: true,
                      },
                    },
                    {
                      key: 'age',
                      type: 'input',
                      templateOptions: {
                        type: 'number',
                        label: 'First year goals',
                        required: true,
                      },
                    },
                    {
                      type: 'button',
                      templateOptions: {
                        text: '+add same elemt',
                        fg: 'cc',
                        /* onClick: ($event) => {this.fields[0].fieldGroup[0].fieldGroup[4].fieldGroup[0].fieldGroup.push(this.fields[0].fieldGroup[0].fieldGroup[4].fieldGroup[0].fieldGroup[1]); console.log(this.fields);console.log('jg',$event)},*/
                      },
                    },
                  ],
                },
                {
                  templateOptions: { label: 'Destination' },
                  fieldGroup: [
                    {
                      key: 'country',
                      type: 'input',
                      templateOptions: {
                        label: 'Country',
                        required: true,
                      },
                    },
                  ],
                },
                {
                  templateOptions: { label: 'Day of the trip' },
                  fieldGroup: [
                    {
                      key: 'day',
                      type: 'input',
                      templateOptions: {
                        type: 'date',
                        label: 'Day of the trip',
                        required: true,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          templateOptions: { label: 'Destination' },
          fieldGroup: [
            {
              key: 'country',
              type: 'input',
              templateOptions: {
                label: 'Country',
                required: true,
              },
            },
          ],
        },
        {
          templateOptions: { label: 'Day of the trip' },
          fieldGroup: [
            {
              key: 'day',
              type: 'input',
              templateOptions: {
                type: 'date',
                label: 'Day of the trip',
                required: true,
              },
            },
          ],
        },
      ],
    },
  ]`;

  submit() {
    sessionStorage.setItem('formConfig', JSON.stringify(this.fields));
    sessionStorage.setItem('model', JSON.stringify(this.model));
  }
  saveFieldsConfig() {
    sessionStorage.setItem('formConfig', JSON.stringify(this.fields));
  }
  saveResponse() {
    sessionStorage.setItem('model', JSON.stringify(this.model));
  }

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
