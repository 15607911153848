import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { replaceAll } from '../core/utils/replaceAllForStringPolyFill';
var EditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EditorComponent, _super);
    function EditorComponent() {
        // constructor(){
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // }
        _this.editorConfig = {
            editable: true,
            spellcheck: true,
            height: '15rem',
            minHeight: '5rem',
            placeholder: 'Enter text here...',
            translate: 'no',
            defaultParagraphSeparator: 'p',
            defaultFontName: 'Arial',
        };
        return _this;
    }
    EditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formControl.valueChanges.subscribe(function (value) {
            // console.log(value);
            if (_this.field.templateOptions && (_this.field.templateOptions.maxLines !== undefined || _this.field.templateOptions.maxLines != null)) {
                var liSplitCount = value.split("<li>").length - 1;
                var pTagSplitCount = value.split("<p>").length - 1;
                if (liSplitCount + pTagSplitCount > _this.field.templateOptions.maxLines) {
                    _this.formControl.setErrors({ maximumNumberOfLinesReached: true });
                }
            }
            if (_this.field.templateOptions && (_this.field.templateOptions.maxLength !== undefined || _this.field.templateOptions.maxLength !== null || _this.field.templateOptions.minLength !== undefined || _this.field.templateOptions.minLength !== undefined)) {
                var finalReplacedValue = replaceAll(value, "<ul>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</ul>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "<ol>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</ol>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "<li>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</li>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</li>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</b>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</b>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</u>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</u>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</i>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, "</i>", "");
                finalReplacedValue = replaceAll(finalReplacedValue, /\s/, "");
                finalReplacedValue = replaceAll(finalReplacedValue, "\n", "");
                // console.log(finalReplacedValue);
                if (_this.field.templateOptions.maxLength !== undefined || _this.field.templateOptions.maxLength !== null) {
                    if (finalReplacedValue.length > _this.field.templateOptions.maxLength) {
                        _this.formControl.setErrors({ exceedingMaxLength: true });
                    }
                }
                if (_this.field.templateOptions.minLength !== undefined || _this.field.templateOptions.minLength !== null) {
                    if (finalReplacedValue.length < _this.field.templateOptions.minLength) {
                        _this.formControl.setErrors({ minLengthNotReached: true });
                    }
                }
            }
        });
    };
    return EditorComponent;
}(FieldType));
export { EditorComponent };
