<!-- begin: Header -->
<div  ktHeader #ktHeader class="kt-header bg-white kt-header--fixed kt-header--minimize" [ngClass]="htmlClassService.getClasses('header', true)" id="kt_header">
	<ngb-progressbar class="kt-loading-bar" *ngIf="(loader.progress$|async) > 0" [value]="loader.progress$|async" height="3px"></ngb-progressbar>

	<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
		<!-- begin:: Brand -->
		<kt-brand></kt-brand>
		<!-- end:: Brand -->

		<!-- begin: Header Menu -->
		<kt-menu-horizontal></kt-menu-horizontal>

		
		<!-- end: Header Menu -->

		<!-- begin:: Header Topbar -->
		<!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
		<!-- <div *ngIf="!menuHeaderDisplay"></div> -->
		<!-- <kt-topbar></kt-topbar> -->
		<div class="kt-header__topbar kt-grid__item">
			<!--begin: Search -->
			<!-- <kt-search-dropdown [icon]="'./assets/media/icons/svg/General/Search.svg'" [useSVG]="true"></kt-search-dropdown> -->
			<!--end: Search -->
			<!--begin: Notifications -->
			<!-- <kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'"
				[pulse]="true"
				[pulseLight]="true"
				[skin]="'dark'" [icon]="'./assets/media/icons/svg/Code/Compiling.svg'" [useSVG]="true"></kt-notification> -->
			<!--end: Notifications -->
			<!--begin: Quick actions -->
			<!-- <kt-quick-action [bgImage]="'./assets/media/misc/bg-2.jpg'" [skin]="'dark'" [icon]="'./assets/media/icons/svg/Media/Equalizer.svg'" [useSVG]="true"></kt-quick-action> -->
			<!--end: Quick actions -->
			<!--begin: My Cart -->
			<!-- <kt-cart [bgImage]="'./assets/media/misc/bg-1.jpg'" [icon]="'./assets/media/icons/svg/Shopping/Cart3.svg'" [useSVG]="true"></kt-cart> -->
			<!--end: My Cart -->
			<!--begin: Quick panel toggler -->
			<!-- <div class="kt-header__topbar-item kt-header__topbar-item--quick-panel" data-placement="bottom" ngbTooltip="Quick panel">
				<div class="kt-header__topbar-wrapper"
				[attr.data-offset]="'10px,0px'">
					<span class="kt-header__topbar-icon" id="kt_quick_panel_toggler_btn">
						<span class="kt-svg-icon" [inlineSVG]="'./assets/media/icons/svg/Layout/Layout-4-blocks.svg'"></span>
					</span>
				</div>
			</div> -->
			<!--end: Quick panel toggler -->
			<div class="card-toolbar text-nowrap mt-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="" *ngIf="onDashboardPage" data-bs-original-title="Click to add a user">
				<a class="btn btn-primary  new-r-btn btn-primary-outline-new mr-3" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends" (click)="openGeneratedReport()">
					{{'TITLE.VIEW_GENERATED_REPORTS' | translate}}</a>
				
				<a class="btn btn-primary  new-r-btn btn-active-primary mr-2" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends" (click)="createProject()">
                            {{'TITLE.CREATE_PROJECT' | translate}}</a>
                            <!-- <a href="#" class="btn btn-primary btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
                                View All</a> -->
                        </div>
			<!--begin: Language bar -->
			<kt-language-selector></kt-language-selector>
			<!--end: Language bar -->
			<!--begin: User bar -->
			<kt-user-profile3></kt-user-profile3>
			<!--end: User bar -->
		</div>
		<!-- end:: Header Topbar -->
	</div>
</div>
<!-- end: Header -->
<!-- begin: Header -->
<div *ngIf="false" ktHeader #ktHeader class="kt-header kt-header--fixed" [ngClass]="htmlClassService.getClasses('header', true)" id="kt_header">
	<ngb-progressbar class="kt-loading-bar" *ngIf="(loader.progress$|async) > 0" [value]="loader.progress$|async" height="3px"></ngb-progressbar>

	<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
		<!-- begin:: Brand -->
		<kt-brand></kt-brand>
		<!-- end:: Brand -->

		<!-- begin: Header Menu -->
		<kt-menu-horizontal></kt-menu-horizontal>
		<!-- end: Header Menu -->

		<!-- begin:: Header Topbar -->
		<!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
		<!-- <div *ngIf="!menuHeaderDisplay"></div> -->
		<kt-topbar></kt-topbar>
		<!-- end:: Header Topbar -->
	</div>
</div>
<!-- end: Header -->
