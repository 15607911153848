/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pie-label.component";
var styles_PieLabelComponent = [];
var RenderType_PieLabelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PieLabelComponent, data: {} });
export { RenderType_PieLabelComponent as RenderType_PieLabelComponent };
export function View_PieLabelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "title", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 2, ":svg:g", [], [[1, "transform", 0], [4, "transform", null], [4, "transition", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, ":svg:text", [["class", "pie-label"], ["dy", ".35em"]], [[2, "animation", null], [4, "textAnchor", null], [4, "shapeRendering", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:path", [["class", "pie-label-line line"], ["fill", "none"]], [[1, "d", 0], [1, "stroke", 0], [2, "animation", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.attrTransform; var currVal_2 = _co.styleTransform; var currVal_3 = _co.textTransition; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.animations; var currVal_5 = _co.textAnchor(); var currVal_6 = "crispEdges"; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = (_co.labelTrim ? _co.trimLabel(_co.label, _co.labelTrimSize) : _co.label); _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.line; var currVal_9 = _co.color; var currVal_10 = _co.animations; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10); }); }
export function View_PieLabelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-pie-label", ""]], null, null, null, View_PieLabelComponent_0, RenderType_PieLabelComponent)), i0.ɵdid(1, 573440, null, 0, i1.PieLabelComponent, [], null, null)], null, null); }
var PieLabelComponentNgFactory = i0.ɵccf("g[ngx-charts-pie-label]", i1.PieLabelComponent, View_PieLabelComponent_Host_0, { data: "data", radius: "radius", label: "label", color: "color", max: "max", value: "value", explodeSlices: "explodeSlices", animations: "animations", labelTrim: "labelTrim", labelTrimSize: "labelTrimSize" }, {}, []);
export { PieLabelComponentNgFactory as PieLabelComponentNgFactory };
