/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i2 from "@ngx-formly/core";
import * as i3 from "@angular/common";
import * as i4 from "./clause-inputs-control";
var styles_ClauseInputsControlComponent = [];
var RenderType_ClauseInputsControlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClauseInputsControlComponent, data: {} });
export { RenderType_ClauseInputsControlComponent as RenderType_ClauseInputsControlComponent };
function View_ClauseInputsControlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "row row-added position-relative"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "formly-field", [["class", "main-col"]], null, null, null, i1.View_FormlyField_0, i1.RenderType_FormlyField)), i0.ɵdid(3, 16760832, null, 0, i2.FormlyField, [i2.FormlyConfig, i0.Renderer2, i0.ComponentFactoryResolver, i0.ElementRef, [8, null]], { field: [0, "field"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-danger btn-remove"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["X"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ClauseInputsControlComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClauseInputsControlComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fieldGroup; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.to.addText; _ck(_v, 5, 0, currVal_1); }); }
export function View_ClauseInputsControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-repeat-section", [], null, null, null, View_ClauseInputsControlComponent_0, RenderType_ClauseInputsControlComponent)), i0.ɵdid(1, 49152, null, 0, i4.ClauseInputsControlComponent, [[2, i2.FORMLY_CONFIG]], null, null)], null, null); }
var ClauseInputsControlComponentNgFactory = i0.ɵccf("formly-repeat-section", i4.ClauseInputsControlComponent, View_ClauseInputsControlComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { ClauseInputsControlComponentNgFactory as ClauseInputsControlComponentNgFactory };
