// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
// Angular in memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';

import { MatTooltipModule} from '@angular/material';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';

// Layout Services
import {
	DataTableService,
	FakeApiService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService
} from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthEffects, authReducer, AuthService } from './core/auth';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
import { LoadJsonComponent } from './load-json/load-json.component';
import { FormlyFieldStepper } from './stepper.type';
// import { FormlyFieldTabs } from './controls/tabs.type';
import { FormlyFieldButton } from './button.type';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CommonModule } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PanelWrapperComponent } from './panel-wrapper.component';
import { RepeatTypeComponent } from './controls/repeat-section.type';
import { ForgotAccountComponent } from './components/forgot-account/forgot-account.component';
import { TextInputControlComponent } from './text-input-control';
import { BulletInputControlComponent } from './controls/bullet-input-control';
import { TextwithBulletInputControlComponent } from './controls/text-with-bullet-input-control';
import { ClauseInputsControlComponent } from './controls/clause-inputs-control';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { QuillModule } from 'ngx-quill';
// import { FieldQuillType } from './controls/quill-input-control';
import { JadwaService } from './core/auth/_services/jadwa.service';
import { EditorComponent } from './controls/editor.component';
import { CustomMaterialModule } from './material.module';
import { InputControlsComponent } from './input-controls/input-controls.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';

import { JwtInterceptor, ErrorInterceptor } from './core/helpers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormlyFieldAccordian } from './accordian.type';
import { FormlyfieldfileComponent } from './controls/formlyfieldfile/formlyfieldfile.component';
import { FileValueAccessor } from './controls/formlyfieldfile/file-value-accessor';
import { FormlyFieldTabs } from './controls/formlyfieldtabs/formlyfieldtabs.component';
import { AutoCompleteControlComponent } from './controls/auto-complete-control/auto-complete-control.component';
import { PaymentComponent } from './components/payment/payment.component';
import { GeneratedReportsListComponent } from './components/dashboard/generated-reports-list/generated-reports-list.component';
import { TestComponent } from './components/test/test.component';

// import { FormlyFieldListWithHeading } from './list-with-heading.type';
// import { FormlyfieldtabsComponent } from './controls/formlyfieldtabs/formlyfieldtabs.component';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		} 
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{name: 'typescript', func: typescript},
		{name: 'scss', func: scss},
		{name: 'xml', func: xml},
		{name: 'json', func: json}
	];
}

@NgModule({
	declarations: [
		AppComponent,
		LoadJsonComponent,
		TestComponent,
		
		FormlyFieldStepper,
		FormlyFieldTabs,
		FormlyFieldAccordian,
		// FormlyFieldListWithHeading,
		PanelWrapperComponent,
		RepeatTypeComponent,
		FormlyFieldButton,
		EditorComponent,
		RegisterComponent,
		LoginComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		ForgotAccountComponent,
		TextInputControlComponent,
		BulletInputControlComponent,
		TextwithBulletInputControlComponent,
		ClauseInputsControlComponent,
		InputControlsComponent,
		DashboardComponent,
		PaymentComponent,
		// CreateProjectComponent,
		// ProjectPlansComponent,
		// FieldQuillType,
		FormlyfieldfileComponent,
		FileValueAccessor,
		AutoCompleteControlComponent,
		// FormlyfieldtabsComponent
		GeneratedReportsListComponent
	],
	imports: [
		SharedModule,
		CommonModule,
		ReactiveFormsModule,
		AngularEditorModule,
		MatProgressBarModule,
		// FormlyBootstrapModule,
		CustomMaterialModule,
		FormsModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		NgxIntlTelInputModule,
		MatTooltipModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgbModule,
		environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
			passThruUnknownUrl: true,
			dataEncapsulation: false
		}) : [],
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, {metaReducers}),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		InlineSVGModule.forRoot(),
		ThemeModule,
		// QuillModule.forRoot(),


		FormlyModule.forRoot({
			validationMessages: [
				{ name: 'required', message: 'This field is required' },
			],
			types: [
				// { name: 'stepper', component: FormlyFieldStepper, wrappers: [] },
				{ name: 'auto-complete-with-api', component: AutoCompleteControlComponent },
				{ name: 'tabs', component: FormlyFieldTabs },
				{ name: 'accordian', component: FormlyFieldAccordian, wrappers: [] },
				// { name: 'list-with-heading', component: FormlyFieldListWithHeading, wrappers: [] },

				
				{ name: 'clause-inputs', component: ClauseInputsControlComponent },
				{ name: 'text-input', component: TextInputControlComponent },
				{ name: 'bullet-input', component: BulletInputControlComponent },
				{ name :'text-with-bullets' , component: TextwithBulletInputControlComponent},
				{
					name: 'editor',
					component: EditorComponent,
					wrappers: ['form-field'],
				},
				{ name: 'file', component: FormlyfieldfileComponent, wrappers: ['form-field'] },
				// {
				//   name: 'quill',
				//   component: FieldQuillType,
				//   wrappers: ['form-field'],
				// },
				

			],
			wrappers: [
				{ name: 'panel', component: PanelWrapperComponent },
			],
		}),
	],
	exports: [
		CustomMaterialModule
		// FormlyfieldfileComponent
	],
	providers: [
		AuthService,
		JadwaService,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {languages: hljsLanguages}
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor, multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor, multi: true
		},
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
	],
	entryComponents : [
		// CreateProjectComponent,
		// ProjectPlansComponent
		GeneratedReportsListComponent
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
