<!-- begin:: Footer -->
<div class="kt-footer kt-footer--extended kt-grid__item" id="kt_footer" style="background-image: url('./assets/media/bg/bg-2.jpg');">
	<!-- <div class="kt-footer__top">
		<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
			<div class="row">
				<div class="col-lg-4">
					<div class="kt-footer__section">
						<h3 class="kt-footer__title">About</h3>
						<div class="kt-footer__content">
							Lorem Ipsum is simply dummy text of the printing<br>
							and typesetting and typesetting industry has been the <br>
							industry's standard dummy text ever since the 1500s,<br>
							when an unknown printer took a galley of type.
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="kt-footer__section">
						<h3 class="kt-footer__title">Quick Links</h3>
						<div class="kt-footer__content">
							<div class="kt-footer__nav">
								<div class="kt-footer__nav-section">
									<a href="javascript:;">General Reports</a>
									<a href="javascript:;">Dashboart Widgets</a>
									<a href="javascript:;">Custom Pages</a>
								</div>
								<div class="kt-footer__nav-section">
									<a href="javascript:;">User Setting</a>
									<a href="javascript:;">Custom Pages</a>
									<a href="javascript:;">Intranet Settings</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="kt-footer__section">
						<h3 class="kt-footer__title">Get In Touch</h3>
						<div class="kt-footer__content">
							<form action="" class="kt-footer__subscribe">
								<div class="input-group">
									<input type="text" class="form-control" placeholder="Enter Your Email">
									<div class="input-group-append">
										<button class="btn btn-brand" type="button">Join</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<div class="kt-footer__bottom">
		<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
			<div class="kt-footer__wrapper">
				<div class="kt-footer__logo">
					<a class="kt-header__brand-logo" routerLink="/">
						<img alt="Logo" src="./assets/media/logos/logo-4-sm.png" class="kt-header__brand-logo-sticky">
					</a>
					<div class="kt-footer__copyright">
						{{today | date:'yyyy'}}&nbsp;©&nbsp;
						<a href="https://jadwa.net/" target="_blank">{{'TITLE.JADWA' | translate}}</a>
					</div>
				</div>
				<div class="kt-footer__menu">
					<!-- <a href="https://jadwa.net/" target="_blank">Purchase Lisence</a> -->
					<!-- <a href="https://jadwa.net/" target="_blank">Team</a>
					<a href="https://jadwa.net/" target="_blank">Contact</a> -->
				</div>
			</div>
		</div>
	</div>
</div>
<!-- end:: Footer -->
