import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let urlInAllowedListForAuthorizationHeader:boolean = false;
    for(let index=0;index<environment.allowedURLsForAuthorizationHeader.length;index++)
    {
      if(request.url.includes(environment.allowedURLsForAuthorizationHeader[index]))
      {
        urlInAllowedListForAuthorizationHeader = true;
        break;
      }
    }
    if(urlInAllowedListForAuthorizationHeader)
    {
      let user: any = localStorage.getItem('userInfo');
      user = JSON.parse(user);
      if (user) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${user.tokenResponse.jwToken}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
