// Angular
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, CurrentUser } from '../../core/auth';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { TranslationService } from '../../../app/core/_base/layout/services/translation.service';
import * as moment from 'moment';




@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	today = new Date();
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		// private changeDetector: ChangeDetectorRef,
		private translationService: TranslationService
	) {
		this.unsubscribe = new Subject();
		let user: any = localStorage.getItem('userInfo');
		user = JSON.parse(user);
		if (user) {
			console.log(moment());
			console.log(moment(moment.utc(user.tokenResponse.expiresOn).toDate()).local());
			console.log(moment().diff(moment(moment.utc(user.tokenResponse.expiresOn).toDate()).local(), 'minutes'));
			if (moment(moment.utc(user.tokenResponse.expiresOn).toDate()).local().diff(moment(), 'minutes') >= 1) {
				this.router.navigateByUrl('/dashboard');
			}
			else {
				localStorage.removeItem('userInfo');
				localStorage.removeItem('userId');
			}
		}
		else {
			localStorage.removeItem('userInfo');
			localStorage.removeItem('userId');
		}
	}

	changeLanguage(lang) {
		this.translationService.setLanguage(lang);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.fbLibrary();
		this.googleSDK();
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		// if (!this.authNoticeService.onNoticeChanged$.getValue()) {
		// 	const initialNotice = `Use account
		// 	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
		// 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
		// 	this.authNoticeService.setNotice(initialNotice, 'info');
		// }

		this.loginForm = this.fb.group({
			email: [
				null
				// DEMO_PARAMS.EMAIL
				, Validators.compose([
					Validators.required,
					Validators.email,
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			password: [
				null,
				// DEMO_PARAMS.PASSWORD,
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100)
				])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			"email": controls.email.value,
			"password": controls.password.value,
			"isSocialLogin": false
		};

		this.auth.JADWALogin(authData).subscribe((response: any) => {
			this.loading = false;
			//   localStorage.setItem('userInfo',JSON.stringify(response.result));
			localStorage.setItem('userId', response.result.id);
			this.store.dispatch(new Login({ authToken: response.result.tokenResponse.jwToken }));
			this.store.dispatch(new CurrentUser({ userInfo: JSON.stringify(response.result) }))
			// this.router.navigateByUrl(this.returnUrl);
			this.router.navigateByUrl('/dashboard');

		}, error => {
			this.loading = false;
			Swal.fire({
				title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#37c0b9',
				confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
		// this.auth
		// 	.login(authData.email, authData.password)
		// 	.pipe(
		// 		tap(user => {
		// 			if (user) {
		// 				this.store.dispatch(new Login({authToken: user.accessToken}));
		// 				this.router.navigateByUrl(this.returnUrl); // Main page
		// 			} else {
		// 				this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
		// 			}
		// 		}),
		// 		takeUntil(this.unsubscribe),
		// 		finalize(() => {
		// 			this.loading = false;
		// 			this.cdr.markForCheck();
		// 		})
		// 	) .subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}






	fbLibrary() {

		(window as any).fbAsyncInit = function () {
			window['FB'].init({
				appId: environment.facebookAppID,//'141940951434140',
				cookie: true,
				xfbml: true,
				version: 'v3.1'
			});
			window['FB'].AppEvents.logPageView();
		};

		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}
	fblogin() {
		window['FB'].login((response) => {
			console.log('login response', response);
			if (response.authResponse) {

				window['FB'].api('/me', {
					fields: 'last_name, first_name, email'
				}, (userInfo) => {
					this.socialLogin(userInfo.email, 'facebook', userInfo.id);
				});
			}
		}, { scope: 'email' });
	}


	auth2: any;

	@ViewChild('loginRef', { static: true }) loginElement: ElementRef;

	prepareLoginButton() {
		this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
			(googleUser) => {
				let profile = googleUser.getBasicProfile();
				this.socialLogin(profile.getEmail(), 'google', profile.getId());
			}, (error) => { });

	}

	googleSDK() {

		window['googleSDKLoaded'] = () => {
			window['gapi'].load('auth2', () => {
				this.auth2 = window['gapi'].auth2.init({
					client_id: environment.clientId,//'880593392183-6jsidf2410l9gqsrgth6u10bi9347284.apps.googleusercontent.com',
					cookiepolicy: 'single_host_origin',
					scope: 'profile email'
				});
				this.prepareLoginButton();
			});
		}

		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'google-jssdk'));

	}

	socialLogin(email, socialPlatform, providerkey) {

		const authData = {
			"email": email,
			"loginProvider": socialPlatform,
			"providerkey": providerkey,
			"isSocialLogin": true
		}
		this.loading = true;
		this.auth.JADWALogin(authData).subscribe((response: any) => {


			this.loading = false;
			if (response.result.id) {

				localStorage.setItem('userInfo', JSON.stringify(response.result));
				localStorage.setItem('userId', response.result.id);
				this.store.dispatch(new Login({ authToken: response.result.tokenResponse.jwToken }));
				this.router.navigateByUrl('/dashboard');
				// this.router.navigate(['/dashboard']);
				try { this.cdr.detectChanges(); } catch (e) { }
			}
		}, error => {
			this.loading = false;
			Swal.fire({
				title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				// title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#37c0b9',
				confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
	}
	showPassword: boolean;
	showPasswordFun() {
		this.showPassword = !this.showPassword;
	}
}

