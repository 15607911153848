import * as tslib_1 from "tslib";
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { ProductRemarksService } from '../_services/';
// Actions
import { ProductRemarkActionTypes, ProductRemarksPageLoaded, ProductRemarksPageToggleLoading, ProductRemarkCreated } from '../_actions/product-remark.actions';
var ProductRemarkEffects = /** @class */ (function () {
    function ProductRemarkEffects(actions$, productRemarksService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.productRemarksService = productRemarksService;
        this.store = store;
        // showLoadingDistpatcher = new ProcutRemarksPageToggleLoading({ isLoading: true });
        this.hideLoadingDistpatcher = new ProductRemarksPageToggleLoading({ isLoading: false });
        this.loadProductRemarksPage$ = this.actions$
            .pipe(ofType(ProductRemarkActionTypes.ProductRemarksPageRequested), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this.productRemarksService.findProductRemarks(payload.page, payload.productId);
        }), map(function (result) {
            return new ProductRemarksPageLoaded({
                productRemarks: result.items,
                totalCount: result.totalCount
            });
        }));
        this.deleteProductRemark$ = this.actions$
            .pipe(ofType(ProductRemarkActionTypes.OneProductRemarkDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductRemarksPageToggleLoading({ isLoading: true }));
            return _this.productRemarksService.deleteProductRemark(payload.id);
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
        this.deleteProductRemarks$ = this.actions$
            .pipe(ofType(ProductRemarkActionTypes.ManyProductRemarksDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductRemarksPageToggleLoading({ isLoading: true }));
            return _this.productRemarksService.deleteProductRemarks(payload.ids);
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
        this.updateProductRemark$ = this.actions$
            .pipe(ofType(ProductRemarkActionTypes.ProductRemarkUpdated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductRemarksPageToggleLoading({ isLoading: true }));
            return _this.productRemarksService.updateProductRemark(payload.productRemark);
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
        this.createProductRemark$ = this.actions$
            .pipe(ofType(ProductRemarkActionTypes.ProductRemarkOnServerCreated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductRemarksPageToggleLoading({ isLoading: true }));
            return _this.productRemarksService.createProductRemark(payload.productRemark).pipe(tap(function (res) {
                _this.store.dispatch(new ProductRemarkCreated({ productRemark: res }));
            }));
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductRemarkEffects.prototype, "loadProductRemarksPage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductRemarkEffects.prototype, "deleteProductRemark$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductRemarkEffects.prototype, "deleteProductRemarks$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductRemarkEffects.prototype, "updateProductRemark$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductRemarkEffects.prototype, "createProductRemark$", void 0);
    return ProductRemarkEffects;
}());
export { ProductRemarkEffects };
