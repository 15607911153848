/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "./language-selector.component";
import * as i4 from "../../../../../core/_base/layout/services/translation.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/router";
var styles_LanguageSelectorComponent = [];
var RenderType_LanguageSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LanguageSelectorComponent, data: {} });
export { RenderType_LanguageSelectorComponent as RenderType_LanguageSelectorComponent };
function View_LanguageSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "li", [["class", "kt-nav__item"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "kt-nav__item--active": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 7, "a", [["class", "kt-nav__link"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLanguage(_v.context.$implicit.lang) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(7, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(8, { "kt-nav__link--active": 0 }), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "kt-nav__link-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "kt-nav__link-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "kt-nav__item"; var currVal_1 = _ck(_v, 4, 0, _v.context.$implicit.active); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "kt-nav__link"; var currVal_3 = _ck(_v, 8, 0, _v.context.$implicit.active); _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.flag, ""); _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.context.$implicit.name; _ck(_v, 12, 0, currVal_5); }); }
export function View_LanguageSelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "kt-header__topbar-item kt-header__topbar-item--langs"], ["ngbDropdown", ""], ["placement", "bottom"]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(1, 1720320, null, 3, i2.NgbDropdown, [i0.ChangeDetectorRef, i2.NgbDropdownConfig, i1.DOCUMENT, i0.NgZone, i0.ElementRef, i0.Renderer2, [2, i2.NgbNavbar]], { placement: [0, "placement"] }, null), i0.ɵqud(603979776, 1, { _menu: 0 }), i0.ɵqud(603979776, 2, { _menuElement: 0 }), i0.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["aria-haspopup", "true"], ["class", "kt-header__topbar-wrapper dropdown-toggle"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i0.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i0.ɵeld(8, 0, null, null, 4, "span", [["class", "kt-header__topbar-icon"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(11, { "kt-header__topbar-icon--brand": 0 }), (_l()(), i0.ɵeld(12, 0, null, null, 0, "img", [["alt", ""], ["class", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, [[2, 0]], null, 5, "div", [["aria-labelledby", "dropdownBasic1"], ["class", "dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i0.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i0.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i0.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i0.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i0.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i0.ɵeld(16, 0, null, null, 2, "ul", [["class", "kt-nav kt-margin-t-10 kt-margin-b-10"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LanguageSelectorComponent_1)), i0.ɵdid(18, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_1); var currVal_3 = "kt-header__topbar-icon"; var currVal_4 = _ck(_v, 11, 0, (_co.iconType === "brand")); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_9 = _co.languages; _ck(_v, 18, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i0.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_2); var currVal_5 = i0.ɵinlineInterpolate(1, "", ((_co.language == null) ? null : _co.language.flag), ""); _ck(_v, 12, 0, currVal_5); var currVal_6 = true; var currVal_7 = i0.ɵnov(_v, 14).dropdown.isOpen(); var currVal_8 = i0.ɵnov(_v, 14).placement; _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_LanguageSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-language-selector", [], [[8, "className", 0]], null, null, View_LanguageSelectorComponent_0, RenderType_LanguageSelectorComponent)), i0.ɵdid(1, 114688, null, 0, i3.LanguageSelectorComponent, [i4.TranslationService, i5.TranslateService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var LanguageSelectorComponentNgFactory = i0.ɵccf("kt-language-selector", i3.LanguageSelectorComponent, View_LanguageSelectorComponent_Host_0, { iconType: "iconType" }, {}, []);
export { LanguageSelectorComponentNgFactory as LanguageSelectorComponentNgFactory };
