/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./portlet-body.component";
var styles_PortletBodyComponent = [];
var RenderType_PortletBodyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PortletBodyComponent, data: {} });
export { RenderType_PortletBodyComponent as RenderType_PortletBodyComponent };
export function View_PortletBodyComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_PortletBodyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-portlet-body", [], [[8, "className", 0]], null, null, View_PortletBodyComponent_0, RenderType_PortletBodyComponent)), i0.ɵdid(1, 114688, null, 0, i1.PortletBodyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classList; _ck(_v, 0, 0, currVal_0); }); }
var PortletBodyComponentNgFactory = i0.ɵccf("kt-portlet-body", i1.PortletBodyComponent, View_PortletBodyComponent_Host_0, { class: "class" }, {}, ["*"]);
export { PortletBodyComponentNgFactory as PortletBodyComponentNgFactory };
