<kt-portlet>

	<kt-portlet-header [sticky]="true" [title]="'Products list'" [class]="'kt-portlet__head--lg'" [viewLoading$]="dataSource.loading$">
		<ng-container ktPortletTools>
			<button (click)="createProduct()" mat-raised-button color="primary" matTooltip="Create new product">New product</button>
		</ng-container>
	</kt-portlet-header>
	<!-- end::Header -->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="kt-form kt-margin-b-30">
			<!-- start::FILTERS -->
			<div class="kt-form__filtration">
				<div class="row align-items-center">
					<div class="col-md-2 kt-margin-bottom-10-mobile">

						<div class="kt-form__control">
							<mat-form-field>
								<mat-select [(value)]="filterStatus" (selectionChange)="loadProductsList()" class="mat-form-field mat-form-field-fluid">
									<mat-option value="">All</mat-option>
									<mat-option value="0">Selling</mat-option>
									<mat-option value="1">Sold</mat-option>
								</mat-select>
								<mat-hint align="start">
									<strong>Filter by Status</strong>
								</mat-hint>
							</mat-form-field>
						</div>

					</div>
					<div class="col-md-2 kt-margin-bottom-10-mobile">
						<div class="kt-form__control">
							<mat-form-field>
								<mat-select [(value)]="filterCondition" (selectionChange)="loadProductsList()" class="mat-form-field mat-form-field-fluid">
									<mat-option value="">All</mat-option>
									<mat-option value="0">New</mat-option>
									<mat-option value="1">Used</mat-option>
								</mat-select>
								<mat-hint align="start">
									<strong>Filter by Contidion</strong>
								</mat-hint>
							</mat-form-field>
						</div>
						<div class="d-md-none kt-margin-bottom-10"></div>
					</div>
					<div class="col-md-4 kt-margin-bottom-10-mobile">
						<mat-form-field>
							<input matInput placeholder="Search product" #searchInput placeholder="Search" class="mat-form-field mat-form-field-fluid">
							<mat-hint align="start">
								<strong>Search in all fields</strong>
							</mat-hint>
						</mat-form-field>
					</div>
				</div>
			</div>
			<!-- end::FILTERS -->

			<!-- start::GROUP ACTIONS -->
			<!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
			<!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
			<div class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20" [ngClass]="{'show' : selection.selected.length > 0}">
				<div class="col-xl-12">
					<div class="kt-form__group kt-form__group--inline">
						<div class="kt-form__label kt-form__label-no-wrap">
							<label class="kt--font-bold kt-font-danger-">Selected records count: {{ selection.selected.length }}</label>
						</div>
						<div class="kt-form__control kt-form__group--inline">
							<button mat-raised-button color="accent" (click)="deleteProducts()" matTooltip="Delete selected products"  class="mat-button-mt-4">
								<mat-icon>delete</mat-icon>
								Delete All
							</button>&nbsp;<!-- Call 'delete-entity-dialog' from _shared folder -->
							<button mat-raised-button (click)="fetchProducts()" matTooltip="Fetch selected products"  class="mat-button-mt-4">
								<mat-icon>clear_all</mat-icon>
								Fetch Selected Records
							</button>&nbsp;<!-- Call 'fetch-entity-dialog' from _shared folder -->
							<button mat-raised-button (click)="updateStatusForProducts()" matTooltip="Update status for selected products"  class="mat-button-mt-4">
								<mat-icon>update</mat-icon>
								Update status
							</button><!-- Call 'update-stated-dialog' from _shared folder -->
						</div>
					</div>
				</div>
			</div>
			<!-- end::GROUP ACTIONS -->
		</div>
		<!-- end::FILTERS & GROUP ACTIONS -->

		<!-- MATERIAL TABLE | Binded to datasources -->
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
		<div class="mat-table__wrapper">
			<!-- Checkbox Column -->
			<!-- Table with selection -->
			<!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
			<mat-table class="lmat-elevation-z8"
				#table
				[dataSource]="dataSource"
				matSort
				#sort1="matSort"
				matSortActive="id"
				matSortDirection="asc"
				matSortDisableClear>
				<!-- Checkbox Column -->
				<ng-container matColumnDef="select">
					<mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
						<mat-checkbox (change)="$event ? masterToggle() : null"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()"
							[color]="'primary'">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *matCellDef="let row" class="mat-column-checkbox">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(row) : null"
							[checked]="selection.isSelected(row)"
							[color]="'primary'">
						</mat-checkbox>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="VINCode">
					<mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-vinCode">VIN Code (ID)</mat-header-cell>
					<mat-cell *matCellDef="let product" class="mat-column-vinCode">{{product.VINCode}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="manufacture">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Manufacture</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.manufacture}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="model">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Model</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.model}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="modelYear">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Model Year</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.modelYear}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="color">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Color</mat-header-cell>
					<mat-cell *matCellDef="let product" [ngStyle]="{ 'color' : product.color }">{{product.color}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="price">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
					<mat-cell *matCellDef="let product">${{product.price}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="status">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
					<mat-cell *matCellDef="let product">
						<span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--{{ getItemCssClassByStatus(product.status) }} kt-badge--wide">{{ getItemStatusString(product.status) }}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="condition">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Condition</mat-header-cell>
					<mat-cell *matCellDef="let product">
						<span class="kt-badge kt-badge--{{ getItemCssClassByCondition(product.condition) }} kt-badge--dot"></span>&nbsp;
						<span class="kt--font-bold kt-font-{{ getItemCssClassByCondition(product.condition) }}">{{ getItemConditionString(product.condition) }}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>
						Actions
					</mat-header-cell>
					<mat-cell *matCellDef="let product">
						<button type="button" (click)="editProduct(product.id)" mat-icon-button color="primary" matTooltip="Edit product">
							<mat-icon>create</mat-icon>
						</button>&nbsp;
						<button mat-icon-button color="warn" type="button" (click)="deleteProduct(product)" matTooltip="Delete product">
							<mat-icon>delete</mat-icon>
						</button>&nbsp;
						<button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="More actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item type="button">
								<mat-icon>subject</mat-icon>
								<span>Remarks</span>
							</button>
							<button mat-menu-item type="button">
								<mat-icon>assignment</mat-icon>
								<span>Specifications</span>
							</button>
						</mat-menu>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table__bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>
