import * as tslib_1 from "tslib";
// RxJS
import { of, Observable, defer, forkJoin } from 'rxjs';
import { mergeMap, map, withLatestFrom, filter, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
// Services
import { AuthService } from '../_services';
// Selectors
import { allRolesLoaded } from '../_selectors/role.selectors';
// Actions
import { AllRolesLoaded, AllRolesRequested, RoleActionTypes, RolesPageLoaded, RolesPageToggleLoading, RoleCreated, RolesActionToggleLoading } from '../_actions/role.actions';
var RoleEffects = /** @class */ (function () {
    function RoleEffects(actions$, auth, store) {
        var _this = this;
        this.actions$ = actions$;
        this.auth = auth;
        this.store = store;
        this.showPageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: true });
        this.hidePageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: false });
        this.showActionLoadingDistpatcher = new RolesActionToggleLoading({ isLoading: true });
        this.hideActionLoadingDistpatcher = new RolesActionToggleLoading({ isLoading: false });
        this.loadAllRoles$ = this.actions$
            .pipe(ofType(RoleActionTypes.AllRolesRequested), withLatestFrom(this.store.pipe(select(allRolesLoaded))), filter(function (_a) {
            var action = _a[0], isAllRolesLoaded = _a[1];
            return !isAllRolesLoaded;
        }), mergeMap(function () { return _this.auth.getAllRoles(); }), map(function (roles) {
            return new AllRolesLoaded({ roles: roles });
        }));
        this.loadRolesPage$ = this.actions$
            .pipe(ofType(RoleActionTypes.RolesPageRequested), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showPageLoadingDistpatcher);
            var requestToServer = _this.auth.findRoles(payload.page);
            var lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }), map(function (response) {
            var result = response[0];
            var lastQuery = response[1];
            _this.store.dispatch(_this.hidePageLoadingDistpatcher);
            return new RolesPageLoaded({
                roles: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
        }));
        this.deleteRole$ = this.actions$
            .pipe(ofType(RoleActionTypes.RoleDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.auth.deleteRole(payload.id);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.updateRole$ = this.actions$
            .pipe(ofType(RoleActionTypes.RoleUpdated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.auth.updateRole(payload.role);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.createRole$ = this.actions$
            .pipe(ofType(RoleActionTypes.RoleOnServerCreated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.auth.createRole(payload.role).pipe(tap(function (res) {
                _this.store.dispatch(new RoleCreated({ role: res }));
            }));
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.init$ = defer(function () {
            return of(new AllRolesRequested());
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoleEffects.prototype, "loadAllRoles$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoleEffects.prototype, "loadRolesPage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoleEffects.prototype, "deleteRole$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoleEffects.prototype, "updateRole$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], RoleEffects.prototype, "createRole$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RoleEffects.prototype, "init$", void 0);
    return RoleEffects;
}());
export { RoleEffects };
