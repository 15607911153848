import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';

import jspdf from 'jspdf';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import Swal from 'sweetalert2';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { multi, single } from './data';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PaymentRequestUrway } from '../../../app/core/auth/_models/payments.model';
import { BarHorizontalComponent, AdvancedPieChartComponent, BarVerticalComponent, LineChartComponent, PieChartComponent, PieGridComponent, AreaChartComponent } from '@swimlane/ngx-charts';
import { BarHorizontal2DComponent, BarHorizontalStackedComponent, BarVertical2DComponent, BarVerticalStackedComponent, TreeMapComponent } from 'ngx-charts-8';
import { barChart, lineChartSeries } from './combo-chart-data';
import { ComboChartComponent } from '../third-party-open-source/jedwa-custom-ngx-charts/custom-chart/combo-chart';


@Component({
  selector: 'kt-plan-report',
  templateUrl: './plan-report.component.html',
  styleUrls: ['./plan-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanReportComponent implements OnInit {

  /*spinner related */

  showMessageInSpinner:boolean = false;
  spinnerMessage:string="";
  showSpinnerProgressBar:boolean= false;
  spinnerProgressPercentage:number=0;
  /*spinner related */

  barChart: any[] = barChart;
  lineChartSeries: any[] = lineChartSeries;
  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };
  animations: boolean = true;
  tooltipDisabled = false;
  yAxisLabelRight: string = 'Utilization';
  noBarWhenZero = true;




  loading: boolean;
  userProejctPlans: any;
  proejctPlans: any
  chart: string = 'RevenueAndMargin';
  single: any[];
  multi: any[];
  view: any[] = [500, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient: boolean = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  comboBarChartDefaultOptions: Partial<ComboChartComponent & {lineData:any,barData:any}> = {
    view: [500, 400],
    scheme: {
      name: 'singleLightBlue',
      selectable: true,
      group: 'Ordinal',
      domain: ['#01579b']
    },
    //@ts-ignore
    colorSchemeLine: { name: 'coolthree',
      selectable: true,
      group: 'Ordinal',
      domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
    },
    gradient: false,
    animations: true,
    tooltipDisabled: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showRightYAxisLabel: true,
    showGridLines: true,
    yAxisLabelRight: "Y Axis Label Right",
    barPadding: 8,
    legendPosition: 'below',
    noBarWhenZero: true,
    lineData:lineChartSeries,
    barData:barChart,
    legendTitle:"'Combo Graph'"    
  };

  verticalBarChartDefaultOptions: Partial<BarVerticalComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    //schemeType:'ordinal',
    showGridLines: true,
    //roundDomains:false,
    barPadding: 8,
    legendPosition: 'below'
  };


  groupedVerticalBarChartDefaultOptions: Partial<BarVertical2DComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    groupPadding: 16
  };

  groupedHorizontalBarChartDefaultOptions: Partial<BarHorizontal2DComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    groupPadding: 16
  };

  stackedVerticalBarChartDefaultOptions: Partial<BarVerticalStackedComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    legendTitle: "Stacked Vertical Chart"
  };

  stackedHorizontalBarChartDefaultOptions: Partial<BarHorizontalStackedComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    legendTitle: "Stacked Vertical Chart"
  };

  horizontalBarChartDefaultOptions: Partial<BarHorizontalComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    //schemeType:'ordinal',
    showGridLines: true,
    //roundDomains:false,
    barPadding: 8,
    legendPosition: 'below'
  };

  lineChartDefaultOptions: Partial<LineChartComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    schemeType: "ordinal",
    rangeFillOpacity: 0.15,
    legend: true,
    legendTitle: "Line Chart",
    legendPosition: 'below',

    gradient: false,
    xAxis: true,
    yAxis: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    roundDomains: false,
    showGridLines: true,
    trimXAxisTicks: false,
    trimYAxisTicks: false,
    autoScale: true
  };
  areaChartDefaultOptions: Partial<AreaChartComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    schemeType: "ordinal",
    legend: true,
    legendTitle: "Line Chart",
    legendPosition: 'below',

    gradient: false,
    xAxis: true,
    yAxis: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    roundDomains: false,
    showGridLines: true,
    trimXAxisTicks: false,
    trimYAxisTicks: false,
    autoScale: true,
    timeline: false
  };

  advancedPieChartDefaultOptions: Partial<AdvancedPieChartComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    label: "Pie Chart",
  };

  treeMapDefaultOptions: Partial<TreeMapComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false
  };

  colorScheme = {
    domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
  };

  pieChartDefaultOptions: Partial<PieChartComponent> = {
    view: [700, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    legend: true,
    legendTitle: "Pie Chart",
    legendPosition: 'below',
    doughnut: false,
    labels: false,
    arcWidth: 0.25
  }

  pieGridChartDefaultOptions: Partial<PieGridComponent> = {
    view: [700, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    label: "Total",
    minWidth: 150
  }


  // For Line Chart
  autoScale: boolean = true;


  // For Pie Chart
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  currentLanguage: string = 'en';;
  private subscriptions: Subscription[] = [];


  userId: string;
  projectId: string;
  planId: string;
  reportId: string;
  report: any;
  singleReport: boolean;
  heightLeft: number;
  pageHeight: number;
  plan: any;
  toggleHideGraphs:boolean = false;

  constructor(
    private jadwaService: JadwaService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data,
    private cdr: ChangeDetectorRef
  ) {


    //this.view = [700];

    this.userId = localStorage.getItem('userId');
    this.projectId = data.projectId;
    this.planId = data.planId;
    this.report = data.report;
    this.reportId = data.report.reportId;
    this.singleReport = data.singleReport;
    this.plan = data.plan;
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.projectId = params['id']
    // });
    // Object.assign(this, { single });


  }

  finalVar: any;

  ngOnInit() {

    const sortSubscription = this.store.pipe(
      select(AuthState => AuthState)
    ).subscribe(State => {
      //@ts-ignore
      this.currentLanguage = State.auth.language;
    });
    this.subscriptions.push(sortSubscription);

    //Revenue and margin
    // this.onProjectChange(this.projectId);
    this.getPlanReport();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  payForReport(plan) {
    let requestObj: PaymentRequestUrway = {
      "userId": this.userId,
      "projectId": this.projectId,
      "planId": plan.planId
    };
    Swal.fire({
      title: this.translate.instant('TITLE.PLEASE_WAIT_REDERECTING_TO_PAYMENT_PAGE'),
      // title: ,
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#37c0b9',
      confirmButtonText: this.translate.instant('TITLE.OKAY'),
    });
    this.jadwaService.createPaymentRequestForPlan(requestObj).subscribe(response => {
      requestObj.planName = plan.name;
      requestObj.planArName = plan.arName;
      sessionStorage.setItem("planDetailsForPayment", JSON.stringify(requestObj));
      window.open(response.result.transUrl, "_self");
    })

  }




  planReport: any;
  reportHtmlTemplate: string;
  reportObject: any = [];
  getPlanReport() {
    const requestObj = {
      "userId": this.userId,//any valid user id
      "projectId": this.projectId,
      "planId": this.planId,
      "reportId": this.reportId,
      "customReportName": "string"
    }
    this.loading = true;
    this.jadwaService.getPlanReport(requestObj).subscribe((response: any) => {
      // this.loading = false;
      this.planReport = response.result;

      if (response.result.reportData) {
        response.result.reportData.forEach(section => {
          let computedSectionDataObj;

          if (!!section.computedSectionData) {
            let computedSectionData = JSON.parse(section.computedSectionData);
            computedSectionData.forEach(csd => {


              // if(csd.placeholderType != 'Chart'){
              //   let placeholderValue = JSON.parse(csd.placeholderValue);
              //   if(!!placeholderValue && !!placeholderValue.value){
              //     csd['placeholderValue'] = placeholderValue.value;
              //   }
              // }

              if (csd.placeholderType == 'Chart') {
                let placeholderValue = JSON.parse(csd.placeholderValue);
                csd['placeholderValue'] = placeholderValue;
              }

              if (csd.placeholderType == 'HtmlTable') {
                let placeholderValue = this.sanitizer.bypassSecurityTrustHtml(csd.placeholderValue);
                // let placeholderValue = JSON.parse(csd.placeholderValue);
                csd['placeholderValue'] = placeholderValue;
              }

            });
            computedSectionDataObj = computedSectionData;
          }



          this.reportObject.push({
            sectionId: section.sectionId,
            htmlTemplate: section.htmlTemplate,
            arHtmlTemplate: section.arHtmlTemplate,
            hasPlaceholder: section.hasPlaceholder,
            computedSectionData: computedSectionDataObj,
            // computedSectionData : (!!section.computedSectionData)?JSON.parse(section.computedSectionData): null,
            order: section.order
          });
        });
      }

      setTimeout(() => {
        this.finalOutput(response.result.reportData, 'bbb');
      }, 2000);





    }, error => {
      this.loading = false;
      Swal.fire({
        title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    });
  }

  changePlan(plan) {
    // this.loading = true;
    // getProjectReport()
  }


  resetSpinnerDefaults()
  {
    setTimeout(()=>{
      this.showMessageInSpinner = false;
      this.spinnerMessage="";
      this.showSpinnerProgressBar= false;
      this.spinnerProgressPercentage=0;
      this.cdr.detectChanges();
    },1);
  }









  onResize(event) {
    this.view = [event.target.innerWidth / 1.35, 400];
  }

  onSelect(data) {
    // console.log(data);
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }


  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  footer(heightLeft, pageHeight) {
    heightLeft -= pageHeight;
  }
  

  downloadPDFNewFormat() {
    setTimeout(() => {
      this.loading= true;
    this.showMessageInSpinner = true;
    this.spinnerMessage= "Please wait, generating pdf file in progress.";
    this.showSpinnerProgressBar = true;
    this.spinnerProgressPercentage =0;
    this.cdr.detectChanges();
    }, 1);
    
    let startDate= new Date();
    var doc:jspdf;
    var imgWidth = 0, imageHeight = 0;
    if (this.planReport.orientation == 'Landscape') {
      imgWidth = 29.5;
      imageHeight = 21;
      doc = new jspdf('landscape', 'cm', 'a4', true);
      
    } else {
      imgWidth = 21;
      imageHeight = 29.5;
      doc = new jspdf('p', 'cm', 'a4', true);
    }
    let pagesAddedSofar = 0;

    let a4AllBlocks = document.getElementsByClassName("a4-block");
      
      let convertHtmlToImagesAndAddToPDF =(index)=>{
         let data = a4AllBlocks.item(index) as HTMLElement;
          /*below 2 lines only if html-to-image libarary not html2canvas */
         data.style.setProperty('margin','0 0');
         data.style.setProperty('margin-bottom','30px');
          /*above 2 lines only if html-to-image libarary not html2canvas */

          console.log("number of pages",a4AllBlocks.length);
          console.log("processing html2cnavas item",index,data);
        /*
        commented html2canvas
          html2canvas(data).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          */
          htmlToImage.toPng(data).then(dataURL=>{
           /*below 2 lines only if html-to-image libarary not html2canvas */
           data.style.setProperty('margin','0 auto');
           data.style.setProperty('margin-bottom','30px');
           /*above 2 lines only if html-to-image libarary not html2canvas */
          pagesAddedSofar = pagesAddedSofar + 1;
          if(pagesAddedSofar !==1)
          {
            doc.addPage();
          }
          doc.addImage(dataURL, 'PNG', 0, 0, imgWidth, imageHeight, undefined, 'FAST');    
          setTimeout(()=>{
            this.spinnerProgressPercentage = (pagesAddedSofar/a4AllBlocks.length)*100;
            this.cdr.detectChanges();
          },1)      
          if(pagesAddedSofar !=a4AllBlocks.length)
          {
            convertHtmlToImagesAndAddToPDF(pagesAddedSofar);
          }
          if (pagesAddedSofar === a4AllBlocks.length) {
          
            let blobObject = doc.output('blob');
              var formData = new FormData();
              formData.append("file", blobObject, 'reportgenerated.pdf');
              formData.append("FolderId", "1");
              formData.append("IgnoreTimestamp", "false");
              this.jadwaService.uploadGeneratedReportPDF(formData).subscribe(response => {
                //console.log(response);
                this.jadwaService.saveGeneratedReportPath({ userReportId: this.planReport.userReportId, reportPath: response['result'] }).subscribe(savePathResponse => {
                  //console.log('savePathResponse',savePathResponse);
                })
              })

              console.log("time taken to pdf genearation in milliseconds",new Date().getMilliseconds() - startDate.getMilliseconds());


              //@ts-ignore
              doc.save('reportgenerated.pdf', { returnPromise: true }).then(
                (finalFile) => {
                  //console.log("finalFile",finalFile);
                },
                (error) => {
                  console.log("error occured in generating file", error);
                }
              );
              this.loading = false;
              this.resetSpinnerDefaults();
              
            }
        },
        errorOccuredWhileGeneratingImageRejected=>{
          console.error("failed to add page", pagesAddedSofar);
          console.error(errorOccuredWhileGeneratingImageRejected);
          /*add next page */
          pagesAddedSofar = pagesAddedSofar+1;
          convertHtmlToImagesAndAddToPDF(pagesAddedSofar);

        });
      }
      if(a4AllBlocks.length>0)
      {
        convertHtmlToImagesAndAddToPDF(0);
      }
  }



  //Landscape Dimensions

  downloadPDF() {
    let data = document.getElementById("pdfsection");
    html2canvas(data).then(canvas => {

      var imgData = canvas.toDataURL('image/png');

      let imgWidth = 295;
      let pageHeight = 210;
      var doc;
      if (this.planReport.orientation == 'Landscape') {
        imgWidth = 295;
        pageHeight = 210;
        doc = new jspdf('l', 'mm', 'a4', true);
      } else {
        imgWidth = 210;
        pageHeight = 295;
        doc = new jspdf('p', 'mm', 'a4', true);
      }

      var imgHeight = canvas.height * imgWidth / canvas.width;
      this.heightLeft = imgHeight;
      // var doc = new jspdf('p', 'mm');
      // var doc = new jspdf('l', 'cm','a4',true);

      var position = 0;

      // const footer = "footer";
      doc.text("Header Text", 0, 10, { baseline: 'top' });
      doc.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight);
      // doc.text(`Footer`, imgWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' }); 

      this.heightLeft -= pageHeight;

      while (this.heightLeft >= 0) {
        position = this.heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        this.heightLeft -= pageHeight;
      }

      doc.save('file.pdf');
    });
  }


  finalOutput(reportData, sections) {

    let finalVar = '';
    this.cdr.detectChanges();
    reportData.forEach(section => {
      let computedSectionDataObj;

      if (!!section.computedSectionData) {
        let computedSectionData = JSON.parse(section.computedSectionData);
        finalVar = section.htmlTemplate;
        computedSectionData.forEach(csd => {


          if (csd.placeholderType == 'HtmlTable' || csd.placeholderType == 'HtmlText' || csd.placeholderType == 'Heading' ||
            csd.placeholderType == 'Text' ||
            csd.placeholderType == 'PageBackGround') {
            // let placeholderValue = this.sanitizer.bypassSecurityTrustHtml(csd.placeholderValue);
            // csd['placeholderValue'] = placeholderValue;
            let placeholderValue = JSON.parse(csd.placeholderValue);
            csd['placeholderValue'] = placeholderValue.value;
          }


          if (csd.placeholderType == 'Chart') {
            // let placeholderValue = JSON.parse(csd.placeholderValue);
            // csd['placeholderValue'] = placeholderValue;
            debugger;

            let chartHTML = document.getElementById(csd.placeholderName);
            debugger;
            csd['placeholderValue'] = chartHTML.innerHTML;

          }


          const currentKey = "{{" + csd.placeholderName + "}}";
          if (finalVar.indexOf(currentKey)) {
            // finalVar = finalVar.replace(currentKey, csd.placeholderType+"----------"+csd.placeholderValue);
            finalVar = finalVar.replace(currentKey, csd.placeholderValue);

          }

          // cdn images are not appearing in jspdf
          // finalVar = finalVar.replace( 'https://cdn.otadevtest.com/profile_pics/utrvl-logo_637769592586406097.png',
          // 'http://localhost:4200/assets/media/plan-img.png'
          // );

        });
      }

    });

    this.finalVar = this.sanitizer.bypassSecurityTrustHtml(finalVar);
    setTimeout(()=>{
      this.toggleHideGraphs = true;
      this.loading = false;
      this.cdr.detectChanges();

    },1000);


  }

  barChartAdded() {
    //fix all x axis labels and y axis labels 
    setTimeout(() => {
      let nodeList = document.querySelectorAll("ngx-charts-bar-vertical g[ngx-charts-x-axis] g[ngx-charts-axis-label] text");
      if (nodeList && nodeList.length > 0) {
        for (let index = 0; index < nodeList.length; index++) {
          if (parseFloat(nodeList.item(index).attributes.getNamedItem('x').value) > 0) {
            if (parseFloat(nodeList.item(index).attributes.getNamedItem('y').value) === 0) {
              nodeList.item(index).attributes.getNamedItem('y').value = '112';
            }
            else {
              nodeList.item(index).attributes.getNamedItem('y').value = (parseFloat(nodeList.item(index).attributes.getNamedItem('y').value) + 30).toString();
            }
          }
          // if(parseFloat(nodeList.item(index).attributes.getNamedItem('x').value)<0)
          // {
          //   if(parseFloat(nodeList.item(index).attributes.getNamedItem('y').value)>0)
          //   {

          //     nodeList.item(index).attributes.getNamedItem('x').value = (parseFloat(nodeList.item(index).attributes.getNamedItem('x').value)-30).toString();
          //   }
          // }


        }
      }

      let nodeListY = document.querySelectorAll("ngx-charts-bar-vertical g[ngx-charts-y-axis] g[ngx-charts-axis-label] text");
      if (nodeListY && nodeListY.length > 0) {
        for (let index = 0; index < nodeListY.length; index++) {
          if (parseFloat(nodeListY.item(index).attributes.getNamedItem('x').value) < 0) {
            if (parseFloat(nodeListY.item(index).attributes.getNamedItem('y').value) < 0) {
              nodeListY.item(index).attributes.getNamedItem('y').value = (parseFloat(nodeListY.item(index).attributes.getNamedItem('y').value) - 25).toString();
            }
          }

        }
      }


    }, 1000);
  }

  getGraphValueForProperty(csObj: { graphType: string, graphOptions: Partial<TreeMapComponent | AreaChartComponent| PieGridComponent | BarHorizontalStackedComponent | BarVerticalStackedComponent | BarVertical2DComponent| BarHorizontal2DComponent | LineChartComponent | BarVerticalComponent | PieChartComponent | AdvancedPieChartComponent | PieGridComponent | LineChartComponent | ComboChartComponent> }, propertyName: string) {
    if (csObj.graphOptions) {
      if (csObj.graphOptions[propertyName]) {
        
        return csObj.graphOptions[propertyName];
      }
    }


    /*if property not found send default */

    if (csObj.graphType === "VerticalBarChart") {
      return this.verticalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "HorizontalBarChart") {
      return this.horizontalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "GroupedVerticalBarChart") {
      return this.groupedVerticalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "GroupedHorizontalBarChart") {
      return this.groupedHorizontalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "StackedVerticalBarChart") {
      return this.stackedVerticalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "StackedHorizontalBarChart") {
      return this.stackedHorizontalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "AdvancedPieChart") {
      return this.advancedPieChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "PieChart") {
      return this.pieChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "PieGridChart") {
      return this.pieGridChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "LineChart") {
      return this.lineChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "AreaChart") {
      return this.areaChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "TreeMap") {
      return this.treeMapDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "TreeMap") {
      return this.comboBarChartDefaultOptions[propertyName];
    }



  }
}
