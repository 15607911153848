<span class="spinner-jadwa">
    <img src="../../../assets/loader.gif" class="img-fluid">
    <img src="../../../assets/media/logos/favicon.png" class="img-fluid logo-icon">
    <ng-container *ngIf="showMessage">
        <div class="spinner-message">{{message}}</div>
    </ng-container>
    <ng-container *ngIf="showProgressBar">   
        <div class="spinner-progress-bar">   
            <mat-progress-bar mode="determinate" [value]="progressPercentage"></mat-progress-bar>
        </div> 
    </ng-container>
    <!-- <div class="loadingio-spinner-ripple-72tqnwkjopd"><div class="ldio-j5wp1yelkdg">
        <div></div><div></div>
        </div></div> -->

    </span>