// Perfect Scroll bar
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// Hammer JS
import 'hammerjs';
// Layout Services
import { LayoutConfigService } from './core/_base/layout';
// Config
import { LayoutConfig } from './core/_config/layout.config';
// Highlight JS
import { HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
// import { FormlyFieldListWithHeading } from './list-with-heading.type';
// import { FormlyfieldtabsComponent } from './controls/formlyfieldtabs/formlyfieldtabs.component';
// tslint:disable-next-line:class-name
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};
export function initializeLayoutConfig(appConfig) {
    // initialize app by loading default demo layout config
    return function () {
        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new LayoutConfig().configs);
        }
    };
}
export function hljsLanguages() {
    return [
        { name: 'typescript', func: typescript },
        { name: 'scss', func: scss },
        { name: 'xml', func: xml },
        { name: 'json', func: json }
    ];
}
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ1 = { languages: hljsLanguages };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
