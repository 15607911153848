import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'no-record-found',
  templateUrl: './no-record-found.component.html',
  styleUrls: ['./no-record-found.component.scss']
})
export class NoRecordFoundComponent implements OnInit {

 
  @Input() notFound: any;

  
  
  constructor() { }

  ngOnInit() {
  }

 

}
