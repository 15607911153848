/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./circle.component";
var styles_CircleComponent = [];
var RenderType_CircleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CircleComponent, data: {} });
export { RenderType_CircleComponent as RenderType_CircleComponent };
export function View_CircleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:circle", [], [[1, "cx", 0], [1, "cy", 0], [1, "r", 0], [1, "fill", 0], [1, "stroke", 0], [1, "opacity", 0], [1, "class", 0], [1, "pointer-events", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cx; var currVal_1 = _co.cy; var currVal_2 = _co.r; var currVal_3 = _co.fill; var currVal_4 = _co.stroke; var currVal_5 = _co.circleOpacity; var currVal_6 = _co.classNames; var currVal_7 = _co.pointerEvents; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CircleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-circle", ""]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_CircleComponent_0, RenderType_CircleComponent)), i0.ɵdid(1, 573440, null, 0, i1.CircleComponent, [], null, null)], null, null); }
var CircleComponentNgFactory = i0.ɵccf("g[ngx-charts-circle]", i1.CircleComponent, View_CircleComponent_Host_0, { cx: "cx", cy: "cy", r: "r", fill: "fill", stroke: "stroke", data: "data", classNames: "classNames", circleOpacity: "circleOpacity", pointerEvents: "pointerEvents" }, { select: "select", activate: "activate", deactivate: "deactivate" }, []);
export { CircleComponentNgFactory as CircleComponentNgFactory };
