import * as tslib_1 from "tslib";
// NGRX
import { select } from '@ngrx/store';
// CRUD
import { BaseDataSource } from '../../_base/crud';
import { selectCustomersInStore, selectCustomersPageLoading, selectCustomersShowInitWaitingMessage } from '../_selectors/customer.selectors';
var CustomersDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(CustomersDataSource, _super);
    function CustomersDataSource(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.loading$ = _this.store.pipe(select(selectCustomersPageLoading));
        _this.isPreloadTextViewed$ = _this.store.pipe(select(selectCustomersShowInitWaitingMessage));
        _this.store.pipe(select(selectCustomersInStore)).subscribe(function (response) {
            _this.paginatorTotalSubject.next(response.totalCount);
            _this.entitySubject.next(response.items);
        });
        return _this;
    }
    return CustomersDataSource;
}(BaseDataSource));
export { CustomersDataSource };
