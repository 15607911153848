/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-record-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./no-record-found.component";
var styles_NoRecordFoundComponent = [i0.styles];
var RenderType_NoRecordFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoRecordFoundComponent, data: {} });
export { RenderType_NoRecordFoundComponent as RenderType_NoRecordFoundComponent };
function View_NoRecordFoundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "no-flight-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "flight-notfound-blo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [["class", "swal2-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("TITLE.NO_RECORD_FOUND")); _ck(_v, 5, 0, currVal_0); }); }
function View_NoRecordFoundComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "no-flight-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "flight-notfound-blo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [["class", "swal2-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("TITLE.NO_RECORD_FOUND")); _ck(_v, 5, 0, currVal_0); }); }
function View_NoRecordFoundComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "no-flight-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "flight-notfound-blo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [["class", "swal2-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("TITLE.NO_RECORD_FOUND")); _ck(_v, 5, 0, currVal_0); }); }
function View_NoRecordFoundComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "no-flight-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "flight-notfound-blo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [["class", "swal2-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("TITLE.NO_RECORD_FOUND")); _ck(_v, 5, 0, currVal_0); }); }
function View_NoRecordFoundComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "no-flight-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-sm-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "flight-notfound-blo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [["class", "swal2-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("TITLE.NO_RECORD_FOUND")); _ck(_v, 5, 0, currVal_0); }); }
export function View_NoRecordFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "no-records-section"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoRecordFoundComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoRecordFoundComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoRecordFoundComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoRecordFoundComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoRecordFoundComponent_5)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notFound == "productNOTfound"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.notFound == "projectsNOTfound"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.notFound == "fixedassetsNOTfound"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.notFound == "databanksNOTfound"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.notFound == "plansNOTfound"); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_NoRecordFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "no-record-found", [], null, null, null, View_NoRecordFoundComponent_0, RenderType_NoRecordFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.NoRecordFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoRecordFoundComponentNgFactory = i1.ɵccf("no-record-found", i4.NoRecordFoundComponent, View_NoRecordFoundComponent_Host_0, { notFound: "notFound" }, {}, []);
export { NoRecordFoundComponentNgFactory as NoRecordFoundComponentNgFactory };
