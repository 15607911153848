import * as tslib_1 from "tslib";
import { BaseModel } from '../../_base/crud';
var ProductModel = /** @class */ (function (_super) {
    tslib_1.__extends(ProductModel, _super);
    function ProductModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductModel.prototype.clear = function () {
        this.model = '';
        this.manufacture = '';
        this.modelYear = 2000;
        this.mileage = 0;
        this.description = '';
        this.color = 'Black';
        this.price = 1000;
        this.condition = 0;
        this.status = 0;
        this.VINCode = '';
    };
    return ProductModel;
}(BaseModel));
export { ProductModel };
