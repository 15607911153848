<spinner *ngIf="loading"></spinner>



<mat-accordion class="custom-plan-tabs custom-accordian"
    *ngIf="field?.fieldGroup[0]?.templateOptions?.planDetails?.planId == '4e71752f-3ca8-4e77-905a-b4cc5a63e1ea'">


    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h4 class="title">Input Dashboard</h4>
            </mat-panel-title>
            <!-- <mat-panel-description>
                <a (click)="submitPlanDocuments($event)">submitPlanDocuments</a>
                Currently I am {{panelOpenState ? 'open' : 'closed'}}
            </mat-panel-description> -->
        </mat-expansion-panel-header>

        <div class="row summary-blocks">
            <div class="col-md-2">
                <div class="card h-100">
                    <div class="card-header">Revenue</div>
                    <div class="card-body">
                        <div class="summary-text"> <span>Option -1:</span> <span class="font-weight-bold">{{ByAmount}}</span></div>
                        <div class="summary-text"> <span>Option -2:</span> <span class="font-weight-bold">{{ByQty}}</span></div>
                        <div class="summary-text"> <span>Option -3:</span> <span class="font-weight-bold">{{ByAmountCost}}</span></div>
                        <div class="summary-text"> <span>Option -4:</span> <span class="font-weight-bold">{{ByQtyCost}}</span></div>
                    </div>
                    <div class="card-footer">
                        <div class="summary-text"> <span class="text-primary">Total </span> <span class="text-aqua">{{TotalRevenue}}</span></div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="card h-100">
                    <div class="card-header">Start Up Expenses</div>
                    <!--<div class="card-body">Option -1: <span>{{startupexpenses}}</span></div>-->
                    <div class="card-body">
                        <div class="summary-text">Option -1: <span class="font-weight-bold">{{startupexpenses}}</span> </div>
                    </div>
                    <div class="card-footer">
                        <div class="summary-text"><span class="text-primary">Total </span> <span class="text-aqua"> {{startupexpenses}}</span> </div></div>
                  </div>
            </div>

            <div class="col-md-2">
                <div class="card h-100">
                    <div class="card-header">Other Expenses</div>
                    <div class="card-body">
                        <div class="summary-text">Option -1: <span class="font-weight-bold">{{ExpensebyRevenuePercentage}}</span> </div>
                        <div class="summary-text">Option -2: <span class="font-weight-bold">{{ExpensebyActualAmount}}</span> </div>
                        <!--<div class="summary-text">Expense by Actual Amount  <span>{{ExpensebyActualAmountAdvance}}</span> </div>
                        <div class="summary-text">Expense Advance <span>{{ExpenseAdvance}}</span> </div>-->
                    </div>
                    <div class="card-footer">
                        <div class="summary-text"><span class="text-primary">Total </span> <span class="text-aqua"> {{TotalExpenses}}</span> </div></div>
                  </div>
            </div>

            <div class="col-md-2">
                <div class="card h-100">
                    <div class="card-header">Fixed Assets</div>
                    <div class="card-body">
                        <div class="summary-text">Option -1: <span class="font-weight-bold">{{FixedAssetOption1}}</span></div>
                        <div class="summary-text">Option -2: <span class="font-weight-bold"> {{FixedAssetOption2}}</span></div>
                        <div class="summary-text">Option -3: <span class="font-weight-bold">{{FixedAssetOption3}}</span></div>
                    </div>
                    <div class="card-footer">
                        <div class="summary-text"> <span class="text-primary">Total </span>  <span class="text-aqua">{{TotalFixedAsset}}</span> </div>
                    </div>
                  </div>
            </div>

            <div class="col-md-2">
                <div class="card h-100">
                    <div class="card-header">Payroll</div>
                    <div class="card-body">
                        <div class="summary-text"> Option1  <span class="font-weight-bold">{{PayrollOption1}}</span></div>
                        <div class="summary-text">  Option2  <span class="font-weight-bold">{{PayrollOption2}}</span></div>
                    </div>
                    <div class="card-footer">
                        <div class="summary-text"><span class="text-primary">Total </span> <span class="text-aqua">{{TotalPayroll}}</span></div>
                    </div>
                  </div>
            </div>

            <div class="col-md-2">
                <div class="card h-100">
                    <div class="card-header">Working Capital</div>
                    <div class="card-body">
                        <div class="summary-text"> STO <span class="font-weight-bold">{{STO}}</span></div>
                        <div class="summary-text"> PTO <span class="font-weight-bold">{{PTO}}</span></div>
                        <div class="summary-text">Percentage of Expenses  <span>{{PercentageofExpenses}}</span></div>
                        <div class="summary-text"> Working Amount <span class="font-weight-bold">{{WorkingAmount}}</span></div>
                        <div class="summary-text"> Min Amount <span class="font-weight-bold">{{MinAmount}} </span></div>
                    </div>
                    <div class="card-footer">
                        <div class="summary-text"><span class="text-primary">Total </span> <span class="text-aqua">0</span></div>
                    </div>
                  </div>
            </div>


        </div>

        <!-- Test -->
        <!-- <span class="btn btn-primary  btn-shadow " (click)="submitPlanDocuments(
            field?.fieldGroup[0]?.templateOptions?.projectId,
            field?.fieldGroup[0]?.templateOptions?.planDetails?.planId)" *ngIf="!!form.valid">Submit Plan Documents </span> -->
    </mat-expansion-panel>

</mat-accordion>


<mat-tab-group class="custom-plan-tabs ml-3" animationDuration="5ms" [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)">
    <mat-tab *ngFor="let tab of field.fieldGroup; let i = index; let first = first ;let last = last;"
        style="overflow:hidden!important">
        <!--  [disabled]="i!=0 && getStepsValue(i-1,field.fieldGroup[i-1])<=90 && getStepsValue(i,tab)==0" -->
        <div class="kt-container">
            <div class="row rtl-offset mt-3">
                <div class="col-md-12">
                    <div class="custom-plan-ui position-relative"  >
                        <ng-template mat-tab-label>
                            <div class="mat-tab-status" [ngClass]="{'mat-tab-completed':getStepsValue(i,tab)>=90, 'mat-tab-partiaily-completed':getStepsValue(i,tab)>0 && getStepsValue(i,tab)<90, 'mat-tab-not-started':getStepsValue(i,tab)==0}">
                                <div class="mat-icon-custom">
                                    <!-- <mat-icon class="example-tab-icon">{{(!!tab.templateOptions.icon)?tab.templateOptions.icon:'assessment'}}</mat-icon> -->
                                    <mat-icon class="example-tab-icon" *ngIf="getStepsValue(i,tab)!=100">settings</mat-icon>
                                    <mat-icon class="example-tab-icon" *ngIf="getStepsValue(i,tab)==100">check</mat-icon>
                                </div>
                                <div class="mat-icon-title-step">
                                    <div class="steps" matTooltipClass="custom-tooltip" matTooltip="{{tab.templateOptions.label}}">Step {{i+1}}</div>
                                    <mat-progress-bar mode="determinate" [value]="getStepsValue(i,tab)"></mat-progress-bar>
                                    <!-- {{tab.templateOptions.label}} -->
                                </div> 
                            </div>                        
                        </ng-template>

                     


                        <div class="row m-0">
                            <div class="col-transition" [ngClass]="{'col-md-3':expand, 'hide-col':!expand}">

                                <div class="left-block-panel">

                                    <div class="position-inside">
                                        <!-- should be based on language if we have to change text need change two place this text -->
                                        <h4>
                                            <!-- <span *ngIf="!!tab?.templateOptions?.planDetails">{{tab?.templateOptions?.planDetails?.name}}
                                                ></span> {{tab?.templateOptions?.label}} -->
                                                Details
                                        </h4>
                                    </div>
                                    <div class="left-block">
                                        <div [innerHTML]="tab.templateOptions.templateMetaData"> </div>
                                    </div>
                                </div>

                            </div>
                            <div class="right-block-panel position-relative pb-5" [ngClass]="{'col-md-9':expand, 'col-md-12':!expand}">

                                <div>
                                    <h4 class="planer-main-title">
                                        <span class="mr-3 menu-icon" [ngClass]="{'md':expand, 'd-none':!expand}">
                                            <!-- should be based on language if we have to change text need change two place this text -->
                                            <div (click)="expandCollapse()" class="collapse-icon collapse-menu">
                                                <span *ngIf="expand" matTooltip="Expand Details" matTooltipPosition="above">
                                                    <mat-icon>close</mat-icon>
                                                </span>
                                            </div>
                                        </span>

                                        <span (click)="expandCollapse()" class="collapse-icon collapse-menu mr-3">
                                            <span class="bg" *ngIf="!expand" matTooltip="Close Details" matTooltipPosition="above">
                                                <mat-icon>help_outline</mat-icon>
                                            </span>
                                        </span>
                                        <span class="plan-title-step" *ngIf="!!tab?.templateOptions?.planDetails">
                                            {{tab?.templateOptions?.planDetails?.name}}
                                        </span> 
                                        <span class="arrow-separation"><mat-icon class="example-tab-icon">chevron_right</mat-icon></span>
                                        <span class="plan-title-step-active">{{tab?.templateOptions?.label}}</span>
                                    </h4>
                                </div>

                                <formly-field [field]="tab"></formly-field>

                                <!-- (click)="saveDocumentData(form.value, tab)" [disabled]="!form.valid"-->
                                <!--*ngIf="form.valid" -->
                                <div class="row">
                                    <div class="col-md-6 next-prev-btn">
                                        <!-- <button class="btn btn-primary mr-3 btn-shadow align-middle left" *ngIf="!first"
                                            (click)="previousStep($event)" matTooltipPosition="left"> 
                                            <mat-icon class="align-middle pt-1 ">keyboard_arrow_left</mat-icon>
                                            Previous
                                        </button>
            
                                        <button class="btn btn-primary  btn-shadow mr-5 align-middle right" *ngIf="!last"
                                            (click)="nextStep($event)"matTooltipPosition="right">Next 
                                            <mat-icon class="align-middle pt-1">keyboard_arrow_right</mat-icon>
                                        </button> -->
                                      
                                    </div>
                                   
                                    <div class="move-upside-btn mat-h m-0 col-12 d-flex justify-content-between">
                                   
                                        <!--*ngIf="!form.valid"  type="submit" -->
                                        <!-- <span class="btn btn-primary btn-border btn-shadow " (click)="saveAsDraft(
                                           tab.templateOptions.projectId,                                        
                                           tab.templateOptions.planDetails.planId,
                                           tab.key
                                       )">Save as Draft </span> -->
                                      <button class="btn btn-primary btn-shadow align-middle" *ngIf="!first"
                                            (click)="previousStep($event)" matTooltipPosition="left"> 
                                            <mat-icon class="align-middle ">keyboard_arrow_left</mat-icon>
                                            Previous
                                        </button>
            
                                        <button class="btn btn-primary   btn-shadow mr-0 align-middle" *ngIf="!last"
                                            (click)="nextStep($event)"matTooltipPosition="right">Next 
                                            <mat-icon class="align-middle ">keyboard_arrow_right</mat-icon>
                                        </button>

                                        <button class="btn btn-primary btn-shadow mx-3"  *ngIf="last" type="submit">
                                            Submit
                                        </button>
                                      
                                       <!-- <button class="btn btn-primary btn-shadow mx-3"  *ngIf="last" type="submit">
                                           Submit
                                       </button> -->
   
                                   </div>
                                </div>
                               
                            </div>
                        </div>


                        <!-- <div class="arrows_left_right">
                            <button class="btn btn-primary mr-3 mb-5 btn-shadow align-middle left" *ngIf="!first"
                                (click)="previousStep($event)" matTooltip="Previous" matTooltipPosition="left">
                                <mat-icon class="align-middle ">keyboard_arrow_left</mat-icon>
                            </button>

                            <button class="btn btn-primary mt-4 btn-shadow mr-5 align-middle right" *ngIf="!last"
                                (click)="nextStep($event)" matTooltip="Next" matTooltipPosition="right">
                                <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
                            </button>
                        </div> -->
                    </div>

                    <br />
                </div>
            </div>

        </div>
    </mat-tab>
</mat-tab-group>
<!-- 
<h1>form</h1>
<pre>
    {{form.value | json}}
</pre>

<h1>mdoel</h1>
<pre>
    {{ model | json}}
</pre> -->