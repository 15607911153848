import * as tslib_1 from "tslib";
import { BaseModel } from '../../_base/crud';
var ProductSpecificationModel = /** @class */ (function (_super) {
    tslib_1.__extends(ProductSpecificationModel, _super);
    function ProductSpecificationModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductSpecificationModel.prototype.clear = function (carId) {
        this.id = undefined;
        this.carId = carId;
        this._specificationName = '';
        this.value = '';
        this.specId = undefined;
    };
    return ProductSpecificationModel;
}(BaseModel));
export { ProductSpecificationModel };
