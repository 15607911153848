import * as tslib_1 from "tslib";
import { FieldArrayType } from '@ngx-formly/core';
var TextwithBulletInputControlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TextwithBulletInputControlComponent, _super);
    function TextwithBulletInputControlComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TextwithBulletInputControlComponent;
}(FieldArrayType));
export { TextwithBulletInputControlComponent };
