import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'formly-field-file',
  templateUrl: './formlyfieldfile.component.html',
  styleUrls: ['./formlyfieldfile.component.scss']
})
export class FormlyfieldfileComponent extends FieldType { //implements OnInit 

  inputId=uuidv4();
  imageId=uuidv4();

  currentlyAddeedFile:File;

  imageSrc:any;
  imageShow:boolean= false;


  constructor(
    private jadwaService: JadwaService,
  ) {
    super();
  }

  ngOnInit() {
    if(this.formControl.value){
      this.imageSrc = this.formControl.value;
      this.imageShow = true;
    }
  }

  openFileInputReader()
  {
    document.getElementById(this.inputId).click();
  }

  fileAdded(event)
  {
    console.log(event);
    this.currentlyAddeedFile = event.target.files[0];
    this.imagePreview(this.currentlyAddeedFile);
    //validate them 
    this.jadwaService.fileupload(event.target.files[0]).subscribe(response=>{
      console.log("response",response);
      this.formControl.setValue(response.result);
      this.formControl.updateValueAndValidity();
    })
  }


  imagePreview(file:File)
  {
    //const preview = document.querySelector('img');
  //const file = document.querySelector('input[type=file]').files[0];
  const reader = new FileReader();

  reader.addEventListener("load", ()=> {
    // convert image file to base64 string
    this.imageSrc = reader.result;
    this.imageShow = true;
  }, false);

  if (file) {
    reader.readAsDataURL(file);
  }
  }

}

