import * as tslib_1 from "tslib";
import { BaseModel } from '../../_base/crud';
var ProductRemarkModel = /** @class */ (function (_super) {
    tslib_1.__extends(ProductRemarkModel, _super);
    function ProductRemarkModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductRemarkModel.prototype.clear = function (carId) {
        this.id = undefined;
        this.carId = carId;
        this.text = '';
        this.type = 0;
        this.dueDate = '';
        this._isEditMode = false;
    };
    return ProductRemarkModel;
}(BaseModel));
export { ProductRemarkModel };
