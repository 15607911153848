/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./plan-reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../spinner/spinner.component.ngfactory";
import * as i3 from "../spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/material/dialog";
import * as i7 from "./plan-reports.component";
import * as i8 from "../../core/auth/_services/jadwa.service";
import * as i9 from "@ngrx/store";
var styles_PlanReportsComponent = [i0.styles];
var RenderType_PlanReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanReportsComponent, data: {} });
export { RenderType_PlanReportsComponent as RenderType_PlanReportsComponent };
function View_PlanReportsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_PlanReportsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_PlanReportsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.arName; _ck(_v, 1, 0, currVal_0); }); }
function View_PlanReportsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "card overly-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Card image cap"], ["class", "card-img-top"], ["src", "../../../assets/media/plan-img.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanReportsComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanReportsComponent_5)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "text-white pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["Version ", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "btn btn-primary btn-active-primary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openReport(_v.context.$implicit, false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Open Report"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentLanguage == "en"); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.currentLanguage != "en"); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.version; _ck(_v, 9, 0, currVal_2); }); }
function View_PlanReportsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row m-0 mb-4 business-templates-block "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanReportsComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.reportsList == null) ? null : _co.reportsList.mappedReportList); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PlanReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanReportsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "project-industry-popup create-project"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "container z-index-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "row m-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h3", [["class", "mb-5 title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "col-12 flex-column mb-3 flex-column d-flex justify-content-center align-content-center overflow-custom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-md-12 plan-right-bg "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanReportsComponent_2)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 8, "div", [["class", "close-alert-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["class", "material-icons"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).dialogRef.close(i1.ɵnov(_v, 17).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_left"])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "span", [["class", "material-icons"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).dialogRef.close(i1.ɵnov(_v, 21).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "div", [["class", "abstract-design bottom right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "img", [["src", "../../../assets/media/bg/abstract-design.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["class", "abstract-design top right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "img", [["src", "../../../assets/media/bg/polygon-borders.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [["class", "abstract-design left top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "img", [["src", "../../../assets/media/bg/polygon-borders-left.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_2 = (!!_co.reportsList && !!((_co.reportsList == null) ? null : _co.reportsList.mappedReportList)); _ck(_v, 13, 0, currVal_2); var currVal_5 = ""; _ck(_v, 17, 0, currVal_5); var currVal_8 = ""; _ck(_v, 21, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("TITLE.REPORTS")); _ck(_v, 8, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 17).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 17).type; _ck(_v, 16, 0, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 21).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 21).type; _ck(_v, 20, 0, currVal_6, currVal_7); }); }
export function View_PlanReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-plan-reports", [], null, null, null, View_PlanReportsComponent_0, RenderType_PlanReportsComponent)), i1.ɵdid(1, 114688, null, 0, i7.PlanReportsComponent, [i6.MatDialog, i8.JadwaService, i5.TranslateService, i6.MAT_DIALOG_DATA, i6.MatDialogRef, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlanReportsComponentNgFactory = i1.ɵccf("kt-plan-reports", i7.PlanReportsComponent, View_PlanReportsComponent_Host_0, {}, {}, []);
export { PlanReportsComponentNgFactory as PlanReportsComponentNgFactory };
