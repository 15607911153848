import * as tslib_1 from "tslib";
import { selectProductsInitWaitingMessage } from './../_selectors/product.selectors';
// CRUD
import { BaseDataSource } from '../../_base/crud';
// State
import { select } from '@ngrx/store';
// Selectors
import { selectProductsInStore, selectProductsPageLoading } from '../_selectors/product.selectors';
var ProductsDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(ProductsDataSource, _super);
    function ProductsDataSource(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.loading$ = _this.store.pipe(select(selectProductsPageLoading));
        _this.isPreloadTextViewed$ = _this.store.pipe(select(selectProductsInitWaitingMessage));
        _this.store.pipe(select(selectProductsInStore)).subscribe(function (response) {
            _this.paginatorTotalSubject.next(response.totalCount);
            _this.entitySubject.next(response.items);
        });
        return _this;
    }
    return ProductsDataSource;
}(BaseDataSource));
export { ProductsDataSource };
