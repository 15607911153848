/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tooltip/tooltip.directive";
import * as i3 from "./tooltip/tooltip.service";
import * as i4 from "./tooltip-area.component";
var styles_TooltipArea = [];
var RenderType_TooltipArea = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipArea, data: { "animation": [{ type: 7, name: "animationState", definitions: [{ type: 1, expr: "inactive => active", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0.7 }, offset: null }, timings: 250 }], options: null }, { type: 1, expr: "active => inactive", animation: [{ type: 6, styles: { opacity: 0.7 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 250 }], options: null }], options: {} }] } });
export { RenderType_TooltipArea as RenderType_TooltipArea };
function View_TooltipArea_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":xhtml:div", [["class", "tooltip-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":xhtml:span", [["class", "tooltip-item-color"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.color; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getToolTipText(_v.context.$implicit); _ck(_v, 2, 0, currVal_1); }); }
function View_TooltipArea_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":xhtml:div", [["class", "area-tooltip-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipArea_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.model; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TooltipArea_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { tooltipAnchor: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:rect", [["class", "tooltip-area"], ["style", "opacity: 0; cursor: 'auto';"], ["y", "0"]], [[1, "x", 0], [1, "width", 0], [1, "height", 0]], [[null, "mousemove"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousemove" === en)) {
        var pd_0 = (_co.mouseMove($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hideTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(0, [["defaultTooltipTemplate", 2]], null, 0, null, View_TooltipArea_1)), (_l()(), i0.ɵeld(4, 16777216, [[1, 0], ["tooltipAnchor", 1]], null, 1, ":svg:rect", [["class", "tooltip-anchor"], ["ngx-tooltip", ""], ["y", "0"]], [[24, "@animationState", 0], [1, "x", 0], [1, "width", 0], [1, "height", 0], [4, "opacity", null], [4, "pointer-events", null]], [[null, "focusin"], [null, "blur"], [null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("focusin" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onBlur() !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5).onMouseEnter() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5).onMouseLeave($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5).onMouseClick() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 147456, null, 0, i2.TooltipDirective, [i3.TooltipService, i0.ViewContainerRef, i0.Renderer2], { tooltipSpacing: [0, "tooltipSpacing"], tooltipDisabled: [1, "tooltipDisabled"], tooltipPlacement: [2, "tooltipPlacement"], tooltipType: [3, "tooltipType"], tooltipTemplate: [4, "tooltipTemplate"], tooltipContext: [5, "tooltipContext"], tooltipImmediateExit: [6, "tooltipImmediateExit"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = 15; var currVal_10 = _co.tooltipDisabled; var currVal_11 = "right"; var currVal_12 = "tooltip"; var currVal_13 = (_co.tooltipTemplate ? _co.tooltipTemplate : i0.ɵnov(_v, 3)); var currVal_14 = _co.anchorValues; var currVal_15 = true; _ck(_v, 5, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = 0; var currVal_1 = _co.dims.width; var currVal_2 = _co.dims.height; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ((_co.anchorOpacity !== 0) ? "active" : "inactive"); var currVal_4 = _co.anchorPos; var currVal_5 = 1; var currVal_6 = _co.dims.height; var currVal_7 = _co.anchorOpacity; var currVal_8 = "none"; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_TooltipArea_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-tooltip-area", ""]], null, null, null, View_TooltipArea_0, RenderType_TooltipArea)), i0.ɵdid(1, 49152, null, 0, i4.TooltipArea, [], null, null)], null, null); }
var TooltipAreaNgFactory = i0.ɵccf("g[ngx-charts-tooltip-area]", i4.TooltipArea, View_TooltipArea_Host_0, { dims: "dims", xSet: "xSet", xScale: "xScale", yScale: "yScale", results: "results", colors: "colors", showPercentage: "showPercentage", tooltipDisabled: "tooltipDisabled", tooltipTemplate: "tooltipTemplate" }, { hover: "hover" }, []);
export { TooltipAreaNgFactory as TooltipAreaNgFactory };
