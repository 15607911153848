import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import SHA256 from "crypto-js/sha256";
import { PaymentRequestUrway } from '../../../app/core/auth/_models/payments.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'kt-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit,OnDestroy {

  constructor(private router : Router,private activateRoute: ActivatedRoute, private translate: TranslateService) { }

  tranId:string='';
  result:string='';
  responseCode:string='';
  paymentDetails:PaymentRequestUrway;

  ngOnInit() {
    if(sessionStorage.getItem('planDetailsForPayment'))
    {
      this.paymentDetails = JSON.parse(sessionStorage.getItem('planDetailsForPayment'))
    }
    this.activateRoute.queryParams.subscribe(response=>{
      this.tranId = response['TranId'];
      this.result = response['Result'];
      this.responseCode = response['ResponseCode'];
      if(this.tranId && this.result && this.responseCode)
      {
        this.showStatus();
      }
      



    });

    setTimeout(()=>{
      if(this.result !== 'Successful')
      {
        this.showError();
      }

    },1000);

    
  }

  showStatus()
  {
    let title= 'Payment Successfull for: '+(this.paymentDetails?this.paymentDetails.planName:'')+' you will be redirected to dashboard in 5 seconds';
    debugger;

    if(localStorage.getItem('language') == 'ar')
    {
      title = 'Payment Successfull for: '+(this.paymentDetails?this.paymentDetails.planName:'')+' you will be redirected to dashboard in 5 seconds';
    } 
    

    if(this.result === 'Successful')
    {
      Swal.fire({
        title : title,
        // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
        }).then((result) => {       
          });
          setTimeout(()=>{
            this.router.navigateByUrl('/dashboard');
          },5000);
    }
  }

  showError()
  {
    
      Swal.fire({
       // title : 'Payment wasnt successfull something went wrong, you will be redirected to dashboard in 5 seconds',
        title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
        }).then((result) => {       
          });
          setTimeout(()=>{
            this.router.navigateByUrl('/dashboard');
          },5000);
  }

  ngOnDestroy()
  {
    // sessionStorage.removeItem('planDetailsForPayment');
  }



}
