import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


import { Subscription } from 'rxjs';

import { BarHorizontalComponent, AdvancedPieChartComponent, BarVerticalComponent, LineChartComponent, PieChartComponent, PieGridComponent, AreaChartComponent } from '@swimlane/ngx-charts';
import { BarHorizontal2DComponent, BarHorizontalStackedComponent, BarVertical2DComponent, BarVerticalStackedComponent, TreeMapComponent } from 'ngx-charts-8';
import { barChart, lineChartSeries } from './combo-chart-data';
import { ComboChartComponent } from '../third-party-open-source/jedwa-custom-ngx-charts/custom-chart/combo-chart';

@Component({
  selector: 'kt-a4-page-template',
  templateUrl: './a4-page-template.component.html',
  styleUrls: ['./a4-page-template.component.scss']
})
export class A4PageTemplateComponent implements OnInit {
@Input() sectionsToAdd:Array<{placeolderName:string,placeholderValue:any, placeholderType:string}>;
@Input() renderingPhase:boolean = true;
@Input() currentIndex;
@Output() contentDetails:EventEmitter<{height:number,index:number}>=new EventEmitter();
currentId:string;
  constructor(private sanitizer:DomSanitizer) {
    A4PageTemplateComponent.id++;
    this.currentId = "internalA4ContentID"+A4PageTemplateComponent.id;

   }
static id:number=0;
  ngOnInit() {
    this.sectionsToAdd.forEach(singleSectionToAdd=>{
      if(typeof singleSectionToAdd.placeholderValue ==="string")
      {
        singleSectionToAdd.placeholderValue = JSON.parse(singleSectionToAdd.placeholderValue);
        if (singleSectionToAdd.placeholderType == 'HtmlTable' || singleSectionToAdd.placeholderType == 'HtmlText' ) {
          singleSectionToAdd.placeholderValue.value = this.sanitizer.bypassSecurityTrustHtml(singleSectionToAdd.placeholderValue.value);
        }
      }  

    });
         

    if(this.renderingPhase){
      let timeout =100;
      if(this.sectionsToAdd[0].placeholderType == 'Chart')
      {
        timeout=3000;
      }
      setTimeout(()=>{
        console.log(document.getElementById(this.currentId).clientHeight);
        this.contentDetails.emit({height:document.getElementById(this.currentId).clientHeight,index:this.currentIndex});
      }, timeout);
    }
  }

  showMessageInSpinner:boolean = false;
  spinnerMessage:string="";
  showSpinnerProgressBar:boolean= false;
  spinnerProgressPercentage:number=0;
  /*spinner related */

  barChart: any[] = barChart;
  lineChartSeries: any[] = lineChartSeries;
  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };
  animations: boolean = true;
  tooltipDisabled = false;
  yAxisLabelRight: string = 'Utilization';
  noBarWhenZero = true;




  loading: boolean;
  userProejctPlans: any;
  proejctPlans: any
  chart: string = 'RevenueAndMargin';
  single: any[];
  multi: any[];
  view: any[] = [500, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient: boolean = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  comboBarChartDefaultOptions: Partial<ComboChartComponent & {lineData:any,barData:any}> = {
    view: [500, 400],
    scheme: {
      name: 'singleLightBlue',
      selectable: true,
      group: 'Ordinal',
      domain: ['#01579b']
    },
    //@ts-ignore
    colorSchemeLine: { name: 'coolthree',
      selectable: true,
      group: 'Ordinal',
      domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
    },
    gradient: false,
    animations: true,
    tooltipDisabled: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showRightYAxisLabel: true,
    showGridLines: true,
    yAxisLabelRight: "Y Axis Label Right",
    barPadding: 8,
    legendPosition: 'below',
    noBarWhenZero: true,
    lineData:lineChartSeries,
    barData:barChart,
    legendTitle:"'Combo Graph'"    
  };

  verticalBarChartDefaultOptions: Partial<BarVerticalComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    //schemeType:'ordinal',
    showGridLines: true,
    //roundDomains:false,
    barPadding: 8,
    legendPosition: 'below'
  };


  groupedVerticalBarChartDefaultOptions: Partial<BarVertical2DComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    groupPadding: 16
  };

  groupedHorizontalBarChartDefaultOptions: Partial<BarHorizontal2DComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    groupPadding: 16
  };

  stackedVerticalBarChartDefaultOptions: Partial<BarVerticalStackedComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    legendTitle: "Stacked Vertical Chart"
  };

  stackedHorizontalBarChartDefaultOptions: Partial<BarHorizontalStackedComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    showGridLines: true,
    barPadding: 8,
    legendTitle: "Stacked Vertical Chart"
  };

  horizontalBarChartDefaultOptions: Partial<BarHorizontalComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    xAxis: true,
    yAxis: true,
    legend: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    //schemeType:'ordinal',
    showGridLines: true,
    //roundDomains:false,
    barPadding: 8,
    legendPosition: 'below'
  };

  lineChartDefaultOptions: Partial<LineChartComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    schemeType: "ordinal",
    rangeFillOpacity: 0.15,
    legend: true,
    legendTitle: "Line Chart",
    legendPosition: 'below',

    gradient: false,
    xAxis: true,
    yAxis: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    roundDomains: false,
    showGridLines: true,
    trimXAxisTicks: false,
    trimYAxisTicks: false,
    autoScale: true
  };
  areaChartDefaultOptions: Partial<AreaChartComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    schemeType: "ordinal",
    legend: true,
    legendTitle: "Line Chart",
    legendPosition: 'below',

    gradient: false,
    xAxis: true,
    yAxis: true,
    showXAxisLabel: true,
    xAxisLabel: "X Axis",
    yAxisLabel: "Y Axis",
    showYAxisLabel: true,
    roundDomains: false,
    showGridLines: true,
    trimXAxisTicks: false,
    trimYAxisTicks: false,
    autoScale: true,
    timeline: false
  };

  advancedPieChartDefaultOptions: Partial<AdvancedPieChartComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    label: "Pie Chart",
  };

  treeMapDefaultOptions: Partial<TreeMapComponent> = {
    view: [500, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false
  };

  colorScheme = {
    domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
  };

  pieChartDefaultOptions: Partial<PieChartComponent> = {
    view: [700, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    gradient: false,
    legend: true,
    legendTitle: "Pie Chart",
    legendPosition: 'below',
    doughnut: false,
    labels: false,
    arcWidth: 0.25
  }

  pieGridChartDefaultOptions: Partial<PieGridComponent> = {
    view: [700, 400],
    scheme: {
      domain: ['#FB4E27', '#78BBAE', '#FAA637', '#262F89']
    },
    label: "Total",
    minWidth: 150
  }


  // For Line Chart
  autoScale: boolean = true;


  // For Pie Chart
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  currentLanguage: string = 'en';;
  private subscriptions: Subscription[] = [];


  userId: string;
  projectId: string;
  planId: string;
  reportId: string;
  report: any;
  singleReport: boolean;
  heightLeft: number;
  pageHeight: number;
  plan: any;
  toggleHideGraphs:boolean = false;


  getGraphValueForProperty(csObj: { graphType: string, graphOptions: Partial<TreeMapComponent | AreaChartComponent| PieGridComponent | BarHorizontalStackedComponent | BarVerticalStackedComponent | BarVertical2DComponent| BarHorizontal2DComponent | LineChartComponent | BarVerticalComponent | PieChartComponent | AdvancedPieChartComponent | PieGridComponent | LineChartComponent | ComboChartComponent> }, propertyName: string) {
    if (csObj.graphOptions) {
      if (csObj.graphOptions[propertyName]) {
        
        return csObj.graphOptions[propertyName];
      }
    }


    /*if property not found send default */

    if (csObj.graphType === "VerticalBarChart") {
      return this.verticalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "HorizontalBarChart") {
      return this.horizontalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "GroupedVerticalBarChart") {
      return this.groupedVerticalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "GroupedHorizontalBarChart") {
      return this.groupedHorizontalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "StackedVerticalBarChart") {
      return this.stackedVerticalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "StackedHorizontalBarChart") {
      return this.stackedHorizontalBarChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "AdvancedPieChart") {
      return this.advancedPieChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "PieChart") {
      return this.pieChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "PieGridChart") {
      return this.pieGridChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "LineChart") {
      return this.lineChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "AreaChart") {
      return this.areaChartDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "TreeMap") {
      return this.treeMapDefaultOptions[propertyName];
    }
    else if (csObj.graphType === "TreeMap") {
      return this.comboBarChartDefaultOptions[propertyName];
    }



  }



}
