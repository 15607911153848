/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./svg-linear-gradient.component.ngfactory";
import * as i2 from "./svg-linear-gradient.component";
import * as i3 from "@angular/common";
import * as i4 from "./area.component";
var styles_AreaComponent = [];
var RenderType_AreaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AreaComponent, data: {} });
export { RenderType_AreaComponent as RenderType_AreaComponent };
function View_AreaComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, ":svg:g", [["ngx-charts-svg-linear-gradient", ""], ["orientation", "vertical"]], null, null, null, i1.View_SvgLinearGradientComponent_0, i1.RenderType_SvgLinearGradientComponent)), i0.ɵdid(2, 573440, null, 0, i2.SvgLinearGradientComponent, [], { orientation: [0, "orientation"], name: [1, "name"], stops: [2, "stops"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "vertical"; var currVal_1 = _co.gradientId; var currVal_2 = _co.gradientStops; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AreaComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AreaComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["class", "area"]], [[1, "d", 0], [1, "fill", 0], [4, "opacity", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gradient; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.areaPath; var currVal_2 = (_co.gradient ? _co.gradientFill : _co.fill); var currVal_3 = _co.opacity; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_AreaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-area", ""]], null, null, null, View_AreaComponent_0, RenderType_AreaComponent)), i0.ɵdid(1, 573440, null, 0, i4.AreaComponent, [i0.ElementRef], null, null)], null, null); }
var AreaComponentNgFactory = i0.ɵccf("g[ngx-charts-area]", i4.AreaComponent, View_AreaComponent_Host_0, { data: "data", path: "path", startingPath: "startingPath", fill: "fill", opacity: "opacity", startOpacity: "startOpacity", endOpacity: "endOpacity", activeLabel: "activeLabel", gradient: "gradient", stops: "stops", animations: "animations" }, { select: "select" }, []);
export { AreaComponentNgFactory as AreaComponentNgFactory };
