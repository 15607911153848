/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./line.component";
var styles_LineComponent = [];
var RenderType_LineComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LineComponent, data: { "animation": [{ type: 7, name: "animationState", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { strokeDasharray: 2000, strokeDashoffset: 2000 }, offset: null }, { type: 4, styles: { type: 6, styles: { strokeDashoffset: 0 }, offset: null }, timings: 1000 }], options: null }], options: {} }] } });
export { RenderType_LineComponent as RenderType_LineComponent };
export function View_LineComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:path", [["class", "line"], ["stroke-width", "1.5px"]], [[24, "@animationState", 0], [1, "d", 0], [1, "fill", 0], [1, "stroke", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = "active"; var currVal_1 = _co.initialPath; var currVal_2 = _co.fill; var currVal_3 = _co.stroke; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_LineComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-line", ""]], null, null, null, View_LineComponent_0, RenderType_LineComponent)), i0.ɵdid(1, 573440, null, 0, i1.LineComponent, [i0.ElementRef], null, null)], null, null); }
var LineComponentNgFactory = i0.ɵccf("g[ngx-charts-line]", i1.LineComponent, View_LineComponent_Host_0, { path: "path", stroke: "stroke", data: "data", fill: "fill", animations: "animations" }, { select: "select" }, []);
export { LineComponentNgFactory as LineComponentNgFactory };
