<mat-accordion>
<mat-expansion-panel *ngFor="let singleCollapsableMenu of field.fieldGroup; let index = index; let last = last;">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{singleCollapsableMenu?.templateOptions?.label}}
    </mat-panel-title>
    <mat-panel-description>
    {{singleCollapsableMenu?.templateOptions?.description}}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <formly-field [field]="singleCollapsableMenu"></formly-field>

</mat-expansion-panel>

</mat-accordion>
