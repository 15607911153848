var LayoutConfig = /** @class */ (function () {
    function LayoutConfig() {
        this.defaults = {
            demo: 'demo4',
            // == Base Layout
            self: {
                layout: 'fluid',
                body: {
                    // 'background-image': './assets/media/demos/demo4/header.jpg',
                    'background-position': 'center top',
                    'background-size': '100% 350px',
                },
                logo: './assets/media/logos/logo-4.png',
            },
            // == Page Splash Screen loading
            loader: {
                enabled: true,
                type: 'spinner-logo',
                logo: './assets/media/logos/logo-mini-md.png',
                message: 'Please wait...',
            },
            // == Colors for javascript
            colors: {
                state: {
                    brand: '#366cf3',
                    light: '#ffffff',
                    dark: '#282a3c',
                    primary: '#41C0B9',
                    success: '#34bfa3',
                    info: '#36a3f7',
                    warning: '#ffb822',
                    danger: '#fd3995',
                },
                base: {
                    label: [
                        '#c5cbe3',
                        '#a1a8c3',
                        '#3d4465',
                        '#3e4466',
                    ],
                    shape: [
                        '#f0f3ff',
                        '#d9dffa',
                        '#afb4d4',
                        '#646c9a',
                    ],
                },
            },
            header: {
                self: {
                    width: 'fixed',
                    fixed: {
                        desktop: {
                            enabled: true,
                            mode: 'menu',
                        },
                        mobile: true,
                    },
                },
                menu: {
                    self: {
                        display: true,
                        'root-arrow': false,
                    },
                    desktop: {
                        arrow: true,
                        toggle: 'click',
                        submenu: {
                            skin: 'light',
                            arrow: true,
                        },
                    },
                    mobile: {
                        submenu: {
                            skin: 'dark',
                            accordion: true,
                        },
                    },
                },
            },
            subheader: {
                display: true,
                layout: 'subheader-v4',
                width: 'fixed',
                style: 'transparent',
            },
            content: {
                width: 'fixed',
            },
            footer: {
                self: {
                    width: 'fixed',
                    layout: 'extended',
                },
            },
        };
    }
    Object.defineProperty(LayoutConfig.prototype, "configs", {
        /**
         * Good place for getting the remote config
         */
        get: function () {
            return this.defaults;
        },
        enumerable: true,
        configurable: true
    });
    return LayoutConfig;
}());
export { LayoutConfig };
