/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formlyfieldfile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./file-value-accessor";
import * as i4 from "ngx-intl-tel-input";
import * as i5 from "@ngx-formly/core";
import * as i6 from "@angular/common";
import * as i7 from "./formlyfieldfile.component";
import * as i8 from "../../core/auth/_services/jadwa.service";
var styles_FormlyfieldfileComponent = [i0.styles];
var RenderType_FormlyfieldfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormlyfieldfileComponent, data: {} });
export { RenderType_FormlyfieldfileComponent as RenderType_FormlyfieldfileComponent };
function View_FormlyfieldfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "img-fluid"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSrc; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormlyfieldfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 text-center mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "btn btn-file btn-primary mx-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileInputReader() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Please click here to add An Image File "])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "input", [["hidden", ""], ["type", "file"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 5).onChange($event.target.files) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("focus" === en)) {
        var pd_6 = (i1.ɵnov(_v, 11).onFocus($event) !== false);
        ad = (pd_6 && ad);
    } if (("blur" === en)) {
        var pd_7 = (i1.ɵnov(_v, 11).onBlur($event) !== false);
        ad = (pd_7 && ad);
    } if (("change" === en)) {
        var pd_8 = (i1.ɵnov(_v, 11).onChange($event) !== false);
        ad = (pd_8 && ad);
    } if (("change" === en)) {
        var pd_9 = (_co.fileAdded($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(5, 16384, null, 0, i3.FileValueAccessor, [], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i3.FileValueAccessor]), i1.ɵdid(7, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(10, 81920, null, 0, i4.NativeElementInjectorDirective, [i2.NgControl, i1.ElementRef], null, null), i1.ɵdid(11, 933888, null, 0, i5.FormlyAttributes, [i1.Renderer2, i1.ElementRef, i6.DOCUMENT], { field: [0, "field"], id: [1, "id"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormlyfieldfileComponent_1)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formControl; _ck(_v, 7, 0, currVal_7); _ck(_v, 10, 0); var currVal_8 = _co.field; var currVal_9 = _co.inputId; _ck(_v, 11, 0, currVal_8, currVal_9); var currVal_10 = _co.imageShow; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FormlyfieldfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "formly-field-file", [], null, null, null, View_FormlyfieldfileComponent_0, RenderType_FormlyfieldfileComponent)), i1.ɵdid(1, 114688, null, 0, i7.FormlyfieldfileComponent, [i8.JadwaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyfieldfileComponentNgFactory = i1.ɵccf("formly-field-file", i7.FormlyfieldfileComponent, View_FormlyfieldfileComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyfieldfileComponentNgFactory as FormlyfieldfileComponentNgFactory };
