import * as tslib_1 from "tslib";
// RxJS
import { debounceTime } from 'rxjs/operators';
// NGRX
import { select } from '@ngrx/store';
// CRUD
import { BaseDataSource } from '../../_base/crud';
import { selectProductRemarksInStore, selectProductRemarksPageLoading, selectPRShowInitWaitingMessage } from '../_selectors/product-remark.selectors';
var ProductRemarksDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(ProductRemarksDataSource, _super);
    function ProductRemarksDataSource(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.store.pipe(select(selectProductRemarksInStore), debounceTime(600)).subscribe(function (response) {
            _this.entitySubject.next(response.items);
            _this.paginatorTotalSubject.next(response.totalCount);
        });
        _this.isPreloadTextViewed$ = _this.store.pipe(select(selectPRShowInitWaitingMessage));
        _this.loading$ = _this.store.pipe(select(selectProductRemarksPageLoading));
        return _this;
    }
    return ProductRemarksDataSource;
}(BaseDataSource));
export { ProductRemarksDataSource };
