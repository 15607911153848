import * as tslib_1 from "tslib";
import { InjectionService } from './injection.service';
import { InjectionRegistery } from './injection-registery.service';
import { TooltipContentComponent } from './tooltip.component';
var TooltipService = /** @class */ (function (_super) {
    tslib_1.__extends(TooltipService, _super);
    function TooltipService(injectionService) {
        var _this = _super.call(this, injectionService) || this;
        _this.injectionService = injectionService;
        _this.type = TooltipContentComponent;
        return _this;
    }
    return TooltipService;
}(InjectionRegistery));
export { TooltipService };
