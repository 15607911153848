<spinner *ngIf="isLoading"></spinner>

<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header header-custom">
		<span class="modal-title-save cus-list"> {{isLicenseAvailable ? 'Activate License' : 'Subscribe' }} </span>
	</div>
	<div class="modal-body ">
		<div class="table-content-top tab-col space" *ngIf="isLicenseAvailable">
			<div>
				License is already available.
			</div>
			<div>
				Do you want to Activate License
			</div>
		</div>

		<!--begin::Table-->
		<div id="kt_table_users_wrapper" *ngIf="!isLicenseAvailable" class="dataTables_wrapper dt-bootstrap4 no-footer">
			<!-- ********************* All Plans Table ********************** -->
			<div class="table-responsive">
				<table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
					<thead>
						<tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
							<td class="width-20p">Plan Name</td>
							<td class="width-20p">Available License Count</td>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let plans of allPlansList">
							<td>{{plans?.name}}</td>
							<td> {{plans?.totalCount}} </td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- ********************* Selected Plan Table ********************** -->
			<div class="table-responsive" *ngIf="existingSubscriptionId != goldSubscriptionId">
				<table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
					<!--begin::Table head-->
					<thead>
						<tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
							<td class="width-20p">Plan Name</td>
							<td class="width-20p">Quantity</td>
							<td class="width-20p">Unit Price</td>
							<td class="width-20p">Total Amount</td>
						</tr>
					</thead>
					<!--end::Table head-->
					<!--begin::Table body-->
					<tbody>
						<tr>
							<td>{{selectedPlanDetails?.name}}</td>
							<td>
								<select [(ngModel)]="planQuantity">
									<option *ngFor="let i of quantityList" [value]="i">{{i}}</option>
								</select>
								<!-- <mat-select [(ngModel)]="planQuantity">
									<mat-option *ngFor="let i of quantityList" [value]="i">{{i}}</mat-option>
								</mat-select> -->
							</td>
							<td>{{selectedPlanDetails?.amount}}</td>
							<td>{{selectedPlanDetails?.amount * planQuantity}}</td>
							<td>
								<a class="btn btn-orange btn-sm px-4 me-2" (click)="buyPlan()">Subscribe</a>
							</td>
						</tr>
					</tbody>
					<!--end::Table body-->
				</table>
				<!--end::Table-->
			</div>
			<div class="row">
				<div class="col" *ngFor="let subscription of subscriptionsList">
					<div> {{subscription?.name}} </div>
					<div> {{subscription?.description}} </div>
					<div> {{subscription?.price}} </div>
					<div>
						<button [disabled]="existingSubscriptionId == subscription['id']"
							class="btn btn-orange btn-sm px-4 me-2" (click)="subscribeToPlan(subscription)">
							{{ existingSubscriptionId == subscription['id'] ? 'Current Subscription' : 'Subscribe' }}
						</button>
					</div>
				</div>
			</div>
			<!--end::Card body-->
		</div>
		<div class="text-right" *ngIf="isLicenseAvailable">
			<button class="btn-gary" (click)="activateLicense()" style="cursor: pointer;">Yes</button>
			<button class="btn-gary" (click)="onPopupClose()" style="cursor: pointer;">No</button>
		</div>
		<div class="text-right" *ngIf="!isLicenseAvailable">
			<button class="btn-gary" (click)="onPopupClose()" style="cursor: pointer;">Cancel</button>
		</div>
	</div>
</ng-template>