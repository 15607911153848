
    <svg:defs *ngIf="gradient">
      <svg:g ngx-charts-svg-linear-gradient
        orientation="vertical"
        [name]="gradientId"
        [stops]="gradientStops"
      />
    </svg:defs>
    <svg:path
      class="area"
      [attr.d]="areaPath"
      [attr.fill]="gradient ? gradientFill : fill"
      [style.opacity]="opacity"
    />
  