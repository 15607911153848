/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./legend-entry.component";
var styles_LegendEntryComponent = [];
var RenderType_LegendEntryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LegendEntryComponent, data: {} });
export { RenderType_LegendEntryComponent as RenderType_LegendEntryComponent };
export function View_LegendEntryComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["tabindex", "-1"]], [[8, "title", 0], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select.emit(_co.formattedLabel) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "legend-label-color"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle.emit(_co.formattedLabel) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "legend-label-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedLabel; var currVal_1 = _co.isActive; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.color; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.trimmedLabel; _ck(_v, 3, 0, currVal_3); }); }
export function View_LegendEntryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-charts-legend-entry", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_LegendEntryComponent_0, RenderType_LegendEntryComponent)), i0.ɵdid(1, 49152, null, 0, i1.LegendEntryComponent, [], null, null)], null, null); }
var LegendEntryComponentNgFactory = i0.ɵccf("ngx-charts-legend-entry", i1.LegendEntryComponent, View_LegendEntryComponent_Host_0, { color: "color", label: "label", formattedLabel: "formattedLabel", isActive: "isActive" }, { select: "select", activate: "activate", deactivate: "deactivate", toggle: "toggle" }, []);
export { LegendEntryComponentNgFactory as LegendEntryComponentNgFactory };
