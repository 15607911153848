/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./svg-linear-gradient.component";
var styles_SvgLinearGradientComponent = [];
var RenderType_SvgLinearGradientComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgLinearGradientComponent, data: {} });
export { RenderType_SvgLinearGradientComponent as RenderType_SvgLinearGradientComponent };
function View_SvgLinearGradientComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:stop", [], [[1, "offset", 0], [4, "stop-color", null], [4, "stop-opacity", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.offset + "%"); var currVal_1 = _v.context.$implicit.color; var currVal_2 = _v.context.$implicit.opacity; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SvgLinearGradientComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":svg:linearGradient", [], [[8, "id", 0], [1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SvgLinearGradientComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.stops; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; var currVal_1 = _co.x1; var currVal_2 = _co.y1; var currVal_3 = _co.x2; var currVal_4 = _co.y2; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_SvgLinearGradientComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-svg-linear-gradient", ""]], null, null, null, View_SvgLinearGradientComponent_0, RenderType_SvgLinearGradientComponent)), i0.ɵdid(1, 573440, null, 0, i2.SvgLinearGradientComponent, [], null, null)], null, null); }
var SvgLinearGradientComponentNgFactory = i0.ɵccf("g[ngx-charts-svg-linear-gradient]", i2.SvgLinearGradientComponent, View_SvgLinearGradientComponent_Host_0, { orientation: "orientation", name: "name", stops: "stops" }, {}, []);
export { SvgLinearGradientComponentNgFactory as SvgLinearGradientComponentNgFactory };
