/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../legend/scale-legend.component.ngfactory";
import * as i2 from "../legend/scale-legend.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../legend/legend.component.ngfactory";
import * as i5 from "../legend/legend.component";
import * as i6 from "@angular/common";
import * as i7 from "../tooltip/tooltip.service";
import * as i8 from "../tooltip/injection.service";
import * as i9 from "./chart.component";
var styles_ChartComponent = [];
var RenderType_ChartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChartComponent, data: { "animation": [{ type: 7, name: "animationState", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "500ms 100ms" }], options: null }], options: {} }] } });
export { RenderType_ChartComponent as RenderType_ChartComponent };
function View_ChartComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-charts-scale-legend", [["class", "chart-legend"]], null, null, null, i1.View_ScaleLegendComponent_0, i1.RenderType_ScaleLegendComponent)), i0.ɵdid(1, 573440, null, 0, i2.ScaleLegendComponent, [i3.DomSanitizer], { valueRange: [0, "valueRange"], colors: [1, "colors"], height: [2, "height"], width: [3, "width"], horizontal: [4, "horizontal"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.legendOptions.domain; var currVal_1 = _co.legendOptions.colors; var currVal_2 = _co.view[1]; var currVal_3 = _co.legendWidth; var currVal_4 = (_co.legendOptions && (_co.legendOptions.position === "below")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ChartComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-charts-legend", [["class", "chart-legend"]], null, [[null, "labelClick"], [null, "labelActivate"], [null, "labelDeactivate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("labelClick" === en)) {
        var pd_0 = (_co.legendLabelClick.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("labelActivate" === en)) {
        var pd_1 = (_co.legendLabelActivate.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("labelDeactivate" === en)) {
        var pd_2 = (_co.legendLabelDeactivate.emit($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_LegendComponent_0, i4.RenderType_LegendComponent)), i0.ɵdid(1, 573440, null, 0, i5.LegendComponent, [i0.ChangeDetectorRef], { data: [0, "data"], title: [1, "title"], colors: [2, "colors"], height: [3, "height"], width: [4, "width"], activeEntries: [5, "activeEntries"], horizontal: [6, "horizontal"] }, { labelClick: "labelClick", labelActivate: "labelActivate", labelDeactivate: "labelDeactivate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.legendOptions.domain; var currVal_1 = _co.legendOptions.title; var currVal_2 = _co.legendOptions.colors; var currVal_3 = _co.view[1]; var currVal_4 = _co.legendWidth; var currVal_5 = _co.activeEntries; var currVal_6 = (_co.legendOptions && (_co.legendOptions.position === "below")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ChartComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "ngx-charts-outer"]], [[4, "width", "px"], [24, "@animationState", 0], [24, "@.disabled", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "ngx-charts"]], [[1, "width", 0], [1, "height", 0]], null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChartComponent_1)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChartComponent_2)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.showLegend && (_co.legendType === "scaleLegend")); _ck(_v, 4, 0, currVal_5); var currVal_6 = (_co.showLegend && (_co.legendType === "legend")); _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view[0]; var currVal_1 = "active"; var currVal_2 = !_co.animations; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.chartWidth; var currVal_4 = _co.view[1]; _ck(_v, 1, 0, currVal_3, currVal_4); }); }
export function View_ChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "ngx-charts-chart", [], null, null, null, View_ChartComponent_0, RenderType_ChartComponent)), i0.ɵprd(512, null, i7.TooltipService, i7.TooltipService, [i8.InjectionService]), i0.ɵdid(2, 573440, null, 0, i9.ChartComponent, [i0.ViewContainerRef, i7.TooltipService], null, null)], null, null); }
var ChartComponentNgFactory = i0.ɵccf("ngx-charts-chart", i9.ChartComponent, View_ChartComponent_Host_0, { view: "view", showLegend: "showLegend", legendOptions: "legendOptions", data: "data", legendData: "legendData", legendType: "legendType", colors: "colors", activeEntries: "activeEntries", animations: "animations" }, { legendLabelClick: "legendLabelClick", legendLabelActivate: "legendLabelActivate", legendLabelDeactivate: "legendLabelDeactivate" }, ["*"]);
export { ChartComponentNgFactory as ChartComponentNgFactory };
