import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { map, startWith } from 'rxjs/operators';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
var AutoCompleteControlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AutoCompleteControlComponent, _super);
    function AutoCompleteControlComponent(jadwaService) {
        var _this = _super.call(this) || this;
        _this.jadwaService = jadwaService;
        return _this;
    }
    AutoCompleteControlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadList();
        console.log(this.field.templateOptions.service);
        // this.filteredOptions = this.formControl.valueChanges.pipe(
        //   startWith(''),
        //   mergeMap(value =>  this.jadwaService.autoComplete(this.field.templateOptions.service, value).pipe(
        //     map(response =>{ 
        //       return response.result;
        //       // const filterValue = value.toLowerCase();
        //       // return response.result.filter(option => option.name.toLowerCase().includes(filterValue));
        //     })
        //   )),
        //   // map(value => this._filter(value)),
        // );
        this.filteredOptions = this.formControl.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
    };
    AutoCompleteControlComponent.prototype.loadList = function () {
        var _this = this;
        // this.NoRecordsDisplay = true;
        // this.loading = true;
        this.jadwaService.autoComplete(this.field.templateOptions.service).subscribe(function (response) {
            // this.loading = false;
            var tempOptionList = [];
            response.result.forEach(function (element) {
                tempOptionList.push(element.name);
            });
            _this.optionsList = tempOptionList;
            // console.log('productsList', this.optionsList)
        }, function (error) {
            // this.loading = false;
            // Swal.fire({
            //   title: error.error.responseException.exceptionMessage,
            //   icon: 'warning',
            //   showCancelButton: false,
            //   confirmButtonColor: '#37c0b9',
            //   confirmButtonText: this.translate.instant('TITLE.OKAY'),
            // });
        });
    };
    AutoCompleteControlComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.optionsList.filter(function (option) { return option.toLowerCase().includes(filterValue); });
    };
    return AutoCompleteControlComponent;
}(FieldType));
export { AutoCompleteControlComponent };
