<ng-container *ngFor="let singleSectionToAdd of sectionsToAdd;let index=index">
    <!--note:  whe nits section its complete page and only single item -->
    <div *ngIf="singleSectionToAdd.placeholderType==='Section'" class="a4-block a4-img-block"
        style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;overflow:hidden;border:1px solid #ccc;margin:0
        auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative;padding:0">
        <div style="position:absolute;top:200px;z-index:2;width:100%;text-align:center;padding-left:10px">
        </div>
        <div
            style="position:absolute;bottom:400px;z-index:2;width:100%;font-size:50px;text-align:center;padding-left:10px;color:#1e1a4d;font-weight:600">
            <div align="center"><img src="assets/media/logo.jpg" style="width:300px;height:96px;margin-bottom:60px">
            </div>{{singleSectionToAdd?.placeholderValue?.value?.mainHeading}}<div
                style="font-size:30px;color:#faab1b;font-weight:400;margin-top:15px">
                {{singleSectionToAdd?.placeholderValue?.value?.subHeading}}</div>
        </div><img src="assets/media/first-page-top-bg.jpg"
            style="width:100%;position:absolute;left:0;right:0;top:0;z-index:1"> <img
            src="assets/media/firstpage-bottom-curve-img.jpg"
            style="width:100%;position:absolute;left:0;right:0;bottom:0;z-index:1">
    </div>
</ng-container>
    <div *ngIf="sectionsToAdd[0].placeholderType!=='Section'" class="a4-block a4-img-block" style="height:297mm;width:210mm;max-height:297mm;max-width:210mm;padding:60px;overflow:hidden;border:1px solid
        #ccc;margin:0
        auto;margin-bottom:30px;box-sizing:border-box;font-family:Arial,Helvetica,sans-serif;position:relative">
        
        <img
            src="assets/media/left-bg.jpg"
            style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1" *ngIf="currentIndex%2==0">
        <img
            src="assets/media/left-bg-2.jpg"
            style="width:18mm;height:217mm;position:absolute;left:0;top:50%;margin-top:-108.5mm;z-index:1" *ngIf="currentIndex%2!=0">
        <div class="pdf-header" style="border-bottom:2px solid
            #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;top:50px;left:50px;right:50px">
            <table width="100%">
                <tr>
                    <td align="left">PageHeadingLeft</td>
                    <td align="right">PageHeadingRight</td>
                </tr>
            </table>
        </div>
        <div class="pdf-footer" id="a4-footer" style="border-top:2px solid
            #1e1a4e;font-weight:700;font-size:16px;color:#50c3b9;position:absolute;bottom:50px;left:50px;right:50px">
            <table width="100%">
                <tr>
                    <td align="left">PageFooterLeft</td>
                    <td align="right">&nbsp;</td>
                </tr>
            </table>
            <div class="number-right" style="background:#fff;position:absolute;right:0;top:-9px;padding-left:9px">
                Page{{currentIndex+1}}</div>
        </div>
        <div style="  margin-top: 40px;min-height: calc(100% - 88px);max-height:calc(100% - 88px); position: relative; z-index: 1;overflow:hidden"
            id="a4-content-limit-tag">
            <ng-container *ngFor="let singleSectionToAdd of sectionsToAdd;let index=index">
            <div [id]="currentId">
                <ng-container *ngIf="singleSectionToAdd.placeholderType ==='HtmlTable'">
                    <div class="html-template" [innerHTML]="singleSectionToAdd.placeholderValue.value"></div>
                </ng-container>
                <ng-container *ngIf="singleSectionToAdd.placeholderType ==='HtmlText'">
                    <div class="html-template" [innerHTML]="singleSectionToAdd.placeholderValue.value"></div>
                </ng-container>
                <ng-container *ngIf="singleSectionToAdd.placeholderType ==='Heading'">
                    <h1 class="html-template">{{singleSectionToAdd.placeholderValue.value}}</h1>
                </ng-container>
                <div class="row" *ngIf="singleSectionToAdd?.placeholderType == 'Chart'">

                    <div class="col-6" id="{{singleSectionToAdd.placeholderName}}" style="width: 200px; display: grid;">
                        <div *ngIf="singleSectionToAdd?.graphType == 'PieChart'">
                            <jedwa-custom-ngx-charts-pie-chart
                                [view]="getGraphValueForProperty(singleSectionToAdd,'view')" [scheme]="colorScheme"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [labels]="getGraphValueForProperty(singleSectionToAdd,'labels')"
                                [doughnut]="getGraphValueForProperty(singleSectionToAdd,'doughnut')"
                                [arcWidth]="getGraphValueForProperty(singleSectionToAdd,'arcWidth')"
                                [legendTitle]="getGraphValueForProperty(singleSectionToAdd,'legendTitle')"
                                [legendPosition]="getGraphValueForProperty(singleSectionToAdd,'legendPosition')"
                                (select)="onSelect($event)" (activate)="onActivate($event)"
                                (deactivate)="onDeactivate($event)">
                            </jedwa-custom-ngx-charts-pie-chart><br />
                        </div>
                        <div *ngIf="singleSectionToAdd?.graphType == 'PieGridChart'">
                            <ngx-charts-pie-grid [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [label]="getGraphValueForProperty(singleSectionToAdd,'label')"
                                (select)="onSelect($event)"
                                [minWidth]="getGraphValueForProperty(singleSectionToAdd,'minWidth')">
                            </ngx-charts-pie-grid><br />
                        </div>
                        <div *ngIf="singleSectionToAdd?.graphType == 'AdvancedPieChart'">
                            <ngx-charts-advanced-pie-chart [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [label]="getGraphValueForProperty(singleSectionToAdd,'label')"
                                (select)="onSelect($event)" (activate)="onActivate($event)"
                                (deactivate)="onDeactivate($event)">
                            </ngx-charts-advanced-pie-chart><br />
                        </div>


                        <div *ngIf="singleSectionToAdd?.graphType == 'LineChart'">
                            <ngx-charts-line-chart [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [schemeType]="getGraphValueForProperty(singleSectionToAdd,'schemeType')"
                                [rangeFillOpacity]="getGraphValueForProperty(singleSectionToAdd,'rangeFillOpacity')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [legendTitle]="getGraphValueForProperty(singleSectionToAdd,'legendTitle')"
                                [legendPosition]="getGraphValueForProperty(singleSectionToAdd,'legendPosition')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                [roundDomains]="getGraphValueForProperty(singleSectionToAdd,'roundDomains')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [trimXAxisTicks]="getGraphValueForProperty(singleSectionToAdd,'trimXAxisTicks')"
                                [trimYAxisTicks]="getGraphValueForProperty(singleSectionToAdd,'trimYAxisTicks')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [autoScale]="getGraphValueForProperty(singleSectionToAdd,'autoScale')"
                                (select)="onSelect($event)">
                            </ngx-charts-line-chart><br />
                        </div>
                        <div *ngIf="singleSectionToAdd?.graphType == 'AreaChart'">
                            <ngx-charts-area-chart [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [schemeType]="getGraphValueForProperty(singleSectionToAdd,'schemeType')"
                                [timeline]="getGraphValueForProperty(singleSectionToAdd,'timeline')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [legendTitle]="getGraphValueForProperty(singleSectionToAdd,'legendTitle')"
                                [legendPosition]="getGraphValueForProperty(singleSectionToAdd,'legendPosition')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                [roundDomains]="getGraphValueForProperty(singleSectionToAdd,'roundDomains')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [trimXAxisTicks]="getGraphValueForProperty(singleSectionToAdd,'trimXAxisTicks')"
                                [trimYAxisTicks]="getGraphValueForProperty(singleSectionToAdd,'trimYAxisTicks')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [autoScale]="getGraphValueForProperty(singleSectionToAdd,'autoScale')"
                                (select)="onSelect($event)">
                            </ngx-charts-area-chart><br />
                        </div>
                        <div *ngIf="singleSectionToAdd?.graphType == 'TreeMap'">
                            <ngx-charts-tree-map [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                (select)="onSelect($event)">
                            </ngx-charts-tree-map>
                        </div>

                        <div elementAddedToView (ngInit)="barChartAdded($event)"
                            *ngIf="singleSectionToAdd?.graphType == 'VerticalBarChart'">
                            <ngx-charts-bar-vertical [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [legendPosition]="getGraphValueForProperty(singleSectionToAdd,'legendPosition')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [barPadding]="getGraphValueForProperty(singleSectionToAdd,'barPadding')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                (select)="onSelect($event)">
                            </ngx-charts-bar-vertical><br />
                        </div>
                        <div elementAddedToView (ngInit)="barChartAdded($event)"
                            *ngIf="singleSectionToAdd?.graphType == 'HorizontalBarChart'">
                            <ngx-charts-bar-horizontal [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [legendPosition]="getGraphValueForProperty(singleSectionToAdd,'legendPosition')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [barPadding]="getGraphValueForProperty(singleSectionToAdd,'barPadding')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                (select)="onSelect($event)">
                            </ngx-charts-bar-horizontal><br />
                        </div>

                        <!-- grouped chart start -->
                        <div *ngIf="singleSectionToAdd?.graphType == 'GroupedVerticalBarChart'">
                            <ngx-charts-bar-vertical-2d [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [barPadding]="getGraphValueForProperty(singleSectionToAdd,'barPadding')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                (select)="onSelect($event)"
                                [groupPadding]="getGraphValueForProperty(singleSectionToAdd,'groupPadding')">
                            </ngx-charts-bar-vertical-2d><br />
                        </div>
                        <div *ngIf="singleSectionToAdd?.graphType == 'GroupedHorizontalBarChart'">
                            <ngx-charts-bar-horizontal-2d [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [barPadding]="getGraphValueForProperty(singleSectionToAdd,'barPadding')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                (select)="onSelect($event)"
                                [groupPadding]="getGraphValueForProperty(singleSectionToAdd,'groupPadding')">
                            </ngx-charts-bar-horizontal-2d><br />
                        </div>
                        <!-- grouped chart end -->
                        <!-- stacked bar chart start -->
                        <div *ngIf="singleSectionToAdd?.graphType == 'StackedVerticalBarChart'">
                            <ngx-charts-bar-vertical-stacked
                                [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [barPadding]="getGraphValueForProperty(singleSectionToAdd,'barPadding')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                (select)="onSelect($event)">
                            </ngx-charts-bar-vertical-stacked><br />
                        </div>
                        <div *ngIf="singleSectionToAdd?.graphType == 'StackedHorizontalBarChart'">
                            <ngx-charts-bar-horizontal-stacked
                                [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue : singleSectionToAdd.arabicPlaceholderValue"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [barPadding]="getGraphValueForProperty(singleSectionToAdd,'barPadding')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                (select)="onSelect($event)">
                            </ngx-charts-bar-horizontal-stacked><br />
                        </div>
                        <div *ngIf="singleSectionToAdd?.graphType == 'ComboChart'">

                            <combo-chart-component class="chart-container"
                                [view]="getGraphValueForProperty(singleSectionToAdd,'view')"
                                [scheme]="getGraphValueForProperty(singleSectionToAdd,'scheme')"
                                [colorSchemeLine]="getGraphValueForProperty(singleSectionToAdd,'colorSchemeLine')"
                                [results]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue.barData  : singleSectionToAdd.arabicPlaceholderValue.barData"
                                [animations]="false"
                                [lineChart]="(currentLanguage == 'en') ? singleSectionToAdd.placeholderValue.lineData : singleSectionToAdd.arabicPlaceholderValue.lineData"
                                [tooltipDisabled]="getGraphValueForProperty(singleSectionToAdd,'tooltipDisabled')"
                                [gradient]="getGraphValueForProperty(singleSectionToAdd,'gradient')"
                                [xAxis]="getGraphValueForProperty(singleSectionToAdd,'xAxis')"
                                [yAxis]="getGraphValueForProperty(singleSectionToAdd,'yAxis')"
                                [legend]="getGraphValueForProperty(singleSectionToAdd,'legend')"
                                [legendTitle]="getGraphValueForProperty(singleSectionToAdd,'legendTitle')"
                                [legendPosition]="getGraphValueForProperty(singleSectionToAdd,'legendPosition')"
                                [showGridLines]="getGraphValueForProperty(singleSectionToAdd,'showGridLines')"
                                [showXAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showXAxisLabel')"
                                [showYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showYAxisLabel')"
                                [showRightYAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'showRightYAxisLabel')"
                                [xAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'xAxisLabel')"
                                [yAxisLabel]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabel')"
                                [yAxisLabelRight]="getGraphValueForProperty(singleSectionToAdd,'yAxisLabelRight')"
                                [noBarWhenZero]="getGraphValueForProperty(singleSectionToAdd,'noBarWhenZero')"
                                [barPadding]="getGraphValueForProperty(singleSectionToAdd,'barPadding')"
                                (select)="onSelect($event)">
                            </combo-chart-component>
                        </div>

                    </div>
                </div>

            </div>
        </ng-container>
        </div>

    </div>
