import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { JadwaService } from '../../core/auth/_services/jadwa.service';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { ProjectPlansComponent } from '../project-plans/project-plans.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectIndustryComponent } from '../project-industry/project-industry.component';
import { Logout } from '../../../app/core/auth';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
@Component({
  selector: 'create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {

  id: string;
  userProjectForm = new FormGroup({
    "name": new FormControl(''),
    // "startDate": new FormControl('2021-10-15'),
    // "firstYear": new FormControl(''),
    // "inputCurrency": new FormControl(''),
    // "outputCurrency": new FormControl(''),
    "industryId": new FormControl(''),
    "userId": new FormControl(''),
    "inputLanguage": new FormControl(''),
  });
  showPlan: boolean;
  subscribedPlansList: Array<any> = [];
  selectionCount = 0;
  isSaveClicked: boolean = false;
  userProjectDetails = {};
  showBuyButton: boolean = false;
  showPlanPopup: boolean = false;
  selectedPlanDetails: any = {};
  allPlansList: Array<any> = [];

  constructor(
    private jadwaService: JadwaService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<CreateProjectComponent>,
    private store: Store<AppState>,) {

    this.id = (!!data.id) ? data.id : null;
    this.allPlansList = data['allPlansList'];
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.id = params['id'];
    // });
  }


  selectIndustry(): void {
    const dialogRef = this.dialog.open(ProjectIndustryComponent, {
      width: '100%',
    });
  }

  startYears: any;
  ngOnInit() {
    this.startYears = this.getNextTenYears(10);
    this.getCurrencies();
    this.getDocuments();
    this.getIndustries();
    this.fetchUserSubscriptions();
    if (this.id) {
      this.getUserProjectDetails();
    }
  }
  // ************ Method to check Subscribed Plans Total Count ************** 
  fetchUserSubscriptions() {
    let params = {
      userId: localStorage.getItem('userId'),
      planId: null,
      filterAvailable: false
    };
    this.jadwaService.getUserSubscriptions(params).subscribe(res => {
      if (res['result']) {
        let count = 0;
        res['result']['subscribedPlans'].forEach(item => {
          item['isSelected'] = false;
          item['isAlreadySelected'] = false;
          if (item['totalCount'] == 0) {
            count++;
          }
        });
        if (count == res['result']['subscribedPlans'].length) {
          this.showBuyButton = true;
        } else {
          this.showBuyButton = false;
        }
        this.subscribedPlansList = res['result']['subscribedPlans'];
        if (this.id) {
          this.updatePlanDisplayList();
        }
      }
    });
  }

  currencies: any;
  getCurrencies() {
    this.jadwaService.getCurrencies().subscribe((response: any) => {
      this.loading = false;
      this.currencies = response.result;
    }, error => {
      this.loading = false;
      if (error.status == 401) {
        Swal.fire({
          title: 'Session Expired',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        }).then(res => {
          // navigate to login page
          this.store.dispatch(new Logout());
          this.dialog.closeAll();
          this.router.navigate(['/login']);
        });
      } else {
        Swal.fire({
          title: 'Something went Wrong',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        });
      }

    });
  }


  industries: any;
  getIndustries() {

    this.jadwaService.getIndustries().subscribe((response: any) => {
      this.loading = false;
      this.industries = response.result;
    }, error => {
      this.loading = false;
      if (error.status == 401) {
        Swal.fire({
          title: 'Session Expired',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        }).then(res => {
          // navigate to login page
          this.store.dispatch(new Logout());
          this.dialog.closeAll();
          this.router.navigate(['/login']);
        });
      } else {
        Swal.fire({
          title: 'Something went Wrong',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        });
      }
    });
  }

  documents: any;
  getDocuments() {
    this.jadwaService.getDocuments().subscribe((response: any) => {
      this.loading = false;
      this.documents = response.result;
    }, error => {
      this.loading = false;
      if (error.status == 401) {
        Swal.fire({
          title: 'Session Expired',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        }).then(res => {
          // navigate to login page
          this.store.dispatch(new Logout());
          this.dialog.closeAll();
          this.router.navigate(['/login']);
        });
      } else {
        Swal.fire({
          title: 'Something went Wrong',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        });
      }
    });
  }

  loading: any;
  planDetails: any;

  // ********** Method to update the Plans Subscription in Edit mode *****************
  updatePlanDisplayList() {
    if (this.subscribedPlansList.length > 0) {
      if (this.userProjectDetails['plansInUse']) {
        this.userProjectDetails['plansInUse'].forEach(plan => {
          let index = this.subscribedPlansList.findIndex(item => item.planId == plan.planId);
          if (index != -1) {
            this.subscribedPlansList[index]['isSelected'] = true;
            this.subscribedPlansList[index]['isAlreadySelected'] = true;
          }
        });
      }
    }
  }

  // mappedDocuments: any;
  getUserProjectDetails() {
    this.jadwaService.getUserProjectDetails(this.id).subscribe((response: any) => {
      this.loading = false;

      if (response.result) {
        let UserDocumentDetails = response.result;
        this.userProjectDetails = response.result;
        this.updatePlanDisplayList();

        this.userProjectForm.patchValue({
          name: UserDocumentDetails.name,
          // "startDate": UserDocumentDetails.startDate,
          // "firstYear": UserDocumentDetails.firstYear,
          // "inputCurrency": UserDocumentDetails.inputCurrency,
          // "outputCurrency": UserDocumentDetails.outputCurrency,
          industryId: UserDocumentDetails.industryId,
          userId: UserDocumentDetails.userId,
          inputLanguage: UserDocumentDetails.inputLanguage
        });
      }
    }, error => {
      this.loading = false;
      Swal.fire({
        title: 'Something went Wrong',
        // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    });
  }


  save() {
    this.selectionCount = 0;
    let selectedPlans = [];
    this.subscribedPlansList.forEach(plan => {
      if (plan['isSelected']) {
        this.selectionCount++;
        selectedPlans.push({ planId: plan.planId })
      }
    });
    this.isSaveClicked = true;

    if (this.userProjectForm.valid && this.selectionCount > 0) {
      this.loading = true;

      let requestObj = {
        id: this.id,
        name: this.userProjectForm.controls['name'].value,
        // "startDate": this.userProjectForm.controls['startDate'].value,
        // "firstYear": this.userProjectForm.controls['firstYear'].value,
        // "inputCurrency": this.userProjectForm.controls['inputCurrency'].value,
        // "outputCurrency": this.userProjectForm.controls['outputCurrency'].value,
        industryId: this.userProjectForm.controls['industryId'].value,
        inputLanguage: this.userProjectForm.controls['inputLanguage'].value,
        userId: localStorage.getItem('userId'),
        plansInUse: selectedPlans
      }

      if (this.id) {
        this.jadwaService.updateUserProject(requestObj).subscribe((response: any) => {
          this.loading = false;
          if (response.result) {
            Swal.fire({
              title: 'Project Updated Successful',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#f08245',
              confirmButtonText: this.translate.instant('TITLE.OKAY'),
            }).then((result) => {
              this.dialogRef.close(true);
            });
          }
          // this.showPlan = true;
        }, error => {
          this.loading = false;
          Swal.fire({
            title: 'Something went Wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#37c0b9',
            confirmButtonText: this.translate.instant('TITLE.OKAY'),
          });
        });
      } else {
        this.jadwaService.createUserProject(requestObj).subscribe((response: any) => {
          this.loading = false;
          if (response.result) {
            Swal.fire({
              title: 'Project Created Successful',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#f08245',
              confirmButtonText: this.translate.instant('TITLE.OKAY'),
            }).then((result) => {
              this.dialogRef.close(true);
              localStorage.setItem('projectId', response.result.id);
            });
          }
          // this.showPlan = true;
        }, error => {
          this.loading = false;
          Swal.fire({
            title: 'Something went Wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#f08245',
            confirmButtonText: this.translate.instant('TITLE.OKAY'),
          });
        });
      }
    } else {
      Swal.fire({
        title: 'Please Fill Required Fields or some fields validation failed, please check for errors mentioned',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#f08245',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    }
  }

  getNextTenYears(years) {
    const year = new Date().getFullYear();
    return Array.from({ length: years }, (v, i) => year + i);
  }
  onBuyPlanClick(plan) {
    this.showPlanPopup = true;
    let data = this.allPlansList.filter(i => i.planId == plan.planId);

    this.selectedPlanDetails = data[0];
    this.subscribedPlansList.forEach(item => {
      this.allPlansList.forEach(element => {
        if (element['planId'] == item['planId']) {
          element['totalCount'] = item['totalCount'];
        }
      });
    });
  }
  onPlanPopupClose(event) {
    this.selectedPlanDetails = {};
    this.showPlanPopup = false;
    if (event['isPlanActivated']) {
      this.fetchUserSubscriptions();
    }
  }

}

