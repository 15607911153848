/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./load-json.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i4 from "@ngx-formly/core";
import * as i5 from "./load-json.component";
var styles_LoadJsonComponent = [i0.styles];
var RenderType_LoadJsonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadJsonComponent, data: {} });
export { RenderType_LoadJsonComponent as RenderType_LoadJsonComponent };
export function View_LoadJsonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.readForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Read Form Settings From JSON"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.readResponse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Read Response"])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.submit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(7, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "formly-form", [], null, null, null, i3.View_FormlyForm_0, i3.RenderType_FormlyForm)), i1.ɵprd(512, null, i4.FormlyFormBuilder, i4.FormlyFormBuilder, [i4.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(12, 966656, null, 0, i4.FormlyForm, [i4.FormlyFormBuilder, i4.FormlyConfig, i1.NgZone, [8, null], [2, i2.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.model; var currVal_10 = _co.fields; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_LoadJsonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-load-json", [], null, null, null, View_LoadJsonComponent_0, RenderType_LoadJsonComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoadJsonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadJsonComponentNgFactory = i1.ɵccf("app-load-json", i5.LoadJsonComponent, View_LoadJsonComponent_Host_0, {}, {}, []);
export { LoadJsonComponentNgFactory as LoadJsonComponentNgFactory };
