export let sampleResponse =[
    {
        "placeholderName": "ReportNo",
        "placeholderValue": "{\"value\":{\"subHeading\":\"123456\",\"mainHeading\":\"ReportName\"}}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Section",
        "graphType": "",
        "graphOptions": null,
        "order":34,
    },
    {
        "placeholderName": "ReportNo",
        "placeholderValue": "{\"value\":\"ProjectDatesTBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null,
        "order":32,
    },

    {
        "placeholderName": "ProjectDatesTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Start Date</td><td>01/01/2022</td></tr><tr><td>Period Type</td><td>Construction</td></tr><tr><td>Start Up Period</td><td>1 month(s)</td></tr><tr><td>Forecast Period</td><td>60 month(s)</td></tr><tr><td>Forecast End Month</td><td>12</td></tr><tr><td>End Date</td><td>31/12/2027</td></tr><tr><td>Forecast Start Date</td><td>01/02/2022</td></tr><tr><td>Forecast End Date</td><td>31/01/2027</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Start Date</td><td>01/01/2022</td></tr><tr><td>Period Type</td><td>Construction</td></tr><tr><td>Start Up Period</td><td>1 month(s)</td></tr><tr><td>Forecast Period</td><td>60 month(s)</td></tr><tr><td>Forecast End Month</td><td>12</td></tr><tr><td>End Date</td><td>31/12/2027</td></tr><tr><td>Forecast Start Date</td><td>01/02/2022</td></tr><tr><td>Forecast End Date</td><td>31/01/2027</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "ReportNo",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "TaxSettingsTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Corporate Tax %</td><td>5</td></tr><tr><td>VAT %</td><td>6</td></tr><tr><td>Zakat % of Income</td><td>4</td></tr><tr><td>% of Zakat Base</td><td>2</td></tr><tr><td>Minimum Contribution</td><td>1000</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Corporate Tax %</td><td>5</td></tr><tr><td>VAT %</td><td>6</td></tr><tr><td>Zakat % of Income</td><td>4</td></tr><tr><td>% of Zakat Base</td><td>2</td></tr><tr><td>Minimum Contribution</td><td>1000</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "ReportNo",
        "placeholderValue": "{\"value\":\"FA Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "FASettingsTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Asset Category</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Useful Life in Years</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Salvage %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Contingency as %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Maintenance as %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Insurance as %</th></tr></thead><tbody><tr><td>Default</td><td>7</td><td>0</td><td>0</td><td>0</td><td>0</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Asset Category</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Useful Life in Years</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Salvage %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Contingency as %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Maintenance as %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Insurance as %</th></tr></thead><tbody><tr><td>Default</td><td>7</td><td>0</td><td>0</td><td>0</td><td>0</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "RevenueOption 1 TBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "RevenueOption1TBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Product/Service Name</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Period</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Quantity</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Sell Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Yearly Growth %</th></tr></thead><tbody><tr><td>Product1</td><td>Yearly</td><td>10</td><td>1000</td><td>10</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Product/Service Name</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Period</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Quantity</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Sell Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Yearly Growth %</th></tr></thead><tbody><tr><td>Product1</td><td>Yearly</td><td>10</td><td>1000</td><td>10</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "RevenueOption 2 TBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "RevenueOption2TBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Product/Service Name</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Period</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Quantity</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Sell Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Yearly Growth %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Direct Cost Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Other Cost As % of Sale Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Other Cost As Fixed Amount Per Qty</th></tr></thead><tbody><tr><td>product2</td><td>Yearly</td><td>10</td><td>1000</td><td>10</td><td>0</td><td>80</td><td>0</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Product/Service Name</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Period</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Quantity</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Sell Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Yearly Growth %</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Direct Cost Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Other Cost As % of Sale Price</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Other Cost As Fixed Amount Per Qty</th></tr></thead><tbody><tr><td>product2</td><td>Yearly</td><td>10</td><td>1000</td><td>10</td><td>0</td><td>80</td><td>0</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "StartupExpenseTBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "StartupExpenseTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Expense</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Amount</th></tr></thead><tbody><tr><td>expense1</td><td>100</td></tr><tr><td>xadd</td><td>200</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Expense</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Amount</th></tr></thead><tbody><tr><td>expense1</td><td>100</td></tr><tr><td>xadd</td><td>200</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "StartupExpenseTBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "ExpenseAsPercentageOfRevenueTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Expense Type</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">% As Revenue</th></tr></thead><tbody><tr><td>Operating</td><td>1</td></tr><tr><td>Marketing</td><td>2</td></tr><tr><td>Payroll</td><td>3</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><thead><tr><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">Expense Type</th><th style=\\\"background:#20bcb7; border-radius:2px; color:#333;\\\">% As Revenue</th></tr></thead><tbody><tr><td>Operating</td><td>1</td></tr><tr><td>Marketing</td><td>2</td></tr><tr><td>Payroll</td><td>3</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    
    {
        "placeholderName": "StartupExpenseTBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "WorkingCapitalTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Minimum Amount as Working Capital</td><td>5000</td></tr><tr><td>Minimum Cash as % of Expenses</td><td>6</td></tr><tr><td>Sales Turn Over Days</td><td>90</td></tr><tr><td>Purchase Turn Over Days</td><td>90</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Minimum Amount as Working Capital</td><td>5000</td></tr><tr><td>Minimum Cash as % of Expenses</td><td>6</td></tr><tr><td>Sales Turn Over Days</td><td>90</td></tr><tr><td>Purchase Turn Over Days</td><td>90</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "StartupExpenseTBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "DividendsTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Dividend Payment Month</td><td>12</td></tr><tr><td>Divident Option</td><td>Fixed Payment Plan</td></tr><tr><td>Value</td><td>100</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Dividend Payment Month</td><td>12</td></tr><tr><td>Divident Option</td><td>Fixed Payment Plan</td></tr><tr><td>Value</td><td>100</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "StartupExpenseTBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "EquityAndLongTermLoanTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Funding Available As Equity</td><td>30</td></tr><tr><td colspan=\\\"2\\\">Long Term Loan Details:</td></tr><tr><td>Loan Start Date</td><td>01/02/2022</td></tr><tr><td>Interest Rate</td><td>10</td></tr><tr><td>Loan Tenor</td><td>36</td></tr><tr><td>Loan Processing Fee</td><td>1</td></tr><tr><td>Loan Grace Period in Months</td><td>6</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Funding Available As Equity</td><td>30</td></tr><tr><td colspan=\\\"2\\\">Long Term Loan Details:</td></tr><tr><td>Loan Start Date</td><td>01/02/2022</td></tr><tr><td>Interest Rate</td><td>10</td></tr><tr><td>Loan Tenor</td><td>36</td></tr><tr><td>Loan Processing Fee</td><td>1</td></tr><tr><td>Loan Grace Period in Months</td><td>6</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "StartupExpenseTBL",
        "placeholderValue": "{\"value\":\"Tax Settings TBL\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Heading",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "ShortTermLoanTBL",
        "placeholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Short Term Working Loan Amount</td><td>3500</td></tr><tr><td colspan=\\\"2\\\">Short Term Loan Details:</td></tr><tr><td>Loan Start Date</td><td>01/03/2022</td></tr><tr><td>Interest Rate</td><td>3</td></tr><tr><td>Loan Tenor</td><td>24</td></tr><tr><td>Loan Processing Fee</td><td>30</td></tr><tr><td>Loan Grace Period in Months</td><td>2</td></tr></tbody></table>\"}",
        "arabicPlaceholderValue": "{\"value\":\"<table class=\\\"table table-bordered\\\" border=\\\"1\\\" style=\\\"border-collapse:collapse;width:100%;\\\" cellpadding=\\\"5\\\"><tbody><tr><td>Short Term Working Loan Amount</td><td>3500</td></tr><tr><td colspan=\\\"2\\\">Short Term Loan Details:</td></tr><tr><td>Loan Start Date</td><td>01/03/2022</td></tr><tr><td>Interest Rate</td><td>3</td></tr><tr><td>Loan Tenor</td><td>24</td></tr><tr><td>Loan Processing Fee</td><td>30</td></tr><tr><td>Loan Grace Period in Months</td><td>2</td></tr></tbody></table>\"}",
        "placeholderType": "HtmlTable",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "ProblemSummaryTXT",
        "placeholderValue": "{\"value\":\"Problem Statement for Lean Canvas&#160;<p>Problem Statement for Lean Canvas 2<br></p><p>Problem Statement for Lean Canvas 3<br></p><p><br></p>\"}",
        "arabicPlaceholderValue": "{\"value\":\"Problem Statement for Lean Canvas&#160;<p>Problem Statement for Lean Canvas 2<br></p><p>Problem Statement for Lean Canvas 3<br></p><p><br></p>\"}",
        "placeholderType": "HtmlText",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "SolutionSummaryTXT",
        "placeholderValue": "{\"value\":\"Solution For Lean Canvas<p>Solution For Lean Canvas 2<br></p><p>Solution For Lean Canvas 3<br></p>\"}",
        "arabicPlaceholderValue": "{\"value\":\"Solution For Lean Canvas<p>Solution For Lean Canvas 2<br></p><p>Solution For Lean Canvas 3<br></p>\"}",
        "placeholderType": "HtmlText",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "KeyMetricsSummaryTXT",
        "placeholderValue": "{\"value\":\"Key Metrics For Lean canvas&#160;<p>Key Metrics For Lean canvas 2<br></p>\"}",
        "arabicPlaceholderValue": "{\"value\":\"Key Metrics For Lean canvas&#160;<p>Key Metrics For Lean canvas 2<br></p>\"}",
        "placeholderType": "HtmlText",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "UVPSummaryTXT",
        "placeholderValue": "{\"value\":\"Unique Value Proposition for Lean canvas<p>Unique Value Proposition for Lean canvas 2<br></p>\"}",
        "arabicPlaceholderValue": "{\"value\":\"Unique Value Proposition for Lean canvas<p>Unique Value Proposition for Lean canvas 2<br></p>\"}",
        "placeholderType": "HtmlText",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "RevenueGrossMarginChart",
        "placeholderValue": "{\"lineData\":[{\"name\":\"Gross Margin\",\"series\":[{\"name\":\"2022\",\"value\":1.0},{\"name\":\"2023\",\"value\":1.0},{\"name\":\"2024\",\"value\":1.0},{\"name\":\"2025\",\"value\":1.0},{\"name\":\"2026\",\"value\":1.0},{\"name\":\"2027\",\"value\":1.0}]}],\"barData\":[{\"name\":\"2022\",\"value\":18333.0},{\"name\":\"2023\",\"value\":22000.0},{\"name\":\"2024\",\"value\":24200.0},{\"name\":\"2025\",\"value\":26620.0},{\"name\":\"2026\",\"value\":29282.0},{\"name\":\"2027\",\"value\":2684.0}]}",
        "arabicPlaceholderValue": "{\"lineData\":[{\"name\":\"Gross Margin\",\"series\":[{\"name\":\"2022\",\"value\":1.0},{\"name\":\"2023\",\"value\":1.0},{\"name\":\"2024\",\"value\":1.0},{\"name\":\"2025\",\"value\":1.0},{\"name\":\"2026\",\"value\":1.0},{\"name\":\"2027\",\"value\":1.0}]}],\"barData\":[{\"name\":\"2022\",\"value\":18333.0},{\"name\":\"2023\",\"value\":22000.0},{\"name\":\"2024\",\"value\":24200.0},{\"name\":\"2025\",\"value\":26620.0},{\"name\":\"2026\",\"value\":29282.0},{\"name\":\"2027\",\"value\":2684.0}]}",
        "placeholderType": "Chart",
        "graphType": "ComboChart",
        "graphOptions": {
            "view": [
                500,
                400
            ],
            "scheme": {
                "name": "singleLightBlue",
                "selectable": true,
                "group": "Ordinal",
                "domain": [
                    "#01579B"
                ]
            },
            "colorSchemeLine": {
                "name": "coolthree",
                "selectable": true,
                "group": "Ordinal",
                "domain": [
                    "#01579B",
                    "#7AA3E5",
                    "#A8385D",
                    "#00BFA5"
                ]
            },
            "gradient": false,
            "animations": true,
            "tooltipDisabled": false,
            "xAxis": true,
            "yAxis": true,
            "legend": true,
            "showXAxisLabel": true,
            "xAxisLabel": "",
            "yAxisLabel": "",
            "showYAxisLabel": true,
            "showRightYAxisLabel": true,
            "showGridLines": true,
            "yAxisLabelRight": "Y Axis Label Right",
            "barPadding": 8,
            "legendPosition": "below",
            "noBarWhenZero": true,
            "legendTitle": "'Combo Graph'"
        }
    },
    {
        "placeholderName": "RevenueProductChart",
        "placeholderValue": "[{\"name\":\"2022\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":9167.0},{\"name\":\"PRODUCT2\",\"value\":9167.0}]},{\"name\":\"2023\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":11000.0},{\"name\":\"PRODUCT2\",\"value\":11000.0}]},{\"name\":\"2024\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":12100.0},{\"name\":\"PRODUCT2\",\"value\":12100.0}]},{\"name\":\"2025\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":13310.0},{\"name\":\"PRODUCT2\",\"value\":13310.0}]},{\"name\":\"2026\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":14641.0},{\"name\":\"PRODUCT2\",\"value\":14641.0}]},{\"name\":\"2027\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":1342.0},{\"name\":\"PRODUCT2\",\"value\":1342.0}]}]",
        "arabicPlaceholderValue": "[{\"name\":\"2022\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":9167.0},{\"name\":\"PRODUCT2\",\"value\":9167.0}]},{\"name\":\"2023\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":11000.0},{\"name\":\"PRODUCT2\",\"value\":11000.0}]},{\"name\":\"2024\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":12100.0},{\"name\":\"PRODUCT2\",\"value\":12100.0}]},{\"name\":\"2025\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":13310.0},{\"name\":\"PRODUCT2\",\"value\":13310.0}]},{\"name\":\"2026\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":14641.0},{\"name\":\"PRODUCT2\",\"value\":14641.0}]},{\"name\":\"2027\",\"series\":[{\"name\":\"PRODUCT1\",\"value\":1342.0},{\"name\":\"PRODUCT2\",\"value\":1342.0}]}]",
        "placeholderType": "Chart",
        "graphType": "StackedVerticalBarChart",
        "graphOptions": {
            "view": [
                600,
                400
            ],
            "scheme": {
                "domain": [
                    "#FB4E27",
                    "#78BBAE",
                    "#FAA637",
                    "#262F89"
                ]
            },
            "gradient": false,
            "xAxis": true,
            "yAxis": true,
            "legend": true,
            "showXAxisLabel": false,
            "xAxisLabel": "",
            "yAxisLabel": "",
            "showYAxisLabel": false,
            "showGridLines": true,
            "legendTitle": "Legend Title",
            "legendPosition": "below",
            "barPadding": 5
        }
    },
    {
        "placeholderName": "CostofSalesProductChart",
        "placeholderValue": "[{\"name\":\"PRODUCT2\",\"series\":[{\"name\":\"2022\",\"value\":-7333.0},{\"name\":\"2023\",\"value\":-8800.0},{\"name\":\"2024\",\"value\":-9680.0},{\"name\":\"2025\",\"value\":-10648.0},{\"name\":\"2026\",\"value\":-11713.0},{\"name\":\"2027\",\"value\":-1074.0}]}]",
        "arabicPlaceholderValue": "[{\"name\":\"PRODUCT2\",\"series\":[{\"name\":\"2022\",\"value\":-7333.0},{\"name\":\"2023\",\"value\":-8800.0},{\"name\":\"2024\",\"value\":-9680.0},{\"name\":\"2025\",\"value\":-10648.0},{\"name\":\"2026\",\"value\":-11713.0},{\"name\":\"2027\",\"value\":-1074.0}]}]",
        "placeholderType": "Chart",
        "graphType": "StackedVerticalBarChart",
        "graphOptions": {
            "view": [
                600,
                400
            ],
            "scheme": {
                "domain": [
                    "#FB4E27",
                    "#78BBAE",
                    "#FAA637",
                    "#262F89"
                ]
            },
            "gradient": false,
            "xAxis": true,
            "yAxis": true,
            "legend": true,
            "showXAxisLabel": false,
            "xAxisLabel": "",
            "yAxisLabel": "",
            "showYAxisLabel": false,
            "showGridLines": true,
            "legendTitle": "Legend Title",
            "legendPosition": "below",
            "barPadding": 5
        }
    },
    {
        "placeholderName": "ExpenseBreakupChart",
        "placeholderValue": "[{\"name\":\"2022\",\"series\":[{\"name\":\"Startup Costs\",\"value\":300.0},{\"name\":\"Operating\",\"value\":183.0},{\"name\":\"Marketing\",\"value\":367.0},{\"name\":\"Payroll\",\"value\":550.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2023\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":220.0},{\"name\":\"Marketing\",\"value\":440.0},{\"name\":\"Payroll\",\"value\":660.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2024\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":242.0},{\"name\":\"Marketing\",\"value\":484.0},{\"name\":\"Payroll\",\"value\":726.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2025\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":266.0},{\"name\":\"Marketing\",\"value\":532.0},{\"name\":\"Payroll\",\"value\":799.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2026\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":293.0},{\"name\":\"Marketing\",\"value\":586.0},{\"name\":\"Payroll\",\"value\":878.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2027\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":27.0},{\"name\":\"Marketing\",\"value\":54.0},{\"name\":\"Payroll\",\"value\":81.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]}]",
        "arabicPlaceholderValue": "[{\"name\":\"2022\",\"series\":[{\"name\":\"Startup Costs\",\"value\":300.0},{\"name\":\"Operating\",\"value\":183.0},{\"name\":\"Marketing\",\"value\":367.0},{\"name\":\"Payroll\",\"value\":550.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2023\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":220.0},{\"name\":\"Marketing\",\"value\":440.0},{\"name\":\"Payroll\",\"value\":660.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2024\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":242.0},{\"name\":\"Marketing\",\"value\":484.0},{\"name\":\"Payroll\",\"value\":726.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2025\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":266.0},{\"name\":\"Marketing\",\"value\":532.0},{\"name\":\"Payroll\",\"value\":799.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2026\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":293.0},{\"name\":\"Marketing\",\"value\":586.0},{\"name\":\"Payroll\",\"value\":878.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]},{\"name\":\"2027\",\"series\":[{\"name\":\"Startup Costs\",\"value\":0.0},{\"name\":\"Operating\",\"value\":27.0},{\"name\":\"Marketing\",\"value\":54.0},{\"name\":\"Payroll\",\"value\":81.0},{\"name\":\"PrePaid Expenses\",\"value\":0.0}]}]",
        "placeholderType": "Chart",
        "graphType": "StackedVerticalBarChart",
        "graphOptions": {
            "view": [
                600,
                400
            ],
            "scheme": {
                "domain": [
                    "#FB4E27",
                    "#78BBAE",
                    "#FAA637",
                    "#262F89"
                ]
            },
            "gradient": false,
            "xAxis": true,
            "yAxis": true,
            "legend": true,
            "showXAxisLabel": false,
            "xAxisLabel": "",
            "yAxisLabel": "",
            "showYAxisLabel": false,
            "showGridLines": true,
            "legendTitle": "Legend Title",
            "legendPosition": "below",
            "barPadding": 5
        }
    },
    {
        "placeholderName": "SourcesChart",
        "placeholderValue": "[{\"name\":\"Construction Costs\",\"value\":0.0},{\"name\":\"Startup/Execution Costs\",\"value\":300.0},{\"name\":\"Acquisition Of FixedAssets\",\"value\":700.0},{\"name\":\"Working Capital Investment\",\"value\":3000.0},{\"name\":\"Upfront Working Capital Investment\",\"value\":123.0}]",
        "arabicPlaceholderValue": "[{\"name\":\"Construction Costs\",\"value\":0.0},{\"name\":\"Startup/Execution Costs\",\"value\":300.0},{\"name\":\"Acquisition Of FixedAssets\",\"value\":700.0},{\"name\":\"Working Capital Investment\",\"value\":3000.0},{\"name\":\"Upfront Working Capital Investment\",\"value\":123.0}]",
        "placeholderType": "Chart",
        "graphType": "PieChart",
        "graphOptions": {
            "view": [
                700,
                400
            ],
            "scheme": {
                "domain": [
                    "#FB4E27",
                    "#78BBAE",
                    "#FAA637",
                    "#262F89"
                ]
            },
            "gradient": false,
            "legendTitle": "Legend Title Name",
            "legend": true,
            "legendPosition": "below",
            "doughnut": true,
            "labels": false,
            "arcWidth": 0.25
        }
    },
    {
        "placeholderName": "UsesChart",
        "placeholderValue": "[{\"name\":\"Construction Costs\",\"value\":0.0},{\"name\":\"Startup/Execution Costs\",\"value\":300.0},{\"name\":\"Acquisition Of FixedAssets\",\"value\":700.0},{\"name\":\"Working Capital Investment\",\"value\":3000.0},{\"name\":\"Upfront Working Capital Investment\",\"value\":123.0}]",
        "arabicPlaceholderValue": "[{\"name\":\"Construction Costs\",\"value\":0.0},{\"name\":\"Startup/Execution Costs\",\"value\":300.0},{\"name\":\"Acquisition Of FixedAssets\",\"value\":700.0},{\"name\":\"Working Capital Investment\",\"value\":3000.0},{\"name\":\"Upfront Working Capital Investment\",\"value\":123.0}]",
        "placeholderType": "Chart",
        "graphType": "PieChart",
        "graphOptions": {
            "view": [
                700,
                400
            ],
            "scheme": {
                "domain": [
                    "#FB4E27",
                    "#78BBAE",
                    "#FAA637",
                    "#262F89"
                ]
            },
            "gradient": false,
            "legendTitle": "Legend Title Name",
            "legend": true,
            "legendPosition": "below",
            "doughnut": true,
            "labels": false,
            "arcWidth": 0.25
        }
    },
    {
        "placeholderName": "ExpenseAsRevenuePercentChart",
        "placeholderValue": "[{\"name\":\"Startup Costs\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"Operating\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"Marketing\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"Payroll\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"PrePaid Expenses\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]}]",
        "arabicPlaceholderValue": "[{\"name\":\"Startup Costs\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"Operating\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"Marketing\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"Payroll\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]},{\"name\":\"PrePaid Expenses\",\"series\":[{\"name\":\"2022\",\"value\":0.0},{\"name\":\"2023\",\"value\":0.0},{\"name\":\"2024\",\"value\":0.0},{\"name\":\"2025\",\"value\":0.0},{\"name\":\"2026\",\"value\":0.0},{\"name\":\"2027\",\"value\":0.0}]}]",
        "placeholderType": "Chart",
        "graphType": "LineChart",
        "graphOptions": {
            "view": [
                700,
                300
            ],
            "scheme": {
                "domain": [
                    "#FB4E27",
                    "#78BBAE",
                    "#FAA637",
                    "#262F89"
                ]
            },
            "gradient": false,
            "legendTitle": "Legend Title",
            "legendPosition": "below",
            "legend": true,
            "doughnut": true,
            "labels": false,
            "arcWidth": 0.25
        }
    },
    {
        "placeholderName": "RevenueGrossMarginChartPageTitle",
        "placeholderValue": "{\"value\":\"Revenue and Margins\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Text",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "CostofSalesProductChartPageTitle",
        "placeholderValue": "{\"value\":\"Cost of Sales By Products\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Text",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "ExpenseBreakupChartPageTitle",
        "placeholderValue": "{\"value\":\"Breakup of Expenses\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Text",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "RevenueProductChartPageTitle",
        "placeholderValue": "{\"value\":\"Revenue By Products\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Text",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "ExpenseAsRevenuePercentChartPageTitle",
        "placeholderValue": "{\"value\":\"Expense As % of Revenue\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Text",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "UsesChartPageTitle",
        "placeholderValue": "{\"value\":\"Uses\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Text",
        "graphType": "",
        "graphOptions": null
    },
    {
        "placeholderName": "SourcesChartPageTitle",
        "placeholderValue": "{\"value\":\"Sources\"}",
        "arabicPlaceholderValue": "",
        "placeholderType": "Text",
        "graphType": "",
        "graphOptions": null
    }
]