import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';

@Component({
  selector: 'kt-auto-complete-control',
  templateUrl: './auto-complete-control.component.html',
  styleUrls: ['./auto-complete-control.component.scss']
})
export class AutoCompleteControlComponent extends FieldType implements OnInit {

  constructor(private jadwaService: JadwaService){
    super();
    
    
  }
  // myControl = new FormControl();
  // optionsX: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.loadList();
    console.log(this.field.templateOptions.service);
    

    // this.filteredOptions = this.formControl.valueChanges.pipe(
    //   startWith(''),
    //   mergeMap(value =>  this.jadwaService.autoComplete(this.field.templateOptions.service, value).pipe(
    //     map(response =>{ 
    //       return response.result;
    //       // const filterValue = value.toLowerCase();
    //       // return response.result.filter(option => option.name.toLowerCase().includes(filterValue));
    //     })
    //   )),
    //   // map(value => this._filter(value)),
    // );

    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }
  
  optionsList : any;
  loadList() {
    // this.NoRecordsDisplay = true;
    // this.loading = true;
    this.jadwaService.autoComplete(this.field.templateOptions.service).subscribe((response: any) => {
      // this.loading = false;
      let tempOptionList = [];
      response.result.forEach(element => {
        tempOptionList.push(element.name);
      });
      this.optionsList = tempOptionList;
      // console.log('productsList', this.optionsList)
    }, error => {
      // this.loading = false;
      // Swal.fire({
      //   title: error.error.responseException.exceptionMessage,
      //   icon: 'warning',
      //   showCancelButton: false,
      //   confirmButtonColor: '#37c0b9',
      //   confirmButtonText: this.translate.instant('TITLE.OKAY'),
      // });
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsList.filter(option => option.toLowerCase().includes(filterValue));
  }

}