import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FieldType } from '@ngx-formly/core';
import { replaceAll } from '../core/utils/replaceAllForStringPolyFill';

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <angular-editor [formControl]="formControl" [config]="editorConfig" ></angular-editor>
    <mat-error *ngIf="formControl.hasError('exceedingMaxLength')">Your input is exceeding maximum length. Maximum Length {{field.templateOptions.maxLength}}</mat-error>
    <mat-error *ngIf="formControl.hasError('minLengthNotReached')">Your input is not satisfying minimum length. Minimum Length {{field.templateOptions.minLength}}</mat-error>
    <mat-error *ngIf="formControl.hasError('maximumNumberOfLinesReached')">Your input is exceeding maximum number of lines. Maximum Lines {{field.templateOptions.maxLines}}</mat-error>


  `,
  styleUrls:['./editor.component.scss']
})
export class EditorComponent extends FieldType implements OnInit {

  // constructor(){

  // }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    // customClasses: [
    //   {
    //     name: 'quote',
    //     class: 'quote',
    //   },
    //   {
    //     name: 'redText',
    //     class: 'redText',
    //   },
    //   {
    //     name: 'titleText',
    //     class: 'titleText',
    //     tag: 'h1',
    //   },
    // ],
  };
  ngOnInit()
  {
    this.formControl.valueChanges.subscribe(value=>{
     // console.log(value);
      if(this.field.templateOptions &&  (this.field.templateOptions.maxLines!==undefined || this.field.templateOptions.maxLines!=null))
      {
        let liSplitCount = value.split("<li>").length-1;
        let pTagSplitCount =value.split("<p>").length-1;
        if(liSplitCount+pTagSplitCount > this.field.templateOptions.maxLines)
        {
          this.formControl.setErrors({maximumNumberOfLinesReached:true});

        }

      }
     if(this.field.templateOptions &&  (this.field.templateOptions.maxLength!==undefined || this.field.templateOptions.maxLength!==null || this.field.templateOptions.minLength!==undefined || this.field.templateOptions.minLength!==undefined))
      {
        let finalReplacedValue = replaceAll(value,"<ul>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</ul>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"<ol>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</ol>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"<li>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</li>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</li>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</b>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</b>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</u>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</u>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</i>","");
        finalReplacedValue = replaceAll(finalReplacedValue,"</i>","");
        finalReplacedValue = replaceAll(finalReplacedValue,/\s/,"");
        finalReplacedValue = replaceAll(finalReplacedValue,"\n","");

        // console.log(finalReplacedValue);

        if(this.field.templateOptions.maxLength!==undefined || this.field.templateOptions.maxLength!==null)
        {
          if(finalReplacedValue.length > this.field.templateOptions.maxLength)
          {
            this.formControl.setErrors({exceedingMaxLength:true});
          }
        }
      
        if(this.field.templateOptions.minLength!==undefined || this.field.templateOptions.minLength!==null)
        {
          if(finalReplacedValue.length < this.field.templateOptions.minLength)
          {
            this.formControl.setErrors({minLengthNotReached:true});
          }
        }
        
      }
    })

  }

}