/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./svg-radial-gradient.component";
var styles_SvgRadialGradientComponent = [];
var RenderType_SvgRadialGradientComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SvgRadialGradientComponent, data: {} });
export { RenderType_SvgRadialGradientComponent as RenderType_SvgRadialGradientComponent };
function View_SvgRadialGradientComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:stop", [], [[1, "offset", 0], [4, "stop-color", null], [4, "stop-opacity", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.offset + "%"); var currVal_1 = _v.context.$implicit.color; var currVal_2 = _v.context.$implicit.opacity; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SvgRadialGradientComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":svg:radialGradient", [["gradientUnits", "userSpaceOnUse"]], [[8, "id", 0], [1, "cx", 0], [1, "cy", 0], [1, "r", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SvgRadialGradientComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.stops; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; var currVal_1 = _co.cx; var currVal_2 = _co.cy; var currVal_3 = _co.r; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_SvgRadialGradientComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-svg-radial-gradient", ""]], null, null, null, View_SvgRadialGradientComponent_0, RenderType_SvgRadialGradientComponent)), i0.ɵdid(1, 573440, null, 0, i2.SvgRadialGradientComponent, [], null, null)], null, null); }
var SvgRadialGradientComponentNgFactory = i0.ɵccf("g[ngx-charts-svg-radial-gradient]", i2.SvgRadialGradientComponent, View_SvgRadialGradientComponent_Host_0, { color: "color", name: "name", startOpacity: "startOpacity", endOpacity: "endOpacity", cx: "cx", cy: "cy", stops: "stops" }, {}, []);
export { SvgRadialGradientComponentNgFactory as SvgRadialGradientComponentNgFactory };
