import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import Swal from 'sweetalert2';
import { CreateProjectComponent } from '../../shared/create-project/create-project.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { of, Subscription } from 'rxjs';
import { PlanDocumentsComponent } from '../../../app/shared/plan-documents/plan-documents.component';
import { PlanReportsComponent } from '../../../app/shared/plan-reports/plan-reports.component';
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient } from '@angular/common/http';
import { PaymentRequestUrway, PaymentResponseUrway } from '../../../app/core/auth/_models/payments.model';
import { GeneratedReportsListComponent } from './generated-reports-list/generated-reports-list.component';
import { catchError, map } from 'rxjs/operators';
import { ProjectDetails } from '../../../app/core/auth/_models/projects.model';

@Component({
  selector: 'kt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // encapsulation : ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  //  Carousel
  images = [944, 1011, 984].map((n) => `assets/media/blog/${n}-900x500.png`);


  userId: string;
  currentLanguage: string = 'en';
  showProjectsList: boolean = false;
  showPlanPopup: boolean = false;
  selectedPlanDetails: any = {};
  subscribedPlansList: Array<any> = [];

  private subscriptions: Subscription[] = [];
  constructor(public dialog: MatDialog,
    private jadwaService: JadwaService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private fileSaverService: FileSaverService,
    private httpClient: HttpClient) {
    if (localStorage.getItem("userInfo")) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if ((userInfo.tokenResponse.roles).indexOf('General') > -1) {
        // this.isGeneral = true;
      }
      if (
        ((userInfo.tokenResponse.roles).indexOf('BusinessAdmin') > -1) ||
        ((userInfo.tokenResponse.roles).indexOf('TechAdmin') > -1)
      ) {
        this.router.navigate(['/project'])
        // this.isBusinessAdmin = true;
      }
    }

  }

  ngOnInit() {
    this.jadwaService.openCreateProject$.subscribe(result => {
      if (result.open) {
        this.createProject();
      }
    });

    this.jadwaService.openGeneratedReports$.subscribe(result => {
      if (result.open) {
        this.viewGeneratedReports({ preventDefault: () => { } });
      }
    });

    const sortSubscription = this.store.pipe(
      select(AuthState => AuthState)
    ).subscribe(State => {
      //@ts-ignore
      this.currentLanguage = State.auth.language;
    });
    this.subscriptions.push(sortSubscription);

    this.userId = localStorage.getItem('userId');
    if (this.userId) {
      this.getUserProjects();
      this.getAllGeneratedReportsOfUser();
    } else {
      // this.userId
      //logout the user
    }

    this.getPlans();
    this.fetchUserSubscriptions();

  }


  animal: string;
  name: string;


  loading: boolean;
  projects: Array<ProjectDetails>;
  projectId: string
  getUserProjects() {
    this.loading = true;
    this.jadwaService.getUserProjects(this.userId).subscribe((response) => {
      this.loading = false;
      this.projects = response.result;
      if (!!this.projects && this.projects.length > 0) {
        let projectId = localStorage.getItem('projectId');
        // ******* If Project Id is Present in storage consider that id and remove from storage *****************
        if (projectId != '' && projectId != null && projectId != undefined) {
          this.projectId = projectId;
          localStorage.removeItem('projectId');
        } else {
          // set first Project
          this.projectId = this.projects[0].id;
        }
        if (sessionStorage.getItem("planDetailsForPayment")) {
          let paymentRequest: PaymentRequestUrway = JSON.parse(sessionStorage.getItem("planDetailsForPayment"));
          this.projectId = paymentRequest.projectId;
          sessionStorage.removeItem('planDetailsForPayment');
          //use it for one time
        }
        this.getPlans(this.projectId);
        this.onProjectChange(this.projectId);
      }
    }, error => {
      this.loading = false;
      // Swal.fire({
      //   title : (error.error) ?error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
      //   // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
      //   icon: 'warning',
      //   showCancelButton: false,
      //   confirmButtonColor: '#37c0b9',
      //   confirmButtonText: this.translate.instant('TITLE.OKAY'),
      // });
    });
  }

  userProejctPlans: Array<any> = [];


  // https://jadwa.otadevtest.com/api/v1/user-projects/plan/07316749-4cf7-4223-ae4a-bfe36b8654ac' \

  proejctPlans: any
  onProjectChange(projectId) {
    this.getPlans(projectId);
    this.hideShowPlansBizTemplates = true;//always true

    this.userProejctPlans = [];
    this.projectId = projectId;
    // get Project Plans
    this.loading = true;
    this.jadwaService.getuserProjectPlans(projectId).subscribe((response: any) => {
      this.loading = false;
      this.userProejctPlans = response.result;
      if (this.userProejctPlans === null) {
        this.userProejctPlans = [];
      }
      // if(this.userProejctPlans.length < 4 || this.userProejctPlans.length ==0)
      //   this.userProejctPlans.push({plusCard:true});
      // if(this.userProejctPlans.length < 4 && this.projects.length!=1) //dont need plus card any more
      // {
      //   this.userProejctPlans.push({plusCard:true});
      // }
      if (this.userProejctPlans.length < 4 && this.projects.length === 1) {
        this.hideShowPlansBizTemplates = true;//show all template // its always true 
      }
    }, error => {
      this.loading = false;
      Swal.fire({
        title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        // title: ,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    });
  }

  storeInvoicesResponses: Array<{ planId: string, invoiceResponse: any }> = [];
  failedInvoicesResponses: Array<{ planId: string, errorResponse: any }> = [];

  getInvoiceForPlanObservable(planId) {
    //return of({invoiceError:true});
    return of({ invoicePath: "https://cdn.otadevtest.com/invoice/Inv_2203416649065796633.pdf" })
    let foundEle = this.storeInvoicesResponses.find(ele => ele.planId === planId);
    if (foundEle) {
      return of(foundEle.invoiceResponse);
    }
    let foundEleInFailed = this.failedInvoicesResponses.find(ele => ele.planId === planId);
    if (foundEleInFailed) {
      //return of(foundEleInFailed.errorResponse);
      return of({ invoiceError: true });
    }
    return this.jadwaService.getInvoiceForPlan({
      projectId: this.projectId,
      userId: this.userId,
      planId: planId
    }).pipe(map(response => {
      this.storeInvoicesResponses.push({ planId: planId, invoiceResponse: response.result });
      return response.result;
    },
      catchError(error => {
        this.failedInvoicesResponses.push({ planId: planId, errorResponse: error });
        return of({ invoiceError: true });
        //return of(error);
      })));
  }

  getFilteredBusinessTemplates(): Array<any> {
    return this.plans.filter(ele => !this.userProejctPlans.find(userPrPlan => userPrPlan.planId == ele.planId));
  }



  plans: any;
  hideShowPlansBizTemplates: boolean = true;
  getPlans(projectId?: string) {
    this.loading = true;
    const includeDocuments = false;
    this.jadwaService.getPlans(includeDocuments, projectId).subscribe((response: any) => {
      this.loading = false;
      this.plans = response.result;
    }, error => {
      this.loading = false;
      Swal.fire({
        title: (error.error) ? error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    });
  }

  openPlan(planId, mode): void {
    // PlanDocumentsComponent , ProjectPlansComponent
    const dialogRef = this.dialog.open(PlanDocumentsComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'custom-dialog-container-bg',
      data: { planId: planId, projectId: this.projectId, mode: mode }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.userId) {
        this.getAllGeneratedReportsOfUser();
      }
      // if(result){
      this.onProjectChange(this.projectId);
      // }
    });
  }

  payForReport(plan) {
    let requestObj: PaymentRequestUrway = {
      "userId": this.userId,
      "projectId": this.projectId,
      "planId": plan.planId
    };
    Swal.fire({
      title: this.translate.instant('TITLE.PLEASE_WAIT_REDERECTING_TO_PAYMENT_PAGE'),
      // title: ,
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#37c0b9',
      confirmButtonText: this.translate.instant('TITLE.OKAY'),
    });
    this.jadwaService.createPaymentRequestForPlan(requestObj).subscribe(response => {
      requestObj.planName = plan.name;
      requestObj.planArName = plan.arName;
      sessionStorage.setItem("planDetailsForPayment", JSON.stringify(requestObj));
      window.open(response.result.transUrl, "_self");
    })

  }

  createProject(): void {
    const dialogRef = this.dialog.open(CreateProjectComponent, {
      width: '100%',
      height: '100%',
      // data: { id: null }
      data: { id: null, allPlansList: this.plans }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.jadwaService.closeOpenProject();
      if (this.userId) {
        this.getAllGeneratedReportsOfUser();
      }
      if (result) {
        this.getUserProjects();
      }
    });
  }

  removeProjectPlan(planId) {

    Swal.fire({
      title: 'Do you want to remove Project Plan ?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.jadwaService.deleteUserProjectPlan(this.projectId, planId).subscribe((response: any) => {
          this.loading = false;
          Swal.fire({
            title: "Plan Deleted from Project",
            // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#37c0b9',
            confirmButtonText: this.translate.instant('TITLE.OKAY'),
          }).then((result) => {
            this.onProjectChange(this.projectId);
          });
        }, error => {
          this.loading = false;
          Swal.fire({
            title: 'Something Went Wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#37c0b9',
            confirmButtonText: this.translate.instant('TITLE.OKAY'),
          });
        });
      }
    });

    return false;

    // this.projectId



  }

  viewReports($event, planId, plan) {
    //ProjectReportsComponent
    const dialogRef = this.dialog.open(PlanReportsComponent, {
      width: '100%',
      height: '100%',
      data: { planId: planId, projectId: this.projectId, plan: plan }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.getUserProjects();
        if (this.userId) {
          this.getAllGeneratedReportsOfUser();
        }

      }
    });
    $event.preventDefault();
  }

  viewGeneratedReports($event) {
    $event.preventDefault();

    //ProjectReportsComponent
    const dialogRef = this.dialog.open(GeneratedReportsListComponent, {
      width: '45%',
      maxHeight: '50%',
      data: {}
    });
    dialogRef.componentInstance.generatedReportsList = this.generatedReportsList;
    dialogRef.afterClosed().subscribe(result => {
      this.jadwaService.closeGeneratedReports();

    });

  }

  generatedInvoicesList: Array<{
    userId: string,
    projectId: string,
    planId: string,
    invoiceNo: string,
    invoicePath: string

  }>

  generatedReportsList: Array<{
    customReportName: string,
    masterReportId: string,
    planId: string,
    projectId: string,
    reportPath: string,
    userId: string,
    userReportId: string
  }> = [];

  getAllGeneratedReportsOfUser() {
    this.jadwaService.getAllGeneratedReportsOfUser(localStorage.getItem('userId')).subscribe(response => {
      this.generatedReportsList = response['result'];

    })
  }

  downloadPDFFromRemote(url: string) {
    this.httpClient.get(url + "?date=" + new Date().getTime(),
      {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((res) => {
        this.fileSaverService.save(res.body, "invoice.pdf");
      });

  }

  scrollCarousel(direction: string = 'left') {
    let minValue = 0;
    let maxValue = 100;
    let nextScroll = 10;
    var cards = document.getElementById('scroll-single-card-project-carousel0');
    if (cards && cards.clientWidth) {
      maxValue = cards.clientWidth;
      nextScroll = cards.clientWidth / 8;
    }
    console.log(cards.clientWidth);

    var container = document.getElementById('scroll-complete-body-project-carousel');

    let scrollAmount = minValue;
    if (direction === 'right') {
      scrollAmount = maxValue;
    }
    var slideTimer = setInterval(function () {
      if (direction === 'left') {
        container.scrollLeft += nextScroll;
        scrollAmount = scrollAmount + nextScroll;
        if (scrollAmount >= maxValue) {
          console.log("cleared interval", slideTimer);
          window.clearInterval(slideTimer);
        }
      }
      else if (direction === 'right') {
        container.scrollLeft -= nextScroll;
        scrollAmount = scrollAmount - nextScroll;
        if (scrollAmount <= minValue) {
          console.log("cleared interval", slideTimer);
          window.clearInterval(slideTimer);
        }
      }
    }, 25);

  }

  getProjectName() {
    if (this.projectId && this.projectId !== '' && this.projects && this.projects.length > 0) {
      return this.projects.find(ele => ele.id === this.projectId).name;
    }
    else {
      return this.translate.instant('TITLE.MY_PROJECT_PLANS');
    }
  }

  changeProject() {

  }
  // ************ Method to check Subscribed Plans Total Count ************** 
  fetchUserSubscriptions() {
    let params = {
      userId: localStorage.getItem('userId'),
      planId: null,
      filterAvailable: false
    };
    this.jadwaService.getUserSubscriptions(params).subscribe(res => {
      if (res['result']) {
        this.subscribedPlansList = res['result']['subscribedPlans'];
      }
    });
  }
  openPlanBuyPopup(plan) {
    this.selectedPlanDetails = plan;
    this.showPlanPopup = true;
    this.subscribedPlansList.forEach(item => {
      this.plans.forEach(element => {
        if (element['planId'] == item['planId']) {
          element['totalCount'] = item['totalCount'];
        }
      });
    });
  }
  onPlanPopupClose(event) {
    this.selectedPlanDetails = {};
    this.showPlanPopup = false;
    if (event['isPlanActivated']) {
      this.getPlans(this.projectId);
    }
  }
}