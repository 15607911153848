import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;" class="row row-added position-relative">
      <div class="col-md-12">
        <formly-field class="main-col" [field]="field"></formly-field>
        <button class="btn btn-danger btn-remove" type="button" (click)="remove(i)">X</button>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12">
        <button class="btn btn-primary" type="button" (click)="add()">{{ to.addText }}</button>
        </div>
    </div>
  `,
})
export class ClauseInputsControlComponent extends FieldArrayType {}