/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-plans.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../spinner/spinner.component.ngfactory";
import * as i3 from "../spinner/spinner.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/dialog";
import * as i7 from "./project-plans.component";
import * as i8 from "../../core/auth/_services/jadwa.service";
import * as i9 from "@ngrx/store";
var styles_ProjectPlansComponent = [i0.styles];
var RenderType_ProjectPlansComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectPlansComponent, data: {} });
export { RenderType_ProjectPlansComponent as RenderType_ProjectPlansComponent };
function View_ProjectPlansComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ProjectPlansComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectPlansComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.arName; _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectPlansComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "btn btn-primary btn-active-primary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPlanReports(_v.parent.context.$implicit.planId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("TITLE.PLAN_REPORTS")); _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectPlansComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "card overly-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Card image cap"], ["class", "card-img-top"], ["src", "../../../assets/media/plan-img.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectPlansComponent_4)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectPlansComponent_5)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "text-white pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " ", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "btn btn-primary btn-active-primary mr-2 mb-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPlanDocuments(_v.context.$implicit.planId, "old") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectPlansComponent_6)), i1.ɵdid(16, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentLanguage == "en"); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.currentLanguage != "en"); _ck(_v, 7, 0, currVal_1); var currVal_5 = !!((_v.context.$implicit == null) ? null : _v.context.$implicit.readyforReport); _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("TITLE.VERSION")); var currVal_3 = _v.context.$implicit.version; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("TITLE.PLAN_DETAILS")); _ck(_v, 13, 0, currVal_4); }); }
function View_ProjectPlansComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row m-0 mb-4 business-templates-block "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectPlansComponent_3)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userProejctPlans; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProjectPlansComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectPlansComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "project-industry-popup create-project"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "container z-index-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "row m-0 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h3", [["class", "mb-5 title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "angular-editor-userform-styles prevent-label-focus"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectPlansComponent_2)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["class", "close-alert-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["class", "material-icons"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dialogRef.close(i1.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_left"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "span", [["class", "material-icons"], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).dialogRef.close(i1.ɵnov(_v, 20).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_2 = (!!_co.userProejctPlans && !!_co.userProejctPlans); _ck(_v, 12, 0, currVal_2); var currVal_5 = ""; _ck(_v, 16, 0, currVal_5); var currVal_8 = ""; _ck(_v, 20, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("TITLE.PROJECT_PLANS")); _ck(_v, 8, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 16).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 16).type; _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 20).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 20).type; _ck(_v, 19, 0, currVal_6, currVal_7); }); }
export function View_ProjectPlansComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "project-plans", [], null, null, null, View_ProjectPlansComponent_0, RenderType_ProjectPlansComponent)), i1.ɵdid(1, 114688, null, 0, i7.ProjectPlansComponent, [i6.MatDialog, i8.JadwaService, i4.TranslateService, i6.MAT_DIALOG_DATA, i6.MatDialogRef, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectPlansComponentNgFactory = i1.ɵccf("project-plans", i7.ProjectPlansComponent, View_ProjectPlansComponent_Host_0, {}, {}, []);
export { ProjectPlansComponentNgFactory as ProjectPlansComponentNgFactory };
