import './polyfills';

import { NgModule } from '@angular/core';
import { ChartCommonModule } from './common/chart-common.module';
import { NumberCardModule } from './number-card/number-card.module';
import { JedwaCustomPieChartModule } from './pie-chart/pie-chart.module';


@NgModule({
  exports: [
    ChartCommonModule,
    NumberCardModule,
    JedwaCustomPieChartModule
  ]
})
export class JedwaCustomNgxChartsModule {}
