<spinner *ngIf="loading"></spinner>
<div id="kt_content_container" class="d-flex flex-column-fluid align-items-start container-xxl">
	<div class="content flex-row-fluid new-dashboard-UI" id="kt_content">
		<div>
			<div class="row gy-3 my-2 g-xl-8">
				<div class="col-xl-12">
					<div class="card card-xl-stretch modren-card mb-xl-8">
						<!-- Content Level DIV starts here -->
						<div *ngIf="!showProjectsList">
							<div class="card-header pb-0 py-2 d-flex justify-content-center text-center">
								<h3 class="card-title align-items-center flex-column justify-content-center d-flex text-center">
									<span class="card-label fw-bolder font-project mb-3">{{getProjectName()}} <span
											class="card-label fw-bolder font-project mb-3 ml-3">
											<a class="btn btn-primary btn-primary-override   new-r-btn btn-primary-outline-new mr-3"
												(click)="$event.preventDefault();showProjectsList=!showProjectsList">
												<i class="la la-exchange text-aqua"></i>
												Switch Project</a>
										</span></span>

									<!-- <span class="card-label fw-bolder font-project mb-3">{{'TITLE.MY_PROJECT_PLANS' |
                                translate}}</span>                               
                            <span class="text-muted mt-1 fw-bold fs-7">{{'TITLE.OVER' | translate}} {{projects?.length}}
                                {{'TITLE.PROJECTS' | translate}}</span> -->

									<div class="text-center">
										<span class="text-muted mt-1 fw-bold fs-7">Choose your plan</span>
									</div>
								</h3>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pb-3 pt-0 mt-0 mb-0 px-0">
										<div class="card bg-none my-project-plan-block border-0 pt-0 mt-0"
											*ngIf="!!projects && projects?.length > 0 ">
											<!-- <div class="card-header px-4 py-3 ">
                                        <div class="row w-100 m-0">
                                            <div class="col-md-12">
                                                <mat-form-field>
                                                    <mat-select [(ngModel)]="projectId"
                                                        class="w-100 p-2 custom-select-option"
                                                        (selectionChange)="onProjectChange($event.value)">
                                                        <mat-option *ngFor="let project of projects"
                                                            [value]="project.id">
                                                            {{project.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div> -->
											<div class="card-body pt-0 mt-0 px-0">
												<div class="row px-5">
													<div
														[ngClass]="{'col-md-3':userProejctPlans.length ==1,'col-md-6':userProejctPlans.length ==2,'col-md-9':userProejctPlans.length ==3,'col-md-12':userProejctPlans.length ==4}">

														<div class="w-100 m-0 row"
															*ngIf="(!!userProejctPlans && userProejctPlans.length > 0) || hideShowPlansBizTemplates">
															<ng-container *ngFor="let plan of userProejctPlans">
																<div *ngIf="!plan?.plusCard" class="position-relative px-0 plan-box col">
																	<span class="plan-delete" (click)="removeProjectPlan(plan.planId)">
																		<mat-icon class="material-icons-outlined" matSuffix>
																			delete
																		</mat-icon>
																	</span>
																	<span *ngIf="!!!plan?.readyforReport" class="plan-generate"
																		(click)="viewReports($event, plan.planId,plan)">
																		<mat-icon>equalizer</mat-icon>
																	</span>
																	<span *ngIf="!!plan?.readyforReport" class="plan-generate"
																		(click)="openPlan(plan.planId, 'old')">
																		<mat-icon>edit</mat-icon>
																	</span>

																	<div class="card my-project-plan img-curve border-0 mx-3 mll-0"
																		[ngClass]="{'cursor-hand':!!plan?.readyforReport}"
																		(click)="!!plan?.readyforReport ? viewReports($event, plan.planId,plan) : openPlan(plan.planId, 'old')">

																		<img alt="Card image cap" class="img-fluid card-img-top"
																			src="../../../assets/media/plan-title-img.png">
																		<div class="card-body overly-body">

																			<h5 class="card-title">{{plan.name}}</h5>
																			<span matTooltip="Pay to get access to report"
																				*ngIf="plan?.isPaid ==true && plan?.paidforReport == false">
																				<button class="btn btn-primary"
																					(click)="payForReport(plan);$event.stopImmediatePropagation();">
																					Pay {{plan.amount}} SAR To Access
																					Report</button>
																			</span>
																			<span class="text-paid"
																				*ngIf="plan?.isPaid ==true && plan?.paidforReport == true">
																				You paid for plan {{plan.amount}} SAR
																				and you
																				have
																				access to
																				complete report
																				<!--commenting invoice, uncomment when api is working 
                                                                 <div *ngIf="getInvoiceForPlanObservable(plan.planId) | async; let invoice">
                                                                <ng-container *ngIf="invoice && invoice.invoicePath">
                                                                <a [href]="invoice.invoicePath" matTooltip="Download Invoice" (click)="$event.preventDefault();$event.stopImmediatePropagation();downloadPDFFromRemote(invoice.invoicePath)"class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                           
                                                                    <span class="svg-icon svg-icon-3">
                                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                                            <g id="Stockholm-icons-/-Files-/-Download" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                                                                <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                                <rect id="Rectangle" fill="#000000" opacity="0.3" transform="translate(12.000000, 8.000000) rotate(-180.000000) translate(-12.000000, -8.000000) " x="11" y="1" width="2" height="14" rx="1"></rect>
                                                                                <path d="M7.70710678,15.7071068 C7.31658249,16.0976311 6.68341751,16.0976311 6.29289322,15.7071068 C5.90236893,15.3165825 5.90236893,14.6834175 6.29289322,14.2928932 L11.2928932,9.29289322 C11.6689749,8.91681153 12.2736364,8.90091039 12.6689647,9.25670585 L17.6689647,13.7567059 C18.0794748,14.1261649 18.1127532,14.7584547 17.7432941,15.1689647 C17.3738351,15.5794748 16.7415453,15.6127532 16.3310353,15.2432941 L12.0362375,11.3779761 L7.70710678,15.7071068 Z" id="Path-102" fill="#000000" fill-rule="nonzero" transform="translate(12.000004, 12.499999) rotate(-180.000000) translate(-12.000004, -12.499999) "></path>
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    
                                                                </a>
                                                            </ng-container>
                                                            <ng-container *ngIf="invoice && invoice.invoiceError">
                                                                There was a problem generating invoice
                                                            </ng-container>
                                                            </div> -->

																			</span>

																		</div>
																	</div>

																</div>
																<div *ngIf="plan?.plusCard && plan?.plusCard ===true"
																	class="col-md-3 position-relative plan-box add-new-plan"
																	(click)="hideShowPlansBizTemplates=!hideShowPlansBizTemplates">


																	<div
																		class="card my-project-plan img-curve border-0 d-flex align-items-center justify-content-center">
																		<button class="btn btn-circle btn-primary">
																			<mat-icon class="material-icons-outlined" matSuffix>
																				add
																			</mat-icon>
																		</button>
																		<img alt="Card image cap" class="card-img-top"
																			src="../../../assets/media/plan-title-img.png">
																		<div class="card-body overly-body">
																			<h5 class="card-title mb-5">Add New Plan
																			</h5>
																		</div>
																	</div>

																</div>
															</ng-container>
															<!-- adding use business plan templates -->
															<div class="row gy-5 g-xl-2 mt-0 no-gutters" *ngIf="hideShowPlansBizTemplates">
																<div class="col-xl-12 pt-0">
																	<div>
																		<!-- <h3
                                                                    class="card-title align-items-center flex-column justify-content-center d-flex text-center">
                                                                    <span
                                                                        class="card-label fw-bolder font-project mb-3">Business
                                                                        Plan Templates</span>
                                                                    <span class="text-muted mt-1 fw-bold fs-7">Over 4
                                                                        Business
                                                                        Plan Templates</span>
                                                                </h3> -->
																	</div>
																	<div>
																		<div class="card border-0 bg-none  mb-0 mb-xl-0 business-templates-block"
																			id="content">
																			<div class="card-body py-3 pt-0 pb-0 mb-0">

																			</div>
																		</div>
																	</div>
																	<!-- <div class="d-flex justify-content-between pos-new">
                                                        <div class="circle-button mr-3"
                                                            (click)="scrollCarousel('right')">
                                                            <i class="fa fa-angle-left"></i>
                                                        </div>
                                                        <div class="circle-button" (click)="scrollCarousel('left')">
                                                            <i class="fa fa-angle-right"></i>
                                                        </div>
                                                    </div> -->
																</div>
															</div>
															<!-- end of adding use business plan templates -->

														</div>
													</div>

													<div
														[ngClass]="{'col-md-3':userProejctPlans.length ==3,'col-md-6':userProejctPlans.length ==2,'col-md-9':userProejctPlans.length ==1,'col-md-12':userProejctPlans.length ==0}">
														<div *ngIf="!!plans" class="img-width row">
															<div class=" img-new-radius col"
																*ngFor="let businessPlanTemplate of getFilteredBusinessTemplates();let plIndex=index">
																<div class="gy-5 g-xl-2 mt-0 no-gutters" *ngIf="hideShowPlansBizTemplates">
																	<div class="pt-0">
																		<div>
																			<div class="card border-0 bg-none  mb-0 mb-xl-0 business-templates-block"
																				id="content">
																				<div class="card-body pb-3 pt-0 pb-0 mb-0 px-0">
																					<div class="card" [id]="'scroll-single-card-project-carousel'+plIndex">
																						<div class="overly-body">
																							<img alt="Card image cap" class="card-img-top"
																								src="../../../assets/media/plan-img.png">
																							<div class="card-body">

																								<h5 class="card-title">
																									{{businessPlanTemplate?.name}}
																								</h5>
																								<p *ngIf="currentLanguage == 'en'">
																									{{businessPlanTemplate?.description}}
																								</p>
																								<p *ngIf="currentLanguage != 'en'">
																									{{businessPlanTemplate?.arDescription}}
																								</p>
																								<div *ngIf="!!projectId">
																									<a class="btn btn-primary btn-active-primary mr-2"
																										(click)="openPlan(businessPlanTemplate.planId, 'new')"
																										*ngIf="businessPlanTemplate?.isAvailable">{{'TITLE.USE_PLAN'
																										|
																										translate}}</a>
																									<a class="btn btn-primary btn-active-primary mr-2"
																										(click)="openPlanBuyPopup(businessPlanTemplate)"
																										*ngIf="!businessPlanTemplate?.isAvailable">{{'TITLE.BUY_PLAN'
																										|
																										translate}}</a>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>

																			</div>
																			<div class="row" *ngIf="!plans">
																				{{'TITLE.NO_PLAN_AVAILABLE' |
																				translate}}
																			</div>
																		</div>
																	</div>
																	<!-- <div class="row w-100 m-0"
                                                                        *ngIf="!userProejctPlans || (!!userProejctPlans &&  userProejctPlans.length == 0)">
                                                                        <div class="col-md-12">
                                                                            <div class="px-5">
                                                                                {{'TITLE.NO_PLANS_CREATED_FOR_THIS_PROJECT'
                                                                                | translate}}
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
																</div>
															</div>
															<div class="card my-project-plan-block py-4" *ngIf="!loading && projects?.length == 0 ">
																<div class="card  my-project-plan col-md-4 offset-md-4">
																	<img alt="Card image cap" class="card-img-top"
																		src="../../../assets/media/plan-title-img.png">
																	<div class="card-body overly-body text-center">
																		<a class="btn btn-primary new-r-btn btn-sm btn-active-primary mr-2 w-100"
																			data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends"
																			(click)="createProject()">
																			{{'TITLE.CREATE_NEW_PROJECT' |
																			translate}}</a>
																	</div>
																</div>

															</div>
														</div>
													</div>
												</div>
											</div>
											<!-- Content Level DIV ends here -->

											<!-- New CARDS -->

										</div>
									</div>
								</div>
								<div class="row gy-5 g-xl-2 mt-0 no-gutters d-none">
									<div
										class="col-xl-4 text-left pl-3 d-flex align-items-start align-self-center flex-column left-curve">
										<h3 class="card-title ">
											<span class="card-label fw-bolder fs-3 mb-1 font-project-2">{{'TITLE.BUSINESS_PLAN'
												|
												translate}} <br> {{'TITLE.TEMPLATES' |
												translate}}</span> <br>
											<span class="text-muted mt-1 fw-bold fs-7">{{'TITLE.OVER' | translate}}
												{{plans?.length}}
												{{'TITLE.BUSINESS_PLAN_TEMPLATES' | translate}}</span>
										</h3>
									</div>
									<div class="col-xl-8 pt-3 ">
										<div class="scroller" id="scroll-complete-body-project-carousel">
											<div class="card border-0 bg-none  mb-0 mb-xl-0 business-templates-block" id="content">
												<div class="card-body py-3 pt-0 pb-0 mb-0">
													<div *ngIf="!!plans" class="img-width">
														<div class=" img-new-radius mr-3" *ngFor="let plan of plans;let plIndex=index">
															<div class="card" [id]="'scroll-single-card-project-carousel'+plIndex">
																<div class="overly-body">
																	<img alt="Card image cap" class="card-img-top"
																		src="../../../assets/media/plan-img.png">
																	<div class="card-body">

																		<h5 class="card-title">{{plan?.name}}</h5>
																		<p *ngIf="currentLanguage == 'en'">
																			{{plan?.description}}</p>
																		<p *ngIf="currentLanguage != 'en'">
																			{{plan?.arDescription}}</p>
																		<div *ngIf="!!projectId">
																			<a class="btn btn-primary btn-active-primary mr-2"
																				(click)="openPlan(plan.planId, 'new')">{{'TITLE.USE_PLAN'
																				|
																				translate}}</a>
																		</div>
																	</div>
																</div>
															</div>
														</div>

													</div>
													<div class="row" *ngIf="!plans">
														{{'TITLE.NO_PLAN_AVAILABLE' | translate}}
													</div>
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-between pos-new">
											<div class="circle-button mr-3" (click)="scrollCarousel('right')">
												<i class="fa fa-angle-left"></i>
											</div>
											<div class="circle-button" (click)="scrollCarousel('left')">
												<i class="fa fa-angle-right"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="showProjectsList">
							<div class="card-header pb-0 py-2 d-flex justify-content-center text-center">
								<h3 class="card-title align-items-center flex-column justify-content-center d-flex text-center">
									<span class="card-label fw-bolder font-project mb-3">My Projects
										List</span>
									<span class="card-label fw-bolder font-project mb-3">
										<a class="btn btn-primary  new-r-btn btn-primary-outline-new mr-3"
											(click)="$event.preventDefault();showProjectsList=!showProjectsList">
											Back</a>
									</span>
									<!-- <span class="card-label fw-bolder font-project mb-3">{{'TITLE.MY_PROJECT_PLANS' |
            translate}}</span>                               
        <span class="text-muted mt-1 fw-bold fs-7">{{'TITLE.OVER' | translate}} {{projects?.length}}
            {{'TITLE.PROJECTS' | translate}}</span> -->
								</h3>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="card border-0 bg-none  mb-0 mb-xl-0 business-templates-block">
										<div class="card-body py-3 pt-0 pb-0 mb-0">
											<div class="img-width">
												<div class=" img-new-radius">
													<div class="row">
														<div class="col-md-3" *ngFor="let singleProject of projects">
															<div class="card">
																<div class="overly-body">
																	<img alt="Card image cap" class="card-img-top"
																		src="../../../assets/media/plan-img.png">
																	<div class="card-body">

																		<h5 class="card-title">
																			{{singleProject?.name}}
																		</h5>
																		<a class="btn btn-primary btn-active-primary mr-2"
																			(click)="$event.preventDefault();showProjectsList=!showProjectsList;onProjectChange(singleProject?.id)">Select
																			Project</a>
																		<!-- <p *ngIf="currentLanguage == 'en'">
                                                        {{plan?.description}}</p>
                                                    <p *ngIf="currentLanguage != 'en'">
                                                        {{plan?.arDescription}}</p> -->

																	</div>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<kt-buy-plan-popup *ngIf="showPlanPopup" [projectId]="projectId" [selectedPlanDetails]="selectedPlanDetails"
						(onPlanPopupClose)="onPlanPopupClose($event)" [allPlansList]="plans">
					</kt-buy-plan-popup>