/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../common/base-chart.component.scss.ngstyle";
import * as i1 from "./pie-chart.component.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../common/charts/chart.component.ngfactory";
import * as i4 from "../common/tooltip/tooltip.service";
import * as i5 from "../common/tooltip/injection.service";
import * as i6 from "../common/charts/chart.component";
import * as i7 from "./pie-series.component.ngfactory";
import * as i8 from "./pie-series.component";
import * as i9 from "./pie-chart.component";
var styles_PieChartComponent = [i0.styles, i1.styles];
var RenderType_PieChartComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_PieChartComponent, data: {} });
export { RenderType_PieChartComponent as RenderType_PieChartComponent };
export function View_PieChartComponent_0(_l) { return i2.ɵvid(2, [(_l()(), i2.ɵeld(0, 16777216, null, null, 6, "ngx-charts-chart", [], null, [[null, "legendLabelActivate"], [null, "legendLabelDeactivate"], [null, "legendLabelClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("legendLabelActivate" === en)) {
        var pd_0 = (_co.onActivate($event, true) !== false);
        ad = (pd_0 && ad);
    } if (("legendLabelDeactivate" === en)) {
        var pd_1 = (_co.onDeactivate($event, true) !== false);
        ad = (pd_1 && ad);
    } if (("legendLabelClick" === en)) {
        var pd_2 = (_co.onClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_ChartComponent_0, i3.RenderType_ChartComponent)), i2.ɵprd(512, null, i4.TooltipService, i4.TooltipService, [i5.InjectionService]), i2.ɵdid(2, 573440, null, 0, i6.ChartComponent, [i2.ViewContainerRef, i4.TooltipService], { view: [0, "view"], showLegend: [1, "showLegend"], legendOptions: [2, "legendOptions"], activeEntries: [3, "activeEntries"], animations: [4, "animations"] }, { legendLabelClick: "legendLabelClick", legendLabelActivate: "legendLabelActivate", legendLabelDeactivate: "legendLabelDeactivate" }), i2.ɵpad(3, 2), (_l()(), i2.ɵeld(4, 0, null, 0, 2, ":svg:g", [["class", "pie-chart chart"]], [[1, "transform", 0]], null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, ":svg:g", [["ngx-charts-pie-series", ""]], null, [[null, "dblclick"], [null, "select"], [null, "activate"], [null, "deactivate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dblclick" === en)) {
        var pd_0 = (_co.dblclick.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("select" === en)) {
        var pd_1 = (_co.onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("activate" === en)) {
        var pd_2 = (_co.onActivate($event) !== false);
        ad = (pd_2 && ad);
    } if (("deactivate" === en)) {
        var pd_3 = (_co.onDeactivate($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_PieSeriesComponent_0, i7.RenderType_PieSeriesComponent)), i2.ɵdid(6, 573440, null, 0, i8.PieSeriesComponent, [], { colors: [0, "colors"], series: [1, "series"], innerRadius: [2, "innerRadius"], outerRadius: [3, "outerRadius"], explodeSlices: [4, "explodeSlices"], showLabels: [5, "showLabels"], gradient: [6, "gradient"], activeEntries: [7, "activeEntries"], labelFormatting: [8, "labelFormatting"], trimLabels: [9, "trimLabels"], maxLabelLength: [10, "maxLabelLength"], tooltipText: [11, "tooltipText"], tooltipDisabled: [12, "tooltipDisabled"], tooltipTemplate: [13, "tooltipTemplate"], animations: [14, "animations"] }, { select: "select", activate: "activate", deactivate: "deactivate", dblclick: "dblclick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.width, _co.height); var currVal_1 = _co.legend; var currVal_2 = _co.legendOptions; var currVal_3 = _co.activeEntries; var currVal_4 = _co.animations; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.colors; var currVal_7 = _co.data; var currVal_8 = _co.innerRadius; var currVal_9 = _co.outerRadius; var currVal_10 = _co.explodeSlices; var currVal_11 = _co.labels; var currVal_12 = _co.gradient; var currVal_13 = _co.activeEntries; var currVal_14 = _co.labelFormatting; var currVal_15 = _co.trimLabels; var currVal_16 = _co.maxLabelLength; var currVal_17 = _co.tooltipText; var currVal_18 = _co.tooltipDisabled; var currVal_19 = _co.tooltipTemplate; var currVal_20 = _co.animations; _ck(_v, 6, 1, [currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20]); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.translation; _ck(_v, 4, 0, currVal_5); }); }
export function View_PieChartComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "jedwa-custom-ngx-charts-pie-chart", [], null, null, null, View_PieChartComponent_0, RenderType_PieChartComponent)), i2.ɵdid(1, 4898816, null, 1, i9.PieChartComponent, [i2.ElementRef, i2.NgZone, i2.ChangeDetectorRef], null, null), i2.ɵqud(603979776, 1, { tooltipTemplate: 0 })], null, null); }
var PieChartComponentNgFactory = i2.ɵccf("jedwa-custom-ngx-charts-pie-chart", i9.PieChartComponent, View_PieChartComponent_Host_0, { results: "results", view: "view", scheme: "scheme", schemeType: "schemeType", customColors: "customColors", animations: "animations", labels: "labels", legend: "legend", legendTitle: "legendTitle", legendPosition: "legendPosition", explodeSlices: "explodeSlices", doughnut: "doughnut", arcWidth: "arcWidth", gradient: "gradient", activeEntries: "activeEntries", tooltipDisabled: "tooltipDisabled", labelFormatting: "labelFormatting", trimLabels: "trimLabels", maxLabelLength: "maxLabelLength", tooltipText: "tooltipText", margins: "margins" }, { select: "select", dblclick: "dblclick", activate: "activate", deactivate: "deactivate" }, []);
export { PieChartComponentNgFactory as PieChartComponentNgFactory };
