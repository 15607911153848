// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';
import { LoadJsonComponent } from './load-json/load-json.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotAccountComponent } from './components/forgot-account/forgot-account.component';
import { InputControlsComponent } from './input-controls/input-controls.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PlanReportTrialComponent } from './shared/plan-report-trial/plan-report-trial.component';
import { PaymentComponent } from './components/payment/payment.component';
import { TestComponent } from './components/test/test.component';

const routes: Routes = [	
	{
		path:'a4-test',
		component:TestComponent
	},
	{
		path: 'register',
		component: RegisterComponent,
	},
	{
		path: 'login',
		component: LoginComponent,
		data: {returnUrl: window.location.pathname}
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent,
	},
	{
		path: 'forgot-account',
		component: ForgotAccountComponent,
	},
	{
		path: 'reset-password',
		component: ResetPasswordComponent,
	},
	{	path: 'load-from-model', component: LoadJsonComponent },
	{
		path: 'form-builder',
		component: LoadJsonComponent,
	},
	{
		path: 'plan-generate-pdf',
		component: PlanReportTrialComponent,
	},
	{
		path:'payment',
		component:PaymentComponent
	},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'industry',
				loadChildren: () => import('../app/industry/industry.module').then(m => m.IndustryModule)
			},
			{
				path: 'products',
				loadChildren: () => import('../app/products/products.module').then(m => m.ProductsModule)
			},
			{
				path: 'fixedassets',
				loadChildren: () => import('../app/fixedassets/fixedassets.module').then(m => m.FixedAssetsModule)
			},
			{
				path: 'databank',
				loadChildren: () => import('../app/databank/databank.module').then(m => m.DataBankModule)
			},
			{
				path: 'plan',
				loadChildren: () => import('../app/plan/plan.module').then(m => m.PlanModule)
			},
			{
				path: 'document',
				loadChildren: () => import('../app/document/document.module').then(m => m.DocumentModule)
			},
			{
				path: 'users',
				loadChildren: () => import('../app/users/users.module').then(m => m.UsersModule)
			},
			{
				path: 'project',
				loadChildren: () => import('../app/project/project.module').then(m => m.ProjectModule)
			},			
			{
				path: 'dashboard',
				// loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
				component : DashboardComponent
			},

	        { path: 'user-profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule) },

			{
				path: 'input-controls',
				component : InputControlsComponent
				// loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			// {
			// 	path: 'mail',
			// 	loadChildren: () => import('../app/views/pages/apps/mail/mail.module').then(m => m.MailModule)
			// },
			// {
			// 	path: 'ecommerce',
			// 	loadChildren: () => import('../app/views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			// },
			// {
			// 	path: 'ngbootstrap',
			// 	loadChildren: () => import('../app/views/pages/ngbootstrap/ngbootstrap.module').then(m => m.NgbootstrapModule)
			// },
			// {
			// 	path: 'material',
			// 	loadChildren: () => import('../app/views/pages/material/material.module').then(m => m.MaterialModule)
			// },
			// {
			// 	path: 'user-management',
			// 	loadChildren: () => import('../app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			// },
			// {
			// 	path: 'wizard',
			// 	loadChildren: () => import('../app/views/pages/wizard/wizard.module').then(m => m.WizardModule)
			// },
			// {
			// 	path: 'builder',
			// 	loadChildren: () => import('../app/views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			// },
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
