
<div class=" kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
        <!--begin::Aside-->
        <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside position-relative" style="background:#fafafa  url(./assets/media/bg/login-bg-5.png) no-repeat center;">
            <div class="kt-grid__item">
                <a href="javascript:;" class="kt-login__logo">
                    <!-- <img src="./assets/media/logos/logo-4.png" alt=""> -->
                    <!-- JADWA -->
                </a>
            </div>
            <!-- <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div class="kt-grid__item kt-grid__item--middle">
                    <h3 class="kt-login__title">مرحبا بكم في جدوى</h3>
                    <h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة التسويقية</h4>
                    <h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة المالية</h4>
                    <h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة الإستراتيجية</h4>
                    <h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة الفنية</h4>
                    
                </div>
            </div> -->
            <div class="kt-grid__item">
                <div class="kt-login__info">
                    <div class="kt-login__copyright">
                        © {{today|date:'yyyy'}} جدوى
                    </div>
                    <div class="kt-login__menu">
                        <a href="javascript:;" class="kt-link">{{'FIELDS.PRIVACY' | translate}}</a>
						<a href="javascript:;" class="kt-link">{{'FIELDS.LEGAL' | translate}}</a>
						<a href="javascript:;" class="kt-link">{{'FIELDS.CONTACT' | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="register-title">{{'TITLE.REGISTERTITLE' | translate}}<br />{{'TITLE.REGISTERTITLE2' | translate}}</div>
        </div>
        <!--begin::Aside-->

        <!--begin::Content-->
        <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper" style="background:#FFF url(./assets/media/bg/Polygon.png) no-repeat top right;">
            <!-- <router-outlet></router-outlet> -->

            <div class="language">
                <a href="">English</a>
                <span class="mx-2">|</span>
                <a href="">Arabic</a>
            </div>

            <!--begin::Body-->
            <div class="kt-login__body">
                <!--begin::Signin-->
                <div class="kt-login__form">
                    <div class="kt-login__title">
                        <img src="../../../assets/logo.png" class="img-fluid login-logo" />
                        <h3>{{ 'TITLE.REGISTER_TITLE' | translate }}</h3>
                    </div>
                    <!-- <kt-auth-notice></kt-auth-notice> -->

                    <form class="kt-login__form kt-form" [formGroup]="registerForm" autocomplete="off" *ngIf="!showConfirmationForm">
                        <!-- <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'TITLE.FIRST_NAME' | translate }}</mat-label>
                                <input matInput type="text" [placeholder]="'FIELDS.FIRST_NAME' | translate" formControlName="firstname"/>
                                <mat-icon matSuffix>person</mat-icon>
                                <mat-error *ngIf="isControlHasError('firstname','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('firstname','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('firstname','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('firstname','pattern')">
                                    <strong>{{ 'VALIDATION.ENGLIGH_ALPHABETS_OR_ARABIC_ALPHABETS' | translate }} </strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field  appearance="outline">
                                <mat-label>{{ 'TITLE.LAST_NAME' | translate }}</mat-label>
                                <input matInput type="text" [placeholder]="'FIELDS.LAST_NAME' | translate" formControlName="lastname"/>
                                <mat-icon matSuffix>person</mat-icon>
                                <mat-error *ngIf="isControlHasError('lastname','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('lastname','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('lastname','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('lastname','pattern')">
                                    <strong>{{ 'VALIDATION.ENGLIGH_ALPHABETS_OR_ARABIC_ALPHABETS' | translate }} </strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'FIELDS.USERNAME' | translate }}</mat-label>
                                <input matInput type="text"  [placeholder]="'FIELDS.USERNAME' | translate" formControlName="username"/>
                                <mat-icon matSuffix>person</mat-icon>
                                <mat-error *ngIf="isControlHasError('username','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('username','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('username','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                            </mat-form-field>
                        </div> 			 -->
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'FIELDS.EMAIL' | translate }}</mat-label>
                                <input matInput type="email" placeholder="{{ 'FIELDS.EMAIL' | translate }}" formControlName="email" autocomplete="off"/>
                                <mat-icon matSuffix>email</mat-icon>
                                <mat-error *ngIf="isControlHasError('email','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','email')">
                                    <strong>{{ 'VALIDATION.INVALID_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group custom-phone-field position-relative">						
                                <ngx-intl-tel-input matInput [inputId]="'adsasd'" [cssClass]="'phone-number-control'"
                                    [enableAutoCountrySelect]="true" [preferredCountries]="preferredCountries" [enablePlaceholder]="true"
                                    [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="true" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true" name="phone"
                                    formControlName="phoneNumber" [selectedCountryISO]="'sa'">
                                </ngx-intl-tel-input>	
                                <mat-icon matSuffix>phone</mat-icon>
                                <mat-error *ngIf="registerForm.get('phoneNumber').touched && isControlHasError('phoneNumber','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>	
                                <mat-error *ngIf="registerForm.get('phoneNumber').touched && isControlHasError('phoneNumber','validatePhoneNumber')">
                                    <strong>{{ 'VALIDATION.INVALID_PHONE_NUMBER' | translate }}</strong>
                                </mat-error>						
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'FIELDS.PASSWORD' | translate }}</mat-label>
                                <input matInput [type]="showPassword ? 'text' : 'password'"  type="password" placeholder="{{ 'FIELDS.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
                                
                                <mat-icon matSuffix (click)="showPasswordFun()">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="isControlHasError('password','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('password','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('password','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'FIELDS.CONFIRM_PASSWORD' | translate }}</mat-label>
                                <input matInput [type]="showConfirmPassword ? 'text' : 'password'"  placeholder="{{ 'FIELDS.CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword" autocomplete="off"/>
                                <mat-icon matSuffix (click)="showConfirmPasswordFun()">{{showConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="isControlHasError('confirmPassword','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                                <mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
                                    <strong> {{'VALIDATION.PASSWORD_TEXT' | translate}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-checkbox name="agree" formControlName="agree">
                                {{'TITLE.I_AGREE_THE' | translate}} <a href="javascript:;"> {{'TITLE.TERMSCONDITIONS' | translate}} </a>
                            </mat-checkbox>
                        </div>

                        <!--begin::Action-->
                        <div class="kt-login__actions">
                            <button routerLink="/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'FIELDS.BACK_BUTTON' | translate }}</button>
                            <button (click)="submit()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'FIELDS.SUBMIT_BUTTON' | translate }}</button>
                        </div>
                        <!--end::Action-->
                    </form>

                    <form class="kt-login__form kt-form" [formGroup]="registerConfirmForm" *ngIf="!!showConfirmationForm">

                        <span>{{'FIELDS.OTP' | translate}}</span>
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>{{ 'FIELDS.CODE' | translate }}</mat-label>
                                <input matInput type="password" placeholder="{{ 'FIELDS.CODE' | translate }}" formControlName="code" autocomplete="off"/>
                                <mat-error *ngIf="isConfirmControlHasError('code','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <!-- <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                                <mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
                                    <strong>Passsword and ConfirmPassword didn't match.</strong>
                                </mat-error> -->
                            </mat-form-field>
                        </div>

                        <!--begin::Action-->
                        <div class="kt-login__actions">
                            <a  class="kt-link kt-login__link-forgot" (click)="resendEmail()">
                                <!-- href="javascript:;" routerLink="/forgot-password" {{ 'FIELDS.FORGOT_BUTTON' | translate }} -->
                                {{ 'FIELDS.RESEND_EMAIL' | translate }}
                            </a>
                            <!-- <button routerLink="/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'FIELDS.BACK_BUTTON' | translate }}</button> -->
                            <button (click)="confirmSubmit()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'FIELDS.SUBMIT_BUTTON' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--end::Content-->
    </div>
</div>




