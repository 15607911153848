import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { PaymentRequestUrway, PaymentResponseUrway } from '../_models/payments.model';
import { GenericResponse } from '../_models/generic-response.model';
import { ProjectDetails } from '../_models/projects.model';


const API_URL = environment.apiURL;

@Injectable()
export class JadwaService {
    sendingClosingPlan: Subject<{ closingPlan: boolean, callback: () => {} }> = new Subject();
    sendingClosingPlan$: Observable<{ closingPlan: boolean, callback: () => {} }> = this.sendingClosingPlan.asObservable();
    constructor(private http: HttpClient) {
        this.sendingClosingPlan$ = this.sendingClosingPlan.asObservable();
    }

    sports = [
        { id: '1', name: 'Soccer' },
        { id: '2', name: 'Basketball' },
    ];

    getSports(): Observable<any[]> {
        return of(this.sports);
    }



    getCurrencies(): Observable<any> {
        return this.http.get(API_URL + 'api/v1/currencies');
    }

    // start Industry
    getIndustries(): Observable<any> {
        return this.http.get(API_URL + 'api/v1/industries');
    }

    getIndustry(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/industries/' + id, { headers: httpHeaders });
    }

    createIndustry(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/industries', requestObj, { headers: httpHeaders });
    }

    updateIndustry(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/industries', requestObj, { headers: httpHeaders });
    }



    deleteIndustry(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/industries/' + id);
    }
    // End Industry

    /*::: START ----Products
    ***********************************************************/
    getProducts(): Observable<any> {
        return this.http.get(API_URL + 'api/v1/products');
    }

    autoComplete(apiendPoint): Observable<any> {
        console.log(apiendPoint);
        return this.http.get(API_URL + 'api/v1/' + apiendPoint);
    }

    // autoComplete(apiendPoint,searchParam): Observable<any> {
    //     console.log(apiendPoint);
    //     return this.http.get(API_URL + 'api/v1/'+apiendPoint+'?searchParam='+searchParam);
    // }


    getProduct(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/products/' + id, { headers: httpHeaders });
    }

    createProduct(CreateRequestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/products', CreateRequestObj, { headers: httpHeaders });
    }

    updateProduct(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/products', requestObj, { headers: httpHeaders });
    }

    deleteProduct(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/products/' + id);
    }
    // End Products

    /*::: START ----FixedAssets
  ***********************************************************/
    getfixedassets(): Observable<any> {
        return this.http.get(API_URL + 'api/v1/fixedassets');
    }

    createFixedassets(CreateRequestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/fixedassets', CreateRequestObj, { headers: httpHeaders });
    }

    getFixedassetId(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/fixedassets/' + id, { headers: httpHeaders });
    }


    updateFixedasset(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/fixedassets', requestObj, { headers: httpHeaders });
    }

    deleteFixedassets(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/fixedassets/' + id);
    }

    // End FixedAssets


    /*::: START ----Data Bank
    ***********************************************************/
    getDataBankListApi(): Observable<any> {
        return this.http.get(API_URL + 'api/v1/databank');
    }

    createDataBankListApi(CreateRequestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/databank', CreateRequestObj, { headers: httpHeaders });
    }

    geteDataBankById(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/databank/' + id, { headers: httpHeaders });
    }

    updateDataBankApi(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/databank', requestObj, { headers: httpHeaders });
    }



    /** Data Bank Consents start */

    //     "dataBankId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     "name": "string",
    //     "arName": "string",
    //     "type": "string",
    //     "sequenceNumber": 0,
    //     "data": "string"
    createDataBankContent(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/databank/contents', requestObj, { headers: httpHeaders });
    }


    //     "dataBankId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     "name": "string",
    //     "arName": "string",
    //     "type": "string",
    //     "sequenceNumber": 0,
    //     "data": "string"
    updateDataBankContent(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/databank/contents', requestObj, { headers: httpHeaders });
    }


    getDataBankContent(dataBankId: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/databank/contents/fetch/' + dataBankId, { headers: httpHeaders });
    }

    getContent(contentId: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/databank/contents/' + contentId, { headers: httpHeaders });
    }

    deleteDataBankContent(contentId: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/databank/contents/' + contentId, { headers: httpHeaders });
    }

    /** Data Bank Consents end */



    deleteBataBankApiId(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/databank/' + id);
    }
    // End Data Bank

    // get Plans


    getPlans(includeDocuments, projectId?: string): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/plans?includeDocuments=' + includeDocuments + (projectId ? `&projectId=${projectId}` : ""));
    }

    getPlanswithTemplateData(): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/plans');
    }
    getPlanDetails(planId: string): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/plans/' + planId);
    }
    createPlan(plan: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/plans', plan, { headers: httpHeaders });
    }
    updatePlan(plan: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/plans', plan, { headers: httpHeaders });
    }
    // [
    //     "3fa85f64-5717-4562-b3fc-2c963f66afa62"
    // ]
    deletePlans(plan: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/plans/' + plan);
    }



    // End Plans

    // Start Document
    getDocuments(): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/documents');
    }
    getDocumentDetails(id): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/documents/' + id);
    }
    createDocument(document) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/documents/', document);
    }

    updateDocument(document) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/documents/', document);
    }

    deleteDocument(documentId) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/documents/' + documentId);
    }
    // End Document


    // get Projects


    getAllProjects(): Observable<any> {
        return this.http.get(API_URL + 'api/v1/user-projects/');
    }
    getUserProjects(userId): Observable<GenericResponse<Array<ProjectDetails>>> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        // return this.http.get(API_URL + 'api/v1/user-projects');
        return this.http.get<GenericResponse<Array<ProjectDetails>>>(API_URL + 'api/v1/user-projects/project/' + userId);
    }

    // api/v1/user-projects/submit/plan
    submitPlan(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/user-projects/submit/plan', requestObj, { headers: httpHeaders });
    }

    createUserProject(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/user-projects', requestObj, { headers: httpHeaders });
    }

    updateUserProject(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/user-projects', requestObj, { headers: httpHeaders });
    }


    createUserProjectPlan(requestObj: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/user-projects/save/plan', requestObj, { headers: httpHeaders });
    }


    getuserProjectPlans(projectId): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/user-projects/plan/' + projectId);
    }



    getPlanMappedReports(projectId): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/plans/mappedreports/' + projectId);
    }


    deleteUserProjectPlan(projectId, planId): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/user-projects/plan/' + projectId + '/' + planId);
    }


    // /api/v1/user-projects/plan/{projectId}/{PlanId}

    // {
    // "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // "projectId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // "planId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // "documentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    // }
    fetchProjectPlan(requestObj): Observable<any> {
        return this.http.post(API_URL + 'api/v1/user-projects/fetch/plan', requestObj);
    }


    getUserProjectDetails(id: string): Observable<any> {
        // const httpHeaders = new HttpHeaders();
        // httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + 'api/v1/user-projects/' + id);
    }

    deleteUserProject(id: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.delete(API_URL + 'api/v1/user-projects/' + id);
    }
    /*
        
    
        // [
        //     "3fa85f64-5717-4562-b3fc-2c963f66afa62"
        // ]
        deletePlans(plan: any): Observable<any> {
            const httpHeaders = new HttpHeaders();
            httpHeaders.set('Content-Type', 'application/json');
            return this.http.delete(API_URL+'api/v1/plans'+plan);
        }*/

    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** start:::: the PROFILE apI service calls.  **/
    getUserProfile(id: string): Observable<any> {
        return this.http.get(API_URL + 'api/v1/user/' + id);
    }
    updateServiceProfile(eiditProfile: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_URL + 'api/v1/user/profile', eiditProfile, { headers: httpHeaders });
    }



    // FORM API Integration Calls
    //FOR :currencies
    getCurrenciesList(): Observable<any> {
        return this.http.get<Observable<any>>(API_URL + 'api/v1/currencies').pipe(map((res: any) => {
            return res.result;
        }));
    }

    //FOR :products
    productsList(): Observable<any> {
        return this.http.get<Observable<any>>(API_URL + 'api/v1/products').pipe(map((res: any) => {
            return res.result;
        }));
    }

    // {
    //     "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     "oldPassword": "string",
    //     "newPassword": "string"
    // }

    updatePassword(requestObj): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/user/password/update', requestObj, { headers: httpHeaders });
    }


    // {
    //     "userId": "d5c96f1e-d6f4-4dfa-bca6-9413776e67b5",//any valid user id
    //     "projectId": "e5e6f6e9-e5ac-46c3-bce8-008e877fb07e",
    //     "planId": "aac56231-cf00-42b0-a8ac-6e5e3c783cca",
    //     "reportId": "6eee63db-a2c9-4cec-b09b-69483279a9d2",
    //     "customReportName": "string"
    // }
    getPlanReport(requestObj): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/user-reports/generate', requestObj, { headers: httpHeaders });
    }

    saveGeneratedReportPath(request: { userReportId: string, reportPath: string }) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post(API_URL + 'api/v1/user-reports/savepath', request, { headers: httpHeaders });

    }

    getAllGeneratedReportsOfUser(userId: string) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get(API_URL + `api/v1/user-reports/reports/${userId}`, { headers: httpHeaders });
    }

    uploadGeneratedReportPDF(formdata: FormData) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'multipart/form-data');
        return this.http.post(API_URL + 'api/v1/fileupload', formdata, { headers: httpHeaders })

    }

    // fileupload(value) {
    //     return this.http.post( API_URL + "api/v1/fileupload", value );
    // }

    fileupload(fileToUpload: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('FolderId', '0');
        formData.append('file', fileToUpload, fileToUpload.name);
        return this.http.post(API_URL + "api/v1/fileupload", formData);
    }

    getUsers(): Observable<any> {
        // const formData: FormData = new FormData();
        // formData.append('FolderId', '0');
        // formData.append('file', fileToUpload, fileToUpload.name);
        return this.http.get(API_URL + "api/v1/user");
    }

    getUserDetails(userId: string): Observable<any> {
        // const formData: FormData = new FormData();
        // formData.append('FolderId', '0');
        // formData.append('file', fileToUpload, fileToUpload.name);
        return this.http.get(API_URL + "api/v1/user/" + userId);
    }

    getInvoiceForPlan(request: {
        planId: string, userId: string,
        projectId: string
    }): Observable<{
        version: string, result: {
            userId: string,
            projectId: string,
            planId: string,
            invoiceNo: string,
            invoicePath: string
        },
        message: string,
        responseException: any
    }> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<{
            version: string, result: {
                userId: string,
                projectId: string,
                planId: string,
                invoiceNo: string,
                invoicePath: string
            },
            message: string,
            responseException: any
        }>(API_URL + "api/v1/payments/invoice", request, { headers: httpHeaders });
    }


    // https://jadwa.otadevtest.com/api/v1/user

    /*Payments apis */
    createPaymentRequestForPlan(requestObj: PaymentRequestUrway): Observable<{ version: any, message: any, responseException: any, result: PaymentResponseUrway }> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<{ version: any, message: any, responseException: any, result: PaymentResponseUrway }>(API_URL + "api/v1/payments/purchase", requestObj, { headers: httpHeaders });
    }
    /*End of payents apis */

    /*opening create projects from header component inside dashboard component */
    openCreateProject: Subject<{ open: boolean }> = new Subject();
    openCreateProject$: Observable<{ open: boolean }> = this.openCreateProject.asObservable();
    currentlyOpenedProject: boolean = false;

    sendOpenCreateProject() {
        if (!this.currentlyOpenedProject) {
            this.currentlyOpenedProject = true;
            this.openCreateProject.next({ open: true });
        }
    }

    closeOpenProject() {
        this.currentlyOpenedProject = false;
    }

    /*opening generated reports from header component inside dashboard component */
    openGeneratedReports: Subject<{ open: boolean }> = new Subject();
    openGeneratedReports$: Observable<{ open: boolean }> = this.openGeneratedReports.asObservable();
    currentlyOpenedGeneratedReports: boolean = false;

    sendGeneratedReportsToOpen() {
        if (!this.currentlyOpenedGeneratedReports) {
            this.currentlyOpenedGeneratedReports = true;
            this.openGeneratedReports.next({ open: true });
        }
    }

    closeGeneratedReports() {
        this.currentlyOpenedGeneratedReports = false;
    }



    saveAsDraftIfRequiredAndCallback(closingPlan: boolean, callback: () => {}) {
        this.sendingClosingPlan.next({ closingPlan: closingPlan, callback: callback });
    }


    getUserSubscriptions(params): Observable<any> {
        return this.http.post(API_URL + "api/v1/user-subscription", params);
    }

    activatePlan(params): Observable<any> {
        return this.http.post(API_URL + "api/v1/user-projects/activate/plan", params);
    }

    getAllSubscriptions(): Observable<any> {
        return this.http.get(API_URL + "api/v1/subscription");
    }

}
