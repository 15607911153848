/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scale-legend.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scale-legend.component";
import * as i3 from "@angular/platform-browser";
var styles_ScaleLegendComponent = [i0.styles];
var RenderType_ScaleLegendComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ScaleLegendComponent, data: {} });
export { RenderType_ScaleLegendComponent as RenderType_ScaleLegendComponent };
export function View_ScaleLegendComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "scale-legend"]], [[2, "horizontal-legend", null], [4, "height", "px"], [4, "width", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "scale-legend-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "scale-legend-wrap"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "scale-legend-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.horizontal; var currVal_1 = (_co.horizontal ? undefined : _co.height); var currVal_2 = _co.width; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.valueRange[1].toLocaleString(); _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.gradient; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.valueRange[0].toLocaleString(); _ck(_v, 7, 0, currVal_5); }); }
export function View_ScaleLegendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-charts-scale-legend", [], null, null, null, View_ScaleLegendComponent_0, RenderType_ScaleLegendComponent)), i1.ɵdid(1, 573440, null, 0, i2.ScaleLegendComponent, [i3.DomSanitizer], null, null)], null, null); }
var ScaleLegendComponentNgFactory = i1.ɵccf("ngx-charts-scale-legend", i2.ScaleLegendComponent, View_ScaleLegendComponent_Host_0, { valueRange: "valueRange", colors: "colors", height: "height", width: "width", horizontal: "horizontal" }, {}, []);
export { ScaleLegendComponentNgFactory as ScaleLegendComponentNgFactory };
