<div class="card-body py-3">
    
    <div class="row pb-3">
        <div class="col-12 d-flex justify-content-end">
            <div>
                <span class="material-icons" (click)="close()"
                    type="button">close</span>
            </div>
        </div>
    </div>
      <div class="table-generated-report-overflow">  
    <div class="table-responsive">
      
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          
            <tbody> 
                
                <ng-container *ngFor="let genReport of generatedReportsList;let genReportIndex=index"> 
                <tr >
                 <td>
                     <div class="d-flex align-items-center">
                         <div class="d-flex justify-content-start flex-column">
                             <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{'TITLE.GENERATED_PLAN' | translate}} {{genReportIndex+1}}</a>
                         </div>
                     </div>
                 </td>
                 <td>
                     <div class="d-flex justify-content-end flex-shrink-0">
                         <a [href]="genReport.reportPath" download="" target="blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                           
                             <span class="svg-icon svg-icon-3">
                                 <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                     <g id="Stockholm-icons-/-General-/-Visible" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                         <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                         <path d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z" id="Shape" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                         <path d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z" id="Path" fill="#000000" opacity="0.3"></path>
                                     </g>
                                 </svg>
                             </span>
                             
                         </a>
                         <a [href]="genReport.reportPath" (click)="$event.preventDefault();downloadPDFFromRemote(genReport.reportPath)"class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                           
                             <span class="svg-icon svg-icon-3">
                                 <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                     <g id="Stockholm-icons-/-Files-/-Download" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                         <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                         <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                         <rect id="Rectangle" fill="#000000" opacity="0.3" transform="translate(12.000000, 8.000000) rotate(-180.000000) translate(-12.000000, -8.000000) " x="11" y="1" width="2" height="14" rx="1"></rect>
                                         <path d="M7.70710678,15.7071068 C7.31658249,16.0976311 6.68341751,16.0976311 6.29289322,15.7071068 C5.90236893,15.3165825 5.90236893,14.6834175 6.29289322,14.2928932 L11.2928932,9.29289322 C11.6689749,8.91681153 12.2736364,8.90091039 12.6689647,9.25670585 L17.6689647,13.7567059 C18.0794748,14.1261649 18.1127532,14.7584547 17.7432941,15.1689647 C17.3738351,15.5794748 16.7415453,15.6127532 16.3310353,15.2432941 L12.0362375,11.3779761 L7.70710678,15.7071068 Z" id="Path-102" fill="#000000" fill-rule="nonzero" transform="translate(12.000004, 12.499999) rotate(-180.000000) translate(-12.000004, -12.499999) "></path>
                                     </g>
                                 </svg>
                             </span>
                             
                         </a>
                     </div>
                 </td>
             </tr> 
             </ng-container>
             <ng-container *ngIf="generatedReportsList?.length==0">
                 <tr>
                     <td>
                         There are no generated reports available
                     </td>
                     <td>

                     </td>
                 </tr>

             </ng-container>
                                         
            </tbody>
   
        </table>
       
    </div>
    </div>
  
</div>