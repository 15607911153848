import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';


export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    Register = '[Register] Action',
    UserRequested = '[Request User] Action',
    UserLoaded = '[Load User] Auth API',
    CurrentUser = '[CurrentUser] Action',
    SetLanguage = "SetLanguage"
}

export class SetLanguage implements Action {
    readonly type = AuthActionTypes.SetLanguage;
    constructor(public payload: { language: any }) { }
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { authToken: string }) { }
}

export class CurrentUser implements Action {
    readonly type = AuthActionTypes.CurrentUser;
    constructor(public payload: { userInfo: string }) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class Register implements Action {
    readonly type = AuthActionTypes.Register;
    constructor(public payload: { authToken: string }) { }
}


export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User }) { }
}



export type AuthActions = SetLanguage | Login | Logout | Register | UserRequested | UserLoaded | CurrentUser;
