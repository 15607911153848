import { Store } from '@ngrx/store';
// Tranlsation
import { TranslateService } from '@ngx-translate/core';
import { SetLanguage } from '../../../../../app/core/auth/_actions/auth.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@ngrx/store";
var TranslationService = /** @class */ (function () {
    /**
     * Service Constructor
     *
     * @param translate: TranslateService
     */
    function TranslationService(translate, store) {
        this.translate = translate;
        this.store = store;
        // Private properties
        this.langIds = [];
        // add new langIds to the list
        this.translate.addLangs(['en']);
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
    }
    /**
     * Load Translation
     *
     * @param args: Locale[]
     */
    TranslationService.prototype.loadTranslations = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var locales = args.slice();
        locales.forEach(function (locale) {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            _this.translate.setTranslation(locale.lang, locale.data, true);
            _this.langIds.push(locale.lang);
        });
        // add new languages to the list
        this.translate.addLangs(this.langIds);
    };
    /**
     * Setup language
     *
     * @param lang: any
     */
    TranslationService.prototype.setLanguage = function (lang) {
        if (lang) {
            this.translate.use(this.translate.getDefaultLang());
            this.translate.use(lang);
            localStorage.setItem('language', lang);
            this.store.dispatch(new SetLanguage({ language: lang }));
            document.body.dir = this.translate.instant('DIRECTION');
        }
    };
    /**
     * Returns selected language
     */
    TranslationService.prototype.getSelectedLanguage = function () {
        return localStorage.getItem('language') || this.translate.getDefaultLang();
    };
    TranslationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.Store)); }, token: TranslationService, providedIn: "root" });
    return TranslationService;
}());
export { TranslationService };
