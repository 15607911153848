import { NgModule } from '@angular/core';
import {MatExpansionModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule, MatProgressSpinnerModule, MatRadioModule, MatStepperModule, MatTabsModule, MatSelectModule, MatAutocompleteModule, MatListModule, MatProgressBarModule } from '@angular/material';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

@NgModule({
	imports: [
		MatListModule,
		MatAutocompleteModule,
        MatTabsModule,
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatStepperModule,
		MatIconModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatDialogModule,
		FormlyMaterialModule,
		FormlyMatDatepickerModule,
		MatRadioModule,
		MatExpansionModule,
		MatSelectModule
	],
	exports: [
		MatListModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatStepperModule,
		MatIconModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatDialogModule,
		MatRadioModule,
		MatExpansionModule,
		FormlyMaterialModule,
		FormlyMatDatepickerModule,
		MatSelectModule
	],
})
export class CustomMaterialModule { }
