<div class="no-records-section">
    <!--products start-->
    <div class="no-flight-main" *ngIf="notFound == 'productNOTfound'">
        <div class="row m-0">
            <div class="col-sm-12 p-0">
                <div class="flight-notfound-blo">

                    <b class="swal2-title">{{'TITLE.NO_RECORD_FOUND' | translate}}</b>

                </div>
            </div>
        </div>
    </div>
    <!--end-->
    <!--projects start-->
    <div class="no-flight-main" *ngIf="notFound == 'projectsNOTfound'">
        <div class="row m-0">
            <div class="col-sm-12 p-0">
                <div class="flight-notfound-blo">

                    <b class="swal2-title">{{'TITLE.NO_RECORD_FOUND' | translate}}</b>

                </div>
            </div>
        </div>
    </div>
    <!--end-->
    <!--fixedassets start-->
    <div class="no-flight-main" *ngIf="notFound == 'fixedassetsNOTfound'">
        <div class="row m-0">
            <div class="col-sm-12 p-0">
                <div class="flight-notfound-blo">

                    <b class="swal2-title">{{'TITLE.NO_RECORD_FOUND' | translate}}</b>

                </div>
            </div>
        </div>
    </div>
    <!--end-->
    <!--databank start-->
    <div class="no-flight-main" *ngIf="notFound == 'databanksNOTfound'">
        <div class="row m-0">
            <div class="col-sm-12 p-0">
                <div class="flight-notfound-blo">

                    <b class="swal2-title">{{'TITLE.NO_RECORD_FOUND' | translate}}</b>

                </div>
            </div>
        </div>
    </div>
    <!--end-->

      <!--plans start-->
      <div class="no-flight-main" *ngIf="notFound == 'plansNOTfound'">
        <div class="row m-0">
            <div class="col-sm-12 p-0">
                <div class="flight-notfound-blo">

                    <b class="swal2-title">{{'TITLE.NO_RECORD_FOUND' | translate}}</b>

                </div>
            </div>
        </div>
    </div>
    <!--end-->
</div>
