/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./x-axis-ticks.component";
var styles_XAxisTicksComponent = [];
var RenderType_XAxisTicksComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_XAxisTicksComponent, data: {} });
export { RenderType_XAxisTicksComponent as RenderType_XAxisTicksComponent };
function View_XAxisTicksComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, ":svg:g", [["class", "tick"]], [[1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, ":svg:text", [["stroke-width", "0.01"]], [[1, "text-anchor", 0], [1, "transform", 0], [4, "font-size", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tickTransform(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.tickFormat(_v.context.$implicit); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.textAnchor; var currVal_3 = _co.textTransform; var currVal_4 = "12px"; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.tickTrim(_co.tickFormat(_v.context.$implicit)); _ck(_v, 4, 0, currVal_5); }); }
function View_XAxisTicksComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:g", [], [[1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:line", [["class", "gridline-path gridline-path-vertical"], ["y2", "0"]], [[1, "y1", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridLineTransform(); _ck(_v, 0, 0, currVal_0); var currVal_1 = (0 - _co.gridLineHeight); _ck(_v, 1, 0, currVal_1); }); }
function View_XAxisTicksComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, ":svg:g", [], [[1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XAxisTicksComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showGridLines; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tickTransform(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
export function View_XAxisTicksComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { ticksElement: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["ticksel", 1]], null, 2, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XAxisTicksComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XAxisTicksComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ticks; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.ticks; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_XAxisTicksComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-x-axis-ticks", ""]], null, null, null, View_XAxisTicksComponent_0, RenderType_XAxisTicksComponent)), i0.ɵdid(1, 4767744, null, 0, i2.XAxisTicksComponent, [], null, null)], null, null); }
var XAxisTicksComponentNgFactory = i0.ɵccf("g[ngx-charts-x-axis-ticks]", i2.XAxisTicksComponent, View_XAxisTicksComponent_Host_0, { scale: "scale", orient: "orient", tickArguments: "tickArguments", tickValues: "tickValues", tickStroke: "tickStroke", trimTicks: "trimTicks", maxTickLength: "maxTickLength", tickFormatting: "tickFormatting", showGridLines: "showGridLines", gridLineHeight: "gridLineHeight", width: "width", rotateTicks: "rotateTicks" }, { dimensionsChanged: "dimensionsChanged" }, []);
export { XAxisTicksComponentNgFactory as XAxisTicksComponentNgFactory };
