import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateProjectComponent } from './create-project/create-project.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from '../material.module';
import { ProjectIndustryComponent } from './project-industry/project-industry.component';
import { ProjectPlansComponent } from './project-plans/project-plans.component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormlyModule } from '@ngx-formly/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { NoRecordFoundComponent } from './no-record-found/no-record-found.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSelectModule } from '@angular/material';
import { PlanReportComponent } from './plan-report/plan-report.component';
// import { NgxChartsModule } from 'ngx-charts-8';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// import { ProjectReportsComponent } from './project-reports/project-reports.component';
import { DataBankNameFilterPipe, IndustryNameFilterPipe, NameFilterPipe } from './pipe/search-filter';
import { PlanReportTrialComponent } from './plan-report-trial/plan-report-trial.component';
import { ProjectPlansDialogComponent } from './project-plans-dialog/project-plans-dialog.component';
import { PlanDocumentsComponent } from './plan-documents/plan-documents.component';
import { PlanReportsComponent } from './plan-reports/plan-reports.component';
import { ElementAddedToViewDirective } from './directives/element-added-to-view.directive';
import { JedwaCustomPieChartModule } from './third-party-open-source/jedwa-custom-ngx-charts';
import { CustomNgxComboChartsModule } from './third-party-open-source/jedwa-custom-ngx-charts/custom-chart/combo-chart.module';
import { PlanReportTemplateAtUIComponent } from './plan-report-template-at-ui/plan-report-template-at-ui.component';
import { A4PageTemplateComponent } from './a4-page-template/a4-page-template.component';
import { BuyPlanPopupComponent } from './buy-plan-popup/buy-plan-popup.component';



@NgModule({
  declarations: [
    NameFilterPipe,
    IndustryNameFilterPipe,
    DataBankNameFilterPipe,

    CreateProjectComponent,
    ProjectPlansComponent,
    ProjectIndustryComponent,
    SpinnerComponent,
    NoRecordFoundComponent,
    PlanReportComponent,
    PlanReportTemplateAtUIComponent,
    PlanReportTrialComponent,
    // ProjectReportsComponent,
    ProjectPlansDialogComponent,
    PlanDocumentsComponent,
    PlanReportsComponent,
    ElementAddedToViewDirective,
    BuyPlanPopupComponent,
    A4PageTemplateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    TranslateModule.forChild(),
    AngularEditorModule,
    MatSelectModule,
    NgxPaginationModule,
    FormlyModule.forChild(),
    NgxChartsModule,
    JedwaCustomPieChartModule,
    CustomNgxComboChartsModule
  ],
  exports: [
    NameFilterPipe,
    IndustryNameFilterPipe,   
    DataBankNameFilterPipe,
    PlanReportTrialComponent,
    BuyPlanPopupComponent,
     
    CreateProjectComponent,
    ProjectPlansComponent,
    SpinnerComponent,
    NoRecordFoundComponent,
    ElementAddedToViewDirective
  ],
  entryComponents: [
    CreateProjectComponent,
    ProjectPlansComponent,
    ProjectIndustryComponent,
    NoRecordFoundComponent,
    PlanReportComponent,
    PlanReportTemplateAtUIComponent,
    // ProjectReportsComponent,
    PlanDocumentsComponent,
    PlanReportsComponent
  ]
})
export class SharedModule { }
