import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import Swal from 'sweetalert2';
import { PlanDocumentsComponent } from '../plan-documents/plan-documents.component';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../../../app/core/reducers';
import { PlanReportsComponent } from '../plan-reports/plan-reports.component';

@Component({
  selector: 'project-plans',
  templateUrl: './project-plans.component.html',
  styleUrls: ['./project-plans.component.scss']
})
export class ProjectPlansComponent implements OnInit {
  loading : boolean;
  reportsList : any;
  dataBank : any;
  projectId : any;
  project :any;

  currentLanguage : string = 'en';
  private subscriptions: Subscription[] = [];
  constructor(
    public dialog: MatDialog,
    private jadwaService: JadwaService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ProjectPlansComponent>,
    private store: Store<AppState>
  ) {
    // this.dataBankId = data.dataBank.id;
    this.projectId = data.projectId;
    if(data.project){
      this.project = data.project;
    }
    
   }

  ngOnInit() {
    const sortSubscription = this.store.pipe(
			select(AuthState => AuthState)
		).subscribe(State => {
			//@ts-ignore
			this.currentLanguage = State.auth.language;
		});
		this.subscriptions.push(sortSubscription);
    
    if(this.projectId){
      this.getProjectPlans();
    }
  }
  userProejctPlans : any;
  getProjectPlans(){
    this.userProejctPlans = null;
    this.loading = true;
    this.jadwaService.getuserProjectPlans(this.projectId).subscribe((response: any) => {
		  this.loading = false;
      this.userProejctPlans = response.result;
		}, error => {
		  this.loading = false;
			Swal.fire({
			  title : (error.error) ?error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  icon: 'warning',
			  showCancelButton: false,
			  confirmButtonColor: '#37c0b9',
			  confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
  }

  openPlanDocuments(planId, mode){
    const dialogRef = this.dialog.open(PlanDocumentsComponent, {
      disableClose:true,
      width: '100%',
      height:'100%',
      panelClass: 'custom-dialog-container-bg',
      data: {planId: planId, projectId: this.projectId, mode: mode, project : this.project}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getProjectPlans();
        // this.onProjectChange(this.projectId);
      }
    });
  }


  openPlanReports(planId){
    const dialogRef = this.dialog.open(PlanReportsComponent, {
      width: '100%',
      height:'100%',
      data: {planId: planId , projectId: this.projectId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        // this.getUserProjects();
      }
    });
  }
  



  close(){
    this.dialogRef.close();
  }

}


