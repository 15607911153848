<spinner *ngIf="loading"></spinner>
<div class="project-industry-popup create-project">
    <div class="container z-index-1">
    <div class="row m-0 text-center">
        <div class="row">
            <div class="col-12">
                <h3  class="mb-5 title">{{'TITLE.REPORTS' | translate}}</h3> 
            </div>
        </div>
        <div class="col-12 flex-column mb-3 flex-column d-flex justify-content-center align-content-center overflow-custom">
        
        <!-- <span (click)="close()">close</span>------------- -->
        <div class="col-md-12 plan-right-bg ">

            <div class="row m-0 mb-4 business-templates-block " *ngIf="!!reportsList && !!reportsList?.mappedReportList">
                <div class="col-md-3"  *ngFor="let report of reportsList?.mappedReportList">

                    
                  <div class="card overly-body">
                      <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                      <div class="card-body">
                          <h5 class="card-title" *ngIf="currentLanguage == 'en'">{{report.name}}</h5>
                          <h5 class="card-title" *ngIf="currentLanguage != 'en'">{{report.arName}}</h5>
                          <div class="text-white pb-2">Version {{report.version}}</div>
                          <!-- <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p> -->
                          <div>
                            <a class="btn btn-primary btn-active-primary mr-2" (click)="openReport(report , false)">Open Report</a>
                              <!-- <a class="btn btn-primary btn-active-primary mr-2" (click)="editContent(content)">{{'TITLE.EDIT' | translate}}</a>
                              <a class="btn btn-primary btn-danger" (click)="deleteContent(content.id)">{{'TITLE.DELETE' | translate}}</a> -->
                          </div>
                          
                      </div>
                  </div>
                </div>
            </div>

            

            </div></div>

            <!-- <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-primary  mx-1" (click)="addContent()">{{'TITLE.ADD_CONTENT' | translate}}</button>
            </div> -->
        </div>

        <div class="close-alert-icons">
            <!-- 
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
    
            <div><span class="material-icons" mat-dialog-close>keyboard_arrow_left</span></div>
            <div><span class="material-icons" mat-dialog-close>close</span></div>
        </div>
    </div>

    
    <div class="abstract-design bottom right"><img src="../../../assets/media/bg/abstract-design.png" /></div>
    <div  class="abstract-design top right"><img src="../../../assets/media/bg/polygon-borders.png" /></div>
    <div  class="abstract-design left top"><img src="../../../assets/media/bg/polygon-borders-left.png" /></div>

</div>



