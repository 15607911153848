<!-- BEGIN: Horizontal Menu -->
<button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
<div ktOffcanvas [options]="offcanvasOptions" class="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
	<div ktMenu [options]="menuOptions" id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile"
		[ngClass]="htmlClassService.getClasses('header_menu', true)">
		<ul class="kt-menu__nav" [ngClass]="htmlClassService.getClasses('header_menu_nav', true)">
			<!-- <ng-container *ngFor="let item of menuHorService.menuList$ | async">
				<ng-container *ngIf="item.title" [ngTemplateOutlet]="menuTemplate"
					[ngTemplateOutletContext]="{ item: item }"></ng-container>
			</ng-container> -->

			<li *ngIf="isGeneral" class="kt-menu__item kt-menu__item--rel" aria-haspopup="true">
				<a class="kt-menu__link kt-menu__toggle" routerLink="/dashboard">
					<span class="kt-menu__item-here"></span><span class="kt-menu__link-text"> {{'TITLE.DASHBOARDS' | translate}} </span>
				</a>
			</li>

			<li *ngIf="isGeneral || isBusinessAdmin" class="kt-menu__item kt-menu__item--rel" aria-haspopup="true">
				<a class="kt-menu__link kt-menu__toggle" routerLink="/project">
					<span class="kt-menu__item-here"></span><span class="kt-menu__link-text"> {{'TITLE.PROJECTS_NAV' | translate}} </span>
				</a>
			</li>


			<!--  -->
			<li *ngIf="!!isBusinessAdmin" class="kt-menu__item"
				[ngClass]="{ 'kt-menu__item--hover kt-menu__item--open kt-menu__item--here kt-menu__item--submenu  kt-menu__item--rel': activeClass == 'BusinessAdminTools' }"
				(mouseenter)="addActiveClass('BusinessAdminTools')" (mouseleave)="addActiveClass(null)">
				<a class="kt-menu__link kt-menu__toggle" href="javascript:;">
					<span class="kt-menu__item-here"></span>
					<span class="kt-menu__link-text"> {{'TITLE.BUSINESS_ADMIN_TOOLS' | translate}} </span> </a>
				<div class="kt-menu__submenu kt-menu__submenu--left kt-menu__submenu--classic">
					<span class="kt-menu__arrow kt-menu__arrow--adjust"></span>
					<ul class="kt-menu__subnav">

						<li class="kt-menu__item">
							<!--kt-menu__item--active kt-menu__item--here-->
							<a class="kt-menu__link kt-menu__toggle" (click)="addActiveClass(null)"
								routerLink="/users">
								<i class="kt-menu__link-icon flaticon-interface-7"></i>
								<span class="kt-menu__item-here"></span><span
									class="kt-menu__link-text">{{'TITLE.USERS' | translate}}</span></a>
							<!--bindings={}-->
						</li>

						<li class="kt-menu__item">
							<!-- kt-menu__item--hover-->
							<a class="kt-menu__link kt-menu__toggle" (click)="addActiveClass(null)"
								routerLink="/industry">
								<i class="kt-menu__link-icon flaticon-interface-7"></i>
								<span class="kt-menu__item-here"></span>
								<span class="kt-menu__link-text">{{'TITLE.INDUSTRY' | translate}}</span>
							</a>
						</li>
						<li class="kt-menu__item">
							<a class="kt-menu__link kt-menu__toggle" (click)="addActiveClass(null)"
								routerLink="/products">
								<i class="kt-menu__link-icon flaticon-interface-7"></i>
								<span class="kt-menu__item-here"></span><span
									class="kt-menu__link-text">{{'TITLE.PRODUCTS' | translate}}</span></a>
						</li>
						<li class="kt-menu__item">
							<a class="kt-menu__link kt-menu__toggle" (click)="addActiveClass(null)"
								routerLink="/fixedassets">
								<i class="kt-menu__link-icon flaticon-interface-7"></i>
								<span class="kt-menu__item-here"></span>
								<span class="kt-menu__link-text">{{'TITLE.FIXEDASSETS_NAV' | translate}}</span>
								<!---->
							</a>
						</li>
						<li class="kt-menu__item">
							<!--kt-menu__item--active kt-menu__item--here-->
							<a class="kt-menu__link kt-menu__toggle" (click)="addActiveClass(null)"
								routerLink="/databank">
								<i class="kt-menu__link-icon flaticon-interface-7"></i>
								<span class="kt-menu__item-here"></span><span
									class="kt-menu__link-text">{{'TITLE.DATABANK' | translate}}</span></a>
							<!--bindings={}-->
						</li>
					</ul>
				</div>

			</li>


			<li *ngIf="!!isTechAdmin" class="kt-menu__item "
				[ngClass]="{ 'kt-menu__item--hover kt-menu__item--submenu  kt-menu__item--rel kt-menu__item--open kt-menu__item--here': activeClass == 'TechnicalAdminTools' }"
				(mouseenter)="addActiveClass('TechnicalAdminTools')" (mouseleave)="addActiveClass(null)">
				<a class="kt-menu__link kt-menu__toggle" href="javascript:;">
					<span class="kt-menu__item-here"></span>
					<span class="kt-menu__link-text"> {{'TITLE.TECHNICAL_ADMIN_TOOLS' | translate}} </span> </a>
				<div class="kt-menu__submenu kt-menu__submenu--left kt-menu__submenu--classic">
					<span class="kt-menu__arrow kt-menu__arrow--adjust"></span>
					<ul class="kt-menu__subnav">
						<li class="kt-menu__item kt-menu__item--hover">
							<a class="kt-menu__link kt-menu__toggle" (click)="addActiveClass(null)" routerLink="/plan">
								<i class="kt-menu__link-icon flaticon-interface-7"></i>
								<span class="kt-menu__item-here"></span>
								<span class="kt-menu__link-text">{{'TITLE.PLANS_NAV' | translate}}</span>
							</a>
						</li>
						<li class="kt-menu__item">
							<a class="kt-menu__link kt-menu__toggle" (click)="addActiveClass(null)"
								routerLink="/document">
								<i class="kt-menu__link-icon flaticon-interface-7"></i>
								<span class="kt-menu__item-here"></span><span
									class="kt-menu__link-text">{{'TITLE.DOCUMENTS_NAV' | translate}}</span></a>
						</li>
					</ul>
				</div>

			</li>



		</ul>
	</div>
</div>
<!-- END: Horizontal Menu -->