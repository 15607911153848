<spinner *ngIf="loading"></spinner>

<ng-container *ngFor="let csObj of arrayOfComputedSectionData;let index = index;">
  <!-- {{csObj | json}} -->
  <div class="page A4" [id]="'page'+index">
    <!-- {{reportObjAnother | json}} -->


    <!-- individual pdf screen a4 dimenstion -->
    <!-- <div class="card-body"> -->

    <!-- <div *ngIf="!!rObj.hasPlaceholder" class="row"> -->
    <!-- <div *ngFor="let csObj of rObj?.computedSectionData" class="col-12"> -->
    <div class="row" [id]="'pageHeader'+index">
      <div class="col-12  pdf-header-text">
        <h3 class="text-center">{{'TITLE.VALUE_INNOVATION' | translate}}</h3>
      </div>
    </div>
    <div class="row" [id]="'content'+index">
      <div class="col-1"></div>
      <div class="col-10">


        <div class="row">
          <div class="vertical-align-top" id="custom-table-style">
            <table>
              <tr>
                <td rowspan="2" width="20%">{{'TITLE.PROBLEM' | translate}} <br /><br /><br /><br /></td>
                <td  width="20%">{{'TITLE.SOLUTION' | translate}} <br /><br /><br /><br /></td>
                <td rowspan="2" width="20%">{{'TITLE.UNIQ' | translate}} <br /><br /><br /><br /></td>
                <td width="20%">{{'TITLE.UNFIR' | translate}} <br /><br /><br /><br /></td>
                <td rowspan="2" width="20%">{{'TITLE.CUSTOMER_SIGMENT' | translate}} <br /><br /><br /><br /></td>
              </tr>
              <tr>
                <td>{{'TITLE.KEY_METRICS' | translate}} <br /><br /><br /><br /></td>
                 <td>{{'TITLE.CHANNELS' | translate}}  <br /><br /><br /><br /></td>
              </tr>
              <tr>
                <td colspan="5" style="padding: 0;">
                  <table style="margin-bottom: 0 !important;">
                    <tr>
                      <td width="50%">{{'TITLE.COST' | translate}}<br /><br /><br /><br /></td>
                      <td width="50%">{{'TITLE.REVENUE' | translate}}<br /><br /><br /><br /></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          
        </div>

        <!-- heading section  -->
        <div class="row">
          <div *ngIf="csObj?.placeholderType == 'Heading'" class="col-12">
            <h1 class="heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
              csObj.arabicPlaceholderValue }}</h1>
          </div>
        </div>

        <!-- text section  -->
        <div class="row">
          <div *ngIf="csObj?.placeholderType == 'Text'" class="col-12 mb-3 sub-heading-style">
            <span class="sub-heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
              csObj.arabicPlaceholderValue }}</span>
          </div>
        </div>


        <!-- HtmlTable section -->
        <div *ngIf="csObj?.placeholderType == 'HtmlTable'" class="mb-3">
          <div id="custom-table-style"
            [innerHTML]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue">
          </div>
        </div>


        <!---->
        <div class="mb-3" *ngIf="csObj?.placeholderType == 'clearsection'">
          <div class="html-template" [style.height.px]="100"></div>
        </div>

        <!-- charts section  -->
        <div class="row bg-white">
          <div *ngIf="csObj?.placeholderType == 'Chart'" class="col-12">

            <div *ngIf="csObj?.graphType == 'Pie Chart'">
              <ngx-charts-pie-chart [view]="[600,600]" [scheme]="colorScheme"
                [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                [gradient]="gradient" [legend]="showLegend" [labels]="showLabels" [doughnut]="isDoughnut"
                (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
              </ngx-charts-pie-chart><br />
            </div>

            <div *ngIf="csObj?.graphType == 'Line Chart'">

              <!-- (window:resize)="onResize($event)" -->
              <ngx-charts-line-chart [view]="[600,600]" [scheme]="colorScheme"
                [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" (select)="onSelect($event)">
              </ngx-charts-line-chart><br />
            </div>

            <div *ngIf="csObj?.graphType == 'Vertical Bar Chart'">
              <ngx-charts-bar-vertical [view]="[600,600]" [scheme]="colorScheme"
                [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel" (select)="onSelect($event)"></ngx-charts-bar-vertical><br />


            </div>

          </div>
        </div>




      </div>
      <div class="col-1">
      </div>
    </div>

    <div class="row" [id]="'pageFooter'+index" style="position: absolute;top: 1026px;z-index: 10;">
      <div class="col-12">
        <img style="width: 100%;" src="assets/footer.png">
      </div>
    </div>
    <!-- </div> -->



    <!-- <div *ngIf="!rObj.hasPlaceholder">
        <div class="html-template" [innerHTML]="rObj?.htmlTemplate"></div>
      </div> -->

    <!-- </div> -->



    <!-- <div>end section</div>
  <button (click)="addReportObjAnotherPush()">add another section</button> -->
  </div>
</ng-container>
<!-- rendered fitted -->
<h1>{{'TITLE.RENDERRED_FITTING' | translate}}</h1>
<ng-container *ngFor="let contentArray of allPagesFitted;let index = index;">
  <!-- {{csObj | json}} -->
  <div class="page A4" [id]="'renderedPage'+index">
    <!-- {{reportObjAnother | json}} -->


    <!-- individual pdf screen a4 dimenstion -->
    <!-- <div class="card-body"> -->

    <!-- <div *ngIf="!!rObj.hasPlaceholder" class="row"> -->
    <div class="row" [id]="'pageHeader'+index">
      <div class="col-12">
        <h3 class="text-center  pdf-header-text">{{'TITLE.VALUE_INNOVATION' | translate}}</h3>
      </div>
    </div>
    <div class="row" *ngFor="let csObj of contentArray" [id]="'renderedContentForPrintPDF'+index">
      <div class="col-1"></div>
      <div class="col-10">


        <!-- heading section  -->
        <div class="row">
          <div *ngIf="csObj?.placeholderType == 'Heading'" class="col-12">
            <h1 class="heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
              csObj.arabicPlaceholderValue }}</h1>
          </div>
        </div>


        <!-- <div class="row" *ngIf="csObj?.placeholderType == 'Text'">
          <div class="vertical-align-top" id="custom-table-style">
            <table>
              <tr>
                <td rowspan="2" width="20%">Problem <br /><br /><br /><br /></td>
                <td  width="20%">Solution <br /><br /><br /><br /></td>
                <td rowspan="2" width="20%">Uniq <br /><br /><br /><br /></td>
                <td width="20%">Unfir <br /><br /><br /><br /></td>
                <td rowspan="2" width="20%">Customer Sigment <br /><br /><br /><br /></td>
              </tr>
              <tr>
                <td>Key Metrics <br /><br /><br /><br /></td>
                 <td>Channels <br /><br /><br /><br /></td>
              </tr>
              <tr>
                <td colspan="5" style="padding: 0;">
                  <table style="margin-bottom: 0 !important;">
                    <tr>
                      <td width="50%">Cost<br /><br /><br /><br /></td>
                      <td width="50%">Revenue<br /><br /><br /><br /></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          
        </div> -->


        <!-- text section  -->
        <div class="row">
          <div *ngIf="csObj?.placeholderType == 'Text'" class="col-12 mb-3 sub-heading-style">
            <span class="sub-heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
              csObj.arabicPlaceholderValue }}</span>
          </div>
        </div>


        <!-- HtmlTable section -->
        <div *ngIf="csObj?.placeholderType == 'HtmlTable'" class="mb-3">
          <div id="custom-table-style"
            [innerHTML]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue">
          </div>
        </div>


        <!---->
        <div class="mb-3" *ngIf="csObj?.placeholderType == 'clearsection'">
          <div class="html-template" [style.height.px]="100"></div>
        </div>

        <!-- charts section  -->
        <div class="row bg-white">
          <div *ngIf="csObj?.placeholderType == 'Chart'" class="col-12">

            <div *ngIf="csObj?.graphType == 'Pie Chart'">
              <ngx-charts-pie-chart [view]="[600,600]" [scheme]="colorScheme"
                [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                [gradient]="gradient" [legend]="showLegend" [labels]="showLabels" [doughnut]="isDoughnut"
                (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
              </ngx-charts-pie-chart><br />
            </div>

            <div *ngIf="csObj?.graphType == 'Line Chart'">

              <!-- (window:resize)="onResize($event)" -->
              <ngx-charts-line-chart [view]="[600,600]" [scheme]="colorScheme"
                [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" (select)="onSelect($event)">
              </ngx-charts-line-chart><br />
            </div>

            <div *ngIf="csObj?.graphType == 'Vertical Bar Chart'">
              <ngx-charts-bar-vertical [view]="[600,600]" [scheme]="colorScheme"
                [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel" (select)="onSelect($event)"></ngx-charts-bar-vertical><br />


            </div>

          </div>
        </div>





      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" [id]="'pageFooter'+index" style="position: absolute;top: 1026px;z-index: 10;">
      <div class="col-12">
        <img style="width: 100%;" src="assets/footer.png">
      </div>
    </div>



    <!-- <div *ngIf="!rObj.hasPlaceholder">
        <div class="html-template" [innerHTML]="rObj?.htmlTemplate"></div>
      </div> -->

    <!-- </div> -->



    <!-- <div>end section</div>
  <button (click)="addReportObjAnotherPush()">add another section</button> -->
  </div>
</ng-container>
<!-- end renderred fittred -->
<div class="toolbar pt-5 pb-2 py-lg-15 mt-5" id="kt_toolbar">
  <!--begin::Container-->
  <div id="kt_toolbar_container" class="container-xl d-flex flex-stack flex-wrap mt-3 ">
    <!--begin::Page title-->
    <div class="page-title d-flex flex-column marginback">
      <!--begin::Title-->
      <h1 class="d-flex  fw-bolder my-1 fs-3">{{'TITLE.CHART_TYPE' | translate}}</h1>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1 bg-transparent p-0">
        <!--begin::Item-->
        <li class="breadcrumb-item  opacity-75">
          <a routerLink="/" class="text-hover-primary">{{'TITLE.HOME' | translate}}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-white opacity-75 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-white opacity-75">{{'TITLE.DATA_BANK_TITLE' | translate}}</li>
        <!--end::Item-->
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Page title-->
    <!--begin::Actions-->
    <div class="d-flex align-items-center py-3 py-md-1 custom-fieds">


      <!--begin::Button-->
      <!-- <button class="btn  btn-active-color-primary mx-3 text-white" (click)="downloadPDF()"><i class="far fa-file-pdf text-white"></i> Download PDF</button> -->
      <button class="btn btn-primary text-white" (click)="downloadPDF()"><i
          class="far fa-file-pdf text-white"></i>  {{'TITLE.DOWNLOAD_NEW_PDF' | translate}}</button>

      <!--end::Button-->

    </div>
    <!--end::Actions-->
  </div>
  <!--end::Container-->
</div>

<div id="kt_content_container" class="d-block flex-column-fluid align-items-start container-xxl p-0 me-5">
  <!--begin::Post-->
  <div class="content flex-row-fluid mx-5 " id="kt_content">
    <!--begin::Card  class="card"-->
    <div id="pdfsection">
      <!--begin::Card body    class="card-body"-->
      <div class="card-body ">

        <!-- <div *ngIf="reportHtmlTemplate">
                  <h1> {{planReport?.customReportName}} </h1>
                  <div [innerHTML]="reportHtmlTemplate"> </div>
                </div> -->
        <!-- <pre>
            {{currentLanguage | json}}
            {{reportObject | json}}
          </pre> -->


        <!--class="mb-5 pdf-card card1"-->
        <div *ngFor="let rObj of reportObject">

          <!-- individual pdf screen a4 dimenstion -->
          <!-- <div class="card-body"> -->

          <div *ngIf="!!rObj.hasPlaceholder" class="row">
            <div *ngFor="let csObj of rObj?.computedSectionData" class="col-12">

              <!-- heading section  -->
              <div class="row">
                <div *ngIf="csObj?.placeholderType == 'Heading'" class="col-12">
                  <h1 class="heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
                    csObj.arabicPlaceholderValue }}</h1>
                </div>
              </div>

              <!-- text section  -->
              <div class="row">
                <div *ngIf="csObj?.placeholderType == 'Text'" class="col-12 mb-3 sub-heading-style">
                  <span class="sub-heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
                    csObj.arabicPlaceholderValue }}</span>
                </div>
              </div>


              <!-- HtmlTable section -->
              <div *ngIf="csObj?.placeholderType == 'HtmlTable'" class="mb-3">
                <div id="custom-table-style"
                  [innerHTML]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue">
                </div>
              </div>


              <!---->
              <div class="mb-3" *ngIf="csObj?.placeholderType == 'clearsection'">
                <div class="html-template" [style.height.px]="100"></div>
              </div>

              <!-- charts section  -->
              <div class="row bg-white">
                <div *ngIf="csObj?.placeholderType == 'Chart'" class="col-12">

                  <div *ngIf="csObj?.graphType == 'Pie Chart'">
                    <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="gradient" [legend]="showLegend" [labels]="showLabels" [doughnut]="isDoughnut"
                      (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                    </ngx-charts-pie-chart><br />
                  </div>

                  <div *ngIf="csObj?.graphType == 'Line Chart'">

                    <!-- (window:resize)="onResize($event)" -->
                    <ngx-charts-line-chart [view]="view" [scheme]="colorScheme"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                      [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" (select)="onSelect($event)" [view]="view">
                    </ngx-charts-line-chart><br />
                  </div>

                  <div *ngIf="csObj?.graphType == 'Vertical Bar Chart'">
                    <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                      [yAxisLabel]="yAxisLabel" (select)="onSelect($event)"></ngx-charts-bar-vertical><br />


                  </div>

                </div>
              </div>




            </div>
          </div>



          <div *ngIf="!rObj.hasPlaceholder">
            <div class="html-template" [innerHTML]="rObj?.htmlTemplate"></div>
          </div>

          <!-- </div> -->

        </div>
      </div>
    </div>


    <div class="close-alert-icons mx-3">
      <!-- 
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->

      <div class="bg-white py-3 "><span class="material-icons" mat-dialog-close>keyboard_arrow_left</span></div>
      <div class="bg-white py-3 "><span class="material-icons" mat-dialog-close>close</span></div>
    </div>


  </div>
</div>