import * as tslib_1 from "tslib";
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { ProductSpecificationsService } from '../_services/';
// Actions
import { ProductSpecificationActionTypes, ProductSpecificationsPageLoaded, ProductSpecificationsPageToggleLoading, ProductSpecificationCreated } from '../_actions/product-specification.actions';
var ProductSpecificationEffects = /** @class */ (function () {
    function ProductSpecificationEffects(actions$, productSpecificationsService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.productSpecificationsService = productSpecificationsService;
        this.store = store;
        // showLoadingDistpatcher = new ProcutSpecificationsPageToggleLoading({ isLoading: true });
        this.hideLoadingDistpatcher = new ProductSpecificationsPageToggleLoading({ isLoading: false });
        this.loadProductSpecificationsPage$ = this.actions$
            .pipe(ofType(ProductSpecificationActionTypes.ProductSpecificationsPageRequested), mergeMap(function (_a) {
            var payload = _a.payload;
            return _this.productSpecificationsService.findProductSpecs(payload.page, payload.productId);
        }), map(function (result) {
            return new ProductSpecificationsPageLoaded({
                productSpecifications: result.items,
                totalCount: result.totalCount
            });
        }));
        this.deleteProductSpecification$ = this.actions$
            .pipe(ofType(ProductSpecificationActionTypes.OneProductSpecificationDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductSpecificationsPageToggleLoading({ isLoading: true }));
            return _this.productSpecificationsService.deleteProductSpec(payload.id);
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
        this.deleteProductSpecifications$ = this.actions$
            .pipe(ofType(ProductSpecificationActionTypes.ManyProductSpecificationsDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductSpecificationsPageToggleLoading({ isLoading: true }));
            return _this.productSpecificationsService.deleteProductSpecifications(payload.ids);
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
        this.updateProductSpecification$ = this.actions$
            .pipe(ofType(ProductSpecificationActionTypes.ProductSpecificationUpdated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductSpecificationsPageToggleLoading({ isLoading: true }));
            return _this.productSpecificationsService.updateProductSpec(payload.productSpecification);
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
        this.createProductSpecification$ = this.actions$
            .pipe(ofType(ProductSpecificationActionTypes.ProductSpecificationOnServerCreated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(new ProductSpecificationsPageToggleLoading({ isLoading: true }));
            return _this.productSpecificationsService.createProductSpec(payload.productSpecification).pipe(tap(function (res) {
                _this.store.dispatch(new ProductSpecificationCreated({ productSpecification: res }));
            }));
        }), map(function () {
            return _this.hideLoadingDistpatcher;
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductSpecificationEffects.prototype, "loadProductSpecificationsPage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductSpecificationEffects.prototype, "deleteProductSpecification$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductSpecificationEffects.prototype, "deleteProductSpecifications$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductSpecificationEffects.prototype, "updateProductSpecification$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ProductSpecificationEffects.prototype, "createProductSpecification$", void 0);
    return ProductSpecificationEffects;
}());
export { ProductSpecificationEffects };
