/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./panel-wrapper.component";
var styles_PanelWrapperComponent = [];
var RenderType_PanelWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PanelWrapperComponent, data: {} });
export { RenderType_PanelWrapperComponent as RenderType_PanelWrapperComponent };
export function View_PanelWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { fieldComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 16777216, [[1, 3], ["fieldComponent", 1]], null, 0, null, null, null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.label; _ck(_v, 3, 0, currVal_0); }); }
export function View_PanelWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-wrapper-panel", [], null, null, null, View_PanelWrapperComponent_0, RenderType_PanelWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i1.PanelWrapperComponent, [], null, null)], null, null); }
var PanelWrapperComponentNgFactory = i0.ɵccf("formly-wrapper-panel", i1.PanelWrapperComponent, View_PanelWrapperComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { PanelWrapperComponentNgFactory as PanelWrapperComponentNgFactory };
