// USA
export const locale = {
	lang: "ar",
	data: {
		DIRECTION:"rtl",
		TRANSLATOR: {
			SELECT: "اختر لغتك",
		},
		ERROR_MESSAGE : {
			SOMETHING_WENTWRONG : 'Something Went Wrong AR',
		},
		TITLE : {
			//  HEADER NAVIGATION
			DASHBOARDS : 'Dashboards AR',	
			PROJECTS_NAV : 'Projects AR',
			BUSINESS_ADMIN_TOOLS : 'Business Admin Tools AR',	
			USERS : 'Users AR',	
			INDUSTRY : 'Industry AR',	
			PRODUCTS : 'Products AR',
			FIXEDASSETS_NAV : 'FixedAssets AR',	
			DATABANK : 'DataBank AR',	
			TECHNICAL_ADMIN_TOOLS : 'Technical Admin Tools AR',
			PLANS_NAV : 'Plans AR',
			DOCUMENTS_NAV : 'Documents AR',
			JADWA : 'Jadwa AR',
			// DASHBOARD
			MY_PROJECT_PLANS : 'My Project Plans AR',
			OVER : 'Over AR',
			PROJECTS : 'Projects AR',
			CREATE_PROJECT : 'Create Project AR',
			NO_PLANS_CREATED_FOR_THIS_PROJECT : 'No Plans created for this project AR',
			CREATE_NEW_PROJECT : 'Create New Project AR',

			// PROJECTS
			PROJECTS_LIST : 'Projects List AR',
			PROJECT : 'Project AR',
			PROJECT_TITLE : 'Project Title AR',
			PROJECT_PLANS : 'Project Plans AR',
			SEARCH_PROJECT : 'Search Project AR',

			// DATA BANK
			DATA_BANK_TITLE : 'Data Bank AR',
			SEARCH_DATA_BANK : 'Search Data Bank AR',
			CREATE_DATA_BANK : 'Create Data Bank AR',
			PROMOTIONAL : 'Promotional AR',
			AREA : 'Area AR',
			BUSINESS_PLAN_TEMPLATES : 'Business Plan Templates AR',
			BUSINESS_PLAN : 'Business Plan AR',
			TEMPLATES : 'Templates AR',
			USE_PLAN : 'Use Plan AR',
			BUY_PLAN:"Buy Plan AR",
			NO_PLAN_AVAILABLE : 'No Plan Available AR',
			GENERATED_BUSINESS_PLANS : 'Generated Business Plans AR',
			GENERATED_PLAN : 'Generated Plan AR',
			// POP-UP
			CREATE_A_NEW_PROJECT : 'Create a new project AR',
			// TABLE
            S_NO: 'S.NO AR',
            INDUSTRY_NAME: 'Industry Name AR', 
			DATABANK_NAME : 'DataBank Name AR',
            CATEGORY_NAME: 'Category Name AR', 
            AMOUNT: 'Amount AR', 
            CURRENCY: 'Currency AR', 
            EDITABLE: 'Editable AR', 
            FREE: 'Free AR', 
            ACTION: 'Action AR', 
			DELETE: 'Delete AR',
			EDIT: 'Edit AR',
			VIEW: 'View AR',
			CONTENTS: 'Contents AR',
			ROLE : 'Role AR',

			CREATED_BY_USER :  'Created By',
			CREATED_AT : 'Created At',
			// DATA BANK DIALOGS 
			// Contents
			SEQUENCE_NO: 'Sequence No AR',
			CONTENTS_DESCRIPTION: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. AR',
			ADD_CONTENT: 'Add Content AR',
			// EDIT DATA BANK
			CREATE_A_NEW_DATA_BANK: 'Create a new Data Bank AR',

			// FIXED ASSETS 
			FIXEDASSETS : 'Fixed Assets AR',
			CREATE_FIXEDASSETS : 'Fixed Assets AR',
			SEARCH_FIXEDASSETS : 'Search FixedAssets AR',
			// POP-UP
			CREATE_A_NEW_FIXED_ASSETS : 'Create a new Fixed Assets AR',
			// TABLE
			NAME : 'Name AR',
			LIFE_TIME : 'Life Time AR',
			SALVAGE_PERCENTAGE_VALUE : 'Salvage Percentage Value AR',
			HOME : 'Home AR',
			USERS_LIST : 'Users List AR',
			CUSTOMER_SEGMENT : 'فئة من العملاء',
			PLANS : 'Plans AR',
			REGISTER_TITLE: 'Sign Up AR',
			DESC: 'Enter your details to create your account AR',
			SUCCESS: 'Your account has been successfuly registered. AR',
			VERIFY_EMAIL_LINK_SENT:"Verification link sent to email AR",
			OKAY : 'okay AR',

			FORGOT_TITLE: 'Forgot Password? AR',
			FORGOT_PASSWORD_DESC: 'Enter your email to reset your password AR',
			FORGOT_PASSWORD_SUCCESS: 'Your account has been successfully reset. AR',
			FORGOT_ACCOUNT_BUTTON: "Forgot Account AR",

			RESET_PASSWORD_TITLE: 'Reset Password? AR',
			RESET_PASSWORD_DESC: 'Enter New password to login AR',
			RESET_PASSWORD_SUCCESS: 'your password has been updated successfully. AR',

			LOGIN_TITLE: 'Login Account AR',
			BUTTON: 'Sign In AR',
			SUBMIT_BUTTON: 'Submit AR',
			NO_ACCOUNT: 'Don\'t have an account? AR',
			SIGNUP_BUTTON: 'Sign Up AR',
			FORGOT_BUTTON: 'Forgot Password AR',
			BACK_BUTTON: 'Back AR',
			PRIVACY: 'Privacy AR',
			LEGAL: 'Legal AR',
			CONTACT: 'Contact AR',
			FIRST_NAME: "First Name AR",
			LAST_NAME: "Last Name AR",
			USERNAME:"User Name AR",
			PASSWORD:"Password AR",
			CONFIRM_PASSWORD:"Confirm Password AR",
			OR: 'Or AR',
			VERSION  : 'Version AR',
			PLAN_DETAILS  : 'Plan Details AR',
			PLAN_REPORTS  : 'Plan Reports AR',

			// PRODUCTS LIST
			PAGE_PRODUCTS : 'Products AR',
			CREATE_PRODUCTS : 'Create Products AR',
			SEARCH_PRODUCT : 'Search Product AR',
			// PRODUCTS DETAILS
			CREATE_NEW_PRODUCT : 'Create New Product AR',
			// INDUSTRY LIST
			INDUSTRIES  : 'Industries AR',
			CREATE_INDUSTRY : 'Create Industry AR',
			SEARCH_USER : 'Search User AR',
			INDUSTRY_DETAILS : 'Industry Details AR',
			// USERS LIST
			CREATE_USER : 'Create User AR',
			USER_TITLE : 'User Title AR',
			REPORT  : 'User Title AR',
			// PLANS
			PLANS_LIST  : 'Plans List AR',
			SEARCH_PLANS  : 'Search plans AR',
			CREATE_PLAN  : 'Create Plan AR',
			PLAN_TITLE : 'Plan Title AR',
			CREATE_A_NEW_PLAN  : 'Create a New Plan AR',
			DESCRIPTION  : 'Description AR',
			SEQUENCE   : 'Sequence AR',
			// DOCUMENTS
			DOCUMENT  : 'Document AR',
			DOCUMENTS  : 'Documents AR',
			SEARCH_DOCUMENT : 'Search Document AR',
			CREATE_DOCUMENT : 'Create Document AR',
			DOCUMENT_NAME : 'Document Name AR',
			ICON  : 'Icon AR',
			TEMPLATE_METADATA : 'Template MetaData AR',
			TEMPLATE_FORM_DATA : 'Template Form Data AR',
			// SIGN IN
			SIGN_IN  : 'Sign In AR',
			FACEBOOK  : 'Facebook AR',
			GOOGLE : 'Google AR',
			REGISTERTITLE : 'Develop a business plan for your',
			REGISTERTITLE2 : 'selected business idea !!! AR',
			// SIGN UP
			I_AGREE_THE : 'I agree the',
			TERMSCONDITIONS : 'terms & conditions AR',
			// USER PROFILE
			PROFILE_DETAILS : 'Profile Details AR',
			CHANGE_PASSWORD : 'Change Password AR',
			EDIT_PROFILE : 'Edit Profile AR',
			USER_ID : 'User Id AR',
			COMPANY : 'Company AR',
			AVATAR : 'Avatar AR',
			ALLOWED_FILE: 'Allowed file types: png, jpg, jpeg. AR',
			// PLAN REPORTS
			CHART_TYPE : 'Chart type AR',
			DOWNLOAD_PDF : 'Download PDF AR',
			// PROJECT INDUSTRY
			PLAN : 'Plan AR',
			USE : 'Use AR',
			PREVIEW : 'Preview AR',
			 // PLAN REPORT TRIAL
			VALUE_INNOVATION : 'Value Innovation Management Consultancy AR',
			PROBLEM : 'Problem AR',
			UNIQ  : 'Uniq AR',
			CUSTOMER_SIGMENT  : 'Customer Sigment AR',
			KEY_METRICS  : 'Key Metrics AR',
			SOLUTION : 'Solution AR',
			UNFIR : 'Unfir AR',
			CHANNELS  : 'Channels AR',
			COST : 'Cost AR',
			REVENUE : 'Revenue AR',
			RENDERRED_FITTING : 'Renderred Fitting AR',
			// NEW RECORD FOUND
			NO_RECORD_FOUND : 'No Record Found AR',
			 // USER DETAILS
			 USER_DETAILS  : 'User Details AR',
			 PHONE_NUMBER_CODE : 'Phone Number Code AR',
			 PHONE_NUMBER : 'Phone Number AR',
			 COUNTRY_CODE  : 'Country Code AR',
			 SECOND_NAME : 'Second Name AR',
			 THIRD_NAME : 'Third Name AR',
			 ADDITIONAL_CONTACT_INFO  : 'Additional Contact Info AR',
			 REPORTS : 'Reports AR',
			 SEQUENCENUMBER : 'Sequence Number AR',
			  // CONTENT DIALOGS
			  CONTENT_DETAILS : 'Content Details AR',
			  TYPE : 'Type AR',
			  HTML  : 'HTML AR',
			  IMAGE  : 'Image AR',	
			  YES:"نعم",
			  NO:"رقم",
			  UNSAVE_DATA_SAVE:"You have Unsaved Data. Do you want to save it",
			  ISPAID:"Should user pay for report",
		   	 AMOUNT_MANDATORY:"المبلغ إلزامي",
			PLEASE_WAIT_REDERECTING_TO_PAYMENT_PAGE:"Please wait while you would be redirected to payment page",
			VIEW_GENERATED_REPORTS:"View Generated Reports AR",
		},

		FIELDS : {
			EMAIL: 'بريد الالكتروني',
			FULLNAME: 'Fullname AR',
			PASSWORD: 'Password AR',
			CONFIRM_PASSWORD: 'Confirm Password AR',
			USERNAME: 'Username AR',
			PLACEHOLDER_NAME : 'Name AR',
			INPUT_CURRENCY : 'Input Currency AR',
			OUTPUT_CURRENCY : 'Output Currency AR',
			SELECT: 'Select AR',
			EDITABLE : 'Editable AR',
			SAVE : 'Save AR',
			PLEASE_WAIT : 'Please wait AR',
			CANCEL : 'Cancel AR',
			START_DATE : 'Start Date AR',
			FIRST_YEAR : 'First Year AR',
			SELECT_YEAR : 'Select Year AR',
			INDUSTRY_ID  : 'Industry Id AR',
			NO_ACCOUNT: 'Don\'t have an account? AR',
			DONT_HAVE_AN_ACCOUNT_YET: 'Don\'t have an account? AR',
			OTP: 'An OTP sent to your email addresss AR',
			RESEND_EMAIL : 'Resend Email AR',
			BACK_BUTTON: 'Back AR',
			SUBMIT_BUTTON: 'Submit AR',
			FULL_NAME : 'Full Name AR',
			CONTACT_PHONE : 'Contact Phone AR',
			USER_ID : 'User Id AR',
			COMPANY : 'Company AR',
			ADDRESS: 'Address AR',
			AGE : 'Age AR',
			COUNTRY : 'Country AR',
			GENDER : 'Gender AR',
			PROFESSION : 'Profession AR',
			CITY  : 'City AR',
			MALE   : 'Male',
			FEMALE  : 'Female AR',
			SAVE_CHANGES : 'Save Changes AR',
			ENTER_OLD_PASSWORD: 'Enter Old Password AR',
			NEW_PASSWORD : 'New Password AR',
			CONFIRM_NEW_PASSWORD : 'Confirm New Password AR',
			SELECT_LANGUAGE:"Select language"
		},

		VALIDATION : {
			INVALID: '{{name}} is not valid AR',
			REQUIRED: '{{name}} is required AR',
			MIN_LENGTH: '{{name}} minimum length is {{min}} AR',
			AGREEMENT_REQUIRED: 'Accepting terms & conditions are required AR',
			NOT_FOUND: 'The requested {{name}} is not found AR',
			INVALID_LOGIN: 'The login detail is incorrect AR',
			REQUIRED_FIELD: 'Required field AR',
			MIN_LENGTH_FIELD: 'Minimum field length: AR',
			MAX_LENGTH_FIELD: 'Maximum field length: AR',
			INVALID_FIELD: 'Field is not valid AR',
			CATEGORY_NAME_IS_REQUIRED: 'Category Name is required AR',
			AMOUNT_SHOULD_ONLY_CONTAIN_NUMBERS: 'Amount should only contain Numbers AR',
			AMOUNTISREQUIRED : 'Amount is required AR',
			CURRENCY_IS_REQUIRED : 'Currency is required AR',
			INDUSTRY_NAME_IS_REQUIRED : 'Industry name is required AR',
			NAME_IS_REQUIRED : 'Name is required AR',
			LIFETIME_NAME_IS_REQUIRED : 'Lifetime name is required AR',
			SALVAGE_PERCENTAGE_VALUE_IS_REQUIRED : 'Salvage Percentage Value is required AR',
			PLEASE_FIX_THE_BELOW_ERRORS : 'Please Fix the below Errors AR',
			PASSWORD_TEXT : 'Passsword and ConfirmPassword didn\'t match. AR',
			PROFILEIMAGE_IS_REQUIRED : 'Profile Image is required AR',
			FIRST_NAME_IS_REQUIRED : 'First Name is required AR',
			LAST_NAME_IS_REQUIRED : 'Last Name is required AR',
			EMAIL_IS_REQUIRED : 'Email is required AR',
			PHONE_NUMBER_IS_REQUIRED  : 'Phone Number is required AR',
			COMPANY_IS_REQUIRED : 'Company is required AR',
			COMPANY_MUST_BE : 'company must be atleast 3 characters AR',
			COMPANY_MUST_NOT : 'company must not exceed 60 characters AR',
			ADDRESS_IS_REQUIRED : 'Address is required AR',
			ADDRESS_MUST_BE : 'Address must be atleast 3 characters AR',
			ADDRESS_MUST_NOT  : 'Address must not exceed 120 characters AR',
			CITY_IS_REQUIRED : 'City is required AR',
			CITY_MUST_BE : 'city must be atleast 3 characters',
			CITY_MUST_NOT : 'city must not exceed 60 characters AR',
			COUNTRY_IS_REQUIRED : 'Country is required AR',
			COUNTRY_MUST_BE : 'country must be atleast 3 characters AR',
			COUNTRY_MUST_NOT : 'country must not exceed 60 characters AR',
			PROFESSION_IS_REQUIRED : 'Profession is required AR', 
			PROFESSION_MUST_BE : 'Profession must be atleast 3 characters',
			PROFESSION_MUST_NOT : 'Profession must not exceed 60 characters',
			AGE_IS_REQUIRED : 'Age is required AR',
			AGE_ONLY_ACCEPT_NUMBERS : 'Age only accept numbers AR',
			GENDER_IS_REQUIRED : 'Gender is required AR',	
			PASDDWORD_MATCH : 'Passsword and ConfirmPassword didn\'t match. AR'
		}
	},
};
