/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/common";
import * as i8 from "./alert.component";
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "kt-mat-alert__close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["color", "warn"], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(2, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "material-icons mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i0.ɵdid(4, 9158656, null, 0, i6.MatIcon, [i0.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["clear"]))], function (_ck, _v) { var currVal_2 = "warn"; _ck(_v, 2, 0, currVal_2); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 2).disabled || null); var currVal_1 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 4).inline; var currVal_4 = (((i0.ɵnov(_v, 4).color !== "primary") && (i0.ɵnov(_v, 4).color !== "accent")) && (i0.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_3, currVal_4); }); }
export function View_AlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["role", "alert"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "kt-mat-alert__icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "la la-warning"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "kt-mat-alert__text"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showCloseButton; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "kt-mat-alert  kt-mat-alert--", _co.type, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i8.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i0.ɵccf("kt-alert", i8.AlertComponent, View_AlertComponent_Host_0, { type: "type", duration: "duration", showCloseButton: "showCloseButton" }, { close: "close" }, ["*"]);
export { AlertComponentNgFactory as AlertComponentNgFactory };
