import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import Swal from 'sweetalert2';
import { PlanReportComponent } from '../plan-report/plan-report.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { Subscription } from 'rxjs';
import { PlanReportTemplateAtUIComponent } from '../plan-report-template-at-ui/plan-report-template-at-ui.component';
// import { ContentDialogComponent } from '../content-dialog/content-dialog.component';

@Component({
  selector: 'kt-plan-reports',
  templateUrl: './plan-reports.component.html',
  styleUrls: ['./plan-reports.component.scss']
})
export class PlanReportsComponent implements OnInit {
  loading : boolean;
  reportsList : any;

  projectId: any;
  planId : any;

  plan:any;
  
  currentLanguage : string = 'en';
  private subscriptions: Subscription[] = [];
  singleReport : boolean;
  constructor(
    public dialog: MatDialog,
    private jadwaService: JadwaService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<PlanReportsComponent>,
    private store: Store<AppState>
  ) {
    this.planId = data.planId;
    this.projectId = data.projectId;
    this.plan = data.plan;
    debugger;
   }

  ngOnInit() {

    const sortSubscription = this.store.pipe(
			select(AuthState => AuthState)
		).subscribe(State => {
			//@ts-ignore
			this.currentLanguage = State.auth.language;
		});
		this.subscriptions.push(sortSubscription);
    
    if(this.planId){
      this.getPlanMappedReports​();
    }
  }


  getPlanMappedReports​() {
    this.loading = true;
    // const planId = this.planId;
    // const planId = 'aac56231-cf00-42b0-a8ac-6e5e3c783cca';
    

    this.jadwaService.getPlanMappedReports​(this.planId).subscribe((response: any) => {
      this.loading = false;
      this.reportsList = response.result;

      if( !!response.result &&  !!response.result.mappedReportList){
         if(response.result.mappedReportList.length == 1){
          this.singleReport = true;
            this.openReport(response.result.mappedReportList[0], true);
         }else{
          this.singleReport = false;
         }
      }

      // if single Report is there for that plan, directly open the report
    }, error => {
      this.loading = false;
      Swal.fire({
        title: 'Something went wrong',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    });
  }


  openReport(report, singleReport){
    
    const dialogRef = this.dialog.open(PlanReportComponent, {
    //const dialogRef = this.dialog.open(PlanReportTemplateAtUIComponent, {
      width: '100%',
      height:'100%',
      data: {projectId : this.projectId , planId : this.planId, report : report, singleReport : singleReport,plan:this.plan}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(this.singleReport){
        this.dialogRef.close();
      }
      if(result){
        // this.getUserProjects();
      }
    });
  }




  close(){
    this.dialogRef.close();
  }



}

