/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legend.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./legend-entry.component.ngfactory";
import * as i3 from "./legend-entry.component";
import * as i4 from "@angular/common";
import * as i5 from "./legend.component";
var styles_LegendComponent = [i0.styles];
var RenderType_LegendComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LegendComponent, data: {} });
export { RenderType_LegendComponent as RenderType_LegendComponent };
function View_LegendComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "header", [["class", "legend-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "legend-title-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_LegendComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "legend-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngx-charts-legend-entry", [], null, [[null, "select"], [null, "activate"], [null, "deactivate"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("select" === en)) {
        var pd_2 = (_co.labelClick.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("activate" === en)) {
        var pd_3 = (_co.activate($event) !== false);
        ad = (pd_3 && ad);
    } if (("deactivate" === en)) {
        var pd_4 = (_co.deactivate($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_LegendEntryComponent_0, i2.RenderType_LegendEntryComponent)), i1.ɵdid(2, 49152, null, 0, i3.LegendEntryComponent, [], { color: [0, "color"], label: [1, "label"], formattedLabel: [2, "formattedLabel"], isActive: [3, "isActive"] }, { select: "select", activate: "activate", deactivate: "deactivate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.color; var currVal_1 = _v.context.$implicit.label; var currVal_2 = _v.context.$implicit.formattedLabel; var currVal_3 = _co.isActive(_v.context.$implicit); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_LegendComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[4, "width", "px"]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegendComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "legend-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "legend-labels"]], [[2, "horizontal-legend", null], [4, "max-height", "px"]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegendComponent_2)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.title == null) ? null : _co.title.length) > 0); _ck(_v, 2, 0, currVal_1); var currVal_4 = _co.legendEntries; var currVal_5 = _co.trackBy; _ck(_v, 6, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.horizontal; var currVal_3 = (_co.height - 45); _ck(_v, 4, 0, currVal_2, currVal_3); }); }
export function View_LegendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-charts-legend", [], null, null, null, View_LegendComponent_0, RenderType_LegendComponent)), i1.ɵdid(1, 573440, null, 0, i5.LegendComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var LegendComponentNgFactory = i1.ɵccf("ngx-charts-legend", i5.LegendComponent, View_LegendComponent_Host_0, { data: "data", title: "title", colors: "colors", height: "height", width: "width", activeEntries: "activeEntries", horizontal: "horizontal" }, { labelClick: "labelClick", labelActivate: "labelActivate", labelDeactivate: "labelDeactivate" }, []);
export { LegendComponentNgFactory as LegendComponentNgFactory };
