/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../core/_base/layout/directives/toggle.directive";
import * as i5 from "./header-mobile.component";
import * as i6 from "../../../../core/_base/layout/services/layout-config.service";
var styles_HeaderMobileComponent = [i0.styles];
var RenderType_HeaderMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderMobileComponent, data: {} });
export { RenderType_HeaderMobileComponent as RenderType_HeaderMobileComponent };
export function View_HeaderMobileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "kt-header-mobile kt-header-mobile--fixed"], ["id", "kt_header_mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "kt-header-mobile__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "logo"]], [[1, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "kt-header-mobile__toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"], ["id", "kt_aside_mobile_toggler"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "kt-header-mobile__toolbar-toggler"], ["id", "kt_header_mobile_toggler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "kt-header-mobile__toolbar-topbar-toggler"], ["id", "kt_header_mobile_topbar_toggler"], ["ktToggle", ""]], null, null, null, null, null)), i1.ɵdid(11, 4210688, null, 0, i4.ToggleDirective, [i1.ElementRef], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "flaticon-more"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 3, 0, currVal_2); var currVal_5 = _co.toggleOptions; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.headerLogo; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.asideDisplay; _ck(_v, 6, 0, currVal_4); }); }
export function View_HeaderMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-header-mobile", [], null, null, null, View_HeaderMobileComponent_0, RenderType_HeaderMobileComponent)), i1.ɵdid(1, 114688, null, 0, i5.HeaderMobileComponent, [i6.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMobileComponentNgFactory = i1.ɵccf("kt-header-mobile", i5.HeaderMobileComponent, View_HeaderMobileComponent_Host_0, {}, {}, []);
export { HeaderMobileComponentNgFactory as HeaderMobileComponentNgFactory };
