import * as tslib_1 from "tslib";
import { BaseModel } from '../../_base/crud';
var CustomerModel = /** @class */ (function (_super) {
    tslib_1.__extends(CustomerModel, _super);
    function CustomerModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerModel.prototype.clear = function () {
        this.dob = new Date();
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.userName = '';
        this.gender = 'Female';
        this.ipAddress = '';
        this.type = 1;
        this.status = 1;
    };
    return CustomerModel;
}(BaseModel));
export { CustomerModel };
