import * as tslib_1 from "tslib";
import { forkJoin } from 'rxjs';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Services
import { CustomersService } from '../_services/';
// Actions
import { CustomerActionTypes, CustomersPageLoaded, CustomerActionToggleLoading, CustomersPageToggleLoading, CustomerCreated } from '../_actions/customer.actions';
import { of } from 'rxjs';
var CustomerEffects = /** @class */ (function () {
    function CustomerEffects(actions$, customersService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.customersService = customersService;
        this.store = store;
        this.showPageLoadingDistpatcher = new CustomersPageToggleLoading({ isLoading: true });
        this.showActionLoadingDistpatcher = new CustomerActionToggleLoading({ isLoading: true });
        this.hideActionLoadingDistpatcher = new CustomerActionToggleLoading({ isLoading: false });
        this.loadCustomersPage$ = this.actions$.pipe(ofType(CustomerActionTypes.CustomersPageRequested), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showPageLoadingDistpatcher);
            var requestToServer = _this.customersService.findCustomers(payload.page);
            var lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }), map(function (response) {
            var result = response[0];
            var lastQuery = response[1];
            var pageLoadedDispatch = new CustomersPageLoaded({
                customers: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        }));
        this.deleteCustomer$ = this.actions$
            .pipe(ofType(CustomerActionTypes.OneCustomerDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.customersService.deleteCustomer(payload.id);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.deleteCustomers$ = this.actions$
            .pipe(ofType(CustomerActionTypes.ManyCustomersDeleted), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.customersService.deleteCustomers(payload.ids);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.updateCustomer$ = this.actions$
            .pipe(ofType(CustomerActionTypes.CustomerUpdated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.customersService.updateCustomer(payload.customer);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.updateCustomersStatus$ = this.actions$
            .pipe(ofType(CustomerActionTypes.CustomersStatusUpdated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.customersService.updateStatusForCustomer(payload.customers, payload.status);
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
        this.createCustomer$ = this.actions$
            .pipe(ofType(CustomerActionTypes.CustomerOnServerCreated), mergeMap(function (_a) {
            var payload = _a.payload;
            _this.store.dispatch(_this.showActionLoadingDistpatcher);
            return _this.customersService.createCustomer(payload.customer).pipe(tap(function (res) {
                _this.store.dispatch(new CustomerCreated({ customer: res }));
            }));
        }), map(function () {
            return _this.hideActionLoadingDistpatcher;
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "loadCustomersPage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "deleteCustomer$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "deleteCustomers$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "updateCustomer$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "updateCustomersStatus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CustomerEffects.prototype, "createCustomer$", void 0);
    return CustomerEffects;
}());
export { CustomerEffects };
