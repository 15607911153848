import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import { ProjectPlansComponent } from '../project-plans/project-plans.component';

@Component({
  selector: 'kt-project-industry',
  templateUrl: './project-industry.component.html',
  styleUrls: ['./project-industry.component.scss']
})
export class ProjectIndustryComponent implements OnInit {

  constructor(
    private jadwaService: JadwaService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  selectPlan(): void {
    const dialogRef = this.dialog.open(ProjectPlansComponent, {
      width: '80%',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

}
