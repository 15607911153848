export const environment = {
	production: false,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	defaultPhoneNumberCode: '+966', // +44, +911
	defaultCountryCode: 'sa', //sa, gb, should be in lower case
	defaultCountryName: 'Saudi Arabia',
	apiURL:"https://api-dev.innvohub-testing-server.com/",
	allowedURLsForAuthorizationHeader:["https://api-dev.innvohub-testing-server.com"],
    webURL:"https://portal-dev.innvohub-testing-server.com",
	// Facebook
	facebookAppID : "141940951434140",
	facebookAppSecret : "debb15529cf1821f3f9e0b80012b8dd6",

	//Google
	clientId	:	"880593392183-6jsidf2410l9gqsrgth6u10bi9347284.apps.googleusercontent.com",
	clientSecret: "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",
	
	//pagenationCount
	itemsPerPage: 10,
};
