import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { Subscription } from 'rxjs';
import { JSONPath } from 'jsonpath-plus';
import { debounceTime, distinctUntilChanged, map, skipUntil, take } from 'rxjs/operators';
import jsonata from 'jsonata';
import * as moment from 'moment';

export function toDateParser(date: Date, index: number) {
  console.log("date",date,"index",index); 
  return date ? moment(date).format('YYYY-MM-DD') : null;
}




interface hiddenExpressionMeta {
  fieldsRequiringValidation: {
    minlength?: number;
    maxlength?: number;
    checkbox?: boolean;
    maxValue?:number;
    minValue?:number;
    valueMatch?:string|number;
  };
  fieldKey: string;
  fieldKeyArray:string;
  validationFields: Array<ValidatingFields>;
}

interface GetOtherFieldExpressionAndDoCalculation {
  listOfExpressions: [{
    expression:string,
    placeholder:string,   
  }],
  singlValueFieldAtSameLevel:string,
  singleValuePlaceHolderInCalculationExpression:string,
  multipleValueFieldAtSameLevel:Array<string>,
  multipleValuePlaceHolderInCalculationExpression:Array<string>,
  calculationExpression:string
}

enum ValidatingFields {
  minlength = 'minlength',
  checkbox = 'checkbox',
  maxlength = 'maxlength',
  maxValue="maxValue",
  minValue="minValue",
  valueMatch="valueMatch"
}

@Component({
  selector: 'kt-plan-documents',
  templateUrl: './plan-documents.component.html',
  styleUrls: ['./plan-documents.component.scss']
})
export class PlanDocumentsComponent implements OnInit {
  mode: any;
  model;
  fields;
  form = new FormGroup({});
  options: FormlyFormOptions = {};
  project : string;
  projectId: string;
  planId: string;
  currentLanguage: string  = 'en';;
  private subscriptions: Subscription[] = [];
  validationErrors : any;
  showValidationErrorListMessages:boolean= false;
  userId :string;
  constructor(

    private jadwaService: JadwaService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PlanDocumentsComponent>,
    private store: Store<AppState>) {
      
    this.projectId = data.projectId;
    this.planId = data.planId;
    this.mode = data.mode;
    this.project = data.project;


    if(localStorage.getItem("userInfo")){
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));			
      if((userInfo.tokenResponse.roles).indexOf('General') > -1){
        // this.isGeneral = true;
        this.userId = localStorage.getItem('userId')
      }
      if(
        ((userInfo.tokenResponse.roles).indexOf('BusinessAdmin') > -1) ||
        ((userInfo.tokenResponse.roles).indexOf('TechAdmin') > -1)
      ) {
        if(this.project){
          this.userId = this.project['userId'];
        }
       
        // this.router.navigate(['/project'])
        // this.isBusinessAdmin = true;
      }
      
    }

    


  }
 
  

  ngOnInit() {


   

    const sortSubscription = this.store.pipe(
      select(AuthState => AuthState)
    ).subscribe(State => {
      //@ts-ignore
      this.currentLanguage = State.auth.language;
    });
    this.subscriptions.push(sortSubscription);

    this.projectId = this.data.projectId;
    this.planId = this.data.planId;
    this.mode = this.data.mode;

    // //console.log(this.data.id);
    if (this.planId) {
      if (this.mode == 'new') {
        this.getPlanDetails();
      } else {
        this.fetchProjectPlan();
      }

    }
  }

  sortBy(mappedDocuments, prop: string) {
    return mappedDocuments.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  fetchProjectPlan() {
    this.loading = true;
    const requestObj = {
      "userId": this.userId,
      "projectId": this.projectId,
      "planId": this.planId,
      "documentId": null
    }

    this.jadwaService.fetchProjectPlan(requestObj).subscribe((response: any) => {
      this.loading = false;
      if (response.result) {
        //response.result = this.mockResult;

        let planDetails = response.result[0]; // should get single plan in this case
        if (!!planDetails && !!planDetails.mappedDocuments) {
          let resultObject = {};
          planDetails.mappedDocuments.forEach(doc => {
            if (!!doc.formData) {
              // const formDataObj = JSON.parse(doc.formData);
              // resultObject[doc.documentId] = formDataObj[doc.documentId];

              const formDataObj = JSON.parse(doc.formData);
              const documentId = doc.documentId.replaceAll('-', '_');
              resultObject[documentId] = formDataObj[doc.documentId];
            }
          });
          this.model = resultObject;
          console.log("this.model",this.model);
          // this.setPlanForm(planDetails);
          let fields = [];
          let planDocs = this.sortBy(planDetails.mappedDocuments, 'sequenceNumber');
          planDetails.mappedDocuments.forEach(doc => {

           

            let templateFormData;
            if (this.currentLanguage == 'en' || this.currentLanguage == 'en-US') {
              templateFormData = this.getUpdatedFormData(JSON.parse(doc.templateFormData));
            } else {
              templateFormData = this.getUpdatedFormData(JSON.parse(doc.arTemplateFormData));
            }

            fields.push({
              key: doc.documentId.replaceAll('-', '_'),
              templateOptions: {
                key: uuidv4().replaceAll('-', '_'),
                icon: doc.icon,
                label: doc.name,
                templateMetaData: doc.templateMetaData,
                arTemplateMetaData: doc.arTemplateMetaData,
                planDetails: planDetails,
                projectId : this.projectId,
              },
              // fieldGroup: JSON.parse(doc.templateFormData)// json parse
              // fieldGroup : this.getUpdatedFormData(JSON.parse(doc.templateFormData))
              fieldGroup: templateFormData
            })
            this.addDocumentIdToAllFields(fields[fields.length-1].fieldGroup,doc.documentId.replaceAll('-', '_'))
          });
          this.fields = [{
            type: 'tabs',
            fieldGroup: fields
          }];
          this.recursivelyCheckForFieldToUpdate(this.fields);
          this.mapHidingModelToHiddenExpressionIfAvailable(this.fields); 
          this.mapFields(this.fields);
        }

      } else {

        Swal.fire({
          title: 'Plan not Found',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        });
      }

    }, error => {
      this.loading = false;
      Swal.fire({
        title: error.error.responseException.exceptionMessage,
        // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    });
  }


  getUpdatedFormData(formData) {
    if(formData === null)
     return formData;
    formData.forEach(formObj => {
      if (formObj.templateOptions && formObj.templateOptions.options) {
        let oldpOtions = formObj.templateOptions.options.valueOf();
        if (formObj.templateOptions.options == 'currencies') {
          formObj.templateOptions.options = this.jadwaService.getCurrenciesList();
        } else if (formObj.templateOptions.options == 'products') {
          formObj.templateOptions.options = this.jadwaService.productsList();
        }
      }
    });

    return formData;
  }

  setPlanForm(planDetails){
    let fields = [];
    planDetails.mappedDocuments.forEach(doc => {
      let templateFormData;
      if (this.currentLanguage == 'en' || this.currentLanguage == 'en-US') {
        templateFormData = this.getUpdatedFormData(JSON.parse(doc.templateFormData));
      } else {
        templateFormData = this.getUpdatedFormData(JSON.parse(doc.arTemplateFormData));
      }
      

      fields.push({
        key: doc.documentId.replaceAll('-', '_'),
        templateOptions: {
          //key: doc.documentId,

          key: uuidv4().replaceAll('-', '_'),
          icon: doc.icon,
          label: doc.name,
          templateMetaData: doc.templateMetaData,
          arTemplateMetaData: doc.arTemplateMetaData,
          projectId : this.projectId,
          planDetails: planDetails
        },
        // fieldGroup: JSON.parse(doc.templateFormData)// json parse
        // fieldGroup : this.getUpdatedFormData(JSON.parse(doc.templateFormData))
        fieldGroup: templateFormData
      })
      this.addDocumentIdToAllFields(fields[fields.length-1].fieldGroup,doc.documentId.replaceAll('-', '_'))
    });
    this.fields = [{
      type: 'tabs',
      fieldGroup: fields
    }];
    this.recursivelyCheckForFieldToUpdate(this.fields);
    this.mapHidingModelToHiddenExpressionIfAvailable(this.fields);
    // //console.log(this.fields);
    this.mapFields(this.fields);
  }

  getPlanDetails() {
    this.loading = true;
    this.jadwaService.getPlanDetails(this.planId).subscribe((response: any) => {
      this.loading = false;
      // //console.log(response.result);
      if(response.result){
        //response.result = this.mockResult;
        let planDetails = response.result;
        if (!!planDetails && !!planDetails.mappedDocuments) {
          let resultObject = {};
          planDetails.mappedDocuments.forEach(doc => {
            if (!!doc.sampleTemplateData) {
              // const formDataObj = JSON.parse(doc.formData);
              // resultObject[doc.documentId] = formDataObj[doc.documentId];

              const formDataObj = JSON.parse(doc.sampleTemplateData);
              const documentId = doc.documentId.replaceAll('-', '_');
              resultObject[documentId] = formDataObj[doc.documentId];
            }
          });
          this.model = resultObject;
          console.log("this.model",this.model);
      }
        this.setPlanForm(planDetails);
      }else{
        Swal.fire({
          title: 'Somethign went wrong',
          // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        });
      }
    }, error => {
      this.loading = false;
      Swal.fire({
        title: error.error.responseException.exceptionMessage,
        // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      });
    });
  }

  loading: any;
  planDetails: any;


  mappedDocuments: any;
  submit() {

    if (this.form.valid) {
      this.validationErrors = null;
      this.showValidationErrorListMessages = false;
      this.loading = true;
      let documentDataArray = []
      //for (let key in this.form.value) {
        for (let key in this.model) {
        //@ts-ignore
        let keyOriginal=key.replaceAll('_','-');
        let tempKey = {};
        tempKey[key] = this.model[key];
        let formDataStringified=JSON.stringify(tempKey);
        //@ts-ignore
        let formDataStringifiedWithOriginalKey=formDataStringified.replaceAll(key,keyOriginal);
        documentDataArray.push({
          "documentId": keyOriginal,
          "formData": formDataStringifiedWithOriginalKey
        })
      }
      const requestObj = {
        "userId": this.userId,
        "projectId": this.projectId,
        "planId": this.planId,
        "documentData": documentDataArray
      };

      this.jadwaService.submitPlan(requestObj).subscribe((response: any) => {
        this.loading = false;
        if (response.result && response.result.validationSucced) {
          Swal.fire({
            title: 'Plan Saved',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#37c0b9',
            confirmButtonText: this.translate.instant('TITLE.OKAY'),
          }).then((result) => {
            this.dialogRef.close(true);
            // this.router.navigateByUrl('/login');
          });;
        }else{
          window.scroll(0,0);
          this.validationErrors = response.result.validationErrors;
          if(this.validationErrors && this.validationErrors.length>0)
          {
            this.showValidationErrorListMessages= true;
          }
        }
      }, error => {
      });

      // this.jadwaService.createUserProjectPlan(requestObj).subscribe((response: any) => {
      //   if (response.result) {
      //     Swal.fire({
      //       title: 'Plan Saved',
      //       icon: 'success',
      //       showCancelButton: false,
      //       confirmButtonColor: '#37c0b9',
      //       confirmButtonText: this.translate.instant('TITLE.OKAY'),
      //     }).then((result) => {
      //       this.dialogRef.close(true);
      //       // this.router.navigateByUrl('/login');
      //     });;
      //   }
      // }, error => {
      // });
    } else {
      Swal.fire({
        title: 'Please Fill Required Fields or some fields validation failed, please check for errors mentioned',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#37c0b9',
        confirmButtonText: this.translate.instant('TITLE.OKAY'),
      })
    }

  }

  closeDialog()
  {
    /*
    if(this.userChangedDataInFormAtleastOnce === false)
    {
      this.dialogRef.close(true); 
    }
    else{
      Swal.fire({
        title:this.translate.instant('TITLE.UNSAVE_DATA_SAVE'),
        // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#37c0b9',
        cancelButtonColor:"#37c0b9",
        cancelButtonText: this.translate.instant('TITLE.NO'),
        confirmButtonText: this.translate.instant('TITLE.YES'),
      }).then((result) => {
        if(result.isConfirmed)
        {
          this.submit();
        }
        else if(result.isDismissed)
        {
          this.dialogRef.close(true);  
        }
      });
    }
    */
   let callback = ()=>{
     this.dialogRef.close(true);
   }
   //@ts-ignore
   this.jadwaService.saveAsDraftIfRequiredAndCallback(true,callback);
  }

  mapFields(fields: FormlyFieldConfig[]) {
    fields.forEach((singleFieldConfig) => {
      if (singleFieldConfig.key) {
        let data = this.matchKeyToFieldModel(singleFieldConfig.key, this.model);
        if (singleFieldConfig.fieldGroup) {
          this.mapFields(singleFieldConfig.fieldGroup);
        } else {
          singleFieldConfig.defaultValue = data ? data : null;
        }
      }
    });
  }


  // getUpdatedFormData(formData){
  //   formData.forEach(formObj => {
  //     if(formObj.templateOptions && formObj.templateOptions.options){
  //       let oldpOtions = formObj.templateOptions.options.valueOf();
  //       if(formObj.templateOptions.options == 'currencies'){
  //         formObj.templateOptions.options = this.jadwaService.getCurrenciesList();
  //       }else if(formObj.templateOptions.options == 'products'){
  //         formObj.templateOptions.options = this.jadwaService.getCurrenciesList();
  //       }
        
  //       ////console.log(formObj.templateOptions);
  //     }
  //   });

  //   return formData;
  // }

  addDocumentIdToAllFields(fields: FormlyFieldConfig[]| FormlyFieldConfig,documentId:string)
  {
    if(Array.isArray(fields))
    {
      fields.forEach(field=>{
        if(field['templateOptions'])
        {
          field.templateOptions['documentId']=documentId;
        }
        else
        {
          field['templateOptions']={'documentId':documentId};
        }
        if(field.fieldGroup)
        {
          this.addDocumentIdToAllFields(field.fieldGroup,documentId);
        }
        if(field.fieldArray)
        {
          this.addDocumentIdToAllFields(field.fieldArray,documentId);
        }
      })
    }
    else
    {
      if(fields.fieldGroup)
      {
        this.addDocumentIdToAllFields(fields.fieldGroup,documentId);
      }
      else if(fields.fieldArray)
      {
        this.addDocumentIdToAllFields(fields.fieldArray,documentId);
      }
      else{ 
        if(fields['templateOptions'])
        {
          fields.templateOptions['documentId']=documentId;
        }
        else
        {
          fields['templateOptions']={'documentId':documentId};
        }
      }
    }

  }

  addHookOnInitForCalculation(field: FormlyFieldConfig) {
    let metaExpressionModel: GetOtherFieldExpressionAndDoCalculation = field['expressionToGetModelAndPerformCalculation'];
    let formValueChangesSubscription: Subscription;
    field.hooks = { onInit: () => { }, onDestroy: () => { } };
    field.hooks.onInit = (fieldFormlyFieldConfig: FormlyFieldConfig) => {
      console.log("fieldFormlyFieldConfig.key", fieldFormlyFieldConfig.key);
      let pathTillRoot: Array<string> = [];
      let parentRefFromRoot: Array<FormlyFieldConfig> = [];//in end will reverse
      parentRefFromRoot.push(fieldFormlyFieldConfig);
      if (typeof fieldFormlyFieldConfig.key === 'string') {
        //if (!fieldFormlyFieldConfig.key.match(/^[0-9]+$/)) 
        pathTillRoot.push(fieldFormlyFieldConfig.key);
      }
      let parent = fieldFormlyFieldConfig.parent;
      while (parent !== null && parent !== undefined && parent.key !== null && parent.key !== undefined) {
        parentRefFromRoot.push(parent);
        parent.key.toString();
        pathTillRoot.push(parent.key.toString());
        parent = parent.parent;
      }
      let pathFromRoot: Array<string> = pathTillRoot.reverse();
      parentRefFromRoot.reverse();
      //console.log(pathFromRoot);
      //$.phoneNumbers[:1].type
      let pathToGetData = "$";
      pathFromRoot.forEach(ele => {
        if (ele.match(/^[0-9]+$/)) {
          pathToGetData = pathToGetData + "[" + ele + "]";
        }
        else {
          pathToGetData = pathToGetData + "." + ele;
        }

      });

      let lastElementWithNonIntegerKey = '';

      pathFromRoot.forEach(ele => {
        if (!ele.match(/^[0-9]+$/)) {
          lastElementWithNonIntegerKey = ele;
        }

      });
      let singlePathDataToReplace='';
      let multipleValuesPathDataToReplace:Array<string>=[];
      let previousMultipleValuesCalculated=[];
      let currentMultipleValuesCalculated =[];
      if (metaExpressionModel.singlValueFieldAtSameLevel && metaExpressionModel.singlValueFieldAtSameLevel != '') {
        singlePathDataToReplace = pathToGetData.replace(lastElementWithNonIntegerKey, metaExpressionModel.singlValueFieldAtSameLevel);
      }
      else if(metaExpressionModel.multipleValueFieldAtSameLevel && metaExpressionModel.multipleValueFieldAtSameLevel.length >0)
      {
        metaExpressionModel.multipleValueFieldAtSameLevel.forEach(multiValueEleField=>{
          multipleValuesPathDataToReplace.push(pathToGetData.replace(lastElementWithNonIntegerKey, multiValueEleField));
          previousMultipleValuesCalculated.push(null);
          currentMultipleValuesCalculated.push(null);
        })

      }
      
      formValueChangesSubscription = this.form.valueChanges.pipe(debounceTime(100), distinctUntilChanged((prev, curr) => {
        if (metaExpressionModel.singlValueFieldAtSameLevel && metaExpressionModel.singlValueFieldAtSameLevel != '') {
          //@ts-ignore
          let indexToCheck = fieldFormlyFieldConfig.key.toString().match(/^[0-9]+$/) ? parseInt(fieldFormlyFieldConfig.key) : 0;
          console.log("singlePathDataToReplace,prev,JSONPath");
          console.log(singlePathDataToReplace, prev,
            JSONPath(
              singlePathDataToReplace,
              prev,
              () => { },
              () => { }
            ));

          console.log("singlePathDataToReplace,curr,JSONPath");
          console.log(singlePathDataToReplace, curr,
            JSONPath(
              singlePathDataToReplace,
              curr,
              () => { },
              () => { }
            ));
          let prevWatchValue = JSONPath(
            singlePathDataToReplace,
            prev,
            () => { },
            () => { }
          )[indexToCheck];
          let currWatchValue = JSONPath(
            singlePathDataToReplace,
            curr,
            () => { },
            () => { }
          )[indexToCheck];
          return prevWatchValue === currWatchValue;
        }
        else if(metaExpressionModel.multipleValueFieldAtSameLevel && metaExpressionModel.multipleValueFieldAtSameLevel.length >0)
        {
          previousMultipleValuesCalculated =[];
          currentMultipleValuesCalculated =[];
          metaExpressionModel.multipleValueFieldAtSameLevel.forEach((multipleValFieldAtSL,index)=>{
              //@ts-ignore
            let indexToCheck = fieldFormlyFieldConfig.key.toString().match(/^[0-9]+$/) ? parseInt(fieldFormlyFieldConfig.key) : 0;
            console.log("multipleValuesPathDataToReplace[index],index,prev,JSONPath");
            console.log(multipleValuesPathDataToReplace[index],index, prev,
            JSONPath(
              multipleValuesPathDataToReplace[index],
              prev,
              () => { },
              () => { }
            ));
            console.log("multipleValuesPathDataToReplace[index],index,curr,JSONPath");
            console.log(multipleValuesPathDataToReplace[index],index, curr,
            JSONPath(
              multipleValuesPathDataToReplace[index],
              curr,
              () => { },
              () => { }
            ));
            let prevWatchValue = JSONPath(
              multipleValuesPathDataToReplace[index],
              prev,
              () => { },
              () => { }
              )[indexToCheck];
              previousMultipleValuesCalculated.push(prevWatchValue);
              let currWatchValue = JSONPath(
                multipleValuesPathDataToReplace[index],
              curr,
              () => { },
              () => { }
              )[indexToCheck];
              currentMultipleValuesCalculated.push(currWatchValue);
          })

          let allPrevAndCurrSame=true;
          for(let index=0;index<previousMultipleValuesCalculated.length;index++)
          {
            if(previousMultipleValuesCalculated[index] !== currentMultipleValuesCalculated[index])
            {
              allPrevAndCurrSame = false;
              break;
            }

          }
          return allPrevAndCurrSame;
        }
      }
      )).subscribe(formData => {
        //console.log(metaExpressionModel);
        //console.log(pathFromRoot);
        if (metaExpressionModel.singlValueFieldAtSameLevel) {
          //@ts-ignore
          let indexToCheck = fieldFormlyFieldConfig.key.toString().match(/^[0-9]+$/) ? parseInt(fieldFormlyFieldConfig.key) : 0;
          let finalExpressionWithValuesToEvaluate = metaExpressionModel.calculationExpression.replace(metaExpressionModel.singleValuePlaceHolderInCalculationExpression, JSONPath(
            singlePathDataToReplace,
            formData,
            () => { },
            () => { }
          )[indexToCheck]);

          //console.log(this.model);                   
          fieldFormlyFieldConfig.formControl.setValue(eval(finalExpressionWithValuesToEvaluate));
        }
        else if(metaExpressionModel.multipleValueFieldAtSameLevel && metaExpressionModel.multipleValueFieldAtSameLevel.length >0)
        {
          let finalExpressionWithValuesToEvaluate = metaExpressionModel.calculationExpression;
          //@ts-ignore
          let indexToCheck = fieldFormlyFieldConfig.key.toString().match(/^[0-9]+$/) ? parseInt(fieldFormlyFieldConfig.key) : 0;
          let fieldExtractedMultipleValuesFromJSON=[];
          metaExpressionModel.multipleValuePlaceHolderInCalculationExpression.forEach((placeholderEle,index)=>{
            fieldExtractedMultipleValuesFromJSON.push(JSONPath(
              multipleValuesPathDataToReplace[index],
              formData,
              () => { },
              () => { }
            )[indexToCheck]);
            if(parseFloat(fieldExtractedMultipleValuesFromJSON[fieldExtractedMultipleValuesFromJSON.length-1])===NaN)
            {
              fieldExtractedMultipleValuesFromJSON[fieldExtractedMultipleValuesFromJSON.length-1] = 0;//make it 0 if its null or udnefined or [Not a number =>NaN]
            }
            while(finalExpressionWithValuesToEvaluate.includes(placeholderEle))//replacing all values
             finalExpressionWithValuesToEvaluate = finalExpressionWithValuesToEvaluate.replace(placeholderEle,fieldExtractedMultipleValuesFromJSON[fieldExtractedMultipleValuesFromJSON.length-1]);
          });
          

          //console.log(this.model);                   
          fieldFormlyFieldConfig.formControl.setValue(eval(finalExpressionWithValuesToEvaluate));

        }
        else {
          let getAllValuesForAllListOfExpressions: Array<Array<any>> = [];
          metaExpressionModel.listOfExpressions.forEach(expressionData => {
            getAllValuesForAllListOfExpressions.push(JSONPath(
              expressionData.expression,
              formData,
              () => { },
              () => { }
            ));
          });
          let finalExpressionWithValuesToEvaluate = metaExpressionModel.calculationExpression;
          metaExpressionModel.listOfExpressions.forEach((expressionData, index) => {
            finalExpressionWithValuesToEvaluate = finalExpressionWithValuesToEvaluate.replace(expressionData.placeholder, getAllValuesForAllListOfExpressions[index][0]);
          });
          fieldFormlyFieldConfig.formControl.setValue(eval(finalExpressionWithValuesToEvaluate));

        }

      })
      field.hooks.onDestroy = (fieldFormlyFieldConfig: FormlyFieldConfig) => {
        formValueChangesSubscription.closed ? "" : formValueChangesSubscription.unsubscribe();
      }

    };
  }

/*
  addHookOnInitForGettingOtherFieldValues(field: FormlyFieldConfig) {
    
    let formValueChangesSubscription: Subscription;
    field.hooks = { onInit: () => { }, onDestroy: () => { } };
    field.hooks.onInit = (fieldFormlyFieldConfig: FormlyFieldConfig) => {
      let evaluateExpression =jsonata(field.templateOptions.optionsRefer.path);
      formValueChangesSubscription = this.form.valueChanges.pipe(map(formValue=>{
        console.log(field.templateOptions.optionsRefer.path);
        console.log(evaluateExpression.evaluate(formValue));
        return evaluateExpression.evaluate(formValue).map(ele=>{return {label:ele,value:ele}}); 
        
      })) 
    
    }
    field.hooks.onDestroy = (fieldFormlyFieldConfig: FormlyFieldConfig) => {
      formValueChangesSubscription.closed ? "" : formValueChangesSubscription.unsubscribe();
    }
  }

 */

  recursivelyCheckForFieldToUpdate(fields: FormlyFieldConfig[]| FormlyFieldConfig)
  {
    if(Array.isArray(fields))
    {
      fields.forEach(field=>{
        if(field['expressionToGetModelAndPerformCalculation'])
        {
          this.addHookOnInitForCalculation(field);
        }
        if(field.type === "select" && field.templateOptions && field.templateOptions.optionsRefer && field.templateOptions.optionsRefer.path)
        {
          console.log(field);
          let evaluateExpression;
          //this.addHookOnInitForGettingOtherFieldValues(field);
          try
          {
            field.templateOptions.optionsRefer.path= "'"+field.templateOptions.optionsRefer.path.substr(0,field.templateOptions.optionsRefer.path.indexOf("."))+"'"+field.templateOptions.optionsRefer.path.substr(field.templateOptions.optionsRefer.path.indexOf("."),field.templateOptions.optionsRefer.path.length);
            field.templateOptions.optionsRefer.path = field.templateOptions.optionsRefer.path+".expense_Category";
            evaluateExpression =jsonata(field.templateOptions.optionsRefer.path);

          }
          catch(e)
          {
            console.log("ec1 ",e);
          }
          
          field.templateOptions.options=this.form.valueChanges.pipe(map(formValue=>{
            try
            {
              console.log(field.templateOptions.optionsRefer.path);
              console.log(evaluateExpression.evaluate(formValue));
              return evaluateExpression.evaluate(formValue).map(ele=>{return {label:ele,value:ele}}); 
            }
            catch(e)
            {
              console.log("ec2", e);
            }
            
            
          })) 
          
          setTimeout(()=>{
            this.form.updateValueAndValidity();
          },1)
          console.log(field.templateOptions);
        }
        if(field.type && field.type=="datepicker")
        {
          //@ts-ignore
          field.parsers=[toDateParser]; 
        }
        if(field.fieldGroup)
        {
          this.recursivelyCheckForFieldToUpdate(field.fieldGroup);
        }
        if(field.fieldArray)
        {
          this.recursivelyCheckForFieldToUpdate(field.fieldArray);
        }
      })
    }
    else
    {
      if(fields.fieldGroup)
      {
        this.recursivelyCheckForFieldToUpdate(fields.fieldGroup);
      }
      else if(fields.fieldArray)
      {
        this.recursivelyCheckForFieldToUpdate(fields.fieldArray);
      }
      else
      {

        if(fields['expressionToGetModelAndPerformCalculation'])
        {
          this.addHookOnInitForCalculation(fields);
        
        }
        if(fields.type === "select" && fields.templateOptions && fields.templateOptions.optionsRefer && fields.templateOptions.optionsRefer.path)
        {
          console.log(fields);
          let evaluateExpression;
          //this.addHookOnInitForGettingOtherFieldValues(field);
          try
          {
            fields.templateOptions.optionsRefer.path= "'"+fields.templateOptions.optionsRefer.path.substr(0,fields.templateOptions.optionsRefer.path.indexOf("."))+"'"+fields.templateOptions.optionsRefer.path.substr(fields.templateOptions.optionsRefer.path.indexOf("."),fields.templateOptions.optionsRefer.path.length);
            fields.templateOptions.optionsRefer.path = fields.templateOptions.optionsRefer.path+".expense_Category";
            evaluateExpression =jsonata(fields.templateOptions.optionsRefer.path);

          }
          catch(e)
          {
            console.log("ec1 ",e);
          }
          
          fields.templateOptions.options=this.form.valueChanges.pipe(map(formValue=>{
            try
            {
              console.log(fields.templateOptions.optionsRefer.path);
              console.log(evaluateExpression.evaluate(formValue));
              return evaluateExpression.evaluate(formValue).map(ele=>{return {label:ele,value:ele}}); 
            }
            catch(e)
            {
              console.log("ec2", e);
            }
            
            
          })) 
          
          setTimeout(()=>{
            this.form.updateValueAndValidity();
          },1)
          console.log(fields.templateOptions);

        }
        if(fields.type && fields.type=="datepicker")
        {
          //@ts-ignore
          field.parsers=[toDateParser]; 
        }
        
      }
    }

  }

  mapHidingModelToHiddenExpressionIfAvailable(fields: FormlyFieldConfig[]| FormlyFieldConfig)
  {
    if(Array.isArray(fields))
    {
      fields.forEach(field=>{
        if(field['hideExpressionModel'])
        {
          let metaExpressionModel:Array<hiddenExpressionMeta>= field['hideExpressionModel'];
          field.hideExpression = this.createHiddenExpression.call(this,metaExpressionModel);
        }
        if(field.fieldGroup)
        {
          this.mapHidingModelToHiddenExpressionIfAvailable(field.fieldGroup);
        }
        if(field.fieldArray)
        {
          this.mapHidingModelToHiddenExpressionIfAvailable(field.fieldArray);
        }
      })
    }
    else
    {
      if(fields.fieldGroup)
      {
        this.mapHidingModelToHiddenExpressionIfAvailable(fields.fieldGroup);
      }
      if(fields.fieldArray)
      {
        this.mapHidingModelToHiddenExpressionIfAvailable(fields.fieldArray);
      }
    }

  }

  getDataFromModel(modelObject,path:string):any
  {
    let keys=path.split(".");
    let dataTobeReturned="";
    for(let index=0;index<keys.length;index++)
    {
      let singleKeyPath=keys[index];
      let arrayPath=[];
      let isArray:boolean=false;
      if(singleKeyPath.includes("["))//array type
      {
        /*
        "loop[79999]".split(/\[|\]/)
        Array(3) [ "loop", "79999", "" ]
         */
        arrayPath=singleKeyPath.split(/\[|\]/);
        isArray=true;
      }
      if(index ==0)
      {
        if(isArray)
        {
          dataTobeReturned=modelObject[arrayPath[0]][arrayPath[1]];
        }
        else
        {
          dataTobeReturned=modelObject[singleKeyPath];
        }
      }
      else
      {
        if(isArray)
        {
          dataTobeReturned=dataTobeReturned[arrayPath[0]][arrayPath[1]];
        }
        else
        {
           dataTobeReturned = dataTobeReturned[singleKeyPath];
        }
      }
    }
    return dataTobeReturned;

  }
  createHiddenExpression(
    hideExpression:Array<hiddenExpressionMeta>
  ): (model: any, formState: any, field?: FormlyFieldConfig) => boolean {
    let self=this;
    let print10ktime=0;
    let hideExpressionClosure: Array<hiddenExpressionMeta> = hideExpression;
    return (model, formState, field?: FormlyFieldConfig) => {
      if (print10ktime % 5000 == 0) {
        //console.log('**************model,formState,field',model,field);
        ////console.log(self.form.value);
      }
      print10ktime++;
      try {
        let hideField: boolean = true;
        for (let index = 0; index < hideExpressionClosure.length; index++) {
          let modelValue;
          if(hideExpressionClosure[index].fieldKeyArray)
          {
            modelValue = self.getDataFromModel(model,hideExpressionClosure[index].fieldKeyArray);
          }
          if(hideExpressionClosure[index].fieldKey)
          {
            modelValue = self.getDataFromModel(self.form.value[field.templateOptions['documentId']],hideExpressionClosure[index].fieldKey);
          }
          for (
            let validatingIndex = 0;
            validatingIndex <
            hideExpressionClosure[index].validationFields.length;
            validatingIndex++
          ) {
            if (
              hideExpressionClosure[index].validationFields[validatingIndex] ===
              ValidatingFields.minlength
            ) {
              if (
                !(
                  modelValue.length >=
                  hideExpressionClosure[index].fieldsRequiringValidation
                    .minlength
                )
              ) {
                hideField = false;
                if (print10ktime % 5000 == 0) {
                  ////console.log('hideField minlength');
                }
                // break;
              }
            }

            if (
              hideExpressionClosure[index].validationFields[validatingIndex] ===
              ValidatingFields.maxlength
            ) {
              if (
                !(
                  modelValue.length <=
                  hideExpressionClosure[index].fieldsRequiringValidation
                    .maxlength
                )
              ) {
                if (print10ktime % 5000 == 0) {
                  ////console.log('hideField maxLength');
                }
                hideField = false;
                // break;
              }
            }

            //////console.log('hjjhjjh', hideExpressionClosure);

            if (
              hideExpressionClosure[index].validationFields[validatingIndex] ===
              ValidatingFields.checkbox
            ) {
              // ////console.log(
              //   'asa',
              //   this.model,
              //   hideExpressionClosure[index].fieldKey,
              //   this.model[hideExpressionClosure[index].fieldKey]
              // );
              // ////console.log(
              //   'asdsa',
              //   this.model[hideExpressionClosure[index].fieldKey] ===
              //     hideExpressionClosure[index].fieldsRequiringValidation
              //       .checkbox
              // );
              if (
                modelValue ===
                hideExpressionClosure[index].fieldsRequiringValidation.checkbox
              ) {
                  //console.log('hideField checkbox',model,field);

                if (print10ktime % 5000 == 0) {
                  ////console.log('hideField checkbox');
                }
                hideField = false;
                // break;
              }
            }
            if (
              hideExpressionClosure[index].validationFields[validatingIndex] ===
              ValidatingFields.minValue
            ) {
              if (
                !(
                  parseInt(modelValue) <=
                  hideExpressionClosure[index].fieldsRequiringValidation
                    .minValue
                )
              ) {
                if (print10ktime % 5000 == 0) {
                  ////console.log('hideField minValue');
                }
                hideField = false;
                // break;
              }
            }
            if (
              hideExpressionClosure[index].validationFields[validatingIndex] ===
              ValidatingFields.valueMatch
            ) {
              if (
                !(
                  modelValue ==
                  hideExpressionClosure[index].fieldsRequiringValidation
                    .valueMatch
                )
              ) {
                if (print10ktime % 5000 == 0) {
                  ////console.log('hideField valueMatch');
                }
                hideField = false;
                // break;
              }
            }
          }
          if (hideField) {
            //break;
          }
        }
        // ////console.log('dffdf', model, formState, hideField);
        return hideField;
      } catch (e) {
        return false;
      }
    };
  }

  matchKeyToFieldModel(key, modelData) {
    ////console.log(key, 'key');
    ////console.log(modelData, 'modelData');
    let keyPath = '';
    for (let k in modelData) {
      ////console.log(k, 'k');
      ////console.log(modelData, 'modelData');
      if (k === key) {
        ////console.log('if', modelData[k]);
        ////console.log(modelData, 'modelData');
        return modelData[k];
      } else {
        ////console.log('else if', modelData[k]);
        ////console.log(modelData, 'modelData');
        if (typeof modelData[k] === 'object') {
          let data = this.matchKeyToFieldModel(key, modelData[k]);
          ////console.log('data', data);
          if (data) return data;
        }
      }
    }
  }
}



