<spinner *ngIf="loading"></spinner>

<div class="p-0">
    <div>
        <div class="">
            <div class="close-alert-icons custom-position">
                <div>
                    <span class="material-icons">keyboard_arrow_left</span>
                </div>
                <div>
                    <span class="material-icons" (click)="closeDialog()"
                        type="button">close</span>
                </div>
            </div>
            <div class="angular-editor-userform-styles prevent-label-focus">

                <!-- <div *ngIf="validationErrors">
                    Please Fix the below Errors :<br />
                    <div *ngFor="let error of validationErrors">
                        {{error.documentName}} - {{error.error}}
                       
                    </div>
                </div> -->
                <div *ngIf="showValidationErrorListMessages">
                    <div class="container-fluid position-relative z-index-1 mt-3 mx-3 px-5">
                        <div class="row px-5">
                            <div class="col-md-12">
                                <div class="card border-30">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between"> 
                                        <h4 class="mb-5">{{'VALIDATION.PLEASE_FIX_THE_BELOW_ERRORS' | translate}}:</h4>
                                        <h3 (click)="showValidationErrorListMessages=!showValidationErrorListMessages"><i class="fas fa-times-circle fa-1x text-red"></i></h3>
                                        </div>
                                        <ul type="none" class="d-flex flex-wrap p-0 m-0">
                                        <li *ngFor="let error of validationErrors" class="pr-4 small mb-5">
                                          <a class="kt-alert-danger">  <strong> {{error.documentName}}</strong> - {{error.error}} <i class="fas fa-times text-error pl-2"></i>
                                          </a>  </li>
                                        <!-- {{error.errorInArabic}} -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- {{model | json }} -->
                
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
                </form>

                
            </div>

        </div>

    
        <!-- <div class="abstract-design bottom right"><img src="../../../assets/media/bg/abstract-design.png" /></div>
        <div  class="abstract-design top right"><img src="../../../assets/media/bg/polygon-borders-1.png" /></div>
        <div  class="abstract-design left top"><img src="../../../assets/media/bg/polygon-borders-left-1.png" /></div> -->
    </div>

</div>