/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./footer.component";
import * as i6 from "../../../core/_base/layout/services/layout-config.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "kt-footer kt-footer--extended kt-grid__item"], ["id", "kt_footer"], ["style", "background-image: url('./assets/media/bg/bg-2.jpg');"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "kt-footer__bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "kt-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "kt-container--fluid": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "kt-footer__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "kt-footer__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "kt-header__brand-logo"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", "Logo"], ["class", "kt-header__brand-logo-sticky"], ["src", "./assets/media/logos/logo-4-sm.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "kt-footer__copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", "\u00A0\u00A9\u00A0 "])), i1.ɵppd(14, 2), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["href", "https://jadwa.net/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "kt-footer__menu"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "kt-container"; var currVal_1 = _ck(_v, 6, 0, _co.fluid); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_4 = "/"; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 10).target; var currVal_3 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), _co.today, "yyyy")); _ck(_v, 13, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("TITLE.JADWA")); _ck(_v, 16, 0, currVal_6); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i5.FooterComponent, [i6.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("kt-footer", i5.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
