/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./portlet-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./portlet-header.component";
import * as i4 from "../../../../../core/_base/layout/services/kt-dialog.service";
var styles_PortletHeaderComponent = [i0.styles];
var RenderType_PortletHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PortletHeaderComponent, data: {} });
export { RenderType_PortletHeaderComponent as RenderType_PortletHeaderComponent };
function View_PortletHeaderComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PortletHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PortletHeaderComponent_3(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 1), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PortletHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h3", [["class", "kt-portlet__head-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
export function View_PortletHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { refIcon: 0 }), i1.ɵqud(402653184, 2, { refTools: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "kt-portlet__head-label"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["refIcon", 1]], null, 4, "span", [["class", "kt-portlet__head-icon"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PortletHeaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PortletHeaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PortletHeaderComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PortletHeaderComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, [[2, 0], ["refTools", 1]], null, 1, "div", [["class", "kt-portlet__head-toolbar"]], [[8, "hidden", 0]], null, null, null, null)), i1.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.icon; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.icon; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.title; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.title; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.hideIcon || !_co.icon); _ck(_v, 3, 0, currVal_1); var currVal_6 = _co.hideTools; _ck(_v, 12, 0, currVal_6); }); }
export function View_PortletHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-portlet-header", [], [[8, "className", 0], [1, "ktSticky", 0]], [["window", "resize"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_PortletHeaderComponent_0, RenderType_PortletHeaderComponent)), i1.ɵdid(1, 4440064, null, 0, i3.PortletHeaderComponent, [i1.ElementRef, i1.PLATFORM_ID, i4.KtDialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).stickyDirective; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var PortletHeaderComponentNgFactory = i1.ɵccf("kt-portlet-header", i3.PortletHeaderComponent, View_PortletHeaderComponent_Host_0, { class: "class", title: "title", icon: "icon", noTitle: "noTitle", sticky: "sticky", viewLoading$: "viewLoading$" }, {}, ["[ktPortletIcon]", "[ktPortletTitle]", "[ktPortletTools]"]);
export { PortletHeaderComponentNgFactory as PortletHeaderComponentNgFactory };
