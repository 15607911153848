<div class="project-industry-popup">
    <div class="row m-0">
        <!-- <div class="col-md-3 plan-left-bg p-0">
          <div class="categorysearch">
            <input type="text" placeholder="Search By Category" class="form-control border-0" />
          </div>
          <div class="category-list">
            <ul>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
              <li><a href="">Category 1</a></li>
            </ul>
          </div>
        </div> -->
        <div class="col-md-12 py-4 plan-right-bg">
            <div class="row m-0 mb-4 business-templates-block">
                <div class="col-md-3">
                  <div class="card overly-body">
                      <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                      <div class="card-body">
                          <h5 class="card-title">{{'TITLE.PLAN' | translate}} 1</h5>
                          <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                          <div>
                              <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                              <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card overly-body">
                      <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                      <div class="card-body">
                        <h5 class="card-title">{{'TITLE.PLAN' | translate}} 2</h5>
                        <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                        <div>
                            <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                            <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card overly-body">
                      <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                      <div class="card-body">
                        <h5 class="card-title">{{'TITLE.PLAN' | translate}} 1</h5>
                        <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                        <div>
                            <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                            <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card overly-body">
                      <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                      <div class="card-body">
                        <h5 class="card-title">{{'TITLE.PLAN' | translate}} 1</h5>
                        <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                        <div>
                            <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                            <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                        </div>
                      </div>
                  </div>
                </div>
              <div class="col-md-3">
                <div class="card overly-body">
                    <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                    <div class="card-body">
                        <h5 class="card-title">{{'TITLE.PLAN' | translate}} 1</h5>
                          <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                          <div>
                            <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                            <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card overly-body">
                    <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                    <div class="card-body">
                        <h5 class="card-title">{{'TITLE.PLAN' | translate}} 1</h5>
                        <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                        <div>
                            <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                            <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card overly-body">
                    <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                    <div class="card-body">
                        <h5 class="card-title">{{'TITLE.PLAN' | translate}} 1</h5>
                          <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                          <div>
                            <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                            <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card overly-body">
                    <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                    <div class="card-body">
                        <h5 class="card-title">{{'TITLE.PLAN' | translate}} 1</h5>
                          <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p>
                          <div>
                            <a class="btn btn-primary btn-active-primary mr-2">{{'TITLE.USE' | translate}}</a>
                            <a href="#" class="btn btn-primary btn-active-primary">{{'TITLE.PREVIEW' | translate}}</a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>