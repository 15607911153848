import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-load-json',
  templateUrl: './load-json.component.html',
  styleUrls: ['./load-json.component.css'],
})
export class LoadJsonComponent implements OnInit {
  model;
  fields;
  form = new FormGroup({});
  options: FormlyFormOptions = {};
  constructor() {
    // this.model = JSON.parse(sessionStorage.getItem('model'));
    // this.fields = JSON.parse(sessionStorage.getItem('formConfig'));
  }

  mapFields(fields: FormlyFieldConfig[]) {
    fields.forEach((singleFieldConfig) => {
      if (singleFieldConfig.key) {
        let data = this.matchKeyToFieldModel(singleFieldConfig.key, this.model);
        if (singleFieldConfig.fieldGroup) {
          this.mapFields(singleFieldConfig.fieldGroup);
        } else {
          singleFieldConfig.defaultValue = data ? data : null;
        }
      }
    });
  }
  matchKeyToFieldModel(key, modelData) {
    console.log(key, 'key');
    console.log(modelData, 'modelData');
    let keyPath = '';
    for (let k in modelData) {
      console.log(k, 'k');
      console.log(modelData, 'modelData');
      if (k === key) {
        console.log('if', modelData[k]);
        console.log(modelData, 'modelData');
        return modelData[k];
      } else {
        console.log('else if', modelData[k]);
        console.log(modelData, 'modelData');
        if (typeof modelData[k] === 'object') {
          let data = this.matchKeyToFieldModel(key, modelData[k]);
          console.log('data', data);
          if (data) return data;
        }
      }
    }
  }
  showForm: boolean = false;
  readForm() {
    this.showForm = true;
    this.fields = JSON.parse(sessionStorage.getItem('formConfig'));
  }

  readResponse() {
    this.model = JSON.parse(sessionStorage.getItem('model'));
    this.mapFields(this.fields);
  }

  ngOnInit() {}

  submit(){
    
  }
}
