/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./user-management.component";
import * as i3 from "@ngrx/store";
var styles_UserManagementComponent = [];
var RenderType_UserManagementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserManagementComponent, data: {} });
export { RenderType_UserManagementComponent as RenderType_UserManagementComponent };
export function View_UserManagementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_UserManagementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-user-management", [], null, null, null, View_UserManagementComponent_0, RenderType_UserManagementComponent)), i0.ɵdid(1, 114688, null, 0, i2.UserManagementComponent, [i3.Store, i1.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserManagementComponentNgFactory = i0.ɵccf("kt-user-management", i2.UserManagementComponent, View_UserManagementComponent_Host_0, {}, {}, []);
export { UserManagementComponentNgFactory as UserManagementComponentNgFactory };
