import * as tslib_1 from "tslib";
// RxJS
import { mergeMap, map } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
// Services
import { AuthService } from '../_services';
// Actions
import { AllPermissionsLoaded, AllPermissionsRequested, PermissionActionTypes } from '../_actions/permission.actions';
var PermissionEffects = /** @class */ (function () {
    function PermissionEffects(actions$, auth) {
        var _this = this;
        this.actions$ = actions$;
        this.auth = auth;
        this.loadAllPermissions$ = this.actions$
            .pipe(ofType(PermissionActionTypes.AllPermissionsRequested), mergeMap(function () { return _this.auth.getAllPermissions(); }), map(function (result) {
            return new AllPermissionsLoaded({
                permissions: result
            });
        }));
        this.init$ = defer(function () {
            return of(new AllPermissionsRequested());
        });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PermissionEffects.prototype, "loadAllPermissions$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], PermissionEffects.prototype, "init$", void 0);
    return PermissionEffects;
}());
export { PermissionEffects };
