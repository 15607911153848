/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./orders-list.component";
var styles_OrdersListComponent = [];
var RenderType_OrdersListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OrdersListComponent, data: {} });
export { RenderType_OrdersListComponent as RenderType_OrdersListComponent };
export function View_OrdersListComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Orders:"])), (_l()(), i0.ɵted(-1, null, [" - Coming soon in the following update.\n"]))], null, null); }
export function View_OrdersListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-orders-list", [], null, null, null, View_OrdersListComponent_0, RenderType_OrdersListComponent)), i0.ɵdid(1, 49152, null, 0, i1.OrdersListComponent, [], null, null)], null, null); }
var OrdersListComponentNgFactory = i0.ɵccf("kt-orders-list", i1.OrdersListComponent, View_OrdersListComponent_Host_0, {}, {}, []);
export { OrdersListComponentNgFactory as OrdersListComponentNgFactory };
