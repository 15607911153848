/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./portlet.component";
import * as i3 from "@ngx-loading-bar/core";
import * as i4 from "../../../../../core/_base/layout/services/layout-config.service";
var styles_PortletComponent = [];
var RenderType_PortletComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PortletComponent, data: {} });
export { RenderType_PortletComponent as RenderType_PortletComponent };
export function View_PortletComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { portlet: 0 }), i0.ɵqud(402653184, 2, { header: 0 }), i0.ɵqud(402653184, 3, { body: 0 }), i0.ɵqud(402653184, 4, { footer: 0 }), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["portlet", 1]], null, 3, "div", [["class", "kt-portlet"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "kt-portlet"; var currVal_1 = _co.class; _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_PortletComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-portlet", [], null, null, null, View_PortletComponent_0, RenderType_PortletComponent)), i0.ɵdid(1, 4308992, null, 0, i2.PortletComponent, [i0.ElementRef, i3.LoadingBarService, i4.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PortletComponentNgFactory = i0.ɵccf("kt-portlet", i2.PortletComponent, View_PortletComponent_Host_0, { loading$: "loading$", options: "options", class: "class" }, {}, ["*"]);
export { PortletComponentNgFactory as PortletComponentNgFactory };
