import { Routes } from '@angular/router';
// Components
import { UserManagementComponent } from './user-management.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
var routes = [
    {
        path: '',
        component: UserManagementComponent,
        children: [
            {
                path: '',
                redirectTo: 'roles',
                pathMatch: 'full'
            },
            {
                path: 'roles',
                component: RolesListComponent
            },
            {
                path: 'users',
                component: UsersListComponent
            },
            {
                path: 'users:id',
                component: UsersListComponent
            },
            {
                path: 'users/add',
                component: UserEditComponent
            },
            {
                path: 'users/add:id',
                component: UserEditComponent
            },
            {
                path: 'users/edit',
                component: UserEditComponent
            },
            {
                path: 'users/edit/:id',
                component: UserEditComponent
            },
        ]
    }
];
var ɵ0 = {
    hasBackdrop: true,
    panelClass: 'kt-mat-dialog-container__wrapper',
    height: 'auto',
    width: '900px'
};
var UserManagementModule = /** @class */ (function () {
    function UserManagementModule() {
    }
    return UserManagementModule;
}());
export { UserManagementModule };
export { ɵ0 };
