<div class="col-12 text-center mb-5">
    <div class="btn btn-file btn-primary mx-auto" (click)="openFileInputReader()">
        Please click here to add An Image File 
    </div>
</div>

<input [id]="inputId" type="file" hidden [formControl]="formControl" [formlyAttributes]="field" (change)="fileAdded($event)">


<div class="col-12 text-center"  *ngIf="imageShow">
    <img [src]="imageSrc" class="img-fluid">
</div>