<spinner *ngIf="loading" [showMessage]="showMessageInSpinner" [message]="spinnerMessage" [showProgressBar]="showSpinnerProgressBar" [progressPercentage]="spinnerProgressPercentage"></spinner>
<div class="toolbar pt-5 pb-2 py-lg-15 mt-5" id="kt_toolbar">
  <!--begin::Container-->
  <div id="kt_toolbar_container" class="container-xl d-flex flex-stack flex-wrap mt-3 report-rtl">
    <!--begin::Page title-->
    <div class="page-title d-flex flex-column marginback">
      <!--begin::Title-->

      <h1 class="d-flex  fw-bolder my-1 fs-3" *ngIf="currentLanguage == 'en'"> Report Name : {{report.name }}</h1>
      <h1 class="d-flex  fw-bolder my-1 fs-3" *ngIf="currentLanguage != 'en'"> Report Name : {{report.arName }}</h1>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1 bg-transparent p-0">
        <!--begin::Item-->
        <li class="breadcrumb-item  opacity-75">
          <a routerLink="/" class="text-hover-primary">{{'TITLE.HOME' | translate}}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-white opacity-75 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item text-white opacity-75">{{'TITLE.DATA_BANK_TITLE' | translate}}</li> -->
        <!--end::Item-->
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Page title-->
    <!--begin::Actions-->
    <div class="d-flex align-items-center py-3 py-md-1 custom-fieds">


      <!--begin::Button-->
      <!-- <button class="btn  btn-active-color-primary mx-3 text-white" (click)="downloadPDF()"><i class="far fa-file-pdf text-white"></i> Download PDF</button> -->
      <button class="btn btn-primary" *ngIf="plan?.isPaid ==true && plan?.paidforReport == true"
        (click)="downloadPDFNewFormat()"><i class="far fa-file-pdf text-white"></i> {{'TITLE.DOWNLOAD_PDF' |
        translate}}</button>
      <button class="btn btn-primary" *ngIf="plan?.isPaid ==false" (click)="downloadPDFNewFormat()"><i
          class="far fa-file-pdf text-white"></i> {{'TITLE.DOWNLOAD_PDF' | translate}}</button>
      <button class="btn btn-primary" *ngIf="plan?.isPaid ==true && plan?.paidforReport == false"
        (click)="payForReport(plan);$event.stopImmediatePropagation();"><i class="far fa-file-pdf text-white"></i> Pay
        {{plan.amount}} SAR To Access Report</button>


      <!--end::Button-->

    </div>
    <!--end::Actions-->
  </div>
  <!--end::Container-->
</div>

<div id="kt_content_container" class="d-block flex-column-fluid align-items-start container-xxl p-0">
  <!--begin::Post-->
  <div class="content flex-row-fluid mx-5 " id="kt_content">
    <!--begin::Card  class="card"-->
    <div id="pdfsection">
      <!--begin::Card body    class="card-body"-->





      <div class="card-body "  [hidden]="toggleHideGraphs">
        <!--*ngIf="false"-->
        <div *ngFor="let rObj of reportObject">
          <div *ngIf="!!rObj.hasPlaceholder" class="row">
            <div *ngFor="let csObj of rObj?.computedSectionData" class="col-12">
              <!-- heading section  -->
              <!-- <div class="row">
                <div *ngIf="csObj?.placeholderType == 'Heading'" class="col-12">
                  <h1 class="heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
                    csObj.arabicPlaceholderValue }}</h1>
                </div>
              </div> -->

              <!-- text section  -->
              <!-- <div class="row">
                <div *ngIf="csObj?.placeholderType == 'Text'" class="col-12 mb-3 sub-heading-style">
                  <span class="sub-heading-style">{{ (currentLanguage == 'en') ? csObj.placeholderValue :
                    csObj.arabicPlaceholderValue }}</span>
                </div>
              </div> -->


              <!-- HtmlText section -->
              <!-- <div *ngIf="csObj?.placeholderType == 'HtmlText'" class="mb-3">
                <div id="custom-table-style"
                  [innerHTML]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue">
                </div>
              </div> -->

              <!-- HtmlTable section -->
              <!-- <div *ngIf="csObj?.placeholderType == 'HtmlTable'" class="mb-3">
                <div id="custom-table-style"
                  [innerHTML]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue">
                </div>
              </div> -->


              <!---->
              <!-- <div class="mb-3" *ngIf="csObj?.placeholderType == 'clearsection'">
                <div class="html-template" [style.height.px]="100"></div>
              </div> -->

              <!-- charts section  -->

              <!-- <pre>
                {{csObj | json}}
              </pre> -->

              <div class="row" *ngIf="csObj?.placeholderType == 'Chart'">

                <div class="col-6" id="{{csObj.placeholderName}}" style="width: 200px; display: grid;">
                  <div *ngIf="csObj?.graphType == 'PieChart'">
                    <jedwa-custom-ngx-charts-pie-chart [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="colorScheme"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [labels]="getGraphValueForProperty(csObj,'labels')"
                      [doughnut]="getGraphValueForProperty(csObj,'doughnut')"
                      [arcWidth]="getGraphValueForProperty(csObj,'arcWidth')"
                      [legendTitle]="getGraphValueForProperty(csObj,'legendTitle')"
                      [legendPosition]="getGraphValueForProperty(csObj,'legendPosition')" (select)="onSelect($event)"
                      (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                    </jedwa-custom-ngx-charts-pie-chart><br />
                  </div>
                  <div *ngIf="csObj?.graphType == 'PieGridChart'">
                    <ngx-charts-pie-grid [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [label]="getGraphValueForProperty(csObj,'label')" (select)="onSelect($event)"
                      [minWidth]="getGraphValueForProperty(csObj,'minWidth')">
                    </ngx-charts-pie-grid><br />
                  </div>
                  <div *ngIf="csObj?.graphType == 'AdvancedPieChart'">
                    <ngx-charts-advanced-pie-chart [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [label]="getGraphValueForProperty(csObj,'label')" (select)="onSelect($event)"
                      (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                    </ngx-charts-advanced-pie-chart><br />
                  </div>


                  <div *ngIf="csObj?.graphType == 'LineChart'">
                    <ngx-charts-line-chart [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [schemeType]="getGraphValueForProperty(csObj,'schemeType')"
                      [rangeFillOpacity]="getGraphValueForProperty(csObj,'rangeFillOpacity')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [legendTitle]="getGraphValueForProperty(csObj,'legendTitle')"
                      [legendPosition]="getGraphValueForProperty(csObj,'legendPosition')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')"
                      [roundDomains]="getGraphValueForProperty(csObj,'roundDomains')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [trimXAxisTicks]="getGraphValueForProperty(csObj,'trimXAxisTicks')"
                      [trimYAxisTicks]="getGraphValueForProperty(csObj,'trimYAxisTicks')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [autoScale]="getGraphValueForProperty(csObj,'autoScale')" (select)="onSelect($event)">
                    </ngx-charts-line-chart><br />
                  </div>
                  <div *ngIf="csObj?.graphType == 'AreaChart'">
                    <ngx-charts-area-chart [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [schemeType]="getGraphValueForProperty(csObj,'schemeType')"
                      [timeline]="getGraphValueForProperty(csObj,'timeline')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [legendTitle]="getGraphValueForProperty(csObj,'legendTitle')"
                      [legendPosition]="getGraphValueForProperty(csObj,'legendPosition')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')"
                      [roundDomains]="getGraphValueForProperty(csObj,'roundDomains')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [trimXAxisTicks]="getGraphValueForProperty(csObj,'trimXAxisTicks')"
                      [trimYAxisTicks]="getGraphValueForProperty(csObj,'trimYAxisTicks')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [autoScale]="getGraphValueForProperty(csObj,'autoScale')" (select)="onSelect($event)">
                    </ngx-charts-area-chart><br />
                  </div>
                  <div *ngIf="csObj?.graphType == 'TreeMap'">
                    <ngx-charts-tree-map [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')" (select)="onSelect($event)">
                    </ngx-charts-tree-map>
                  </div>

                  <div elementAddedToView (ngInit)="barChartAdded($event)"
                    *ngIf="csObj?.graphType == 'VerticalBarChart'">
                    <ngx-charts-bar-vertical [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [legendPosition]="getGraphValueForProperty(csObj,'legendPosition')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [barPadding]="getGraphValueForProperty(csObj,'barPadding')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical><br />
                  </div>
                  <div elementAddedToView (ngInit)="barChartAdded($event)"
                    *ngIf="csObj?.graphType == 'HorizontalBarChart'">
                    <ngx-charts-bar-horizontal [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [legendPosition]="getGraphValueForProperty(csObj,'legendPosition')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [barPadding]="getGraphValueForProperty(csObj,'barPadding')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')" (select)="onSelect($event)">
                    </ngx-charts-bar-horizontal><br />
                  </div>

                  <!-- grouped chart start -->
                  <div *ngIf="csObj?.graphType == 'GroupedVerticalBarChart'">
                    <ngx-charts-bar-vertical-2d [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [barPadding]="getGraphValueForProperty(csObj,'barPadding')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')" (select)="onSelect($event)"
                      [groupPadding]="getGraphValueForProperty(csObj,'groupPadding')">
                    </ngx-charts-bar-vertical-2d><br />
                  </div>
                  <div *ngIf="csObj?.graphType == 'GroupedHorizontalBarChart'">
                    <ngx-charts-bar-horizontal-2d [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [barPadding]="getGraphValueForProperty(csObj,'barPadding')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')" (select)="onSelect($event)"
                      [groupPadding]="getGraphValueForProperty(csObj,'groupPadding')">
                    </ngx-charts-bar-horizontal-2d><br />
                  </div>
                  <!-- grouped chart end -->
                  <!-- stacked bar chart start -->
                  <div *ngIf="csObj?.graphType == 'StackedVerticalBarChart'">
                    <ngx-charts-bar-vertical-stacked [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [barPadding]="getGraphValueForProperty(csObj,'barPadding')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical-stacked><br />
                  </div>
                  <div *ngIf="csObj?.graphType == 'StackedHorizontalBarChart'">
                    <ngx-charts-bar-horizontal-stacked [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue : csObj.arabicPlaceholderValue"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [barPadding]="getGraphValueForProperty(csObj,'barPadding')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')" (select)="onSelect($event)">
                    </ngx-charts-bar-horizontal-stacked><br />
                  </div>
                  <div *ngIf="csObj?.graphType == 'ComboChart'">


                    <!-- <combo-chart-component
        
        class="chart-container"
        [view]="view"
        [scheme]="comboBarScheme"
        [colorSchemeLine]="lineChartScheme"
        [results]="barChart"
        [animations]="animations"
        [lineChart]="lineChartSeries"
        [tooltipDisabled]="tooltipDisabled"  
        [gradient]="gradient"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [legendTitle]="'legendTitle'"    
        [legendPosition]="'below'"
        [showGridLines]="true"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [showRightYAxisLabel]="true"
        [xAxisLabel]="'xAxisLabel'"
        [yAxisLabel]="'yAxisLabel'"
        [yAxisLabelRight]="yAxisLabelRight"
        [noBarWhenZero]="noBarWhenZero"
        
         

        (select)="onSelect($event)"
      >
      </combo-chart-component> -->

                    <combo-chart-component class="chart-container" [view]="getGraphValueForProperty(csObj,'view')"
                      [scheme]="getGraphValueForProperty(csObj,'scheme')"
                      [colorSchemeLine]="getGraphValueForProperty(csObj,'colorSchemeLine')"
                      [results]="(currentLanguage == 'en') ? csObj.placeholderValue.barData  : csObj.arabicPlaceholderValue.barData"
                      [animations]="false"
                      [lineChart]="(currentLanguage == 'en') ? csObj.placeholderValue.lineData : csObj.arabicPlaceholderValue.lineData"
                      [tooltipDisabled]="getGraphValueForProperty(csObj,'tooltipDisabled')"
                      [gradient]="getGraphValueForProperty(csObj,'gradient')"
                      [xAxis]="getGraphValueForProperty(csObj,'xAxis')"
                      [yAxis]="getGraphValueForProperty(csObj,'yAxis')"
                      [legend]="getGraphValueForProperty(csObj,'legend')"
                      [legendTitle]="getGraphValueForProperty(csObj,'legendTitle')"
                      [legendPosition]="getGraphValueForProperty(csObj,'legendPosition')"
                      [showGridLines]="getGraphValueForProperty(csObj,'showGridLines')"
                      [showXAxisLabel]="getGraphValueForProperty(csObj,'showXAxisLabel')"
                      [showYAxisLabel]="getGraphValueForProperty(csObj,'showYAxisLabel')"
                      [showRightYAxisLabel]="getGraphValueForProperty(csObj,'showRightYAxisLabel')"
                      [xAxisLabel]="getGraphValueForProperty(csObj,'xAxisLabel')"
                      [yAxisLabel]="getGraphValueForProperty(csObj,'yAxisLabel')"
                      [yAxisLabelRight]="getGraphValueForProperty(csObj,'yAxisLabelRight')"
                      [noBarWhenZero]="getGraphValueForProperty(csObj,'noBarWhenZero')"
                      [barPadding]="getGraphValueForProperty(csObj,'barPadding')" (select)="onSelect($event)">
                    </combo-chart-component>
                  </div>
                  <!-- stacked bar char end -->
                </div>
                <div class="col-6"></div>
              </div>
            </div>
          </div>



          <div *ngIf="!rObj.hasPlaceholder">
            <div class="html-template" [innerHTML]="rObj?.htmlTemplate"></div>
          </div>

          <!-- </div> -->

        </div>
      </div>

      <div class="card-body p-0" id="final-output">
        <div [innerHTML]="finalVar"> </div>
      </div>
    </div>


    <div class="close-alert-icons mx-3">
      <div class="bg-white py-3 "><span class="material-icons" mat-dialog-close>keyboard_arrow_left</span></div>
      <div class="bg-white py-3 "><span class="material-icons" mat-dialog-close>close</span></div>
    </div>


  </div>
</div>