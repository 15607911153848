<spinner *ngIf="loading"></spinner>
<div class="project-industry-popup create-project">
    <div class="container z-index-1">
    <div class="row m-0 text-center">
        <div class="row">
            <div class="col-12">
                <h3  class="mb-5 title">{{'TITLE.PROJECT_PLANS' | translate}}</h3> 
            </div>
            <div class="angular-editor-userform-styles prevent-label-focus">
              
                
            </div> 

            <div class="row m-0 mb-4 business-templates-block " *ngIf="!!userProejctPlans && !!userProejctPlans">
                <div class="col-md-3"  *ngFor="let plan of userProejctPlans">
                    
                  <div class="card overly-body">
                      <img alt="Card image cap" class="card-img-top"  src="../../../assets/media/plan-img.png">
                      <div class="card-body">
                          <h5 class="card-title" *ngIf="currentLanguage =='en'">{{plan.name}}</h5>
                          <h5 class="card-title"  *ngIf="currentLanguage !='en'">{{plan.arName}}</h5>
                          <div class="text-white pb-2">{{'TITLE.VERSION' | translate}} {{plan.version}}</div>
                          <!-- <p>{{'TITLE.CONTENTS_DESCRIPTION' | translate}}</p> -->
                          <div>
                            <a class="btn btn-primary btn-active-primary mr-2 mb-3" (click)="openPlanDocuments(plan.planId,  'old')">{{'TITLE.PLAN_DETAILS' | translate}}</a>
                            <a *ngIf="!!plan?.readyforReport" class="btn btn-primary btn-active-primary mr-2" (click)="openPlanReports(plan.planId)">{{'TITLE.PLAN_REPORTS' | translate}}</a>
                          </div>
                          
                          
                      </div>
                  </div>
                </div>
            </div>

            

            </div></div>

            <!-- <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-primary  mx-1" (click)="addContent()">{{'TITLE.ADD_CONTENT' | translate}}</button>
            </div> -->
        </div>

        <div class="close-alert-icons">
            <!-- 
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
    
            <div><span class="material-icons" mat-dialog-close>keyboard_arrow_left</span></div>
            <div><span class="material-icons" mat-dialog-close>close</span></div>
        </div>
    </div>

    
    <!-- <div class="abstract-design bottom right"><img src="../../../assets/media/bg/abstract-design.png" /></div>
    <div  class="abstract-design top right"><img src="../../../assets/media/bg/polygon-borders.png" /></div>
    <div  class="abstract-design left top"><img src="../../../assets/media/bg/polygon-borders-left.png" /></div> -->





