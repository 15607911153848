import * as tslib_1 from "tslib";
import { FieldWrapper } from '@ngx-formly/core';
var PanelWrapperComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PanelWrapperComponent, _super);
    function PanelWrapperComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PanelWrapperComponent;
}(FieldWrapper));
export { PanelWrapperComponent };
