import '../polyfills';

import { NgModule } from '@angular/core';
import { ChartCommonModule } from '../common';
import { JedwaCustomNgxChartsModule } from '..';
import { ComboChartComponent } from './combo-chart/combo-chart.component';
import { ComboSeriesVerticalComponent } from './combo-chart/combo-series-vertical.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { LineChartModule } from '../line-chart';
import { BarChartModule } from '@swimlane/ngx-charts';
import { CommonModule } from '@angular/common';



@NgModule({
  imports: [
    CommonModule,
    JedwaCustomNgxChartsModule,
    ChartCommonModule,
    //BrowserModule,
    //BrowserAnimationsModule,
    FormsModule,
    LineChartModule,
    BarChartModule
    
  ],
  declarations:[
    ComboChartComponent,
    ComboSeriesVerticalComponent,
  ],
  exports:[
    ComboChartComponent,
    ComboSeriesVerticalComponent,
  ]
})
export class CustomNgxComboChartsModule {}
