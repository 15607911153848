/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./brand.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./brand.component";
import * as i5 from "../../../core/_base/layout/services/layout-config.service";
import * as i6 from "../html-class.service";
var styles_BrandComponent = [i0.styles];
var RenderType_BrandComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrandComponent, data: {} });
export { RenderType_BrandComponent as RenderType_BrandComponent };
export function View_BrandComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "kt-header__brand   kt-grid__item"], ["id", "kt_header_brand"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "kt-header__brand-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["href", "javascript:;"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "logo"], ["class", "kt-header__brand-logo-default"]], [[1, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "logo"], ["class", "kt-header__brand-logo-sticky"]], [[1, "src", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "kt-header__brand   kt-grid__item"; var currVal_1 = _co.htmlClassService.getClasses("brand", true); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = "/"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 5).target; var currVal_3 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _co.headerLogo; _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.headerStickyLogo; _ck(_v, 7, 0, currVal_6); }); }
export function View_BrandComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-brand", [], null, null, null, View_BrandComponent_0, RenderType_BrandComponent)), i1.ɵdid(1, 4308992, null, 0, i4.BrandComponent, [i5.LayoutConfigService, i6.HtmlClassService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrandComponentNgFactory = i1.ɵccf("kt-brand", i4.BrandComponent, View_BrandComponent_Host_0, {}, {}, []);
export { BrandComponentNgFactory as BrandComponentNgFactory };
