import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { JadwaService } from '../../core/auth/_services/jadwa.service';
import { PaymentRequestUrway, PaymentResponseUrway } from '../../core/auth/_models/payments.model';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-buy-plan-popup',
  templateUrl: './buy-plan-popup.component.html',
  styleUrls: ['./buy-plan-popup.component.scss']
})
export class BuyPlanPopupComponent implements OnInit {

  @ViewChild('content', { static: false }) content: ElementRef;

  @Input() selectedPlanDetails;
  @Input() projectId;
  @Input() allPlansList;

  @Output() onPlanPopupClose: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = true;
  isLicenseAvailable: boolean = false;
  planQuantity: number = 1;
  quantityList: Array<any> = [1, 2, 3, 4, 5];
  subscriptionsList: Array<any> = [];
  isPlanActivated: boolean = false;
  existingSubscriptionId: any = '';
  goldSubscriptionId: any = '36fffa52-33ec-4d72-b347-890bfc36777a';

  constructor(private modalService: NgbModal, private jadwaService: JadwaService,
    private translate: TranslateService,
  ) {
    config: NgbModalConfig

  }
  ngOnInit() {
    this.fetchUserSubscriptions();
  }
  // ************ Method to check Subscribed Plans Total Count ************** 
  fetchUserSubscriptions() {
    let params = {
      userId: localStorage.getItem('userId'),
      planId: this.selectedPlanDetails['planId'],
      filterAvailable: true
    };
    this.jadwaService.getUserSubscriptions(params).subscribe(res => {
      if (res['result']) {
        this.existingSubscriptionId = res['result']['subscriptionId'];
        if (res['result']['subscribedPlans'].length > 0) {
          let data = res['result']['subscribedPlans'].filter(plan => plan.planId == this.selectedPlanDetails['planId']);
          if (data && data[0] && data[0]['totalCount'] > 0) {
            this.isLicenseAvailable = true;
          } else {
            this.isLicenseAvailable = false;
            this.fetchAllSubscriptions();
          }
        } else {
          this.isLicenseAvailable = false;
          this.fetchAllSubscriptions();
        }
      }
      this.isLoading = false;
      this.openPopup(this.content);
    });
  }
  // ******** Method to display Popup ********************
  openPopup(content) {
    this.modalService.open(content, { windowClass: 'modal-custom-cb', size: 'md' });
  }

  activateLicense() {
    let params = {
      projectId: this.projectId,
      planIds: [this.selectedPlanDetails['planId']],
    };
    this.jadwaService.activatePlan(params).subscribe(res => {
      if (res['result']) {
        this.isPlanActivated = true;
        this.onPopupClose();
      }
    })
  }

  // ******** Method to Fetch All the Subscriptions List ********************
  fetchAllSubscriptions() {
    this.jadwaService.getAllSubscriptions().subscribe(res => {
      if (res['result']) {
        this.subscriptionsList = res['result'];
      }
    });
  }

  // ***********  Triggers on Buy Pan click ************************
  buyPlan() {
    let requestObj: PaymentRequestUrway = {
      userId: localStorage.getItem('userId'),
      planId: this.selectedPlanDetails['planId'],
      projectId: this.projectId,
      subscriptionId: '',
      planCount: this.planQuantity
    };
    this.createPaymentReq(requestObj, this.selectedPlanDetails);
  }

  // ***********  Triggers on Subscribe Pan click ************************
  subscribeToPlan(subscription) {
    let requestObj: PaymentRequestUrway = {
      userId: localStorage.getItem('userId'),
      planId: '',
      projectId: this.projectId,
      subscriptionId: subscription['id'],
      planCount: 1
    };
    this.createPaymentReq(requestObj, subscription);
  }

  // ***********  Method to create the Payment request ************************
  createPaymentReq(requestObj, plan) {
    Swal.fire({
      title: this.translate.instant('TITLE.PLEASE_WAIT_REDERECTING_TO_PAYMENT_PAGE'),
      // title: ,
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#37c0b9',
      confirmButtonText: this.translate.instant('TITLE.OKAY'),
    });

    this.jadwaService.createPaymentRequestForPlan(requestObj).subscribe(response => {
      requestObj.planName = plan.name;
      requestObj.planArName = plan.arName;
      sessionStorage.setItem("planDetailsForPayment", JSON.stringify(requestObj));
      window.open(response.result.transUrl, "_self");
    })
  }

  // ************* Method to close Popup **********************
  onPopupClose() {
    this.modalService.dismissAll();
    this.onPlanPopupClose.emit({ isPlanActivated: this.isPlanActivated });
  }

}
