import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AuthNoticeService = /** @class */ (function () {
    function AuthNoticeService() {
        this.onNoticeChanged$ = new BehaviorSubject(null);
    }
    AuthNoticeService.prototype.setNotice = function (message, type) {
        var notice = {
            message: message,
            type: type
        };
        this.onNoticeChanged$.next(notice);
    };
    AuthNoticeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthNoticeService_Factory() { return new AuthNoticeService(); }, token: AuthNoticeService, providedIn: "root" });
    return AuthNoticeService;
}());
export { AuthNoticeService };
