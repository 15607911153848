import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'kt-generated-reports-list',
  templateUrl: './generated-reports-list.component.html',
  styleUrls: ['./generated-reports-list.component.scss']
})
export class GeneratedReportsListComponent implements OnInit {
  @Input() generatedReportsList=[];

  constructor(private httpClient: HttpClient,private fileSaverService: FileSaverService,@Inject(MAT_DIALOG_DATA) data,
  public dialogRef: MatDialogRef<GeneratedReportsListComponent>,) { }

  ngOnInit() {
  }

  downloadPDFFromRemote(url:string)
  {
    this.httpClient.get(url+"?date="+new Date().getTime(),
      {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((res) => {
        this.fileSaverService.save(res.body, "report.pdf");
      });

  }

  close()
  {
    this.dialogRef.close();
  }

}
