import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
import { v4 as uuidv4 } from 'uuid';
var FormlyfieldfileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyfieldfileComponent, _super);
    function FormlyfieldfileComponent(jadwaService) {
        var _this = _super.call(this) || this;
        _this.jadwaService = jadwaService;
        _this.inputId = uuidv4();
        _this.imageId = uuidv4();
        _this.imageShow = false;
        return _this;
    }
    FormlyfieldfileComponent.prototype.ngOnInit = function () {
        if (this.formControl.value) {
            this.imageSrc = this.formControl.value;
            this.imageShow = true;
        }
    };
    FormlyfieldfileComponent.prototype.openFileInputReader = function () {
        document.getElementById(this.inputId).click();
    };
    FormlyfieldfileComponent.prototype.fileAdded = function (event) {
        var _this = this;
        console.log(event);
        this.currentlyAddeedFile = event.target.files[0];
        this.imagePreview(this.currentlyAddeedFile);
        //validate them 
        this.jadwaService.fileupload(event.target.files[0]).subscribe(function (response) {
            console.log("response", response);
            _this.formControl.setValue(response.result);
            _this.formControl.updateValueAndValidity();
        });
    };
    FormlyfieldfileComponent.prototype.imagePreview = function (file) {
        var _this = this;
        //const preview = document.querySelector('img');
        //const file = document.querySelector('input[type=file]').files[0];
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            // convert image file to base64 string
            _this.imageSrc = reader.result;
            _this.imageShow = true;
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    return FormlyfieldfileComponent;
}(FieldType));
export { FormlyfieldfileComponent };
