<div>
    <mat-form-field class="example-full-width" appearance="fill" *ngIf="optionsList">
        <mat-label>{{field.templateOptions.label}}</mat-label>
        <input type="text"
               placeholder="Pick one"
               aria-label="Number"
               matInput
               [formControl]="formControl"
               [matAutocomplete]="auto" >
               <!--[displayWith]="displayFn"-->
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    <!-- <button class="btn btn-primary" [type]="to.type" [ngClass]="'btn btn-' + to.btnType" (click)="onClick($event)">
      {{ to.text }}
    </button> -->
  </div>