/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./axis-label.component";
var styles_AxisLabelComponent = [];
var RenderType_AxisLabelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AxisLabelComponent, data: {} });
export { RenderType_AxisLabelComponent as RenderType_AxisLabelComponent };
export function View_AxisLabelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:text", [], [[1, "stroke-width", 0], [1, "x", 0], [1, "y", 0], [1, "text-anchor", 0], [1, "transform", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.strokeWidth; var currVal_1 = _co.x; var currVal_2 = _co.y; var currVal_3 = _co.textAnchor; var currVal_4 = _co.transform; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.label; _ck(_v, 1, 0, currVal_5); }); }
export function View_AxisLabelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-charts-axis-label", ""]], null, null, null, View_AxisLabelComponent_0, RenderType_AxisLabelComponent)), i0.ɵdid(1, 573440, null, 0, i1.AxisLabelComponent, [i0.ElementRef], null, null)], null, null); }
var AxisLabelComponentNgFactory = i0.ɵccf("g[ngx-charts-axis-label]", i1.AxisLabelComponent, View_AxisLabelComponent_Host_0, { orient: "orient", label: "label", offset: "offset", width: "width", height: "height" }, {}, []);
export { AxisLabelComponentNgFactory as AxisLabelComponentNgFactory };
