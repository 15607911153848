/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@swimlane/ngx-charts/release/bar-chart/bar.component.ngfactory";
import * as i2 from "../../common/tooltip/tooltip.directive";
import * as i3 from "../../common/tooltip/tooltip.service";
import * as i4 from "@swimlane/ngx-charts/release/bar-chart/bar.component";
import * as i5 from "@angular/common";
import * as i6 from "./combo-series-vertical.component";
var styles_ComboSeriesVerticalComponent = [];
var RenderType_ComboSeriesVerticalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ComboSeriesVerticalComponent, data: { "animation": [{ type: 7, name: "animationState", definitions: [{ type: 1, expr: "* => void", animation: [{ type: 6, styles: { opacity: 1, transform: "*" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0, transform: "scale(0)" }, offset: null }, timings: 500 }], options: null }], options: {} }] } });
export { RenderType_ComboSeriesVerticalComponent as RenderType_ComboSeriesVerticalComponent };
function View_ComboSeriesVerticalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, ":svg:g", [["ngx-charts-bar", ""], ["ngx-tooltip", ""]], [[24, "@animationState", 0]], [[null, "select"], [null, "activate"], [null, "deactivate"], [null, "focusin"], [null, "blur"], [null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focusin" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onBlur() !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 1).onMouseClick() !== false);
        ad = (pd_4 && ad);
    } if (("mouseenter" === en)) {
        var pd_5 = (i0.ɵnov(_v, 2).onMouseEnter() !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i0.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_6 && ad);
    } if (("select" === en)) {
        var pd_7 = (_co.onClick($event) !== false);
        ad = (pd_7 && ad);
    } if (("activate" === en)) {
        var pd_8 = (_co.activate.emit($event) !== false);
        ad = (pd_8 && ad);
    } if (("deactivate" === en)) {
        var pd_9 = (_co.deactivate.emit($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, i1.View_BarComponent_0, i1.RenderType_BarComponent)), i0.ɵdid(1, 147456, null, 0, i2.TooltipDirective, [i3.TooltipService, i0.ViewContainerRef, i0.Renderer2], { tooltipTitle: [0, "tooltipTitle"], tooltipDisabled: [1, "tooltipDisabled"], tooltipPlacement: [2, "tooltipPlacement"], tooltipType: [3, "tooltipType"] }, null), i0.ɵdid(2, 573440, null, 0, i4.BarComponent, [i0.ElementRef], { fill: [0, "fill"], data: [1, "data"], width: [2, "width"], height: [3, "height"], x: [4, "x"], y: [5, "y"], orientation: [6, "orientation"], roundEdges: [7, "roundEdges"], gradient: [8, "gradient"], isActive: [9, "isActive"], stops: [10, "stops"], animations: [11, "animations"], noBarWhenZero: [12, "noBarWhenZero"] }, { select: "select", activate: "activate", deactivate: "deactivate" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.tooltipText; var currVal_2 = _co.tooltipDisabled; var currVal_3 = "top"; var currVal_4 = "tooltip"; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.color; var currVal_6 = _v.context.$implicit.data; var currVal_7 = _v.context.$implicit.width; var currVal_8 = _v.context.$implicit.height; var currVal_9 = _v.context.$implicit.x; var currVal_10 = _v.context.$implicit.y; var currVal_11 = "vertical"; var currVal_12 = _v.context.$implicit.roundEdges; var currVal_13 = _co.gradient; var currVal_14 = _co.isActive(_v.context.$implicit.data); var currVal_15 = _v.context.$implicit.gradientStops; var currVal_16 = _co.animations; var currVal_17 = _co.noBarWhenZero; _ck(_v, 2, 1, [currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17]); }, function (_ck, _v) { var currVal_0 = "active"; _ck(_v, 0, 0, currVal_0); }); }
export function View_ComboSeriesVerticalComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ComboSeriesVerticalComponent_1)), i0.ɵdid(1, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bars; var currVal_1 = _co.trackBy; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ComboSeriesVerticalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "g", [["ngx-combo-charts-series-vertical", ""]], null, null, null, View_ComboSeriesVerticalComponent_0, RenderType_ComboSeriesVerticalComponent)), i0.ɵdid(1, 573440, null, 0, i6.ComboSeriesVerticalComponent, [], null, null)], null, null); }
var ComboSeriesVerticalComponentNgFactory = i0.ɵccf("g[ngx-combo-charts-series-vertical]", i6.ComboSeriesVerticalComponent, View_ComboSeriesVerticalComponent_Host_0, { dims: "dims", type: "type", series: "series", seriesLine: "seriesLine", xScale: "xScale", yScale: "yScale", colors: "colors", tooltipDisabled: "tooltipDisabled", gradient: "gradient", activeEntries: "activeEntries", seriesName: "seriesName", animations: "animations", noBarWhenZero: "noBarWhenZero" }, { select: "select", activate: "activate", deactivate: "deactivate", bandwidth: "bandwidth" }, []);
export { ComboSeriesVerticalComponentNgFactory as ComboSeriesVerticalComponentNgFactory };
