// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User } from '../_models/user.model';

export interface AuthState {
    language: string,
    loggedIn: boolean;
    authToken: string;
    user: User;
    isUserLoaded: boolean;
    userInfo?: any;
}

export const initialAuthState: AuthState = {
    language : 'en',
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    isUserLoaded: false,
    userInfo: {}
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {



        case AuthActionTypes.Login: {
            const _token: string = action.payload.authToken;
            return {
                language : 'en',
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false
            };
        }

        case AuthActionTypes.Register: {
            const _token: string = action.payload.authToken;
            return {
                language : 'en',
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false
            };
        }

        case AuthActionTypes.Logout:
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userId');
            return initialAuthState;

        case AuthActionTypes.UserLoaded: {
            const _user: User = action.payload.user;
            return {
                ...state,
                user: _user,
                isUserLoaded: true
            };
        }
            
        case AuthActionTypes.CurrentUser: {
            if (action.payload && action.payload.userInfo) {
                const _user: any =JSON.parse(action.payload.userInfo);
            return {
                ...state,
                userInfo: _user,
            };
            } else {
            return state; 
         }
        }

        case AuthActionTypes.SetLanguage: {
            return {
                ...state,
                language : action.payload.language
            };
        }
         
        default:
            return state;
    }
}
