import {Directive, Input, Output, EventEmitter, ElementRef} from '@angular/core';

@Directive({
  selector: '[elementAddedToView]'
})
export class ElementAddedToViewDirective {

  @Input() isLast: boolean;

  @Output('ngInit') initEvent: EventEmitter<any> = new EventEmitter();
  constructor(private elRef:ElementRef)
  {
    console.log(this.elRef.nativeElement);

  }

  ngOnInit() {
    setTimeout(() => this.initEvent.emit(), 10);
  }
}