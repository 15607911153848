<spinner *ngIf="loading"></spinner> 
<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
		<!--begin::Aside-->
		<div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside position-relative" style="background:#fafafa  url(./assets/media/bg/login-bg-5.png) no-repeat center;">
			<div class="kt-grid__item">
				<a href="javascript:;" class="kt-login__logo">
					<!-- <img src="./assets/media/logos/logo-4.png" alt=""> -->
					<!-- JADWA -->
				</a>
			</div>
			<!-- <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
				<div class="kt-grid__item kt-grid__item--middle">
					<h3 class="kt-login__title">مرحبا بكم في جدوى</h3>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة التسويقية</h4>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة المالية</h4>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة الإستراتيجية</h4>
					<h4 class="kt-login__subtitle" style="margin-left:60px">الدراسة الفنية</h4>
					
				</div>
			</div> -->
			<div class="kt-grid__item">
				<div class="kt-login__info">
					<div class="kt-login__copyright">
						© {{today|date:'yyyy'}} جدوى
					</div>
					<div class="kt-login__menu">
						<a href="javascript:;" class="kt-link">{{'FIELDS.PRIVACY' | translate}}</a>
						<a href="javascript:;" class="kt-link">{{'FIELDS.LEGAL' | translate}}</a>
						<a href="javascript:;" class="kt-link">{{'FIELDS.CONTACT' | translate}}</a>
					</div>
				</div>
			</div>
            <div class="register-title">{{'TITLE.REGISTERTITLE' | translate}}<br />{{'TITLE.REGISTERTITLE2' | translate}}</div>
		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper" style="background:#FFF url(./assets/media/bg/Polygon.png) no-repeat top right;">
			<!-- <router-outlet></router-outlet> -->

            <div class="language cursor-pointer">
                <a (click)="changeLanguage('en')">English</a>
                <span class="mx-2">|</span>
                <a (click)="changeLanguage('ar')">Arabic</a>
            </div>

            <!--begin::Body-->
            <div class="kt-login__body">
                <!--begin::Signin-->
                <div class="kt-login__form">
                    <div class="kt-login__title">
                        <img src="../../../assets/logo.png" class="img-fluid login-logo" />
                        <h3>{{'TITLE.SIGN_IN' | translate}}</h3>
                    </div>

                    <!-- <kt-auth-notice></kt-auth-notice> -->

                    <!--begin::Form-->
                    <form class="kt-form" [formGroup]="loginForm" autocomplete="off">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'FIELDS.EMAIL' | translate }}</mat-label>
                                <input matInput type="email" placeholder="{{ 'FIELDS.EMAIL' | translate }}" formControlName="email" autocomplete="off"/>
                                <mat-icon matSuffix>email</mat-icon>
                                <mat-error *ngIf="isControlHasError('email','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','email')">
                                    <strong>{{ 'VALIDATION.INVALID_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('email','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'FIELDS.PASSWORD' | translate }}</mat-label>
                                <input matInput [type]="showPassword ? 'text' : 'password'" placeholder="{{ 'FIELDS.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
                                <mat-icon matSuffix (click)="showPasswordFun()">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="isControlHasError('password','required')">
                                    <strong>{{ 'VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('password','minlength')">
                                    <strong>{{ 'VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                                </mat-error>
                                <mat-error *ngIf="isControlHasError('password','maxlength')">
                                    <strong>{{ 'VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--begin::Action-->
                        <div class="kt-login__actions">
                            <a href="javascript:;" routerLink="/forgot-password" class="kt-link kt-login__link-forgot">
                                {{ 'TITLE.FORGOT_BUTTON' | translate }}
                            </a>
                            <a href="javascript:;" routerLink="/forgot-account" class="kt-link kt-login__link-forgot">
                                {{ 'TITLE.FORGOT_ACCOUNT_BUTTON' | translate }}
                            </a>
                            <button (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary">{{ 'TITLE.LOGIN_BUTTON' | translate }}</button>
                        </div>
                        <!--end::Action-->
                    </form>
                    <!--end::Form-->

                    <!--begin::Divider-->
                    <div class="kt-login__divider">
                        <div class="kt-divider">
                            <span></span>
                            <span>{{'FIELDS.OR' | translate}}</span>
                            <span></span>
                        </div>
                    </div>
                    <!--end::Divider-->

                    <!--begin::Options-->
                    <div class="kt-login__options">
                        <!--href="https://www.facebook.com/keenthemes/" target="_blank"-->
                        <a  (click)="fblogin()" class="btn btn-purple kt-btn">
                            <i class="fab fa-facebook-f"></i>
                            {{'TITLE.FACEBOOK' | translate}}
                        </a>
                        <!--href="https://twitter.com/keenthemes/" target="_blank" -->
                        <!-- <a (click)="socialSignIn('google')" class="btn btn-info kt-btn">
                            <i class="fab fa-twitter"></i>
                            Twitter
                        </a> -->
                        <!-- (click)="socialSignIn('google')"-->
                        <a #loginRef class="btn btn-orange kt-btn">
                            <i class="fab fa-google"></i>
                             {{'TITLE.GOOGLE' | translate}}
                        </a>
                    </div>
                    <!--end::Options-->

                     <!--begin::Head-->
                    <div class="kt-login__head mt-5">
                        <span class="kt-login__signup-label"> {{'FIELDS.DONT_HAVE_AN_ACCOUNT_YET' | translate}}</span>&nbsp;&nbsp;
                        <a href="javascript:;" routerLink="/register" class="kt-link kt-login__signup-link">{{ 'TITLE.SIGNUP_BUTTON' | translate }}</a>
                    </div>
                    <!--end::Head-->

                </div>
                <!--end::Signin-->
            </div>
            <!--end::Body-->

             

		</div>
		<!--end::Content-->
	</div>
</div>
