/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./subheader.component";
import * as i2 from "../../../core/_base/layout/services/layout-config.service";
var styles_SubheaderComponent = [];
var RenderType_SubheaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubheaderComponent, data: {} });
export { RenderType_SubheaderComponent as RenderType_SubheaderComponent };
export function View_SubheaderComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SubheaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-subheader", [], null, null, null, View_SubheaderComponent_0, RenderType_SubheaderComponent)), i0.ɵdid(1, 114688, null, 0, i1.SubheaderComponent, [i2.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubheaderComponentNgFactory = i0.ɵccf("kt-subheader", i1.SubheaderComponent, View_SubheaderComponent_Host_0, {}, {}, []);
export { SubheaderComponentNgFactory as SubheaderComponentNgFactory };
