
    <svg:g class="arc-group">
      <svg:defs *ngIf="gradient">
        <svg:g ngx-charts-svg-radial-gradient
          [color]="fill"
          orientation="vertical"
          [name]="radialGradientId"
          [startOpacity]="startOpacity"
        />
      </svg:defs>
      <svg:path
       [id]="uniqueId"
        [attr.d]="path"
        class="arc"
        [class.active]="isActive"
        [attr.fill]="getGradient()"
        (click)="onClick()"
        (dblclick)="onDblClick($event)"
        (mouseenter)="activate.emit(data)"
        (mouseleave)="deactivate.emit(data)"
        [style.pointer-events]="getPointerEvents()"
      />
      <use [attr.xlink:href]="'#'+uniqueId" fill="none" stroke="white"  />
      <text font-family="Verdana" font-size="17" [attr.fill]="'white'" [attr.x]="getXValue()" rotate="180">
        <textPath [attr.xlink:href]="'#'+uniqueId">
          {{value.toString().split('').reverse().join('')}}
        </textPath>
      </text>
    </svg:g>
   
  