// USA
export const locale = {
	lang: 'en',
	data: {
		DIRECTION:"ltr",
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		ERROR_MESSAGE : {
			SOMETHING_WENTWRONG : 'Something Went Wrong',
		},
		
		TITLE : {

			
		    //  HEADER NAVIGATION
			DASHBOARDS : 'Dashboards',	
			PROJECTS_NAV : 'Projects',	
			BUSINESS_ADMIN_TOOLS : 'Business Admin Tools',	
			USERS : 'Users',	
			INDUSTRY : 'Industry',	
			PRODUCTS : 'Products',	
			FIXEDASSETS_NAV : 'FixedAssets',	
			DATABANK : 'DataBank',	
			TECHNICAL_ADMIN_TOOLS : 'Technical Admin Tools',	
			PLANS_NAV : 'Plans',	
			DOCUMENTS_NAV : 'Documents',	
			JADWA : 'Jadwa',
			
			// DASHBOARD
			MY_PROJECT_PLANS : 'My Project Plans',
			OVER : 'Over',
			CREATE_PROJECT : 'Create Project',
			// DATA BANK
			DATA_BANK_TITLE : 'Data Bank',
			SEARCH_DATA_BANK : 'Search Data Bank',
			CREATE_DATA_BANK : 'Create Data Bank',
			NO_PLANS_CREATED_FOR_THIS_PROJECT : 'No Plans created for this project',
			CREATE_NEW_PROJECT : 'Create New Project',
			PROMOTIONAL : 'Promotional',
			AREA : 'Area',
			BUSINESS_PLAN_TEMPLATES : 'Business Plan Templates',
			BUSINESS_PLAN : 'Business Plan',
			TEMPLATES : 'Templates',
			USE_PLAN : 'Use Plan',
			BUY_PLAN:"Buy Plan",
			NO_PLAN_AVAILABLE : 'No Plan Available',
			GENERATED_BUSINESS_PLANS : 'Generated Business Plans',
			GENERATED_PLAN : 'Generated Plan',
			// POP-UP
			CREATE_A_NEW_PROJECT : 'Create a new project',

			// PROJECTS
			PROJECTS_LIST : 'Projects List',
			PROJECT : 'Project',
			PROJECT_TITLE : 'Project Title',
			PROJECT_PLANS : 'Project Plans',
			SEARCH_PROJECT : 'Search Project',
			VERSION  : 'Version',
			PLAN_DETAILS  : 'Plan Details',
			PLAN_REPORTS  : 'Plan Reports',

			// TABLE
            S_NO: 'S.NO',
            INDUSTRY_NAME: 'Industry Name', 
			DATABANK_NAME : 'DataBank Name',
            CATEGORY_NAME: 'Category Name', 
            AMOUNT: 'Amount', 
            CURRENCY: 'Currency', 
            EDITABLE: 'Editable', 
            FREE: 'Free', 
            ACTION: 'Action', 
			DELETE: 'Delete',
			EDIT: 'Edit',
			VIEW: 'View',
			CONTENTS: 'Contents',
			ROLE : 'Role',

			CREATED_BY_USER :  'Created By',
			CREATED_AT : 'Created At',

			
			
			// DATA BANK DIALOGS 
			// Contents
			SEQUENCE_NO: 'Sequence No',
			CONTENTS_DESCRIPTION: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			ADD_CONTENT: 'Add Content',
			// EDIT DATA BANK
			CREATE_A_NEW_DATA_BANK: 'Create a new Data Bank',

			// FIXED ASSETS 
			FIXEDASSETS : 'FixedAssets',
			CREATE_FIXEDASSETS : 'FixedAssets',
			SEARCH_FIXEDASSETS : 'Search FixedAssets',
			// POP-UP
			CREATE_A_NEW_FIXED_ASSETS : 'Create a new Fixed Assets',
			// TABLE
			NAME : 'Name',
			LIFE_TIME : 'Life Time',
			SALVAGE_PERCENTAGE_VALUE : 'Salvage Percentage Value',

			HOME : 'Home',
			USERS_LIST : 'Users List',
			CUSTOMER_SEGMENT : 'Customer Segment',
			DASHBOARD : 'DASHBOARD',
			PLANS : 'Plans',
			PROJECTS : 'Projects',
			REGISTER_TITLE: 'Sign Up',
			DESC: 'Enter your details to create your account',
			SUCCESS: 'Your account has been successfuly registered.',
			VERIFY_EMAIL_LINK_SENT:"Verification link sent to email",
			OKAY : 'okay',

			FORGOT_TITLE: 'Forgot Password?',
			FORGOT_PASSWORD_DESC: 'Enter your email to reset your password',
			FORGOT_PASSWORD_SUCCESS: 'Your account has been successfully reset.',


			RESET_PASSWORD_TITLE: 'Reset Password?',
			RESET_PASSWORD_DESC: 'Enter New password to login',
			RESET_PASSWORD_SUCCESS: 'your password has been updated successfully.',

			LOGIN_TITLE: 'Login Account',
			SUBMIT_BUTTON: 'Submit',
			LOGIN_BUTTON :'Sign In',
			NO_ACCOUNT: 'Don\'t have an account?',
			SIGNUP_BUTTON: 'Sign Up',
			FORGOT_BUTTON: 'Forgot Password',				
			FORGOT_ACCOUNT_BUTTON: "Forgot Account",
			BACK_BUTTON: 'Back',
			PRIVACY: 'Privacy',
			LEGAL: 'Legal',
			CONTACT: 'Contact',
			FIRST_NAME: "First Name",
			LAST_NAME: "Last Name",
			USERNAME:"User Name",
			PASSWORD:"Password",
			CONFIRM_PASSWORD:"Confirm Password",
			OR: 'Or',

			// PRODUCTS LIST
			PAGE_PRODUCTS : 'Products',
			CREATE_PRODUCTS : 'Create Products',
			SEARCH_PRODUCT : 'Search Product',
			// PRODUCTS DETAILS
			CREATE_NEW_PRODUCT : 'Create New Product',
			// INDUSTRY LIST
			INDUSTRIES  : 'Industries',
			CREATE_INDUSTRY : 'Create Industry',
			SEARCH_USER : 'Search User',
			INDUSTRY_DETAILS : 'Industry Details',
			// USERS LIST
			CREATE_USER : 'Create User',
			USER_TITLE : 'User Title',
			REPORT : 'User Title',
			// PLANS
			PLANS_LIST  : 'Plans List',
			SEARCH_PLANS  : 'Search plans',
			CREATE_PLAN  : 'Create Plan',
			PLAN_TITLE : 'Plan Title',
			CREATE_A_NEW_PLAN  : 'Create a New Plan',
			DESCRIPTION  : 'Description',
			SEQUENCE  : 'Sequence',
			// DOCUMENTS
			DOCUMENT  : 'Document',
			DOCUMENTS  : 'Documents',
			SEARCH_DOCUMENT : 'Search Document',
			CREATE_DOCUMENT : 'Create Document',
			DOCUMENT_NAME : 'Document Name',
			ICON  : 'Icon',
			TEMPLATE_METADATA : 'Template MetaData',
			TEMPLATE_FORM_DATA : 'Template Form Data',
			// SIGN IN
			SIGN_IN  : 'Sign In',
			FACEBOOK  : 'Facebook',
			GOOGLE : 'Google',
			REGISTERTITLE : 'Develop a business plan for your',
			REGISTERTITLE2 : 'selected business idea !!!',
			// SIGN UP
			I_AGREE_THE : 'I agree the',
			TERMSCONDITIONS : 'terms & conditions',
			// USER PROFILE
			PROFILE_DETAILS : 'Profile Details',
			CHANGE_PASSWORD : 'Change Password',
			EDIT_PROFILE : 'Edit Profile',
			USER_ID : 'User Id',
			COMPANY : 'Company',
			AVATAR : 'Avatar',
			ALLOWED_FILE: 'Allowed file types: png, jpg, jpeg.',
			ENTER_OLD_PASSWORD: 'Enter Old Password',
			NEW_PASSWORD : 'New Password',
			CONFIRM_NEW_PASSWORD : 'Confirm New Password',

			// PLAN REPORTS
			CHART_TYPE : 'Chart type',
			DOWNLOAD_PDF : 'Download PDF',
			DOWNLOAD_NEW_PDF : 'Download New PDF',
           // PROJECT INDUSTRY
		    PLAN : 'Plan',
		    USE : 'Use',
			PREVIEW : 'Preview',
		   // PLAN REPORT TRIAL
		   VALUE_INNOVATION : 'Value Innovation Management Consultancy',
		   PROBLEM : 'Problem',
		   UNIQ  : 'Uniq',
		   CUSTOMER_SIGMENT  : 'Customer Sigment',
		   KEY_METRICS  : 'Key Metrics',
		   SOLUTION : 'Solution',
		   UNFIR : 'Unfir',
		   CHANNELS  : 'Channels',
		   COST : 'Cost',
		   REVENUE : 'Revenue',
		   RENDERRED_FITTING : 'Renderred Fitting',
		   // NEW RECORD FOUND
		   NO_RECORD_FOUND : 'No Record Found',
		   // USER DETAILS
		   USER_DETAILS  : 'User Details',
		   PHONE_NUMBER_CODE : 'Phone Number Code',
		   PHONE_NUMBER : 'Phone Number',
		   COUNTRY_CODE  : 'Country Code',
		   SECOND_NAME : 'Second Name',
		   THIRD_NAME : 'Third Name',
		   REPORTS : 'Reports',
		   SEQUENCENUMBER : 'Sequence Number',
		   // CONTENT DIALOGS
		   CONTENT_DETAILS : 'Content Details',
		   TYPE : 'Type',
		   HTML  : 'HTML',
		   IMAGE  : 'Image',	
		   YES:"Yes",
		   NO:"No",
		   UNSAVE_DATA_SAVE:"You have Unsaved Data. Do you want to save it",
		   ISPAID:"Should user pay for report",
		   AMOUNT_MANDATORY:"Amount is Mandatory",
		   PLEASE_WAIT_REDERECTING_TO_PAYMENT_PAGE:"Please wait while you would be redirected to payment page",
		   VIEW_GENERATED_REPORTS:"View Generated Reports",
		},

		FIELDS : {
			EMAIL: 'Email',
			FULLNAME: 'Fullname',
			PASSWORD: 'Password',
			CONFIRM_PASSWORD: 'Confirm Password',
			USERNAME: 'Username',
			OR: 'Or',
			SUBMIT_BUTTON: 'Submit',
			NO_ACCOUNT: 'Don\'t have an account?',
			DONT_HAVE_AN_ACCOUNT_YET: 'Don\'t have an account?',
			SIGNUP_BUTTON: 'Sign Up',
			FORGOT_BUTTON: 'Forgot Password',		
			BACK_BUTTON: 'Back',
			PRIVACY: 'Privacy',
			LEGAL: 'Legal',
			CONTACT: 'Contact',
			FIRST_NAME: "First Name",
			LAST_NAME: "Last Name",
			CODE : "Code",
			PLACEHOLDER_NAME : 'Name',
			INPUT_CURRENCY : 'Input Currency',
			OUTPUT_CURRENCY : 'Output Currency',
			SELECT: 'Select',
			EDITABLE : 'Editable',
			SAVE : 'Save',
			PLEASE_WAIT : 'Please wait',
			CANCEL : 'Cancel',
			START_DATE : 'Start Date',
			FIRST_YEAR : 'First Year',
			SELECT_YEAR : 'Select Year',
			INDUSTRY_ID  : 'Industry Id',
			OTP: 'An OTP sent to your email addresss',
			RESEND_EMAIL : 'Resend Email',
			FULL_NAME : 'Full Name',
			CONTACT_PHONE : 'Contact Phone',
			ADDRESS: 'Address',
			AGE : 'Age',
			COUNTRY : 'Country',
			GENDER : 'Gender',
			PROFESSION : 'Profession',
			CITY  : 'City',
			MALE   : 'Male',
			FEMALE  : 'Female',
			SAVE_CHANGES : 'Save Changes',
			ADDITIONAL_CONTACT_INFO  : 'Additional Contact Info',
			SELECT_LANGUAGE:"Select language"
			// USERNAME:"User Name",
			// PASSWORD:"Password",
			// CONFIRM_PASSWORD:"Confirm Password",
		},

		VALIDATION : {
			INVALID_PHONE_NUMBER : 'Invalid Phone Number',
			INVALID: '{{name}} is not valid',
			REQUIRED: '{{name}} is required',
			MIN_LENGTH: '{{name}} minimum length is {{min}}',
			AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
			NOT_FOUND: 'The requested {{name}} is not found',
			INVALID_LOGIN: 'The login detail is incorrect',
			REQUIRED_FIELD: 'Required field',
			MIN_LENGTH_FIELD: 'Minimum field length:',
			MAX_LENGTH_FIELD: 'Maximum field length:',
			INVALID_FIELD: 'Field is not valid',
			CATEGORY_NAME_IS_REQUIRED: 'Category Name is required',
			AMOUNT_SHOULD_ONLY_CONTAIN_NUMBERS: 'Amount should only contain Numbers AR',
			AMOUNTISREQUIRED : 'Amount is required',
			CURRENCY_IS_REQUIRED : 'Currency is required',
			INDUSTRY_NAME_IS_REQUIRED : 'Industry name is required',
			NAME_IS_REQUIRED : 'Name is required',
			LIFETIME_NAME_IS_REQUIRED : 'Lifetime name is required',
			SALVAGE_PERCENTAGE_VALUE_IS_REQUIRED : 'Salvage Percentage Value is required',
			PLEASE_FIX_THE_BELOW_ERRORS : 'Please Fix the below Errors',
			PASSWORD_TEXT : 'Passsword and ConfirmPassword didn\'t match.',
			PROFILEIMAGE_IS_REQUIRED : 'Profile Image is required',
			FIRST_NAME_IS_REQUIRED : 'First Name is required',
			LAST_NAME_IS_REQUIRED : 'Last Name is required',
			USER_NAME_IS_REQUIRED : 'Last Name is required',
			EMAIL_IS_REQUIRED : 'Email is required',
			PHONE_NUMBER_IS_REQUIRED  : 'Phone Number is required',
			COMPANY_IS_REQUIRED : 'Company is required',
			COMPANY_MUST_BE : 'company must be atleast 3 characters',
			COMPANY_MUST_NOT : 'company must not exceed 60 characters',
			ADDRESS_IS_REQUIRED : 'Address is required',
			ADDRESS_MUST_BE : 'Address must be atleast 3 characters',
			ADDRESS_MUST_NOT  : 'Address must not exceed 120 characters',
			CITY_IS_REQUIRED : 'City is required',
			CITY_MUST_BE : 'city must be atleast 3 characters',
			CITY_MUST_NOT : 'city must not exceed 60 characters',
			COUNTRY_IS_REQUIRED : 'Country is required',
			COUNTRY_MUST_BE : 'country must be atleast 3 characters',
			COUNTRY_MUST_NOT  : 'country must not exceed 60 characters',
			PROFESSION_IS_REQUIRED : 'Profession is required',
			PROFESSION_MUST_BE : 'Profession must be atleast 3 characters',
			PROFESSION_MUST_NOT : 'Profession must not exceed 60 characters',
			AGE_IS_REQUIRED : 'Age is required',	
			AGE_ONLY_ACCEPT_NUMBERS : 'Age only accept numbers',	
			GENDER_IS_REQUIRED : 'Gender is required',	
			PASDDWORD_MATCH : 'Passsword and ConfirmPassword didn\'t match.'
		}
	}
};
