import * as tslib_1 from "tslib";
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import jsonata from 'jsonata';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { JadwaService } from '../../../app/core/auth/_services/jadwa.service';
var FormlyFieldTabs = /** @class */ (function (_super) {
    tslib_1.__extends(FormlyFieldTabs, _super);
    function FormlyFieldTabs(jadwaService, translate) {
        var _this = _super.call(this) || this;
        _this.jadwaService = jadwaService;
        _this.translate = translate;
        _this.recentlyFormInputChangedAfterLastSavedToDraft = false;
        _this.optionOneExpression = jsonata("$sum(bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_amount.Arrayrevenues_amount.year1 * bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_amount.period_type)");
        _this.optionTwoExpression = jsonata("$sum(bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_qty.Arrayrevenues_qty.sellprice * bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_qty.Arrayrevenues_qty.year1 * bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_qty.period_type)");
        _this.optionThreeExpression = jsonata("$sum(bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_with_cost_amount.ArrayRevenues_with_cost_amount.year1* bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_with_cost_amount.period_type)");
        _this.optionFourExpression = jsonata("$sum(bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_with_cost_qty.ArrayRevenues_with_cost_qty.sellprice * bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_with_cost_qty.ArrayRevenues_with_cost_qty.year1 * bd5a7024_62f1_4f84_8f56_04d62d4814ee.revenue.revenues_with_cost_qty.period_type)");
        _this.startupexpensesExp = jsonata("$sum(f4e58ada_2e8e_46fe_8f7d_2afacbc9c944.startup_Expenses.startupExpenseArray.expense_amount)");
        _this.ExpensebyActualAmountExp = jsonata("$sum(c9ea238_6930_4a63_bc2e_d3d436337493.expense.quick_expenses_as_amount.expenseArrayByValue.year1 * c9ea238_6930_4a63_bc2e_d3d436337493.expense.quick_expenses_as_amount.expenseArrayByValue.period_type)");
        _this.ExpensebyRevenuePercentageExp = jsonata("$sum(c9ea238_6930_4a63_bc2e_d3d436337493.expense.quick_expenses_from_revenue.expenseArrayByRevenue.year1)");
        _this.ExpensebyActualAmountAdvanceExp = 0; //jsonata("$sum(c9ea238_6930_4a63_bc2e_d3d436337493.expense.expenses.expenseArrayByValue.year1 * c9ea238_6930_4a63_bc2e_d3d436337493.expense.expenses.expenseArrayByValue.period_type)");
        _this.ExpenseAdvanceExp = 0; //jsonata("$sum(c9ea238_6930_4a63_bc2e_d3d436337493.expense.advanced_expenses.expenseArrayByValue.year1 * c9ea238_6930_4a63_bc2e_d3d436337493.expense.advanced_expenses.expenseArrayByValue.period_type)");
        //Fixed Assets
        _this.FixedAssetOption1Exp = jsonata("$sum(c2708c0b_fc1a_45b2_bb72_34ea23096710.fixed_assets.fa1.fixed_assetsSimpleArray.asset_price)");
        _this.FixedAssetOption2Exp = jsonata("$sum(c2708c0b_fc1a_45b2_bb72_34ea23096710.fixed_assets.fa2.fixed_assetsArray.asset_price)");
        _this.FixedAssetOption3Exp = jsonata("$sum(c2708c0b_fc1a_45b2_bb72_34ea23096710.fixed_assets.fa2.fixed_assetsAdvancedArray.asset_price)");
        _this.PayrollOption1Exp = jsonata("$sum(de0328ed_779a_4bb2_b2f8_45c6161a04a5.payroll_expenses.quickemployee.quick_payroll_expense_Array.monthlyCTC*12)");
        _this.PayrollOption2Exp = jsonata("$sum(de0328ed_779a_4bb2_b2f8_45c6161a04a5.payroll_expenses.fullemployee.payroll_expense_Array.monthlyCTC *    de0328ed_779a_4bb2_b2f8_45c6161a04a5.payroll_expenses.fullemployee.payroll_expense_Array.period *  de0328ed_779a_4bb2_b2f8_45c6161a04a5.payroll_expenses.fullemployee.payroll_expense_Array.emp_count )");
        _this.STOExp = jsonata("$.b1e3286b_b679_4146_b9bd_ed5d329f3ea7.working_capital.sales_turnoverdays");
        _this.PTOExp = jsonata("$.b1e3286b_b679_4146_b9bd_ed5d329f3ea7.working_capital.purchase_turnoverdays");
        _this.PercentageofExpensesExp = jsonata("$.b1e3286b_b679_4146_b9bd_ed5d329f3ea7.working_capital.min_cr_percentage");
        _this.MinAmountExp = jsonata("$.b1e3286b_b679_4146_b9bd_ed5d329f3ea7.working_capital.min_ce_amount");
        _this.STO = 0;
        _this.PTO = 0;
        _this.PercentageofExpenses = 0;
        _this.WorkingAmount = 0;
        _this.MinAmount = 0;
        _this.ByAmount = 0;
        _this.ByQty = 0;
        _this.ByAmountCost = 0;
        _this.ByQtyCost = 0;
        _this.TotalRevenue = 0;
        _this.startupexpenses = 0;
        //In Direct Expenses
        _this.ExpensebyActualAmount = 0;
        _this.ExpensebyRevenuePercentage = 0;
        _this.ExpensebyActualAmountAdvance = 0;
        _this.ExpenseAdvance = 0;
        _this.TotalExpenses = 0;
        //Fixed Assets
        _this.FixedAssetOption1 = 0;
        _this.FixedAssetOption2 = 0;
        _this.FixedAssetOption3 = 0;
        _this.TotalFixedAsset = 0;
        _this.PayrollOption1 = 0;
        _this.PayrollOption2 = 0;
        _this.TotalPayroll = 0;
        _this.selectedIndex = 0;
        return _this;
    }
    FormlyFieldTabs.prototype.ngOnInit = function () {
        var _this = this;
        this.jadwaService.sendingClosingPlan$.pipe(first()).subscribe(function (response) {
            if (_this.recentlyFormInputChangedAfterLastSavedToDraft) {
                Swal.fire({
                    title: _this.translate.instant('TITLE.UNSAVE_DATA_SAVE'),
                    // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#37c0b9',
                    cancelButtonColor: "#37c0b9",
                    cancelButtonText: _this.translate.instant('TITLE.NO'),
                    confirmButtonText: _this.translate.instant('TITLE.YES'),
                }).then(function (result) {
                    if (result.isConfirmed) {
                        _this.recentlyFormInputChangedAfterLastSavedToDraft = false;
                        _this.saveAsDraft(undefined, undefined, undefined, response.callback);
                    }
                    else if (result.isDismissed) {
                        response.callback();
                    }
                });
            }
            else {
                response.callback();
            }
        });
        this.form.valueChanges.subscribe(function (formData) {
            _this.recentlyFormInputChangedAfterLastSavedToDraft = true;
            try {
                _this.calculateFieldsUsingFormData(formData);
            }
            catch (e) {
                console.error("exception occured", e);
            }
        });
    };
    FormlyFieldTabs.prototype.checkForNull = function (value) {
        return (value == null) ? 0 : value;
    };
    FormlyFieldTabs.prototype.evaluateJSONATAExpression = function (expression, formData) {
        try {
            var value = expression.evaluate(formData);
            //console.log(value);
            if (value === null || value === undefined)
                return 0;
            else
                return value;
        }
        catch (e) {
            console.log(e);
            return 0;
        }
    };
    FormlyFieldTabs.prototype.calculateFieldsUsingFormData = function (formData) {
        /*
    
        this.ByAmount = this.checkForNull(this.optionOneExpression.evaluate(formData));
        this.ByQty = this.checkForNull(this.optionTwoExpression.evaluate(formData));
        this.ByAmountCost = this.checkForNull(this.optionThreeExpression.evaluate(formData));
        this.ByQtyCost = this.checkForNull(this.optionFourExpression.evaluate(formData));
        this.TotalRevenue = this.ByAmount + this.ByQty + this.ByAmountCost + this.ByQtyCost;
    
        //Start Up Expenses
        this.startupexpenses = this.checkForNull(this.startupexpensesExp.evaluate(formData));
    
        //In Direct Expenses
        this.ExpensebyActualAmount = this.checkForNull(this.ExpensebyActualAmountExp.evaluate(formData));
        this.ExpensebyRevenuePercentage = this.checkForNull(this.ExpensebyRevenuePercentageExp.evaluate(formData) * this.TotalRevenue / 100);
        this.ExpensebyActualAmountAdvance = this.checkForNull(this.ExpensebyActualAmountAdvanceExp.evaluate(formData));
        this.ExpenseAdvance = this.checkForNull(this.ExpenseAdvanceExp.evaluate(formData));
        this.TotalExpenses = this.ExpensebyRevenuePercentage + this.ExpensebyActualAmount + this.ExpensebyActualAmountAdvance + this.ExpenseAdvance
    
    
        //Fixed Assets
        this.FixedAssetOption1 = this.checkForNull(this.FixedAssetOption1Exp.evaluate(formData));
        this.FixedAssetOption2 = this.checkForNull(this.FixedAssetOption2Exp.evaluate(formData));
        this.FixedAssetOption3 = this.checkForNull(this.FixedAssetOption3Exp.evaluate(formData));
        this.TotalFixedAsset = this.FixedAssetOption1 + this.FixedAssetOption2 + this.FixedAssetOption3;
    
    
        //Payroll
        this.PayrollOption1 = this.checkForNull(this.PayrollOption1Exp.evaluate(formData));
        this.PayrollOption2 = this.checkForNull(this.PayrollOption2Exp.evaluate(formData));
        this.TotalPayroll = this.PayrollOption1 + this.PayrollOption2;
    
        //Working Capital
        this.STO = this.checkForNull(this.STOExp.evaluate(formData));
        this.PTO = this.checkForNull(this.STOExp.evaluate(formData));
        this.PercentageofExpenses = this.checkForNull(this.PercentageofExpensesExp.evaluate(formData));
        this.WorkingAmount =this.PercentageofExpenses * this.TotalExpenses / 100;
        this.MinAmount = this.checkForNull(this.PayrollOption1Exp.evaluate(formData));
        */
        this.ByAmount = this.checkForNull(this.evaluateJSONATAExpression(this.optionOneExpression, formData));
        this.ByQty = this.checkForNull(this.evaluateJSONATAExpression(this.optionTwoExpression, formData));
        this.ByAmountCost = this.checkForNull(this.evaluateJSONATAExpression(this.optionThreeExpression, formData));
        this.ByQtyCost = this.checkForNull(this.evaluateJSONATAExpression(this.optionFourExpression, formData));
        this.TotalRevenue = this.ByAmount + this.ByQty + this.ByAmountCost + this.ByQtyCost;
        //Start Up Expenses
        this.startupexpenses = this.checkForNull(this.evaluateJSONATAExpression(this.startupexpensesExp, formData));
        //In Direct Expenses
        this.ExpensebyActualAmount = this.checkForNull(this.evaluateJSONATAExpression(this.ExpensebyActualAmountExp, formData));
        this.ExpensebyRevenuePercentage = this.checkForNull(this.evaluateJSONATAExpression(this.ExpensebyRevenuePercentageExp, formData) * this.TotalRevenue / 100);
        //this.ExpensebyActualAmountAdvance = this.checkForNull(this.evaluateJSONATAExpression(this.ExpensebyActualAmountAdvanceExp,formData));
        //this.ExpenseAdvance = this.checkForNull(this.evaluateJSONATAExpression(this.ExpenseAdvanceExp,formData));
        this.TotalExpenses = this.ExpensebyRevenuePercentage + this.ExpensebyActualAmount + this.ExpensebyActualAmountAdvance + this.ExpenseAdvance;
        //Fixed Assets
        this.FixedAssetOption1 = this.checkForNull(this.evaluateJSONATAExpression(this.FixedAssetOption1Exp, formData));
        this.FixedAssetOption2 = this.checkForNull(this.evaluateJSONATAExpression(this.FixedAssetOption2Exp, formData));
        this.FixedAssetOption3 = this.checkForNull(this.evaluateJSONATAExpression(this.FixedAssetOption3Exp, formData));
        this.TotalFixedAsset = this.FixedAssetOption1 + this.FixedAssetOption2 + this.FixedAssetOption3;
        //Payroll
        this.PayrollOption1 = this.checkForNull(this.evaluateJSONATAExpression(this.PayrollOption1Exp, formData));
        this.PayrollOption2 = this.checkForNull(this.evaluateJSONATAExpression(this.PayrollOption2Exp, formData));
        this.TotalPayroll = this.PayrollOption1 + this.PayrollOption2;
        //Working Capital
        this.STO = this.checkForNull(this.evaluateJSONATAExpression(this.STOExp, formData));
        this.PTO = this.checkForNull(this.evaluateJSONATAExpression(this.PTOExp, formData));
        this.PercentageofExpenses = this.checkForNull(this.evaluateJSONATAExpression(this.PercentageofExpensesExp, formData));
        this.WorkingAmount = this.PercentageofExpenses * this.TotalExpenses / 100;
        this.MinAmount = this.checkForNull(this.evaluateJSONATAExpression(this.PayrollOption1Exp, formData));
    };
    FormlyFieldTabs.prototype.expandCollapse = function () {
        this.expand = !this.expand;
    };
    FormlyFieldTabs.prototype.saveAsDraft = function (projectId, planId, documentId, callback) {
        var _this = this;
        if (projectId === undefined || projectId === null) {
            projectId = this.field.fieldGroup[0].templateOptions.projectId;
        }
        if (planId === undefined || planId === null) {
            planId = this.field.fieldGroup[0].templateOptions.planDetails.planId;
        }
        this.loading = true;
        var documentDataArray = [];
        //for (let key in this.form.value) {
        for (var key in this.model) {
            //@ts-ignore
            var keyOriginal = key.replaceAll('_', '-');
            //if (key == documentId) { //commented as saving draft all documents now
            var tempKey = {};
            tempKey[keyOriginal] = this.model[key];
            documentDataArray.push({
                "documentId": keyOriginal,
                "formData": JSON.stringify(tempKey)
            });
            //}
        }
        var requestObj = {
            "userId": localStorage.getItem('userId'),
            "projectId": projectId,
            "planId": planId,
            "documentData": documentDataArray
        };
        this.jadwaService.createUserProjectPlan(requestObj).subscribe(function (response) {
            if (response.result) {
                _this.loading = false;
                if (callback) {
                    Swal.fire({
                        title: 'Plan saved as Draft',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#37c0b9',
                        timer: 1000,
                        timerProgressBar: true
                        // confirmButtonText: this.translate.instant('TITLE.OKAY'),
                    });
                    callback();
                }
                // .then((result) => {
                //   // this.dialogRef.close(true);
                //   // this.router.navigateByUrl('/login');
                // });
            }
        }, function (error) {
            _this.loading = false;
            Swal.fire({
                title: 'Something went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#37c0b9',
                confirmButtonText: _this.translate.instant('TITLE.OKAY'),
            });
        });
        // } else {
        //   Swal.fire({
        //     title: 'Please Fill Required Fields',
        //     icon: 'warning',
        //     showCancelButton: false,
        //     confirmButtonColor: '#37c0b9',
        //     confirmButtonText: this.translate.instant('TITLE.OKAY'),
        //   })
        // }
    };
    FormlyFieldTabs.prototype.tabChanged = function (event) {
        if (this.recentlyFormInputChangedAfterLastSavedToDraft) //invoke save as draft
         {
            this.recentlyFormInputChangedAfterLastSavedToDraft = false;
            this.saveAsDraft();
        }
    };
    FormlyFieldTabs.prototype.nextStep = function (event) {
        // if (this.selectedIndex != maxNumberOfTabs) {
        /*
      if(this.selectedIndex!=0 && this.getStepsValue(this.selectedIndex,this.field.fieldGroup[this.selectedIndex])<=90 && this.getStepsValue(this.selectedIndex+1,this.field.fieldGroup[this.selectedIndex+1])==0)
      {
        Swal.fire({
          title: 'You have filled'+this.getStepsValue(this.selectedIndex,this.field.fieldGroup[this.selectedIndex])+'% of current document, please fill atleast 90% of current document data before proceeding to next document',
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: '#37c0b9',
          confirmButtonText: this.translate.instant('TITLE.OKAY'),
        }).then((result) => {
            
        });
        return;
      }
      */
        this.selectedIndex = this.selectedIndex + 1;
        event.preventDefault();
        this.tabChanged();
        // }
        // console.log(this.selectedIndex);
    };
    FormlyFieldTabs.prototype.previousStep = function (event) {
        if (this.selectedIndex != 0) {
            this.selectedIndex = this.selectedIndex - 1;
        }
        event.preventDefault();
        this.tabChanged();
    };
    FormlyFieldTabs.prototype.saveDocumentData = function (DocumentData, fields) {
        // {
        //   "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //   "projectId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //   "planId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //   "documentDataList": [
        //     {
        //       "documentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //       "formData": "string"
        //     }
        //   ]
        // }
        // fields.templateOptions.key
        // console.log(DocumentData);
        // console.log(fields);
        // DocumentData /// all tabs mode
        // if(' xtz'){
        // let obj = {
        //     76e2cddd_16f4_40a5_a71c_ea2ac92868bd : value,
        //     76e2cddd_16f4_40a5_a71c_ea2ac92868b2 : value,
        // }
        // }
        // if(' cus'){
        //   let obj {
        //     key : value,
        //     key : value,
        //   }
        // }
    };
    FormlyFieldTabs.prototype.isValid = function (field) {
        // if (field.key) {
        //   return field.formControl.valid;
        // }
        // try {
        //   return field.fieldGroup.every((f) => this.isValid(f));
        // } catch (e) {
        //   return true;
        // }
    };
    // addRow(fieldGroup, field, index) {
    //   console.log(fieldGroup);
    //   console.log(field);
    //   console.log(index);
    //   fieldGroup = fieldGroup.splice(index, 0, field);
    //   console.log(fieldGroup);
    //   console.log(field);
    //   console.log(index);
    // }
    FormlyFieldTabs.prototype.submitPlanDocuments = function (projectId, planId) {
        var _this = this;
        // event.preventDefault();
        if (this.form.valid) { // dont check validations here
            this.loading = true;
            var documentDataArray = [];
            //for (let key in this.form.value) {
            for (var key in this.model) {
                //@ts-ignore
                var keyOriginal = key.replaceAll('_', '-');
                var tempKey = {};
                tempKey[key] = this.model[key];
                var formDataStringified = JSON.stringify(tempKey);
                //@ts-ignore
                var formDataStringifiedWithOriginalKey = formDataStringified.replaceAll(key, keyOriginal);
                documentDataArray.push({
                    "documentId": keyOriginal,
                    "formData": formDataStringifiedWithOriginalKey
                });
            }
            var requestObj = {
                "userId": localStorage.getItem('userId'),
                "projectId": projectId,
                "planId": planId,
                "documentData": documentDataArray
            };
            this.jadwaService.createUserProjectPlan(requestObj).subscribe(function (response) {
                if (response.result) {
                    _this.loading = false;
                    Swal.fire({
                        title: 'Plan Save as Draft',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#37c0b9',
                    });
                    // .then((result) => {
                    //   // this.dialogRef.close(true);
                    //   // this.router.navigateByUrl('/login');
                    // });
                }
            }, function (error) {
                _this.loading = false;
                Swal.fire({
                    title: 'Something went Wrong',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#37c0b9',
                    confirmButtonText: _this.translate.instant('TITLE.OKAY'),
                });
            });
        }
        else {
            Swal.fire({
                title: 'Please Fill Required Fields or some fields validation failed, please check for errors mentioned',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#37c0b9',
                confirmButtonText: this.translate.instant('TITLE.OKAY'),
            });
        }
    };
    FormlyFieldTabs.prototype.getStepsValue = function (index, data) {
        var total = 0;
        var containsValue = 0;
        function calculateNumberOfValuesWhichAreNotNullOrUndefinedOrEmptyString(o) {
            if (Array.isArray(o)) {
                //console.log("array", o)
                o.forEach(function (e) {
                    calculateNumberOfValuesWhichAreNotNullOrUndefinedOrEmptyString(e);
                    return;
                });
            }
            else if (typeof o === "object" && o !== null && o !== undefined) {
                //console.log("object", o);
                Object.keys(o).forEach(function (k) {
                    //console.log("key", k);
                    calculateNumberOfValuesWhichAreNotNullOrUndefinedOrEmptyString(o[k]);
                    return;
                });
            }
            else {
                total++;
                if (o === undefined) {
                    return;
                }
                else if (o === null) {
                    return;
                }
                else if (typeof o === "string" && o == '') {
                    return;
                }
                else {
                    containsValue++;
                }
            }
        }
        //@ts-ignore
        calculateNumberOfValuesWhichAreNotNullOrUndefinedOrEmptyString(this.model[data.key]);
        //console.log("containsValue",containsValue,"total",total);
        //console.log("index percentage calculation",index, data.key,containsValue/total *100);
        //getting documentId which is first level, idenitfy how many are null or undefined or empty string vs total count 
        //return percentage 
        return containsValue / total * 100;
    };
    return FormlyFieldTabs;
}(FieldType));
export { FormlyFieldTabs };
