// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService } from '../../core/auth';
import Swal from "sweetalert2";
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
	// encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	today = new Date();
	// Public params
	resetPasswordForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {

		this.resetPasswordForm = this.fb.group({
			userId: ['', Validators.compose([
				Validators.required
			]) ],
      code: ['', Validators.compose([
				Validators.required
			]) ],
      password: ['', Validators.compose([
				Validators.required
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required
			])
			]
		},{
			validator: ConfirmPasswordValidator.MatchPassword
		});


    this.activatedRoute.queryParams.subscribe(params => {

      this.resetPasswordForm.patchValue({
        userId : params['userId'],
        code : params['resetCode']
      })
  

    });
	}

	/**
	 * Form Submit
	 */

	 
	
	showResetForm : boolean;
  
	submit() {
		const controls = this.resetPasswordForm.controls;
		/** check form */
		if (this.resetPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		
    const emailObj = {
			userId : controls.userId.value,
			password : controls.password.value,
			code : controls.code.value
		} ;
		this.authService.resetPassword(emailObj).subscribe((response: any) => {
		  this.loading = false;
		  if(response.result){
        this.showResetForm = true;
		  }
		}, error => {
		  this.loading = false;
			Swal.fire({
			  title : (error.error) ?error.error.responseException.exceptionMessage : this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
			  icon: 'warning',
			  showCancelButton: false,
			  confirmButtonColor: '#37c0b9',
			  confirmButtonText: this.translate.instant('TITLE.OKAY'),
			});
		});
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
