// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import { debug } from 'util';
import Swal from 'sweetalert2';
// import { AppState } from '../../../../../../../app/core/reducers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

	constructor(
		// private jadwaService: JadwaService,
		private translate: TranslateService,
		private router: Router,
		// private activatedRoute: ActivatedRoute,
		// private store: Store<AppState>,
		// private sanitizer: DomSanitizer,
		// @Inject(MAT_DIALOG_DATA) data,
		private dialogRef: MatDialog
	  ) {
	  }
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
		// 	}
		// });
		// console.log('----request----');
		// console.log(request);
		// console.log('--- end of request---');

		return next.handle(request).pipe(
			tap(
				event => {
					 if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {

					if(error.status == 401){
						Swal.fire({
						  title: 'Session Expired',
						  icon: 'warning',
						  showCancelButton: false,
						  confirmButtonColor: '#37c0b9',
						  // title: this.translate.instant('ERROR_MESSAGE.SOMETHING_WENTWRONG'),
						  confirmButtonText: this.translate.instant('TITLE.OKAY'),
						}).then(res =>{
						  // navigate to login page
						//   this.store.dispatch(new Logout());
						//   this.dialog.closeAll();
						if(this.dialogRef){ this.dialogRef.closeAll(); }						
						localStorage.removeItem('userInfo');
						localStorage.removeItem('userId');
						  this.router.navigate(['/login']);
						});
					}

					// http response status code
					// console.log('----response----');
					// console.error('status code:');
					// tslint:disable-next-line:no-debugger
					console.error(error.status);
					console.error(error.message);
					// console.log('--- end of response---');
				}
			)
		);
	}
}
