<!-- begin:: Brand -->
<div class="kt-header__brand   kt-grid__item" [ngClass]="htmlClassService.getClasses('brand', true)" id="kt_header_brand">
	<div class="kt-header__brand-logo">
		<a href="javascript:;" routerLink="/">
			<img alt="logo" [attr.src]="headerLogo" class="kt-header__brand-logo-default"/>
			<img alt="logo" [attr.src]="headerStickyLogo" class="kt-header__brand-logo-sticky"/>
		</a>
	</div>
</div>
<!-- end:: Brand -->
